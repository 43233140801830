/*Kelvin Roger Ang Yap 2017
	krayap@gmail.com
*/
//import on files
Vue.component('KrResetPassword', require('./components/kr-reset-password.vue').default);
Vue.component('KrCalculator', require('./components/kr-calculator.vue').default);
Vue.component('TestStatus', require('./components/test-status.vue').default);
Vue.component('PdfViewer', require('./components/pdf-viewer.vue').default);
Vue.component('ScrollBoxShadow', require('./components/scroll-box-shadow.vue').default);
Vue.component('HighlightToolbar', require('./components/highlight-toolbar.vue').default);
Vue.component('SentimentAnalyzer', require('./components/sentiment-analyzer.vue').default);
Vue.component('LegendGuide', require('./components/legend-guide.vue').default);
