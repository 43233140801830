<template>
    <div>
        {{ result }}
    </div>
</template>

<script>
import Errors from '../../components/forms/errors';

export default {
    data() {
        return {
            result: {},
        };
    },
    created() {
        this.send();
    },
    methods: {
        send() {
            var that = this;
            axios
                .post(`auth/external/tokens`, {
                    url: this.$route.query.url,
                    name: this.$route.query.name,
                })
                .then(function (response) {
                    that.result = response.data;
                    window.location = that.result.url + `?token=${that.result.token}`;
                })
                .catch(function (errors) {
                    that.result = _.get(errors, 'response.data');
                });
        },
    },
};
</script>
