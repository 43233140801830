<template>
    <kr-panel :with-footer="false">
        <template #title>
            <div class="col-xs-12 flexSpaceBetween align-items">
                <h2>Enrollment Settings</h2>
            </div>
        </template>
        <template #content>
            <section class="marginBottom30" :class="{ 'flexOnly alignFlexStart': !isMobileView }">
                <kr-toggle
                    id="isLtiEnabled-toggle"
                    :options="{ reverse: true, showLabel: false }"
                    name="isLtiEnabled"
                    :form="form"
                    class="marginTop5"
                    @changed="updateIsLtiEnabled"
                />
                <div :class="{ 'width82 paddingLeft20': !isMobileView, marginTop10: isMobileView }">
                    <label for="isLtiEnabled-toggle" class="fs-24px marginBottom8"> Enable LTI Integration with my LMS </label>
                    <template v-if="form.model.isLtiEnabled">
                        <p class="marginBottom10">
                            Use the following LTI configuration and credentials to set up the LTI link between your LMS and InteDashboard.
                            <a
                                href="https://help.intedashboard.com/kb-search-results?term=LTI"
                                class="link"
                                target="newTab"
                                aria-label="Click here for more information of on LTI configuration"
                            >
                                More information on LTI configuration
                            </a>
                        </p>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Configuration URL</label>
                                </div>
                                <div>
                                    <p>{{ API_URL }}/lti/config/{{ course.consumerKeyLTI }}</p>
                                </div>
                            </div>

                            <div class="enrollment-settings-actions">
                                <div>
                                    <button
                                        v-clipboard:copy="API_URL + '/lti/config/' + course.consumerKeyLTI"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary"
                                        aria-label="Copy Configuration URL"
                                    >
                                        <i class="fa fa-files-o" :class="{ marginRight8: !isMobileView }" aria-hidden="true" />
                                        <span class="hidden-xs hidden-sm">Copy Link</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Launch URL</label>
                                </div>
                                <div>
                                    <p>{{ API_URL }}/lti/launch/{{ course.consumerKeyLTI }}</p>
                                </div>
                            </div>

                            <div class="enrollment-settings-actions">
                                <div>
                                    <button
                                        v-clipboard:copy="API_URL + '/lti/launch/' + course.consumerKeyLTI"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary"
                                        aria-label="Copy Launch URL"
                                    >
                                        <i class="fa fa-files-o" :class="{ marginRight8: !isMobileView }" aria-hidden="true" />
                                        <span class="hidden-xs hidden-sm">Copy Link</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Consumer Key</label>
                                </div>
                                <div>
                                    <p>{{ course.consumerKeyLTI }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Shared Secret</label>
                                </div>
                                <div>
                                    <p>{{ course.sharedSecretLTI }}</p>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <section v-if="auth.user().canUseExpressSignIn" class="marginBottom30" :class="{ 'flexOnly alignFlexStart': !isMobileView }">
                <kr-toggle
                    id="isSelfSignIn-toggle"
                    :options="{ reverse: true, showLabel: false }"
                    name="isSelfSignIn"
                    :form="form"
                    class="marginTop5"
                    @changed="updateIsSelfSignIn"
                />
                <div :class="{ 'width82 paddingLeft20': !isMobileView, marginTop10: isMobileView }">
                    <label for="isSelfSignIn-toggle" class="fs-24px marginBottom8"> Enable Express Sign In </label>
                    <template v-if="form.model.isSelfSignIn">
                        <p class="paddingBottom10">Students will be able to self-register for this course at the following URL:</p>
                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label"> Express Sign In URL </label>
                                </div>
                                <div>
                                    <p>{{ EXPRESS_SIGN_IN_URL }}</p>
                                </div>
                            </div>

                            <div class="enrollment-settings-actions">
                                <div>
                                    <button
                                        v-clipboard:copy="EXPRESS_SIGN_IN_URL"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary"
                                        aria-label="Copy Express Sign In URL"
                                    >
                                        <i class="fa fa-files-o" :class="{ marginRight8: !isMobileView }" aria-hidden="true" />
                                        <span class="hidden-xs hidden-sm">Copy Link</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Sign In QR Code</label>
                                </div>
                                <div>
                                    <vue-qrcode :value="EXPRESS_SIGN_IN_URL" />
                                </div>
                            </div>
                        </div>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <p>
                                        Students can alternatively sign in to the course from our Sign in Page by choosing "Express Sign in" and then entering
                                        the following Course ID
                                    </p>
                                </div>
                                <div>
                                    <label class="control-label">Course ID</label>
                                </div>
                                <div>
                                    <p>
                                        {{ course.urlCode }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </section>

            <section class="marginBottom30" :class="{ 'flexOnly alignFlexStart': !isMobileView }">
                <kr-toggle
                    id="selfAssignmentEnabled-toggle"
                    :options="{ reverse: true, showLabel: false }"
                    name="selfAssignmentEnabled"
                    :form="form"
                    class="marginTop5"
                    :disabled="!(form.model.isLtiEnabled || form.model.isSelfSignIn) ? true : undefined"
                    @changed="updateIsSelfAssigned"
                />
                <div :class="{ 'width82 paddingLeft20': !isMobileView, marginTop10: isMobileView }">
                    <label for="selfAssignmentEnabled-toggle" class="fs-24px marginBottom8"> Allow students to self enroll into sections and teams </label>
                    <template v-if="!form.model.selfAssignmentEnabled">
                        <p>
                            This option is only available to students to sign in via LTI or Express Sign in. Teachers must explicitly assign sections and teams
                            to students enrolled via manual-mode or bulk CSV uploads.
                        </p>
                        <div><b>Allowed Sections</b></div>
                        <template v-for="section in course.sections" :key="section.uuid">
                            <div class="checkboxField flexOnly align-items">
                                <input
                                    :id="section.name"
                                    v-model="selectedSections"
                                    type="checkbox"
                                    :value="section.uuid"
                                    :disabled="selectedSections.length == 1 && isSectionIncluded(section.uuid) ? true : undefined"
                                    @change="updateIsSelfAssigned"
                                />
                                <label class="fs-18px" :for="section.name"> Section {{ section.name }} </label>
                            </div>
                        </template>
                    </template>
                </div>
            </section>

            <div class="marginBottom30" :class="{ 'flexOnly alignFlexStart': !isMobileView }">
                <kr-toggle
                    id="selfEnrollmentEnabled-toggle"
                    :options="{ reverse: true, showLabel: false }"
                    name="selfEnrollmentEnabled"
                    :form="form"
                    class="marginTop5"
                    @changed="updateSelfEnrollment"
                />
                <div :class="{ 'width82 paddingLeft20': !isMobileView, marginTop10: isMobileView }">
                    <label for="selfEnrollmentEnabled-toggle" class="fs-24px marginBottom8"> Allow teachers to toggle enrollment at the course level </label>
                    <p>Allow students who sign in with LTI v1.3 to self enroll into this course</p>
                </div>
            </div>

            <template v-if="auth.user().canUseExpressSignIn">
                <div v-if="form.model.isSelfSignIn" class="marginBottom30" :class="{ 'flexOnly alignFlexStart': !isMobileView }">
                    <kr-toggle
                        v-if="auth.user().account.paymentMethod != 'Student-Paid'"
                        id="anonymous-toggle"
                        :options="{
                            trueval: false,
                            falseval: true,
                            reverse: true,
                            showLabel: false,
                        }"
                        name="anonymous"
                        :form="form"
                        class="marginTop5"
                        @changed="updateOthers"
                    />
                    <template v-else>
                        <div class="marginRight10" style="width: 60px; padding-top: 8px; padding-left: 15px">
                            <b>Yes</b>
                        </div>
                    </template>
                    <div :class="{ 'width82 paddingLeft20': !isMobileView, marginTop10: isMobileView }">
                        <label for="anonymous-toggle" class="fs-24px marginBottom8"> Require students to set an email and password </label>
                        <p>
                            If this is turned off, students only need to enter their name/nickname to enter the course. Turn this off only if you are running
                            ad-hoc workshops and convenience of access is more important than security.
                            <a
                                href="https://help.intedashboard.com/how-do-i-enable-learners-to-self-register-with-an-email-and-password"
                                class="link"
                                target="newTab"
                                aria-label="click here on how do I enable students to self register with an email and password?"
                            >
                                How do I enable learners to self-register with an email and password?
                            </a>
                        </p>
                        <label for="enrollment-setting-student-email" class="marginTop20">
                            Enter allowed domains, separated by commas.
                            <i class="fw-normal">(If you leave this empty, all domains are accepted.)</i>
                        </label>
                        <textarea
                            v-tooltip="form.model.anonymous ? 'Require students to set an email and password is not endable' : ''"
                            id="enrollment-setting-student-email"
                            v-model="form.model.allowedDomains"
                            placeholder="E.g. intedashboard.com, intelearner.com"
                            :class="{ 'isDisabled hasTooltip': form.model.anonymous }"
                            @blur="updateOthers"
                            @keyup="debouncedSubmit"
                            @paste="updateOthers"
                            :readonly="form.model.anonymous"
                        />
                    </div>
                </div>

                <div v-if="form.model.isSelfSignIn && form.model.anonymous" class="marginBottom30" :class="{ 'flexOnly alignFlexStart': !isMobileView }">
                    <kr-toggle
                        id="isSecretPin-toggle"
                        :options="{ reverse: true, showLabel: false }"
                        name="isSecretPin"
                        :form="form"
                        class="marginTop5"
                        @changed="form.model.isSecretPin == false ? updateOthers() : ''"
                    />
                    <div :class="{ 'width82 paddingLeft20': !isMobileView, marginTop10: isMobileView }">
                        <label for="isSecretPin-toggle" class="fs-24px marginBottom8"> Students are required to enter a secret pin to register </label>
                        <label for="enrollment-setting-pin-code"> Enter a minimum of 6 numeric characters below. </label>
                        <pin-code
                            :tooltipMessage="'Students are <b>NOT</b> required to enter a secret pin to register'"
                            id="enrollment-setting-pin-code"
                            v-model="form.model.secretPin"
                            :disabled="!form.model.isSecretPin ? true : undefined"
                            @completed="updateOthers"
                        />
                        <p
                            :class="{
                                red: form.model.secretPin != null && form.model.secretPin.length < 6,
                                green: form.model.secretPin != null && form.model.secretPin.length >= 6,
                            }"
                        >
                            {{ form.model.secretPin != null ? form.model.secretPin.length : 0 }}
                            character/s &nbsp; 6 characters required
                        </p>
                    </div>
                </div>

                <div v-if="form.model.isSelfSignIn && !form.model.anonymous" class="marginBottom30" :class="{ 'flexOnly alignFlexStart': !isMobileView }">
                    <kr-toggle
                        id="ssoMicrosoft-toggle"
                        :options="{ reverse: true, showLabel: false }"
                        name="ssoMicrosoft"
                        :form="form"
                        class="marginTop5"
                        @changed="updateOthers"
                    />
                    <div :class="{ 'width82 paddingLeft20': !isMobileView, marginTop10: isMobileView }">
                        <label for="ssoMicrosoft-toggle" class="fs-24px marginBottom8"> Enable sign in with a Microsoft Teams Account </label>

                        <label for="enrollment-setting-microsoft-email">
                            Enter allowed domains, separated by commas.
                            <i class="fw-normal">(If you leave this empty, all domains are accepted.)</i>
                        </label>
                        <textarea
                            v-tooltip="!form.model.ssoMicrosoft ? 'Enable sign in with a Microsoft Teams Account is not endable' : ''"
                            id="enrollment-setting-microsoft-email"
                            v-model="form.model.ssoMicrosoftAllowedDomains"
                            :class="{ 'isDisabled hasTooltip': !form.model.ssoMicrosoft }"
                            placeholder="E.g. intedashboard.com, intelearner.com"
                            @blur="updateOthers"
                            @keyup="debouncedSubmit"
                            @paste="updateOthers"
                            :readonly="!form.model.ssoMicrosoft"
                        />
                    </div>
                </div>

                <div v-if="form.model.isSelfSignIn && !form.model.anonymous" class="marginBottom30" :class="{ 'flexOnly alignFlexStart': !isMobileView }">
                    <kr-toggle
                        id="ssoGoogle-toggle"
                        :options="{ reverse: true, showLabel: false }"
                        name="ssoGoogle"
                        :form="form"
                        class="marginTop5"
                        @changed="updateOthers()"
                    />
                    <div :class="{ 'width82 paddingLeft20': !isMobileView, marginTop10: isMobileView }">
                        <label for="ssoGoogle-toggle" class="fs-24px marginBottom8"> Enable sign-in with a Google Classroom Account </label>

                        <label for="enrollment-setting-google-email">
                            Enter allowed domains, separated by commas.
                            <i class="fw-normal">(If you leave this empty, all domains are accepted.)</i>
                        </label>
                        <textarea
                            v-tooltip="!form.model.ssoGoogle ? 'Enable sign-in with a Google Classroom Account is not endable' : ''"
                            id="enrollment-setting-google-email"
                            v-model="form.model.ssoGoogleAllowedDomains"
                            :class="{ 'isDisabled hasTooltip': !form.model.ssoGoogle }"
                            placeholder="E.g. intedashboard.com, intelearner.com"
                            @blur="updateOthers"
                            @keyup="debouncedSubmit"
                            @paste="updateOthers"
                            :readonly="!form.model.ssoGoogle"
                        />
                    </div>
                </div>
            </template>
        </template>
    </kr-panel>
</template>
<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        var auth = new KrAuth();
        let debouncedSubmit = _.debounce(this.updateOthers, 3000);
        return {
            API_URL: auth.credential().API_URL,
            APP_URL: process.env.VUE_APP_APP_URL,
            EXPRESS_SIGN_IN_URL: process.env.VUE_APP_EXPRESS_SIGN_IN_URL,
            auth: auth,
            mode: 'view',
            form: new KrForm({
                fields: {
                    isLtiEnabled: '',
                    anonymous: '',
                    isSelfSignIn: '',
                    allowedDomains: '',
                    isSecretPin: '',
                    secretPin: '',
                    ssoGoogle: '',
                    ssoGoogleAllowedDomains: '',
                    ssoMicrosoft: '',
                    ssoMicrosoftAllowedDomains: '',
                    selfAssignmentEnabled: '',
                    selfEnrollmentEnabled: '',
                },
            }),
            debouncedSubmit: debouncedSubmit,
            latestModelCopy: {},
            selectedSections: [],
        };
    },
    created() {
        this.form.setModel(this.course);
        let replaceValues = (str, params) => str.replace(/(^|\/):(\w+)(?=\/|$)/g, (m, g1, g2) => g1 + (params[g2] || m));
        this.EXPRESS_SIGN_IN_URL = replaceValues(this.EXPRESS_SIGN_IN_URL, { courseID: this.course.urlCode });
        /*Initialize selectedSections*/
        for (var i = 0; i < this.course.sections.length; i++) {
            let section = this.course.sections[i];
            let found = false;
            if (this.course.selfAssignmentBlacklist) {
                found = this.course.selfAssignmentBlacklist.find((element) => element == section.uuid);
            }
            if (!found) {
                this.selectedSections.push(section.uuid);
            }
        }
        this.form.model.isSecretPin = this.course.secretPin ? true : false;
        if (this.course.allowedDomains != null) {
            this.form.model.allowedDomains = this.form.model.allowedDomains.toString();
        }
        if (this.course.ssoGoogleAllowedDomains != null) {
            this.form.model.ssoGoogleAllowedDomains = this.form.model.ssoGoogleAllowedDomains.toString();
        }
        if (this.course.ssoMicrosoftAllowedDomains != null) {
            this.form.model.ssoMicrosoftAllowedDomains = this.form.model.ssoMicrosoftAllowedDomains.toString();
        }
    },
    methods: {
        isSectionIncluded(sectionUuid) {
            return this.selectedSections.includes(sectionUuid);
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        updateIsLtiEnabled() {
            var that = this;
            axios
                .post('/courses/' + this.course.uuid + '/lti', {
                    isLtiEnabled: this.form.model.isLtiEnabled,
                })
                .then(function (response) {
                    that.course.isLtiEnabled = that.form.model.isLtiEnabled;
                    if (!(that.form.model.isLtiEnabled || that.form.model.isSelfSignIn)) {
                        that.form.model.selfAssignmentEnabled = false;
                        that.updateIsSelfAssigned();
                    }
                })
                .catch(function (errors) {});
        },
        updateIsSelfAssigned() {
            var that = this;
            this.form.model.selfAssignmentBlacklist = [];
            if (!this.form.model.selfAssignmentEnabled) {
                this.selectedSections = this.course.sections.map((a) => a.uuid);
            }
            for (var i = 0; i < this.course.sections.length; i++) {
                let section = this.course.sections[i];
                let found = this.selectedSections.find((element) => element == section.uuid);
                if (!found) {
                    this.form.model.selfAssignmentBlacklist.push(section.uuid);
                }
            }
            axios
                .post('/courses/' + this.course.uuid + '/self-assigned', {
                    selfAssignmentEnabled: this.form.model.selfAssignmentEnabled,
                    selfAssignmentBlacklist: this.form.model.selfAssignmentBlacklist,
                })
                .then(function (response) {
                    that.course.selfAssignmentEnabled = that.form.model.selfAssignmentEnabled;
                })
                .catch(function (errors) {});
        },
        updateIsSelfSignIn() {
            var that = this;
            axios
                .post('/courses/' + this.course.uuid + '/express-sign-in', {
                    isSelfSignIn: this.form.model.isSelfSignIn,
                })
                .then(function (response) {
                    that.course.isSelfSignIn = that.form.model.isSelfSignIn;
                    if (!(that.form.model.isLtiEnabled || that.form.model.isSelfSignIn)) {
                        that.form.model.selfAssignmentEnabled = false;
                        that.updateIsSelfAssigned();
                    }
                })
                .catch(function (errors) {});
        },
        updateOthers() {
            var that = this;

            if (this.form.model.anonymous) {
                this.form.model.allowedDomains = '';
                this.form.model.ssoGoogle = false;
                this.form.model.ssoMicrosoft = false;
            }
            if (!this.form.model.ssoGoogle) {
                this.form.model.ssoGoogleAllowedDomains = '';
            }
            if (!this.form.model.ssoMicrosoft) {
                this.form.model.ssoMicrosoftAllowedDomains = '';
            }
            let allowedDomains = [];
            if (this.form.model.allowedDomains != null && this.form.model.allowedDomains.trim().length !== 0) {
                allowedDomains = this.form.model.allowedDomains.split(',');
            }
            let ssoGoogleAllowedDomains = [];
            if (this.form.model.ssoGoogleAllowedDomains != null && this.form.model.ssoGoogleAllowedDomains.trim().length !== 0) {
                ssoGoogleAllowedDomains = this.form.model.ssoGoogleAllowedDomains.split(',');
            }
            let ssoMicrosoftAllowedDomains = [];
            if (this.form.model.ssoMicrosoftAllowedDomains != null && this.form.model.ssoMicrosoftAllowedDomains.trim().length !== 0) {
                ssoMicrosoftAllowedDomains = this.form.model.ssoMicrosoftAllowedDomains.split(',');
            }

            if (!this.form.model.anonymous) {
                this.form.model.isSecretPin = false;
            }
            if (!this.form.model.isSecretPin) {
                this.form.model.secretPin = '';
            }
            if (_.isEqual(this.form.model, this.latestModelCopy)) {
                //console.log('same')
                return;
            } else {
                //console.log('not same')
                this.latestModelCopy = _.cloneDeep(this.form.model);
            }
            axios
                .patch('/courses/' + this.course.uuid + '/express-sign-in', {
                    anonymous: this.form.model.anonymous,
                    allowedDomains: allowedDomains,
                    secretPin: this.form.model.secretPin,
                    ssoGoogle: this.form.model.ssoGoogle,
                    ssoGoogleAllowedDomains: ssoGoogleAllowedDomains,
                    ssoMicrosoft: this.form.model.ssoMicrosoft,
                    ssoMicrosoftAllowedDomains: ssoMicrosoftAllowedDomains,
                })
                .then(function (response) {
                    that.course.anonymous = that.form.model.isSelfSignIn;
                    that.course.allowedDomains = that.form.model.allowedDomains;
                    that.course.secretPin = that.form.model.secretPin;
                    that.course.ssoGoogle = that.form.model.ssoGoogle;
                    that.course.ssoGoogleAllowedDomains = that.form.model.ssoGoogleAllowedDomains;
                    that.course.ssoMicrosoftAllowedDomains = that.form.model.ssoMicrosoftAllowedDomains;
                })
                .catch(function (errors) {});
        },
        updateSelfEnrollment() {
            axios
                .post(`courses/${this.course.uuid}/self-enrollment`, {
                    selfEnrollmentEnabled: this.form.model.selfEnrollmentEnabled,
                })
                .catch(function (errors) {});
        },
        onCopyClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Copied to clipboard',
            });
        },
        onErrorClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Error copying to clipboard',
            });
        },
    },
};
</script>
