<template>
    <div>{{ comment }}</div>
    <div>{{ sentiment }}</div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
var Analyzer = require('natural').SentimentAnalyzer;
var stemmer = require('natural').PorterStemmer;
var analyzer = new Analyzer('English', stemmer, 'afinn');
const score = ref(0);
const { comment } = defineProps({
    comment: {
        type: String,
        default: '',
    },
    modelModifiers: { default: () => ({}) },
});
const evaluateSentiment = () => {
    let tokens = comment ? comment.split(' ') : '';
    score.value = analyzer.getSentiment(tokens);
};
onMounted(() => {
    evaluateSentiment();
});

const sentiment = computed(() => {
    if (score.value > 0.5) return 'Strongly Positive';
    if (score.value > 0) return 'Positive';
    if (score.value === 0) return 'Neutral';
    if (score.value > -0.5) return 'Negative';
    return 'Strongly Negative';
});
</script>
