<template>
    <div class="padding0">
        <div class="evaluation-sub-header">
            <p id="PD-requirements" class="tableStatusTag">Requirements</p>

            <div class="visible-hidden" aria-live="polite">
                {{ computedRequirements.noOfCompleted }} out of {{ computedRequirements.totalRequirements }} requirements completed
            </div>

            <ul class="listStyleNone padding0" aria-labelledby="PD-requirements">
                <li
                    v-if="props.question.settings.shouldDistributePointsWithoutRepeatingValues"
                    :class="{ 'requirements-complete': isDifferentPoints, 'requirements-notComplete': !isDifferentPoints }"
                >
                    <i
                        :class="{
                            'fa-regular fa-circle-check': !isDifferentPoints,
                            'fa-solid fa-circle-check': isDifferentPoints,
                        }"
                        aria-label="Requirement Status"
                        aria-describedby="isDifferentPoints"
                        role="img"
                    />
                    <span id="isDifferentPoints">
                        Must distribute points without repeating values
                        <span class="visible-hidden"> &nbsp;{{ isDifferentPoints ? 'Completed' : 'Not Complete' }} </span>
                    </span>
                </li>

                <li
                    v-if="props.question.settings.shouldDistributeAllPoints"
                    :class="{ 'requirements-complete': availablePoints == 0, 'requirements-notComplete': availablePoints != 0 }"
                >
                    <i
                        :class="{
                            'fa-regular fa-circle-check': availablePoints != 0,
                            'fa-solid fa-circle-check': availablePoints == 0,
                        }"
                        aria-label="Requirement Status"
                        aria-describedby="availablePoints"
                        role="img"
                    />
                    <span id="availablePoints">
                        Must distribute <b>ALL</b> points
                        <span class="visible-hidden"> &nbsp;{{ availablePoints == 0 ? 'Completed' : 'Not Complete' }} </span>
                    </span>
                </li>

                <li
                    v-if="props.question.settings.shouldGiveOneTeammateAboveTenPoints"
                    :class="{ 'requirements-complete': isAboveTen, 'requirements-notComplete': !isAboveTen }"
                >
                    <i
                        :class="{
                            'fa-regular fa-circle-check': !isAboveTen,
                            'fa-solid fa-circle-check': isAboveTen,
                        }"
                        aria-label="Requirement Status"
                        aria-describedby="isAboveTen"
                        role="img"
                    />
                    <span id="isAboveTen">
                        Must give at least one teammate a point above {{ props.question.settings.pointsAllocatedPerMember }}
                        <span class="visible-hidden"> &nbsp;{{ isAboveTen ? 'Completed' : 'Not Complete' }} </span>
                    </span>
                </li>

                <li
                    v-if="props.question.settings.shouldGiveOneTeammateBelowTenPoints"
                    :class="{ 'requirements-complete': isBelowTen, 'requirements-notComplete': !isBelowTen }"
                >
                    <i
                        :class="{
                            'fa-regular fa-circle-check': !isBelowTen,
                            'fa-solid fa-circle-check': isBelowTen,
                        }"
                        aria-label="Requirement Status"
                        aria-describedby="isBelowTen"
                        role="img"
                    />
                    <span id="isBelowTen">
                        Must give at least one teammate a point below {{ props.question.settings.pointsAllocatedPerMember }}&nbsp;<b>(0 is allowed)</b>
                        <span class="visible-hidden"> &nbsp;{{ isBelowTen ? 'Completed' : 'Not Complete' }} </span>
                    </span>
                </li>

                <li
                    v-if="props.question.settings.allowToGiveThemselvesPoints"
                    :class="{
                        'requirements-complete': props.question.settings.allowToGiveThemselvesPoints,
                        'requirements-notComplete': !props.question.settings.allowToGiveThemselvesPoints,
                    }"
                >
                    <i
                        :class="{
                            'fa-regular fa-circle-check': !props.question.settings.allowToGiveThemselvesPoints,
                            'fa-solid fa-circle-check': props.question.settings.allowToGiveThemselvesPoints,
                        }"
                        aria-label="Requirement Status"
                        aria-describedby="allowToGiveThemselvesPoints"
                        role="img"
                    />
                    <span id="allowToGiveThemselvesPoints">
                        Give points to self&nbsp;<b>(0 is allowed)</b>
                        <span class="visible-hidden"> &nbsp;{{ props.question.settings.allowToGiveThemselvesPoints ? 'Completed' : 'Not Complete' }} </span>
                    </span>
                </li>
            </ul>
        </div>

        <div class="padding16">
            <fieldset>
                <legend class="width100 fs-22px flexSpaceBetween align-items">
                    Point Distribution

                    <span class="fs-18px important">
                        <span class="circular-points">
                            <span>{{ availablePoints }}</span>
                        </span>
                        Points Left
                    </span>
                </legend>

                <div v-for="(student, studentIdx) in value" class="col-xs-12 col-lg-5" :class="{ marginTop20: studentIdx + 1 > 1 }">
                    <label :for="'student-name-' + student.receiverId + '-label'" class="flexSpaceBetween">
                        <div class="flexOnly align-items">
                            <div class="profile_image" role="img" :aria-label="'Initial Name ' + globalInitials(getStudentName(student.receiverId))">
                                <div class="badge_profile img-circle">
                                    <span> {{ globalInitials(getStudentName(student.receiverId)) }} </span>
                                </div>
                            </div>
                            <p :id="'student-name-' + student.receiverId" class="fs-18px fw-normal marginLeft10">
                                <span class="visible-hidden"> Teammate name </span> {{ getStudentName(student.receiverId) }}
                            </p>
                        </div>

                        <b class="color-purple fs-20px">
                            <template v-if="student.point == null"> 0 </template>
                            <template v-else>
                                {{ student.point }}
                            </template>
                            <span class="visible-hidden"> Point </span>
                        </b>
                    </label>

                    <cl-slider
                        :slider-idx="'student-name-' + student.receiverId"
                        :slider-name="getStudentName(student.receiverId)"
                        :value-text="'point'"
                        v-model.number="student.point"
                        :show-number-input="false"
                        :is-range-only="false"
                        :include-buttons="true"
                        :set-max="totalPoints"
                        :left-points="availablePoints"
                        :is-disabled="disabled ? true : undefined"
                    />
                </div>
            </fieldset>
        </div>
    </div>
</template>
<script>
export default {
    compatConfig: {
        MODE: 3,
    },
};
</script>
<script setup>
import { ref, computed, onBeforeUpdate } from 'vue';
const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
    testObj: {
        type: Object,
        default: () => ({}),
    },
    question: {
        type: Object,
        default: () => ({}),
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    modelModifiers: { default: () => ({}) },
});
const emit = defineEmits(['update:modelValue']);
const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
const isAboveTen = computed({
    get() {
        for (var i = 0; i < value.value.length; i++) {
            if (value.value[i].point > 10) {
                return true;
            }
        }
        return false;
    },
});
const isBelowTen = computed({
    get() {
        for (var i = 0; i < value.value.length; i++) {
            if (value.value[i].point < 10) {
                return true;
            }
        }
        return false;
    },
});
const isDifferentPoints = computed({
    get() {
        for (var i = 0; i < value.value.length; i++) {
            for (var j = 0; j < value.value.length; j++) {
                if (i != j && value.value[i].point == value.value[j].point) {
                    return false;
                }
            }
        }
        return true;
    },
});
const totalPoints = computed({
    get() {
        if (props.question.settings.pointDistributionStyle == 'fink') {
            return _.get(props.question, 'settings.pointsAllocatedPerMember', 100);
        } else {
            return value.value.length * _.get(props.question, 'settings.pointsAllocatedPerMember', 10);
        }
    },
});

const availablePoints = computed({
    get() {
        let usedPoints = 0;
        for (var i = 0; i < value.value.length; i++) {
            usedPoints += value.value[i].point ? value.value[i].point : 0;
        }
        return totalPoints.value - usedPoints;
    },
});

const totalPointsUsed = computed(() => {
    let newPoints = 0;
    for (var i = 0; i < value.value.length; i++) {
        newPoints += value.value[i].point ? parseInt(value.value[i].point, 10) : 0;
    }
    return newPoints;
});

const getAnswer = () => {
    let studentAnswer = props.question.pointDistributions;
    studentAnswer = studentAnswer ? studentAnswer : [];
    if (studentAnswer.length > 0) {
        return studentAnswer;
    } else {
        let distributions = [];
        for (var i = 0; i < props.testObj.team.length; i++) {
            let student = props.testObj.team[i];
            distributions.push({ receiverId: student.id, point: null });
        }
        return distributions;
    }
};
const canSubmitAnswer = computed(() => {
    if (
        (props.question.settings.shouldDistributePointsWithoutRepeatingValues && !isDifferentPoints.value) ||
        (props.question.settings.shouldDistributeAllPoints && availablePoints.value != 0) ||
        (props.question.settings.shouldGiveOneTeammateAboveTenPoints && !isAboveTen.value) ||
        (props.question.settings.shouldGiveOneTeammateBelowTenPoints && !isBelowTen.value)
    ) {
        return false;
    }
    let savedAnswer = _.map(getAnswer(), 'point');
    let currentAnswer = _.map(value.value, 'point');
    if (_.isEqual(savedAnswer, currentAnswer)) {
        return false;
    }
    return true;
});

const maxReached = (e, index) => {
    if (totalPointsUsed.value >= totalPoints.value) {
        value.value[index].point -= totalPointsUsed.value - totalPoints.value;
        e.preventDefault();
    }
};
const getStudentName = (id) => {
    let idx = _.findIndex(props.testObj.team, function (o) {
        return o.id == id;
    });
    if (idx != -1) {
        return props.testObj.team[idx].displayName;
    } else {
        return '';
    }
};
const setupEventListeners = () => {
    const inputs = document.querySelectorAll("input[type='range']");

    inputs.forEach((input, index) => {
        input.addEventListener('input', (e) => maxReached(e, index));
    });
};

const computedRequirements = computed(() => {
    var noOfCompleted = 0;
    var totalRequirements = 0;

    if (props.question.settings.shouldDistributePointsWithoutRepeatingValues) {
        if (isDifferentPoints.value) {
            noOfCompleted++;
        }
        totalRequirements++;
    }

    if (props.question.settings.shouldDistributeAllPoints) {
        if (availablePoints.value == 0) {
            noOfCompleted++;
        }
        totalRequirements++;
    }

    if (props.question.settings.shouldGiveOneTeammateAboveTenPoints) {
        if (isAboveTen.value) {
            noOfCompleted++;
        }
        totalRequirements++;
    }

    if (props.question.settings.shouldGiveOneTeammateBelowTenPoints) {
        if (isBelowTen.value) {
            noOfCompleted++;
        }
        totalRequirements++;
    }

    if (props.question.settings.allowToGiveThemselvesPoints) {
        if (props.question.settings.allowToGiveThemselvesPoints) {
            noOfCompleted++;
        }
        totalRequirements++;
    }
    return { noOfCompleted, totalRequirements };
});

onBeforeUpdate(() => {
    setupEventListeners();
});
// expose data and method thingy
defineExpose({
    canSubmitAnswer,
});
</script>
