<template>
    <div v-if="component_done_loading">
        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <h2>Status</h2>
            </template>
            <template #content>
                <div
                    v-if="
                        !['ended', 'completed'].includes(testObj.status) ||
                        (['ended', 'completed'].includes(testObj.status) && testObj.activityStatus.ongoing.length != 0)
                    "
                    class="statusProgression"
                >
                    <div class="flexSpaceBetween align-items">
                        <span>
                            %
                            <template v-if="testObj.others.applicationType == 'team'">Teams</template>
                            <template v-else>Students</template> who
                            <template v-if="!['ended', 'completed'].includes(testObj.status)">may time out</template>
                            <template v-else> timed out</template>
                        </span>
                        <span class="fontBold">
                            <span class="blue">{{ testObj.activityStatus.ongoing }}</span>
                            /
                            {{ entered }}
                            <template v-if="testObj.others.applicationType == 'team'">Teams</template>
                            <template v-else>Students</template>
                        </span>
                    </div>
                    <div class="bar">
                        <div class="barProgress colorRedBg whiteText" :style="`width:${round(divide(testObj.activityStatus.ongoing, entered) * 100, 0)}%`">
                            <template v-if="round(divide(testObj.activityStatus.ongoing, entered) * 100, 0) >= 20">
                                {{ round(divide(testObj.activityStatus.ongoing, entered) * 100, 0) }}%
                            </template>
                        </div>
                        <!--<div class="greyText" :style="`width:calc(${100-round(divide(testObj.activityStatus.ongoing.length,entered)*100,0)}%)`"><template  v-if="(100-round(divide(testObj.activityStatus.ongoing.length,entered)*100,0))>=20">{{(100-round(divide(testObj.activityStatus.ongoing.length,entered)*100,0))==100?100:(100-round(divide(testObj.activityStatus.ongoing.length,entered)*100,0))}}%</template></div>-->
                    </div>
                </div>
                <div class="statusProgression">
                    <div class="flexSpaceBetween align-items">
                        <span>
                            %
                            <template v-if="testObj.others.applicationType == 'team'">Teams</template>
                            <template v-else>Students</template>
                            who have answered all questions
                        </span>
                        <span class="fontBold">
                            <span class="blue">{{ testObj.activityStatus.finished }}</span>
                            /
                            {{ entered }}
                            <template v-if="testObj.others.applicationType == 'team'">Teams</template>
                            <template v-else>Students</template>
                        </span>
                    </div>
                    <div class="bar">
                        <div class="barProgress colorYellowBg" :style="`width:${round(divide(testObj.activityStatus.finished, entered) * 100, 0)}%`">
                            <template v-if="round(divide(testObj.activityStatus.finished, entered) * 100, 0) >= 20">
                                {{ round(divide(testObj.activityStatus.finished, entered) * 100, 0) }}%
                            </template>
                        </div>
                        <!--<div class="greyText" :style="`width:calc(${100-round(divide(testObj.activityStatus.finished,entered)*100,0)}%)`"><template v-if="(100-round(divide(testObj.activityStatus.finished,entered)*100,0))>=20">{{(100-round(divide(testObj.activityStatus.finished,entered)*100,0))==100?0:(100-round(divide(testObj.activityStatus.finished,entered)*100,0))}}%</template></div>-->
                    </div>
                </div>
                <div class="statusProgression">
                    <div class="flexSpaceBetween align-items">
                        <span
                            >% <template v-if="testObj.others.applicationType == 'team'">Teams</template><template v-else>Students</template> who have
                            submitted</span
                        >
                        <span class="fontBold">
                            <span class="blue">{{ testObj.activityStatus.submitted }}</span>
                            /
                            {{ entered }}
                            <template v-if="testObj.others.applicationType == 'team'">Teams</template><template v-else>Students</template>
                        </span>
                    </div>
                    <div class="bar">
                        <div class="barProgress colorGreenBg whiteText" :style="`width:${round(divide(testObj.activityStatus.submitted, entered) * 100, 0)}%`">
                            <template v-if="round(divide(testObj.activityStatus.submitted, entered) * 100, 0) >= 20"
                                >{{ round(divide(testObj.activityStatus.submitted, entered) * 100, 0) }}%</template
                            >
                        </div>

                        <!--<div class="greyText" :style="`width:calc(${100-round(divide(testObj.activityStatus.submitted,entered)*100,0)}%)`"><template v-if="(100-round(divide(testObj.activityStatus.submitted,entered)*100,0))>=20">{{(100-round(divide(testObj.activityStatus.submitted,entered)*100,0))==100?0:(100-round(divide(testObj.activityStatus.submitted,entered)*100,0))}}%</template></div>-->
                    </div>
                </div>
                <!--<div class="statusProgression marginTop20">
					<div class="flexSpaceBetween align-items">
						<span>% Avg of questions finished by present students</span>
						<span class="fontBold">
							<span class="blue">{{testObj.activityStatus.questionsAnswered}}</span> / {{testObj.activityStatus.started.length}} students
						</span>
					</div>
					<div class="bar">
						<div class="barProgress colorGreenBg whiteText" :style="`width:${round(divide(testObj.activityStatus.submitted.length,entered)*100,0)}%`"> <template v-if="round(divide(testObj.activityStatus.submitted.length,entered)*100,0)>=20">{{round(divide(testObj.activityStatus.submitted.length,entered)*100,0)}}%</template></div>
						<div class="greyText" :style="`width:calc(${100-round(divide(testObj.activityStatus.submitted.length,entered)*100,0)}%)`"><template v-if="(100-round(divide(testObj.activityStatus.submitted.length,entered)*100,0))>=20">{{(100-round(divide(testObj.activityStatus.submitted.length,entered)*100,0))==100?0:(100-round(divide(testObj.activityStatus.submitted.length,entered)*100,0))}}%</template></div>
					</div>
				</div>-->
            </template>
        </kr-panel>
    </div>
</template>
<script>
export default {
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
            entered: 0,
        };
    },
    watch: {
        testObj: {
            handler: function () {
                this.init();
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.component_done_loading = true;
    },
    methods: {
        divide(up, down) {
            if (down == 0) {
                return 0;
            }
            return up / down;
        },
        init() {
            //this.present = this.testObj.activityStatus.present.length;
            this.entered = this.testObj.activityStatus.entered;
        },
    },
};
</script>

<style>
.pieChartDiv {
    height: 118px;
}

.dashboardStatus .names div,
.dashboardStatus .names div div {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 5px;
}

.dashboardStatus .names div:first-child {
    font-weight: bold;
    font-size: 12px;
    margin-top: 0;
}

.dashboardStatus .names div div:first-child {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 0;
}

.dashboardStatus .details div:first-child {
    font-size: 12px;
    margin-right: 10px;
}

.dashboardStatus .details div:last-child {
    font-weight: bold;
    color: #4a4a4a;
}

.dashboardStatus .details span {
    font-size: 24px;
}

.dashboardStatusBar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}

.dashboardStatusBar .column {
    flex: 1;
    margin-right: 20px;
}

.dashboardStatusBar .column:last-child {
    flex: 1;
    margin-right: 0;
}

.dashboardStatus .details {
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #d8d8d8;
}

.dashboardStatus .details:first-child {
    padding-left: 0;
    padding-right: 10px;
}

.dashboardStatus .details:last-child {
    padding-left: 10px;
    padding-right: 0;
    border-right: 0;
}

.dashboardStatus .namesScroll {
    height: 110px;
    overflow: hidden;
    overflow-y: scroll;
}
</style>
