<template>
    <div v-if="component_done_loading" id="superuser-edit" class="col-xs-12 right-container">
        <div class="pageTitle">
            <h1>Global Settings</h1>
            <p class="col-md-6">
                This screen allows only you, the Super Admin to change the global settings for your institution. Changes are saved automatically.
            </p>
        </div>
        <div class="form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <h2>General Settings</h2>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <settings-form :form="form" @instant-update="submit" @debounced-update="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>

            <kr-panel :with-footer="false">
                <template #title>
                    <h2>Messages</h2>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <set-message-form :form="form" @instant-update="submit" @debounced-update="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>

            <kr-panel :with-footer="false">
                <template #title>
                    <h2>Security Settings</h2>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <security-settings-form :form="form" @instant-update="submit" @debounced-update="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>
            <kr-panel :with-footer="false">
                <template #title>
                    <h2>Default IRAT Settings</h2>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <irat-settings-form :form="form" @instant-update="submit" @debounced-update="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>
            <kr-panel :with-footer="false">
                <template #title>
                    <h2>Default TRAT Settings</h2>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <trat-settings-form :form="form" @instant-update="submit" @debounced-update="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>
            <kr-panel :with-footer="false">
                <template #title>
                    <h2>Default Application Settings</h2>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <application-settings-form :form="form" @instant-update="submit" @debounced-update="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>
            <kr-panel :with-footer="false">
                <template #title>
                    <h2>LTI v1.3 Settings</h2>
                </template>
                <template #content>
                    <div class="marginBottom20">
                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Json URL</label>
                                </div>
                                <div>
                                    <p>{{ ltiConfig.config_url }}</p>
                                </div>
                            </div>

                            <div class="enrollment-settings-actions">
                                <div>
                                    <button
                                        v-clipboard:copy="ltiConfig.config_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary"
                                        aria-label="Copy JSON URL"
                                    >
                                        <i class="fa fa-files-o" :class="{ marginRight8: !isMobileView }" aria-hidden="true" />
                                        <span class="hidden-xs hidden-sm">Copy Link</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Authentication URL</label>
                                </div>
                                <div>
                                    <p>{{ ltiConfig.authentication_url }}</p>
                                </div>
                            </div>

                            <div class="enrollment-settings-actions">
                                <div>
                                    <button
                                        v-clipboard:copy="ltiConfig.authentication_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary"
                                        aria-label="Copy Authentication URL"
                                    >
                                        <i class="fa fa-files-o" :class="{ marginRight8: !isMobileView }" aria-hidden="true" />
                                        <span class="hidden-xs hidden-sm">Copy Link</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Authorization URL</label>
                                </div>
                                <div>
                                    <p>{{ ltiConfig.authorization_url }}</p>
                                </div>
                            </div>

                            <div class="enrollment-settings-actions">
                                <div>
                                    <button
                                        v-clipboard:copy="ltiConfig.authorization_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary"
                                        aria-label="Copy Authorization URL"
                                    >
                                        <i class="fa fa-files-o" :class="{ marginRight8: !isMobileView }" aria-hidden="true" />
                                        <span class="hidden-xs hidden-sm">Copy Link</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Json Webkey URL</label>
                                </div>
                                <div>
                                    <p>{{ ltiConfig.json_webkey_url }}</p>
                                </div>
                            </div>

                            <div class="enrollment-settings-actions">
                                <div>
                                    <button
                                        v-clipboard:copy="ltiConfig.json_webkey_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary"
                                        aria-label="Copy Json Webkey URL"
                                    >
                                        <i class="fa fa-files-o" :class="{ marginRight8: !isMobileView }" aria-hidden="true" />
                                        <span class="hidden-xs hidden-sm">Copy Link</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="enrollment-settings-row">
                            <div>
                                <div>
                                    <label class="control-label">Launch URL</label>
                                </div>
                                <div>
                                    <p>{{ ltiConfig.launch_url }}</p>
                                </div>
                            </div>

                            <div class="enrollment-settings-actions">
                                <div>
                                    <button
                                        v-clipboard:copy="ltiConfig.launch_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary"
                                        aria-label="Copy Launch URL"
                                    >
                                        <i class="fa fa-files-o" :class="{ marginRight8: !isMobileView }" aria-hidden="true" />
                                        <span class="hidden-xs hidden-sm">Copy Link</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-12">
                        <kr-search
                            name="lti_settings_listing"
                            :url="{ url: '/platforms' }"
                            :columns="{
                                record_name: 'display:Platform Name|sortable:false|width:15%',
                                issuer_id: 'display:Platform ID|sortable:false|width:25%|mobile:hide',
                                client_id: 'display:Client ID|sortable:false|width:25%|mobile:hide',
                                action: 'display:Actions|sortable:false|width:132px',
                            }"
                            :options="{
                                search_field: false,
                                lti3_setting: true,
                                action_column: false,
                            }"
                        >
                            <template #upper-right>
                                <div class="hidden-xs hidden-sm">
                                    <router-link v-slot="{ navigate }" :to="{ name: 'settings.lti.create' }" custom>
                                        <button class="btn btn-primary" @click="navigate">
                                            <i class="fa fa-plus marginRight8" aria-hidden="true" />New Platform
                                        </button>
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link v-slot="{ navigate }" v-tooltip="'Add New Platform'" :to="{ name: 'settings.lti.create' }" custom>
                                        <button class="btn btn-primary" @click="navigate">
                                            <i class="fa fa-plus" aria-hidden="true" />
                                        </button>
                                    </router-link>
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    <a class="link" @click.prevent="openPlatformInfoModal(props.model)">{{ props.model.record_name }}</a>
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.issuer_id }}
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.client_id }}
                                </td>
                                <td class="py-9px">
                                    <div class="dropup">
                                        <button
                                            id="viewDropdownMenuButton"
                                            class="btn floatRight"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <router-link
                                                        v-tooltip="'Edit'"
                                                        class="primary-state"
                                                        :to="{
                                                            name: 'settings.lti.edit',
                                                            params: {
                                                                id: props.model.uuid,
                                                            },
                                                        }"
                                                        aria-label="Edit"
                                                    >
                                                        <span> <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit </span>

                                                        <!-- <span>⇧⌘D</span> -->
                                                    </router-link>
                                                </li>

                                                <hr />

                                                <li>
                                                    <a class="danger-state" href="#" aria-label="Delete" @click.prevent="deleteLtiModal(props.model)">
                                                        <span> <i class="fas fa-trash-alt marginRight8" aria-hidden="true" />Delete </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </kr-search>
                    </div>
                    <div class="col-xs-12 col-md-6 marginTop30">
                        <h3 class="fs-20px">Choose which courses students who sign in with LTI v1.3 can enroll into respectively</h3>
                        <table class="table tableBodyFixedHeight-lti-course-list">
                            <thead>
                                <tr>
                                    <th class="selectAllFixedWidth">
                                        <div v-if="courses.length != 0" class="checkboxField justifyCenter align-items">
                                            <input id="selectAllStudents" type="checkbox" :checked="isAllToggled" @click="toggleAll($event)" />
                                            <label for="selectAllStudents" class="paddingTop2 fs-16px"> Select All </label>
                                        </div>
                                    </th>
                                    <th>Courses</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="course in courses">
                                    <tr>
                                        <td class="selectAllFixedWidth">
                                            <div class="checkboxField justifyCenter align-items">
                                                <input :id="'selectedCourse-' + course.uuid" v-model="selectedCourses" type="checkbox" :value="course.uuid" />
                                            </div>
                                        </td>
                                        <td>
                                            <label :for="'selectedCourse-' + course.uuid" class="word-wrap-break-word fw-normal">
                                                {{ course.name }}
                                            </label>
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="courses.length == 0">
                                    <tr>
                                        <td colspan="2" style="text-align: center">
                                            <b>No courses to add</b>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                        <div class="buttonGroup marginTop30">
                            <button
                                class="btn btn-success"
                                :disabled="isEqual(selectedCourses, oldSelectedCourses) ? true : undefined"
                                aria-label="Confirm"
                                @click="updateCourseSelfEnrollment"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true" />Update
                            </button>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>

        <div id="delete-lti-modal" class="modal danger-modal" tabindex="-1" role="dialog" aria-labelledby="deleteLtiModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deleteLtiModal-title" class="modal-title">Delete LTI Setting</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />
                        <h2 class="marginTop40">Delete</h2>

                        <p class="marginTop20">Do you want to continue delete this lti setting?</p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click.prevent="deleteLti()">
                            <i class="fas fa-check marginRight8" aria-hidden="true" />Yes, Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="platformInfoModal" class="modal" data-backdrop="static" role="dialog" aria-labelledby="platformInfoModal-title">
            <div class="modal-dialog modal-lg default-modal">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="platformInfoModal-title" class="modal-title">Platform Details</h2>
                    </div>
                    <div class="modal-body">
                        <h3>Platform: {{ platform.record_name }}</h3>
                        <div class="col-xs-12 marginTop20">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Platform Name </label>
                                        <p class="form-control-static">
                                            {{ platform.record_name }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Platform ID </label>
                                        <p class="form-control-static">
                                            {{ platform.issuer_id }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Client ID </label>
                                        <p class="form-control-static">
                                            {{ platform.client_id }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Deployment ID </label>
                                        <p class="form-control-static">
                                            {{ platform.deployment_id }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Authorization URL </label>
                                        <p class="form-control-static">
                                            {{ platform.authorization_url }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Authentication URL </label>
                                        <p class="form-control-static">
                                            {{ platform.authentication_url }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> JSON Webkey URL </label>
                                        <p class="form-control-static">
                                            {{ platform.json_webkey_url }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Signature Method </label>
                                        <p class="form-control-static">
                                            {{ platform.signature_method }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <router-link
                            v-if="platform.uuid"
                            v-slot="{ navigate }"
                            :to="{
                                name: 'settings.lti.edit',
                                params: { id: platform.uuid },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate"><i class="fa fa-edit marginRight8" aria-hidden="true" />Edit</button>
                        </router-link>
                        <button class="btn btn-danger" @click.prevent="deleteLtiModal(platform)">
                            Delete<i class="fas fa-trash-alt marginLeft8" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        let debouncedSubmit = _.debounce(this.submit, 3000);
        return {
            auth: auth,
            component_done_loading: false,
            superuser: {},
            form: new KrForm({
                fields: {
                    settings: '',
                    coverImage: '',
                },
                props: {},
                urls: {
                    put: `/accounts/${auth.user().account.uuid}/settings`,
                },
            }),
            latestModelCopy: {},
            debouncedSubmit,
            ltiToDelete: {},
            API_URL: process.env.VUE_APP_API_URL,
            ltiConfig: {},
            platform: {},
            courses: {},
            oldSelectedCourses: [],
            selectedCourses: [],
        };
    },
    components: {
        'settings-form': require(`./form-partials/partial.settings.vue`).default,
        'irat-settings-form': require(`./form-partials/partial.irat-settings.vue`).default,
        'trat-settings-form': require(`./form-partials/partial.trat-settings.vue`).default,
        'application-settings-form': require(`./form-partials/partial.application-settings.vue`).default,
        'security-settings-form': require(`./form-partials/partial.security.settings.vue`).default,
        'set-message-form': require(`./form-partials/partial.messages.settings.vue`).default,
    },
    computed: {
        canSend() {
            return true;
        },
        isAllToggled() {
            return this.selectedCourses.length == this.courses.length && this.selectedCourses.length != 0;
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: 'Settings',
            breadcrumb: [{ name: 'Home' }, { name: 'Global Settings' }],
        });
        this.form.model.settings = {
            mfaForTeachers: false,
            mfaForStudents: false,
            maxFailedSignInAttempts: 3,
            allowGenericUsers: false,
            enableLti: false,
            defaultIratSettings: {
                durationDays: 0,
                durationHours: 0,
                durationMinutes: 20,
            },
            defaultTratSettings: {
                points: [5, 3, 1, 0],
                durationDays: 0,
                durationHours: 0,
                durationMinutes: 20,
            },
            defaultApplicationSettings: {
                durationDays: 0,
                durationHours: 0,
                durationMinutes: 20,
            },
            defaultMessagesSettings: {
                message: 'Please be patient. Your activities will appear once your instructors have published them.',
            },
            pronounsEnabled: false,
        };
        this.fetch();
    },
    methods: {
        fetch() {
            var that = this;
            axios.all([axios.get(`/accounts/${that.auth.user().account.uuid}`), axios.get('/lti/1p3/settings'), axios.get('/courses/self-enrollment')]).then(
                axios.spread((account, config, courses) => {
                    if (account.data.data.settings) {
                        if (!account.data.data.settings.hasOwnProperty('defaultIratSettings')) {
                            account.data.data.settings.defaultIratSettings = {
                                durationDays: 0,
                                durationHours: 0,
                                durationMinutes: 20,
                            };
                        }
                        if (!account.data.data.settings.hasOwnProperty('defaultTratSettings') || _.isArray(account.data.data.settings.defaultTratSettings)) {
                            let points = [5, 3, 1, 0];
                            if (_.isArray(account.data.data.settings.defaultTratSettings)) {
                                points = account.data.data.settings.defaultTratSettings;
                            }
                            account.data.data.settings.defaultTratSettings = {
                                points: points,
                                durationDays: 0,
                                durationHours: 0,
                                durationMinutes: 20,
                            };
                        }
                        if (!account.data.data.settings.hasOwnProperty('defaultApplicationSettings')) {
                            account.data.data.settings.defaultApplicationSettings = {
                                durationDays: 0,
                                durationHours: 0,
                                durationMinutes: 20,
                            };
                        }
                        if (!account.data.data.settings.hasOwnProperty('defaultMessagesSettings')) {
                            account.data.data.settings.defaultMessagesSettings = {
                                message: 'Please be patient. Your activities will appear once your instructors have published them.',
                            };
                        }
                        that.form.setModel(account.data.data);
                    }
                    that.ltiConfig = config.data.data;
                    that.courses = courses.data.data;
                    for (var i = 0; i < that.courses.length; i++) {
                        if (that.courses[i].selfEnrollmentEnabled) {
                            that.selectedCourses.push(that.courses[i].uuid);
                        }
                    }
                    that.oldSelectedCourses = _.cloneDeep(that.selectedCourses);
                    that.component_done_loading = true;
                })
            );
        },
        isEqual(arr1, arr2) {
            return _.isEqual(arr1, arr2);
        },
        toggleAll() {
            if (this.isAllToggled) {
                this.selectedCourses = [];
            } else {
                this.selectedCourses = [];
                for (var i = 0; i < this.courses.length; i++) {
                    this.selectedCourses.push(this.courses[i].uuid);
                }
            }
        },
        updateCourseSelfEnrollment() {
            var that = this;
            axios
                .post(`courses/self-enrollment`, {
                    allowCourseSelfEnrollmentUuidList: that.selectedCourses,
                })
                .then(function () {
                    that.oldSelectedCourses = _.cloneDeep(that.selectedCourses);
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Allowed courses have been updated',
                    });
                })
                .catch(function (errors) {});
        },
        deleteLtiModal(model) {
            this.ltiToDelete = model;
            $('#delete-lti-modal').modal('show');
        },
        deleteLti() {
            var that = this;
            axios
                .delete(`/platforms/${this.ltiToDelete.uuid}`)
                .then(function (response) {
                    Events.fire('lti_settings_listing_refresh');
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'LTI setting has been deleted',
                    });
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
            $('#platformInfoModal').modal('hide');
        },
        submit() {
            if (_.isEqual(this.form.model, this.latestModelCopy)) {
                //console.log('same')
                //alert('equal!')
                return;
            } else {
                this.latestModelCopy = _.cloneDeep(this.form.model);
            }
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                }
            });
        },
        onCopyClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Copied to clipboard',
            });
        },
        onErrorClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Error copying to clipboard',
            });
        },
        openPlatformInfoModal(platform) {
            this.platform = platform;
            $('#platformInfoModal').modal('show');
        },
    },
};
</script>
<style scoped lang="scss">
.enrollmentSetting {
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    &:not(first-child) {
        margin-top: 20px;
    }

    label {
        font-size: 24px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 8px;
    }
}

.w-auto {
    width: auto;
}

.dropdown.open #viewDropdownMenuButton {
    margin-bottom: 0;
}

.setting .dropdown-menu {
    top: inherit;
}
.dropup .dropdown-menu {
    margin-bottom: 10px;
}
</style>

<style>
#superuser-edit .table-responsive {
    overflow-x: inherit;
}
</style>
