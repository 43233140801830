<template>
    <div class="create row flex">
        <div class="profileImg">
            <div class="col-xs-12" style="padding: 0">
                <kr-file-picture :form="form" name="avatar" :options="{ width: '200', height: '200' }" />
            </div>
        </div>
        <div class="col-xs-12 col-md-6">
            <div class="col-xs-12 flex">
                <div v-if="isPronounEnabled" class="col-xs-12 col-md-6">
                    <kr-field
                        display="Pronoun"
                        :form="form"
                        name="pronoun"
                        aria-label="pronoun"
                        :options="{
                            placeholder: 'Enter pronoun',
                        }"
                    />
                </div>
            </div>
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        display="First Name"
                        :form="form"
                        name="firstname"
                        aria-label="first name"
                        :options="{
                            placeholder: 'Enter first name',
                            required: true,
                        }"
                    />
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        display="Last Name"
                        :form="form"
                        name="lastname"
                        aria-label="last name"
                        :options="{
                            placeholder: 'Enter last name',
                            required: isLastNameRequired,
                        }"
                    />
                </div>
            </div>
            <div v-if="[2, 3].includes(auth.user().roleId)" class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <kr-field display="Job Title" :form="form" name="jobTitle" aria-label="job title" :options="{ placeholder: 'Enter job title' }" />
                </div>

                <div class="col-xs-12 col-md-6">
                    <kr-select
                        id="academiDiscipline-Select"
                        class="no-error"
                        display="Academic Discipline"
                        name="academicDiscipline"
                        :form="form"
                        :items="kr_var.academicDisciplines"
                        item_value_idx="value"
                        item_desc_idx="description"
                    >
                        <template #option="props">
                            <option value="null" disabled selected>Select your option</option>
                            <template v-for="option in props.model" :key="option.value">
                                <option :value="option.value">
                                    {{ option.description }}
                                </option>
                            </template>
                        </template>
                    </kr-select>
                </div>
            </div>
            <div v-if="form.model.email">
                <div class="form-group">
                    <label id="emailLabel" class="control-label">Email</label>
                    <p class="form-control-static" aria-labelledby="emailLabel">
                        {{ form.model.email }}
                    </p>
                </div>
            </div>
            <div v-if="user.role != 'Student' || (user.role == 'Student' && form.model.email)">
                <kr-change-password :form="form" />
            </div>

            <fieldset>
                <legend class="control-label">Highlight Color</legend>
                <template v-if="colorPicked == '#17244A'">
                    <p :style="`background-color: ${colorPicked} ; color: #fff; display: inline-block;`"><b>Example: </b> <i>This is highlighted text</i></p>
                </template>
                <template v-else>
                    <p :style="`background-color: ${colorPicked} ; color: #222; display: inline-block;`"><b>Example: </b> <i>This is highlighted text</i></p>
                </template>

                <div class="flex">
                    <div v-for="(color, idx) in colors" :key="`${idx}-${color}`" class="colorPicker margin10">
                        <label class="visible-hidden" :for="'highlightColor-' + color"> Highlight Color {{ color }} </label>
                        <input
                            :id="'highlightColor-' + color"
                            v-model="colorPicked"
                            v-tooltip="'Color ' + color"
                            type="radio"
                            name="color-picker"
                            :style="'background:' + color"
                            :value="color"
                        />
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</template>
<script>
import KrAuth from './../../../../components/auth/auth';
export default {
    props: ['form', 'colorPick', 'isPronounEnabled'],
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            user: auth.user(),
            isLastNameRequired: true,
            colors: ['#FFF5BD', '#E2E2E2', '#E4E1FF', '#FFCFCF', '#FFFF00', '#2FFF2F', '#17244A', '#00FEFE', '#b5b5b5'],
            colorPicked: null,
        };
    },
    watch: {
        colorPicked() {
            this.emitSelectedValue();
        },
    },
    created() {
        if (this.form.model.email == null) {
            this.isLastNameRequired = false;
        }

        if (window.localStorage.getItem('highlightColorLocal') == null) {
            this.colorPicked = '#b5b5b5';
        } else {
            this.colorPicked = window.localStorage.getItem('highlightColorLocal');
        }
    },
    methods: {
        emitSelectedValue() {
            this.$emit('selected', this.colorPicked);
        },
    },
};
</script>
