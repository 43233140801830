<template>
    <div class="border1pxGrey padding15 br-3 bg-plain-white">
        <h3 class="marginTop0 fs-18px marginBottom10">Legend</h3>
        <div class="flex">
            <div class="flexOnly marginRight20">
                <div class="marginRight8">
                    <i class="fas fa-minus successColor d-block lh-0-5" aria-hidden="true" />
                    <i class="fas fa-minus newCorrectColor d-block lh-0-5" aria-hidden="true" />
                </div>
                <p>100%</p>
            </div>
            <div class="flexOnly marginRight20">
                <i class="fas fa-minus primaryColor marginRight8" aria-hidden="true" />
                <p>> 50% and 100%</p>
            </div>
            <div class="flexOnly marginRight20">
                <i class="fas fa-minus warningColor marginRight8" aria-hidden="true" />
                <p>≤ 50% and > 0%</p>
            </div>
            <div class="flexOnly">
                <i class="fas fa-minus dangerColor marginRight8" aria-hidden="true" />
                <p>0%</p>
            </div>
        </div>
    </div>
</template>
