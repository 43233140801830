<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="row">
            <div>
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">
                            <h1 class="fontSize16 margin0">Add Students</h1>
                        </div>
                        <div class="col-xs-6 flexRight">
                            <div class="hidden-xs hidden-sm buttonGroupInline">
                                <!-- Manual Enroll -->
                                <!-- <template>
                                    <button class="btn btn-success " :disabled="(createType=='') ? true:undefined">
                                        Confirm 3 Students&nbsp;<i class="fas fa-check"></i>
                                    </button>
                                </template> -->
                                <!-- Generic Users button -->
                                <!-- <template>
                                    <button class="btn btn-success " :disabled="(createType=='') ? true:undefined">
                                        Generate 25 Students&nbsp;<i class="far fa-sync-alt"></i>
                                    </button>
                                </template> -->
                                <router-link v-if="!linked_course_id" v-slot="{ navigate }" :to="{ name: 'students.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                                <template v-else>
                                    <router-link
                                        v-if="step == 1"
                                        v-slot="{ navigate }"
                                        :to="{
                                            name: 'courses.view',
                                            params: { id: linked_course_id },
                                            query: { tab: 'student' },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                        </button>
                                    </router-link>
                                    <button v-else class="btn btn-default" aria-label="Back" @click.prevent="step--">
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </template>
                                <template v-if="step == 1">
                                    <button
                                        class="btn btn-primary"
                                        :disabled="createType == '' ? true : undefined"
                                        aria-label="Next Step"
                                        @click.prevent="processNextStep()"
                                    >
                                        Next Step<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                                    </button>
                                </template>
                                <template v-if="createType == 'manual' || step == 3">
                                    <button v-if="step > 1" class="btn btn-success" aria-label="Confirm" @click.prevent="processNextStep()">
                                        Confirm<i class="fas fa-check marginLeft8" aria-hidden="true" />
                                    </button>
                                </template>
                                <template v-else>
                                    <button v-if="step > 1" class="btn btn-primary" aria-label="Next Step" @click.prevent="processNextStep()">
                                        Next Step<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                                    </button>
                                </template>
                            </div>
                            <div class="hidden-md hidden-lg buttonGroupInline">
                                <!-- Manual Enroll -->
                                <!-- <template>
                                    <button class="btn btn-success " :disabled="(createType=='') ? true:undefined">
                                        <i class="fas fa-check"></i>
                                    </button>
                                </template> -->
                                <!-- Generic Users button -->
                                <!-- <template>
                                    <button class="btn btn-success " :disabled="(createType=='') ? true:undefined">
                                        <i class="far fa-sync-alt"></i>
                                    </button>
                                </template> -->
                                <router-link v-if="!linked_course_id" v-slot="{ navigate }" v-tooltip="'Back'" :to="{ name: 'students.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </button>
                                </router-link>
                                <template v-else>
                                    <router-link
                                        v-if="step == 1"
                                        v-slot="{ navigate }"
                                        v-tooltip="'Back'"
                                        :to="{
                                            name: 'courses.view',
                                            params: { id: linked_course_id },
                                            query: { tab: 'student' },
                                        }"
                                        custom
                                    >
                                        <button class="btn btn-default" @click="navigate">
                                            <i class="fa fa-caret-left" aria-hidden="true" />
                                        </button>
                                    </router-link>
                                    <button v-else class="btn btn-default" aria-label="Back" @click.prevent="step--">
                                        <i class="fa fa-caret-left" aria-hidden="true" />
                                    </button>
                                </template>
                                <template v-if="step == 1">
                                    <button
                                        class="btn btn-primary"
                                        :disabled="createType == '' ? true : undefined"
                                        aria-label="Next Step"
                                        @click.prevent="processNextStep()"
                                    >
                                        <i class="fa fa-caret-right" aria-hidden="true" />
                                    </button>
                                </template>
                                <template v-if="createType == 'manual' || step == 3">
                                    <button v-if="step > 1" class="btn btn-success" aria-label="Next Step" @click.prevent="processNextStep()">
                                        <i class="fas fa-check" aria-hidden="true" />
                                    </button>
                                </template>
                                <template v-else>
                                    <button v-if="step > 1" class="btn btn-primary" aria-label="Next Step" @click.prevent="processNextStep()">
                                        <i class="fa fa-caret-right" aria-hidden="true" />
                                    </button>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <div class="stepsNavi flex align-items">
                            <div class="viewed">
                                <div>
                                    <span class="hidden-xs hidden-sm"><b>STEP</b></span>
                                    <span class="fa-stack">
                                        <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true" />
                                        <strong class="fa-stack-1x"> 1 </strong>
                                    </span>
                                </div>
                                <div class="hidden-xs hidden-sm">Select method</div>
                            </div>
                            <div>
                                <i class="fas fa-long-arrow-alt-right" />
                            </div>
                            <div :class="{ viewed: step > 1 }">
                                <div>
                                    <span class="hidden-xs hidden-sm"><b>STEP</b></span>
                                    <span class="fa-stack">
                                        <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true" />
                                        <strong class="fa-stack-1x"> 2 </strong>
                                    </span>
                                </div>
                                <div class="hidden-xs hidden-sm">Add students</div>
                            </div>
                            <div v-if="createType != 'manual' && createType != ''">
                                <i class="fas fa-long-arrow-alt-right" aria-hidden="true" />
                            </div>
                            <div v-if="createType != 'manual' && createType != ''" :class="{ viewed: step > 2 }">
                                <div>
                                    <span class="hidden-xs hidden-sm"><b>STEP</b></span>
                                    <span class="fa-stack">
                                        <i class="fa fa-circle-o fa-stack-2x" aria-hidden="true" />
                                        <strong class="fa-stack-1x"> 3 </strong>
                                    </span>
                                </div>
                                <div class="hidden-xs hidden-sm">Confirm Students</div>
                            </div>
                        </div>

                        <div class="table-responsive creation">
                            <!-- Select Method -->
                            <template v-if="step == 1">
                                <table class="table" tabindex="0">
                                    <caption class="visible-hidden">
                                        Add Student Method table (Please select 1 option)
                                    </caption>
                                    <thead>
                                        <tr>
                                            <th aria-hidden="true">Select Method</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <div class="content">
                                            <div class="hidden-xs hidden-sm" role="radiogroup">
                                                <div class="flex">
                                                    <button
                                                        class="btn btn-default d-block"
                                                        :class="{
                                                            activated: createType == 'copy',
                                                        }"
                                                        aria-label="Copy & Paste"
                                                        role="radio"
                                                        :aria-checked="createType == 'copy' ? 'true' : 'false'"
                                                        @click="createType = 'copy'"
                                                    >
                                                        <div>
                                                            <i class="fas fa-clipboard" aria-hidden="true" />
                                                        </div>
                                                        <label class="flex align-items justifyCenter marginTop10">
                                                            <i v-if="createType == 'copy'" class="fa fa-check-circle marginRight8" aria-hidden="true" />
                                                            Copy & Paste
                                                        </label>
                                                    </button>

                                                    <button
                                                        class="btn btn-default d-block"
                                                        :class="{
                                                            activated: createType == 'upload',
                                                        }"
                                                        aria-label="File Upload"
                                                        role="radio"
                                                        :aria-checked="createType == 'upload' ? 'true' : 'false'"
                                                        @click="createType = 'upload'"
                                                    >
                                                        <div>
                                                            <i class="fas fa-file-upload" aria-hidden="true" />
                                                        </div>
                                                        <label class="flex align-items justifyCenter marginTop10">
                                                            <i v-if="createType == 'upload'" class="fa fa-check-circle marginRight8" aria-hidden="true" />
                                                            File Upload
                                                        </label>
                                                    </button>

                                                    <button
                                                        class="btn btn-default d-block"
                                                        :class="{
                                                            activated: createType == 'manual',
                                                        }"
                                                        aria-label="Manual Enroll"
                                                        role="radio"
                                                        :aria-checked="createType == 'manual' ? 'true' : 'false'"
                                                        @click="createType = 'manual'"
                                                    >
                                                        <div>
                                                            <i class="fa fa-user-plus" aria-hidden="true" />
                                                        </div>
                                                        <label class="flex align-items justifyCenter marginTop10">
                                                            <i v-if="createType == 'manual'" class="fa fa-check-circle marginRight8" aria-hidden="true" />
                                                            Manual Enroll
                                                        </label>
                                                    </button>

                                                    <button
                                                        v-if="auth.user().account.version == 'full' && auth.user().account.paymentMethod != 'Student-Paid'"
                                                        class="btn btn-default d-block"
                                                        :class="{
                                                            activated: createType == 'generic',
                                                        }"
                                                        aria-label="Generic Users"
                                                        role="radio"
                                                        :aria-checked="createType == 'generic' ? 'true' : 'false'"
                                                        @click="createType = 'generic'"
                                                    >
                                                        <div>
                                                            <i class="fa fa-refresh" aria-hidden="true" />
                                                        </div>
                                                        <label class="flex align-items justifyCenter marginTop10">
                                                            <i v-if="createType == 'generic'" class="fa fa-check-circle marginRight8" aria-hidden="true" />
                                                            Generic Users
                                                        </label>
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="hidden-md hidden-lg">
                                                <div>
                                                    <button
                                                        class="btn btn-default"
                                                        :class="{
                                                            activated: createType == 'copy',
                                                        }"
                                                        aria-label="Copy & Paste"
                                                        @click="createType = 'copy'"
                                                    >
                                                        <template v-if="createType == 'copy'">
                                                            <i class="fa fa-check-circle marginRight8" aria-hidden="true" />
                                                        </template>
                                                        <template v-else>
                                                            <i class="fa fa-circle-o marginRight8" aria-hidden="true" />
                                                        </template>
                                                        <i class="fas fa-clipboard marginRight8" aria-hidden="true" />Copy & Paste
                                                    </button>
                                                    <button
                                                        class="btn btn-default"
                                                        :class="{
                                                            activated: createType == 'upload',
                                                        }"
                                                        aria-label="File Upload"
                                                        @click="createType = 'upload'"
                                                    >
                                                        <template v-if="createType == 'upload'">
                                                            <i class="fa fa-check-circle marginRight8" aria-hidden="true" />
                                                        </template>
                                                        <template v-else>
                                                            <i class="fa fa-circle-o marginRight8" aria-hidden="true" />
                                                        </template>
                                                        <i class="fas fa-file-upload marginRight8" aria-hidden="true" />
                                                        File Upload
                                                    </button>
                                                    <button
                                                        class="btn btn-default"
                                                        :class="{
                                                            activated: createType == 'manual',
                                                        }"
                                                        aria-label="Manual Enroll"
                                                        @click="createType = 'manual'"
                                                    >
                                                        <template v-if="createType == 'manual'">
                                                            <i class="fa fa-check-circle marginRight8" aria-hidden="true" />
                                                        </template>
                                                        <template v-else>
                                                            <i class="fa fa-circle-o marginRight8" aria-hidden="true" />
                                                        </template>
                                                        <i class="fa fa-user-plus marginRight8" aria-hidden="true" />
                                                        Manual Enroll
                                                    </button>
                                                    <button
                                                        v-if="auth.user().account.version == 'full' && auth.user().account.paymentMethod != 'Student-Paid'"
                                                        class="btn btn-default"
                                                        :class="{
                                                            activated: createType == 'generic',
                                                        }"
                                                        aria-label="Generic Users"
                                                        @click="createType = 'generic'"
                                                    >
                                                        <template v-if="createType == 'generic'">
                                                            <i class="fa fa-check-circle marginRight8" aria-hidden="true" />
                                                        </template>
                                                        <template v-else>
                                                            <i class="fa fa-circle-o marginRight8" aria-hidden="true" />
                                                        </template>
                                                        <i class="fa fa-user-plus marginRight8" aria-hidden="true" />
                                                        Generic Users
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </tbody>
                                </table>
                            </template>
                            <!-- Copy and Paste -->
                            <template v-if="step == 2 && createType == 'copy'">
                                <table class="table" tabindex="0">
                                    <caption class="visible-hidden">
                                        Copy & Paste Table
                                    </caption>
                                    <thead>
                                        <tr>
                                            <th aria-hidden="true">Copy & Paste</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="alert alert-primary marginBottom20" role="alert">
                                                    <p>
                                                        Student’s email, first name and last name are
                                                        <span class="important">compulsory</span>, the remaining columns are optional and can be changed later.

                                                        <a
                                                            v-if="auth.user().account.version == 'full'"
                                                            class="btn btn-primary marginLeft8"
                                                            href="#"
                                                            aria-label="Download CSV Template"
                                                            role="button"
                                                            @click.prevent="download('intedashboard-student-upload-template.csv')"
                                                        >
                                                            <i class="fa fa-download marginRight8" aria-hidden="true" />
                                                            CSV Template
                                                        </a>
                                                        <a
                                                            v-else
                                                            class="btn btn-primary marginLeft8"
                                                            href="#"
                                                            aria-label="Download CSV Template"
                                                            role="button"
                                                            @click.prevent="download('intedashboard-student-upload-template-lite.csv')"
                                                        >
                                                            <i class="fa fa-download marginRight8" aria-hidden="true" />
                                                            CSV Template
                                                        </a>
                                                    </p>
                                                </div>
                                                <kr-field
                                                    :form="copy_paste_form"
                                                    class="no-error"
                                                    name="data"
                                                    :options="{
                                                        type: 'text-area',
                                                        hasLabel: false,
                                                        placeholder: 'Copy the student data from excel and paste in this box.',
                                                        rows: 5,
                                                    }"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                            <!-- Upload -->
                            <template v-if="step == 2 && createType == 'upload'">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>File Upload</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <div class="content">
                                            <div class="alert alert-primary marginBottom20" role="alert">
                                                <div>
                                                    Student’s email, first name and last name are
                                                    <span class="important">compulsory</span>, the remaining columns are optional and can be changed
                                                    later.&nbsp;
                                                    <a
                                                        v-if="auth.user().account.version == 'full'"
                                                        class="btn btn-primary"
                                                        href="#"
                                                        @click.prevent="download('intedashboard-student-upload-template.csv')"
                                                    >
                                                        Download CSV Template
                                                    </a>
                                                    <a
                                                        v-else
                                                        class="btn btn-primary"
                                                        href="#"
                                                        @click.prevent="download('intedashboard-student-upload-template-lite.csv')"
                                                    >
                                                        Download CSV Template
                                                    </a>
                                                </div>
                                            </div>
                                            <div v-if="upload_error" class="alert alert-danger marginBottom20" role="alert">
                                                <div v-html="upload_error" />
                                            </div>
                                            <div class="uploadDiv">
                                                <div>
                                                    <div>
                                                        <div>
                                                            <i class="fas fa-file-upload" aria-hidden="true" />
                                                        </div>
                                                        <!-- <div>
                                                            Drag and drop files
                                                            here to upload or
                                                        </div> -->
                                                    </div>
                                                    <button class="btn btn-primary" @click.prevent="openBulkUpload">Upload Here</button>
                                                </div>
                                            </div>
                                        </div>
                                    </tbody>
                                </table>
                            </template>
                            <!-- MANUAL ENROLL -->
                            <template v-if="step == 2 && createType == 'manual'">
                                <table class="table hidden-xs hidden-sm" tabindex="0">
                                    <caption class="visible-hidden">
                                        Manual Enroll Students Table
                                    </caption>
                                    <thead>
                                        <tr>
                                            <th colspan="12" aria-hidden="true">Manual Enroll</th>
                                        </tr>
                                        <tr>
                                            <th>
                                                First Name
                                                <span class="visible-hidden"> required </span>
                                                <span class="important" aria-hidden="true"> * </span>
                                            </th>
                                            <th>
                                                Last name
                                                <span class="visible-hidden"> required </span>
                                                <span class="important" aria-hidden="true"> * </span>
                                            </th>
                                            <th>
                                                Email
                                                <span class="visible-hidden"> required </span>
                                                <span class="important" aria-hidden="true"> * </span>
                                            </th>
                                            <th>Student ID</th>
                                            <th>Section</th>
                                            <th>Team</th>
                                            <th>Remarks</th>
                                            <th>
                                                <p class="visible-hidden">Actions</p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(student, idx) in manual_form.model.students" :key="student + '_' + idx">
                                            <td aria-describedby="first_name_header">
                                                <div :id="'new-row-' + idx + '-alert'" class="visible-hidden" />

                                                <kr-field
                                                    :form="manual_form"
                                                    :name="`students.${idx}.firstname`"
                                                    :options="{
                                                        hasLabel: false,
                                                        id: `firstname-${idx}-md`,
                                                        autocomplete: 'off',
                                                    }"
                                                    :aria-label="`Row ${idx + 1} First Name`"
                                                />
                                            </td>
                                            <td aria-describedby="last_name_header">
                                                <kr-field
                                                    :form="manual_form"
                                                    :name="`students.${idx}.lastname`"
                                                    :options="{
                                                        hasLabel: false,
                                                        id: `lastname-${idx}-md`,
                                                        autocomplete: 'off',
                                                    }"
                                                    :aria-label="`Row ${idx + 1} Last Name`"
                                                />
                                            </td>
                                            <td aria-describedby="email_header">
                                                <kr-field
                                                    :form="manual_form"
                                                    :name="`students.${idx}.email`"
                                                    :options="{
                                                        hasLabel: false,
                                                        id: `email-${idx}-md`,
                                                        type: 'email',
                                                        autocomplete: 'off',
                                                    }"
                                                    :aria-label="`Row ${idx + 1} Email`"
                                                />
                                            </td>
                                            <td aria-describedby="student_id_header">
                                                <kr-field
                                                    :form="manual_form"
                                                    :name="`students.${idx}.studentID`"
                                                    :options="{
                                                        hasLabel: false,
                                                        id: `studentID-${idx}-md`,
                                                        autocomplete: 'off',
                                                    }"
                                                    :aria-label="`Row ${idx + 1} Student ID`"
                                                />
                                            </td>
                                            <td aria-describedby="section_header">
                                                <!--<div class="form-group displayContent">
                                                            <div class="formDropdown">
                                                                <select class="form-control">
                                                                    <option value="-">-</option> 
                                                                </select>
                                                            </div>
                                                        </div>-->
                                                <input
                                                    v-if="auth.user().account.version == 'full'"
                                                    v-model="manual_form.model.students[idx].section"
                                                    class="form-control"
                                                    :aria-label="`Row ${idx + 1} Section`"
                                                />
                                                <template v-else> 1 </template>
                                            </td>
                                            <td aria-describedby="team_header">
                                                <!--<div class="form-group displayContent">
                                                            <div class="formDropdown">
                                                                <select class="form-control">
                                                                    <option value="-">-</option> 
                                                                </select>
                                                            </div>
                                                        </div>-->
                                                <input
                                                    v-model="manual_form.model.students[idx].team"
                                                    class="form-control"
                                                    :aria-label="`Row ${idx + 1} Team`"
                                                />
                                            </td>
                                            <td aria-describedby="remarks_header">
                                                <kr-field
                                                    :form="manual_form"
                                                    :name="`students.${idx}.remarks`"
                                                    :options="{
                                                        hasLabel: false,
                                                        id: `remarks-${idx}-md`,
                                                    }"
                                                    :aria-label="`Row ${idx + 1} Remarks`"
                                                />
                                            </td>
                                            <td aria-describedby="action_header">
                                                <div class="inlineFlex">
                                                    <button
                                                        v-if="manual_form.model.students.length > 1"
                                                        v-tooltip="`Delete row ${idx + 1}`"
                                                        class="btn btn-danger"
                                                        :aria-label="`Delete row ${idx + 1}`"
                                                        @click.prevent="deleteManual(idx)"
                                                    >
                                                        <i class="fas fa-trash-alt" aria-hidden="true" />
                                                    </button>
                                                    <button
                                                        v-if="idx == manual_form.model.students.length - 1"
                                                        v-tooltip="`Add new row`"
                                                        class="btn btn-primary"
                                                        :aria-label="`Add new row`"
                                                        @click.prevent="addMoreManual(idx)"
                                                    >
                                                        <i class="fas fa-plus" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table class="table hidden-md hidden-lg">
                                    <thead>
                                        <tr>
                                            <th>Manual Enroll</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(student, idx) in manual_form.model.students">
                                            <div class="header">
                                                <div class="flexSpaceBetween">
                                                    <div class="flex align-item">
                                                        <label>
                                                            <template v-if="student.displayName">
                                                                {{ student.displayName }}
                                                            </template>
                                                            <template v-else>
                                                                Student
                                                                {{ idx + 1 }}
                                                            </template>
                                                        </label>

                                                        <button
                                                            v-if="manual_form.model.students.length > 1"
                                                            v-tooltip="'Delete'"
                                                            class="btn-empty padding0 marginLeft8"
                                                            :aria-label="'delete ' + student.displayName"
                                                            @click.prevent="deleteManual(idx)"
                                                        >
                                                            <i class="fas fa-trash-alt important fontSize16" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            v-if="!student._hide"
                                                            v-tooltip="'Show Less'"
                                                            class="btn btn-empty padding0"
                                                            aria-label="show less"
                                                            @click.prevent="
                                                                manual_form.model.students[idx]._hide = true;
                                                                manual_form.model.students.splice();
                                                            "
                                                        >
                                                            <i class="fa fa-caret-up" aria-hidden="true" />
                                                        </button>
                                                        <button
                                                            v-if="student._hide"
                                                            v-tooltip="'Show More'"
                                                            class="btn btn-empty padding0"
                                                            aria-label="show more"
                                                            @click.prevent="
                                                                manual_form.model.students[idx]._hide = false;
                                                                manual_form.model.students.splice();
                                                            "
                                                        >
                                                            <i class="fa fa-caret-down" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="!student._hide" class="body">
                                                <div class="col-xs-12">
                                                    <kr-field
                                                        display="First Name"
                                                        :form="manual_form"
                                                        :name="'students.' + idx + '.firstname'"
                                                        aria-label="first name"
                                                        :options="{
                                                            required: true,
                                                        }"
                                                    />
                                                </div>
                                                <div class="col-xs-12">
                                                    <kr-field
                                                        display="Last Name"
                                                        :form="manual_form"
                                                        :name="'students.' + idx + '.lastname'"
                                                        aria-label="last name"
                                                        :options="{
                                                            required: true,
                                                        }"
                                                    />
                                                </div>
                                                <div class="col-xs-12">
                                                    <kr-field
                                                        display="Email"
                                                        :form="manual_form"
                                                        :name="'students.' + idx + '.email'"
                                                        :options="{
                                                            type: 'email',
                                                            required: true,
                                                        }"
                                                        aria-label="email"
                                                    />
                                                </div>
                                                <div class="col-xs-12">
                                                    <kr-field
                                                        display="Student ID"
                                                        :form="manual_form"
                                                        :name="'students.' + idx + '.studentID'"
                                                        aria-label="student ID"
                                                        :options="{
                                                            required: true,
                                                        }"
                                                    />
                                                </div>
                                                <div v-if="auth.user().account.version == 'full'" class="col-xs-12">
                                                    <kr-field
                                                        display="Section"
                                                        :form="manual_form"
                                                        :name="'students.' + idx + '.section'"
                                                        aria-label="section"
                                                        :options="{
                                                            required: true,
                                                        }"
                                                    />
                                                </div>
                                                <div class="col-xs-12">
                                                    <kr-field
                                                        display="Team"
                                                        :form="manual_form"
                                                        :name="'students.' + idx + '.team'"
                                                        aria-label="team"
                                                        :options="{
                                                            required: true,
                                                        }"
                                                    />
                                                </div>
                                                <div class="col-xs-12">
                                                    <kr-field
                                                        display="Remarks"
                                                        :form="manual_form"
                                                        :name="'students.' + idx + '.remarks'"
                                                        aria-label="remarks"
                                                    />
                                                </div>
                                                <!--<div class="col-xs-12 flex">
                                                        <div class="col-xs-6">
                                                            <div class="form-group displayContent">
                                                                <label class="control-label">SECTION</label>
                                                                <div class="formDropdown">
                                                                    <select class="form-control">
                                                                        <option value="-">-</option> 
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-6">
                                                            <div class="form-group displayContent">
                                                                <label class="control-label">TEAM</label>
                                                                <div class="formDropdown">
                                                                    <select class="form-control">
                                                                        <option value="-">-</option> 
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>-->
                                            </div>
                                        </template>
                                    </tbody>
                                </table>
                            </template>
                            <!-- GENERIC USERS -->
                            <template v-if="step == 2 && createType == 'generic'">
                                <table class="table genericUsers">
                                    <thead>
                                        <tr>
                                            <th>Generic Users</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <div class="content">
                                            <div class="col-xs-12 col-md-6">
                                                <kr-field
                                                    display="Prefix"
                                                    :form="generic_form"
                                                    name="prefix"
                                                    :options="{
                                                        required: true,
                                                    }"
                                                />
                                            </div>
                                            <div class="col-xs-12 col-md-6">
                                                <kr-field
                                                    display="No. of Sections"
                                                    :form="generic_form"
                                                    name="noOfSections"
                                                    :options="{
                                                        required: true,
                                                    }"
                                                    aria-label="number of sections"
                                                />
                                            </div>
                                            <div class="col-xs-12 col-md-6">
                                                <!--<div class="form-group displayContent">
                                                    <label class="control-label">NO. OF TEAMS</label>
                                                    <div class="formDropdown">
                                                        <select class="form-control">
                                                            <option value="-">-</option> 
                                                        </select>
                                                    </div>
                                                </div>-->
                                                <kr-select
                                                    display="No. of Teams"
                                                    :form="generic_form"
                                                    name="noOfTeams"
                                                    :items="generic_teams_dropdown"
                                                    item_value_idx="value"
                                                    item_desc_idx="description"
                                                    aria-label="number of teams"
                                                />
                                            </div>
                                            <div class="col-xs-12 col-md-6">
                                                <!--<div class="form-group displayContent">
                                                    <label class="control-label">NO. OF STUDENTS PER TEAM</label>
                                                    <div class="formDropdown">
                                                        <select class="form-control">
                                                            <option value="-">-</option> 
                                                        </select>
                                                    </div>
                                                </div>-->
                                                <kr-select
                                                    display="No. of Students Per Team"
                                                    :form="generic_form"
                                                    name="noOfStudentsPerTeam"
                                                    :items="generic_students_dropdown"
                                                    item_value_idx="value"
                                                    item_desc_idx="description"
                                                    aria-label="number of students per team"
                                                />
                                            </div>
                                        </div>
                                    </tbody>
                                </table>
                            </template>
                            <!-- Confirm Students -->
                            <template v-if="step == 3">
                                <div class="hidden-xs hidden-sm">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th colspan="12">Please Confirm Your Students</th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    First name
                                                    <span class="important" aria-label="required">*</span>
                                                </th>
                                                <th v-if="createType != 'generic'">
                                                    Last name
                                                    <span class="important" aria-label="required">*</span>
                                                </th>
                                                <th v-if="createType != 'generic'">
                                                    Email
                                                    <span class="important" aria-label="required">*</span>
                                                </th>
                                                <th>Student ID</th>
                                                <th>Section</th>
                                                <th>Team</th>
                                                <th>Remarks</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(student, idx) in manual_form.model.students">
                                                <tr>
                                                    <td>
                                                        <input
                                                            v-model="manual_form.model.students[idx].firstname"
                                                            class="form-control"
                                                            :aria-label="'Row ' + idx + ' First Name'"
                                                            @change="addMoreManual(idx)"
                                                        />
                                                    </td>
                                                    <td v-if="createType != 'generic'">
                                                        <input
                                                            v-model="manual_form.model.students[idx].lastname"
                                                            class="form-control"
                                                            :aria-label="'Row ' + idx + ' Last Name'"
                                                            @change="addMoreManual(idx)"
                                                        />
                                                    </td>
                                                    <td v-if="createType != 'generic'">
                                                        <kr-field
                                                            :form="manual_form"
                                                            :name="`students.${idx}.email`"
                                                            :options="{
                                                                hasLabel: false,
                                                                type: 'email',
                                                            }"
                                                            :aria-label="'Row ' + idx + ' Email'"
                                                            @changed="addMoreManual(idx)"
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            v-model="manual_form.model.students[idx].studentID"
                                                            class="form-control"
                                                            :aria-label="'Row ' + idx + ' student ID'"
                                                            @change="addMoreManual(idx)"
                                                        />
                                                    </td>
                                                    <td>
                                                        <!--<div class="form-group displayContent">
                                                            <div class="formDropdown">
                                                                <select class="form-control">
                                                                    <option value="-">-</option> 
                                                                </select>
                                                            </div>
                                                        </div>-->
                                                        <input
                                                            v-model="manual_form.model.students[idx].section"
                                                            class="form-control"
                                                            :aria-label="'Row ' + idx + ' section'"
                                                            @change="addMoreManual(idx)"
                                                        />
                                                    </td>
                                                    <td>
                                                        <!--<div class="form-group displayContent">
                                                            <div class="formDropdown">
                                                                <select class="form-control">
                                                                    <option value="-">-</option> 
                                                                </select>
                                                            </div>
                                                        </div>-->
                                                        <input
                                                            v-model="manual_form.model.students[idx].team"
                                                            class="form-control"
                                                            :aria-label="'Row ' + idx + ' team'"
                                                            @change="addMoreManual(idx)"
                                                        />
                                                    </td>
                                                    <td>
                                                        <kr-field
                                                            :form="manual_form"
                                                            :name="`students.${idx}.remarks`"
                                                            :options="{
                                                                hasLabel: false,
                                                            }"
                                                            :aria-label="'Row ' + idx + ' remarks'"
                                                            @changed="addMoreManual(idx)"
                                                        />
                                                    </td>
                                                    <td>
                                                        <button
                                                            v-if="manual_form.model.students.length > 1"
                                                            v-tooltip="'Delete'"
                                                            class="btn btn-danger"
                                                            :aria-label="'Delete row ' + idx"
                                                            @click.prevent="deleteManual(idx)"
                                                        >
                                                            <i class="fas fa-trash-alt" aria-hidden="true" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Please Confirm Your Students</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(student, idx) in manual_form.model.students">
                                                <div class="header">
                                                    <div class="flexSpaceBetween">
                                                        <div class="displayInlineBlock">
                                                            <label>
                                                                {{ student.displayName }}
                                                            </label>

                                                            <button
                                                                v-if="manual_form.model.students.length > 1"
                                                                v-tooltip="'Delete'"
                                                                class="btn-empty padding0 marginLeft8"
                                                                :aria-label="'delete ' + student.displayName"
                                                                @click.prevent="deleteManual(idx)"
                                                            >
                                                                <i class="fas fa-trash-alt important fontSize16" aria-hidden="true" />
                                                            </button>
                                                        </div>

                                                        <div>
                                                            <button
                                                                v-if="!student._hide"
                                                                v-tooltip="'Show Less'"
                                                                class="btn btn-empty padding0"
                                                                aria-label="show less"
                                                                @click.prevent="
                                                                    manual_form.model.students[idx]._hide = true;
                                                                    manual_form.model.students.splice();
                                                                "
                                                            >
                                                                <i class="fa fa-caret-up" aria-hidden="true" />
                                                            </button>
                                                            <button
                                                                v-if="student._hide"
                                                                v-tooltip="'Show More'"
                                                                class="btn btn-empty padding0"
                                                                aria-label="show more"
                                                                @click.prevent="
                                                                    manual_form.model.students[idx]._hide = false;
                                                                    manual_form.model.students.splice();
                                                                "
                                                            >
                                                                <i class="fa fa-caret-down" aria-hidden="true" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="!student._hide" class="body">
                                                    <div class="col-xs-12">
                                                        <kr-field
                                                            display="First Name"
                                                            :form="manual_form"
                                                            :name="'students.' + idx + '.firstname'"
                                                            aria-label="first name"
                                                            :options="{
                                                                required: true,
                                                            }"
                                                        />
                                                    </div>
                                                    <div v-if="createType != 'generic'" class="col-xs-12">
                                                        <kr-field
                                                            display="Last Name"
                                                            :form="manual_form"
                                                            :name="'students.' + idx + '.lastname'"
                                                            aria-label="last name"
                                                            :options="{
                                                                required: true,
                                                            }"
                                                        />
                                                    </div>
                                                    <div v-if="createType != 'generic'" class="col-xs-12">
                                                        <kr-field
                                                            display="Email"
                                                            :form="manual_form"
                                                            :name="'students.' + idx + '.email'"
                                                            :options="{
                                                                id: `email-${idx}`,
                                                                type: 'email',
                                                                required: true,
                                                            }"
                                                            aria-label="email"
                                                        />
                                                    </div>
                                                    <div class="col-xs-12">
                                                        <kr-field
                                                            v-if="createType != 'generic'"
                                                            display="Student ID"
                                                            :form="manual_form"
                                                            :name="'students.' + idx + '.studentID'"
                                                            aria-label="student id"
                                                            :options="{
                                                                required: true,
                                                            }"
                                                        />
                                                        <div v-else>
                                                            <label class="control-label">Student ID</label>
                                                            <p class="form-control-static">
                                                                {{ manual_form.model.students[idx].studentID }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12">
                                                        <kr-field
                                                            display="Section"
                                                            :form="manual_form"
                                                            :name="'students.' + idx + '.section'"
                                                            aria-label="section"
                                                            :options="{
                                                                required: true,
                                                            }"
                                                        />
                                                    </div>
                                                    <div class="col-xs-12">
                                                        <kr-field
                                                            display="Team"
                                                            :form="manual_form"
                                                            :name="'students.' + idx + '.team'"
                                                            aria-label="team"
                                                            :options="{
                                                                required: true,
                                                            }"
                                                        />
                                                    </div>
                                                    <div class="col-xs-12">
                                                        <kr-field
                                                            display="Remarks"
                                                            :form="manual_form"
                                                            :name="'students.' + idx + '.remarks'"
                                                            aria-label="remarks"
                                                        />
                                                    </div>
                                                    <!--<div class="col-xs-12 flex">
                                                        <div class="col-xs-6">
                                                            <div class="form-group displayContent">
                                                                <label class="control-label">SECTION</label>
                                                                <div class="formDropdown">
                                                                    <select class="form-control">
                                                                        <option value="-">-</option> 
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xs-6">
                                                            <div class="form-group displayContent">
                                                                <label class="control-label">TEAM</label>
                                                                <div class="formDropdown">
                                                                    <select class="form-control">
                                                                        <option value="-">-</option> 
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>-->
                                                </div>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                        </div>
                        <div class="hidden-xs hidden-sm col-md-6 buttonGroupInline marginTop20">
                            <!-- Manual Eroll button -->
                            <!-- <template>
                                <button class="btn btn-success " :disabled="(createType=='') ? true:undefined">
                                    Confirm 3 Students&nbsp;<i class="fas fa-check"></i>
                                </button>
                            </template> -->
                            <!-- Generic Users button -->
                            <!-- <template>
                                <button class="btn btn-success " :disabled="(createType=='') ? true:undefined">
                                    Generate 25 Students&nbsp;<i class="far fa-sync-alt"></i>
                                </button>
                            </template> -->
                            <router-link v-if="!linked_course_id" v-slot="{ navigate }" :to="{ name: 'students.index' }" custom>
                                <button class="btn btn-default" @click="navigate"><i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                            </router-link>
                            <template v-else>
                                <router-link
                                    v-if="step == 1"
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'courses.view',
                                        params: { id: linked_course_id },
                                        query: { tab: 'student' },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                                <button v-else class="btn btn-default" aria-label="Back" @click.prevent="step--">
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                </button>
                            </template>
                            <template v-if="step == 1">
                                <button
                                    class="btn btn-primary"
                                    :disabled="createType == '' ? true : undefined"
                                    aria-label="Next Step"
                                    @click.prevent="processNextStep()"
                                >
                                    Next Step<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                                </button>
                            </template>
                            <template v-if="createType == 'manual' || step == 3">
                                <button v-if="step > 1" class="btn btn-success" aria-label="Confirm" @click.prevent="processNextStep()">
                                    Confirm<i class="fas fa-check marginLeft8" aria-hidden="true" />
                                </button>
                            </template>
                            <template v-else>
                                <button v-if="step > 1" class="btn btn-primary" aria-label="Next Step" @click.prevent="processNextStep()">
                                    Next Step<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                                </button>
                            </template>
                        </div>
                        <div class="hidden-md hidden-lg col-xs-12 buttonGroupInline">
                            <!-- Manual Eroll button -->
                            <!-- <template>
                                <button class="btn btn-success " :disabled="(createType=='') ? true:undefined">
                                    Confirm 3 Students&nbsp;<i class="fas fa-check"></i>
                                </button>
                            </template> -->
                            <!-- Generic Users button -->
                            <!-- <template>
                                <button class="btn btn-success " :disabled="(createType=='') ? true:undefined">
                                    Generate 25 Students&nbsp;<i class="far fa-sync-alt"></i>
                                </button>
                            </template> -->
                            <router-link v-if="!linked_course_id" v-slot="{ navigate }" :to="{ name: 'students.index' }" custom>
                                <button class="btn btn-default" @click="navigate"><i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back</button>
                            </router-link>
                            <template v-else>
                                <router-link
                                    v-if="step == 1"
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'courses.view',
                                        params: { id: linked_course_id },
                                        query: { tab: 'student' },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                    </button>
                                </router-link>
                                <button v-else class="btn btn-default" aria-label="Back" @click.prevent="step--">
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                </button>
                            </template>
                            <template v-if="step == 1">
                                <button
                                    class="btn btn-primary"
                                    :disabled="createType == '' ? true : undefined"
                                    aria-label="Next Step"
                                    @click.prevent="processNextStep()"
                                >
                                    Next Step<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                                </button>
                            </template>
                            <template v-if="createType == 'manual' || step == 3">
                                <button v-if="step > 1" class="btn btn-success" aria-label="Confirm" @click.prevent="processNextStep()">
                                    Confirm<i class="fas fa-check marginLeft8" aria-hidden="true" />
                                </button>
                            </template>
                            <template v-else>
                                <button v-if="step > 1" class="btn btn-primary" aria-label="Next Step" @click.prevent="processNextStep()">
                                    Next Step<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                                </button>
                            </template>
                        </div>

                        <!-- <div class="tab_panel">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="form-group" v-if="!linked_course_id" style="align-items:center;margin-bottom:30px;">
                                        <label style="margin-right:10px;">
                                            ADD STUDENTS TO SELECTED COURSE
                                        </label>
                                        <select id="course_id" class="form-control" @change="changedCourse" style="width:auto;">
                                            <template v-for="course in courses">
                                                <option :value="course.course.uuid">{{course.course.name}}</option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="form-group" v-else>
                                        <label class="control-label text-uppercase">COURSE</label>
                                        <p class="form-control-static">{{course.code?course.code:''}}{{course.name?course.name:''}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="course.uuid">
                                <div class="col-xs-12">
                                    <ul id="myTab" class="nav nav-tabs bar_tabs" role="tablist">
                                        <li role="presentation" class="active">
                                            <a id="copy-tab" data-toggle="tab" href="#copypaste" role="tab" aria-controls="copypaste" aria-selected="true">COPY & PASTE</a>
                                        </li>
                                        <li role="presentation" class="">
                                            <a id="bulk-tab" data-toggle="tab" href="#bulk" role="tab" aria-controls="bulk" aria-selected="false">BULK UPLOAD</a>
                                        </li>
                                        <li role="presentation" class="">
                                            <a id="manual-tab" data-toggle="tab" href="#manual" role="tab" aria-controls="manual" aria-selected="false">MANUALLY ENROLL</a>
                                        </li>
                                        <li role="presentation" class="">
                                            <a id="generic-tab" data-toggle="tab" href="#generic" role="tab" aria-controls="generic" aria-selected="false">GENERIC USERS</a>
                                        </li>
                                        <li role="presentation" class="">
                                            <a id="lti-tab" data-toggle="tab" href="#lti" role="tab" aria-controls="lti" aria-selected="false">LMS INTEGRATION</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade" id="manual" role="tabpanel" aria-labelledby="manual-tab">
                                            <div class="clearfix"></div>
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th>First name <span style="color:red">*</span></th>
                                                            <th>Last name</th>
                                                            <th>Display name</th>
                                                            <th>Email <span style="color:red">*</span></th>
                                                            <th>Student ID</th>
                                                            <th>Team #</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <template v-for="(student,idx) in manual_form.model.data">
                                                            <tr>
                                                                <td><kr-field @changed="addMoreManual(idx)" :options="{hasLabel:false}":form="manual_form" :name="'data.'+idx+'.firstname'"></kr-field></td>
                                                                <td><kr-field @changed="addMoreManual(idx)" :options="{hasLabel:false}":form="manual_form" :name="'data.'+idx+'.lastname'"></kr-field></td>
                                                                <td><kr-field @changed="addMoreManual(idx)" :options="{hasLabel:false}":form="manual_form" :name="'data.'+idx+'.displayName'"></kr-field></td>
                                                                <td><kr-field @changed="addMoreManual(idx)" :options="{hasLabel:false}":form="manual_form" :name="'data.'+idx+'.email'"></kr-field></td>
                                                                <td><kr-field @changed="addMoreManual(idx)" :options="{hasLabel:false}":form="manual_form" :name="'data.'+idx+'.studentID'"></kr-field></td>
                                                                <td><kr-field  @changed="addMoreManual(idx)" :options="{hasLabel:false}":form="manual_form" :name="'data.'+idx+'.team'"></kr-field></td>
                                                                <td><i v-if="idx!=0" style="line-height:35px;color:red" @click.prevent="deleteManual(idx)"class="glyphicon glyphicon-trash"></i></td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-xs-12">
                                                <button class="btn btn-success btn-action" style="width:100%" @click.prevent="submitManual":disabled="(manual_form.isBusy()) ? true:undefined"><span v-if="manual_form.isBusy()" class="glyphicon glyphicon-refresh glyphicon-spin"></span><template v-else> ADD STUDENTS</template></button>
                                            </div>
                                            <br>
                                            
                                        </div>
                                        <div class="tab-pane fade" id="bulk" role="tabpanel" aria-labelledby="bulk-tab">

                                            <div style="text-align:center;padding-top:24px;padding-bottom:24px">
                                                Upload the student data from excel.<br><a href="/public/files/intedashboard-sample-student-upload.xls" download>Click here</a> for excel data format. Student’s email and first name are <span style="color:red">compulsory</span>, the remaining columns are optional and can be changed later.
                                                <br><br>
                                                <button class="btn btn-info" @click.prevent="openBulkUpload">Choose file</button>

                                            </div>
                                        </div>
                                        <div class="tab-pane fade active in" id="copypaste" role="tabpanel" aria-labelledby="copy-tab">
                                            <div class="col-xs-12" style="text-align:center">
                                                <div style=";padding-top:24px;padding-bottom:24px;">
                                                    Copy the student data from excel and paste in below box.<br> <a href="/public/files/intedashboard-sample-student-upload.xls" download>Click here</a> for excel data format. Student’s email and first name are <span style="color:red">compulsory</span>, the remaining columns are optional and can be changed later.
                                                </div>

                                                <kr-field :options="{hasLabel:false,type:'text-area',rows:5,cols:5,placeholder:'Paste students list here'}" :form="copy_paste_form" name="data"></kr-field>
                                            </div>

                                            <div class="col-xs-12">
                                                <button class="btn btn-success btn-action" style="width:100%" @click.prevent="submitCopyPaste":disabled="(copy_paste_form.isBusy()) ? true:undefined"><span v-if="copy_paste_form.isBusy()" class="glyphicon glyphicon-refresh glyphicon-spin"></span><template v-else>SAVE</template></button>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="generic" role="tabpanel" aria-labelledby="generic-tab">
                                            <div class="row"style="padding-top:24px">
                                                <div class="col-xs-12 col-md-6 col-md-offset-3">
                                                    <kr-field display="Prefix" :form="generic_form"name="prefix">                          
                                                    </kr-field>
                                                    <kr-select display="No. of teams" :form="generic_form" name="noOfTeams" :items="generic_teams_dropdown" item_value_idx="value" item_desc_idx="description"></kr-select>
                                                    <kr-select display="No. of students per team" :form="generic_form" name="noOfStudentsPerTeam" :items="generic_students_dropdown" item_value_idx="value" item_desc_idx="description"></kr-select>
                                                </div>
                                                <div class="col-xs-12">
                                                     <button class="btn btn-success btn-action" style="width:100%" @click.prevent="submitGeneric":disabled="(generic_form.isBusy()) ? true:undefined"><span v-if="generic_form.isBusy()" class="glyphicon glyphicon-refresh glyphicon-spin"></span><template v-else>SAVE</template></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="lti" role="tabpanel" aria-labelledby="lti-tab">
                                          <div class="col-xs-6 col-xs-offset-3" style="padding-top:24px;padding-bottom:24px">
                                                <a style="cursor:pointer">Click here</a> to download the LTI integration user-guide.
                                                <br>
                                                <br>
                                                <p><b>LTI configuration URL</b>: {{API_URL}}/lti/config/{{course.consumerKeyLTI}}</p>
                                                <p><b>LTI launch URL</b>: {{API_URL}}/lti/launch/{{course.consumerKeyLTI}}</p>
                                                <p><b>Consumer key</b>: {{course.consumerKeyLTI}}</p>
                                                <p><b>Shared secret</b>: {{course.sharedSecretLTI}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <br>
                                
                                <div class="col-xs-12" style="margin-top:30px">
                                    <h2 style="padding:15px">Students ({{search_data.meta.paginator.total}})</h2>
                                    <kr-search @retrieveData="getSearchData" name="students_listing" :url="{url:'courses/'+course.uuid+'/users'}"
                                        :columns="{
                                            displayName:'display:FULL NAME|sortable:false|width:20%',
                                            email:'display:EMAIL|sortable:false|width:10%',
                                            identity:'display: Student ID|sortable:false',
                                        }"
                                        :options="{trash_field:true,params:{role:4}}"
                                        >
                                        <slot name="listing-top">
                                            &nbsp;
                                        </slot>
                                        <template  slot="cdata" slot-scope="props">
                                            <td>{{props.model.displayName}}</td>
                                            <td>{{props.model.email}}</td>

                                            <td>{{props.model.identity}}</td>
                                            <td style="text-align:right">
                                                 <router-link v-tooltip="{
                                                    content: 'Edit'}" v-if="!props.model.isTrashed" :to="{name:'courses.manage.students.edit',params:{id:props.model.uuid,course_id:$route.params.id}}" class="btn btn-secondary  btn-icon hidden-xs hidden-sm"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></router-link>
                                                    <router-link v-if="!props.model.isTrashed" :to="{name:'courses.manage.students.edit',params:{id:props.model.uuid,course_id:$route.params.id}}"class="btn btn-secondary btn-action hidden-md hidden-lg" style="width:100%;line-height:1;padding:10px;"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</router-link>
                                                <button v-tooltip="{
                                                    content: 'Suspend'}" v-if="!props.model.isTrashed" class="btn btn-danger btn-icon hidden-xs hidden-sm" @click.prevent="removeStudent(props.model.uuid)"><i class="fa fa-trash-o" aria-hidden="true"></i></button>
                                                 <button  v-if="!props.model.isTrashed" class="btn btn-danger btn-action hidden-md hidden-lg" style="width:100%;line-height:1;padding:10px;" @click.prevent="removeStudent(props.model.uuid)"><i class="fa fa-trash-o" aria-hidden="true"></i> Suspend</button>
                                            </td>
                                        </template>                     
                                    </kr-search>
                                </div>    
                            </div>
                        </div> -->
                    </template>
                </kr-panel>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../components/auth/auth';
import KrForm from '../../../components/forms/form';
import * as filestack from 'filestack-js';
import { useDownload } from '@/composables/helpers/useDownload.ts';
export default {
    data() {
        let { download } = useDownload();
        var auth = new KrAuth();
        return {
            download: download,
            step: 1,
            component_done_loading: false,
            auth: auth,
            courses: [],
            course: {},
            linked_course_id: false,
            isFromCourse: false,
            createType: '',
            manual_form: new KrForm({
                fields: {
                    students: 'type!:array',
                    save: 'default!:true',
                    prefix: '',
                    courseUuid: '',
                    isGeneric: 'default!:false',
                },
                urls: {
                    // post:'/courses/'+course.id+'/students',
                },
                props: {
                    //successMessage:false,
                    //manualReset:true
                },
            }),
            generic_form: new KrForm({
                fields: {
                    prefix: '',
                    noOfSections: 'default!:1',
                    noOfTeams: 'default!:5',
                    noOfStudentsPerTeam: 'default!:5',
                    courseUuid: '',
                },
                urls: {
                    //post:'/courses/'+course.id+'/students/generic',
                },
                props: {
                    //successMessage:false,
                    manualReset: true,
                    failureMessage: true,
                },
            }),
            copy_paste_form: new KrForm({
                fields: {
                    courseUuid: '',
                    data: '',
                },
                urls: {
                    //post:'/courses/'+course.id+'/students/paste',
                },
                props: {
                    successMessage: false,
                },
            }),
            generic_teams_dropdown: [],
            generic_students_dropdown: [],
            upload_error: false,
        };
    },
    watch: {
        /*course: {
            handler: function () {
                this.manual_form.model.students = [];
                this.addMoreManual(-1);
            },
            deep: true,
        },*/
        createType: {
            handler: function () {
                var tabName =
                    this.createType == 'generic'
                        ? 'Generic'
                        : this.createType == 'copy'
                        ? 'Copy & Paste'
                        : this.createType == 'manual'
                        ? 'Manual Enroll'
                        : this.createType == 'upload'
                        ? 'File Upload'
                        : '';
                document.title = `${tabName} | Add Students | InteDashboard | TBL Makes Teams Work`;
            },
        },
    },
    created() {
        var that = this;

        if (this.$route.name == 'courses.manage.students.create') {
            this.linked_course_id = _.get(this, '$route.params.course_id', false);
        }

        // if (this.$route.name == "courses.manage.students.create") {
        //   Events.fire("topbar_update", {
        //     title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
        //     breadcrumb: [
        //       { name: "Home" },
        //       { name: "Courses", link: "courses.index" },
        //       {
        //         name: "Course",
        //         link: "courses.view",
        //         params: { id: this.$route.params.course_id },
        //         query: { tab: "student" },
        //       },
        //       { name: "Create Students" },
        //     ],
        //   });
        //   this.linked_course_id = _.get(this, "$route.params.course_id", false);
        //   this.isFromCourse = true;
        // } else {
        //   Events.fire("topbar_update", {
        //     title: '<i class="fas fa-users"></i>&nbsp;Students',
        //     breadcrumb: [
        //       { name: "Home" },
        //       { name: "Students", link: "students.index" },
        //       { name: "Update Student" },
        //     ],
        //   });
        // }

        for (var i = 1; i <= 100; i++) {
            this.generic_teams_dropdown.push({ value: i, description: i });
            if (i <= 10) {
                this.generic_students_dropdown.push({
                    value: i,
                    description: i,
                });
            }
        }
        axios.get('/courses/' + that.linked_course_id).then(function (response) {
            that.courses = [response.data.data];
            that.course = response.data.data;
            that.manual_form.model.courseUuid = that.course.uuid;
            that.addMoreManual(-1);

            if (that.$route.name == 'courses.manage.students.create') {
                let codeStr = '';
                if (that.course.code) {
                    codeStr = ' (' + that.course.code + ')';
                }

                Events.fire('topbar_update', {
                    title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
                    breadcrumb: [
                        { name: 'Home' },
                        { name: 'Courses', link: 'courses.index' },
                        {
                            name: that.course.name + codeStr,
                            link: 'courses.view',
                            params: { id: that.$route.params.course_id },
                            query: { tab: 'student' },
                        },
                        { name: 'Add Students' },
                    ],
                });
                that.isFromCourse = true;
            } else {
                Events.fire('topbar_update', {
                    title: '<i class="fas fa-users"></i>&nbsp;Students',
                    breadcrumb: [{ name: 'Home' }, { name: 'Students', link: 'students.index' }, { name: 'Update Student' }],
                });
            }

            that.component_done_loading = true;
        });

        /*axios.get('/courses?limit=10000').then(function(response){
            that.courses = response.data.data;
            if(!that.linked_course_id){
                that.course = that.courses[0].course;
            }else{
                that.course = _.find(that.courses,function(o){
                    return o.course.uuid == that.linked_course_id;
                }).course;
            }
            that.addMoreManual(-1);
            that.component_done_loading = true;
        });*/
        document.title = 'Add Students | InteDashboard | TBL Makes Teams Work';
    },
    methods: {
        processNextStep() {
            if (this.step == 1) {
                this.step++;
                return;
            }
            if (this.step == 2) {
                if (this.createType == 'generic') {
                    this.submitGeneric();
                }
                if (this.createType == 'copy') {
                    this.submitCopyPaste();
                }
                if (this.createType == 'manual') {
                    this.submitManual();
                }

                return;
            }
            if (this.step == 3) {
                this.submitManual();
                return;
            }
        },
        changedCourse() {
            let course_id = $('#course_id').val(); // The value of the selected option
            this.course = _.find(this.courses, function (o) {
                return o.course.uuid == course_id;
            }).course;
        },
        /*removeStudent(id){

            var success=function(){
                var that = this;
                this.is_busy_remove = false;
                //this.$router.push({ name: 'portfolio'});
                Events.fire('students_listing_refresh');
            }.bind(this);
            var del = function(){
                this.is_busy_remove = true;
                axios.post('/students/remove',{courseUuid:this.course.uuid,userUuid:id}).then(function(){
                    success();
            });
            }.bind(this);
            return swal({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, remove!',
                  confirmButtonColor: '#bf5329',
            }).then(del);
        },*/
        submitGeneric() {
            var that = this;
            this.generic_form.model.courseUuid = this.course.uuid;
            this.generic_form.post('/students/store-generic').then(function (response) {
                if (!response.krFormError) {
                    if (that.linked_course_id) {
                        that.manual_form.model.students = [];
                        for (var i = 0; i < response.data.length; i++) {
                            let student = response.data[i];
                            that.manual_form.model.students.push({
                                remarks: '',
                                firstname: student.firstname,
                                lastname: student.lastname,
                                displayName: student.displayName,
                                email: student.email,
                                identity: student.identity,
                                team: student.team,
                                section: student.section,
                                studentID: student.studentID,
                                courseUuid: that.course.uuid,
                                _hide: false,
                            });
                        }
                        that.step++;
                        //that.$router.push({name:'courses.view',params:{id:that.$route.params.course_id},query:{tab:'student'}});
                    }
                }

                var notificationContent = '';
                var updateForNPrefix = '';
                var updateForNumberOfSection = '';

                if (response.krFormError) {
                    if (response.data.errors.noOfSections) {
                        updateForNumberOfSection = response.data.errors.noOfSections.toString().replace('no', 'number');
                    }

                    if (response.data.errors.prefix) {
                        updateForNPrefix = response.data.errors.prefix.toString();
                    }
                    notificationContent = `Please check the field/s <b> ${'<br>' + updateForNPrefix} </b> <b> ${'<br>' + updateForNumberOfSection} </b>`;

                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: notificationContent,
                    });
                }
            });
        },
        submitCopyPaste() {
            var that = this;
            this.copy_paste_form.model.courseUuid = this.course.uuid;
            this.copy_paste_form.post('/students/store-pasted').then(function (response) {
                if (!response.krFormError) {
                    if (that.linked_course_id) {
                        that.manual_form.model.students = [];
                        for (var i = 0; i < response.data.length; i++) {
                            let student = response.data[i];
                            let section = that.auth.user().account.version != 'full' ? '1' : student.section;
                            that.manual_form.model.students.push({
                                remarks: student.remarks,
                                firstname: student.firstname,
                                lastname: student.lastname,
                                displayName: student.displayName,
                                email: student.email,
                                identity: student.identity,
                                studentID: student.studentID,
                                courseUuid: that.course.uuid,
                                section: section,
                                team: student.team,
                                _hide: false,
                            });
                        }
                        that.step++;
                        //that.$router.push({name:'courses.view',params:{id:that.$route.params.course_id},query:{tab:'student'}});
                    }
                }
            });
        },
        submitManual() {
            var errors = {};
            var hasError = false;
            var that = this;
            var total_length = this.manual_form.model.students.length;
            if (
                total_length > 1 &&
                !this.manual_form.model.students[total_length - 1].firstname &&
                !this.manual_form.model.students[total_length - 1].lastname &&
                !this.manual_form.model.students[total_length - 1].displayName &&
                !this.manual_form.model.students[total_length - 1].email &&
                !this.manual_form.model.students[total_length - 1].studentID &&
                !this.manual_form.model.students[total_length - 1].team &&
                !this.manual_form.model.students[total_length - 1].section &&
                !this.manual_form.model.students[total_length - 1].remarks
            ) {
                this.manual_form.model.students = this.manual_form.model.students.slice(0, total_length - 1);
            }
            for (var i = 0; i < this.manual_form.model.students.length; i++) {
                this.manual_form.model.students[i].displayName =
                    this.manual_form.model.students[i].firstname + this.manual_form.model.students[i].lastname
                        ? ' ' + this.manual_form.model.students[i].lastname
                        : '';
            }
            /*for(var i = 0 ;i<this.manual_form.model.students.length;i++){
        		if(!this.manual_form.model.students[i].firstname){
        			errors['students.'+i+'.firstname'] =['Firstname field is required'];
        			hasError = true;
        		}
        		if(!this.manual_form.model.students[i].email){
        			errors['students.'+i+'.email'] =['Email field is required'];
        			hasError = true;
        		}
        	}*/
            if (this.createType == 'generic') {
                this.manual_form.model.prefix = this.generic_form.model.prefix;
                this.manual_form.model.isGeneric = true;
            } else {
                this.manual_form.model.prefix = '';
                this.manual_form.model.isGeneric = false;
            }
            this.manual_form.post('/students/store-manual').then(function (response) {
                if (!response.krFormError) {
                    /*that.manual_form.model.data=[];
                    that.addMoreManual(-1);*/
                    if (that.linked_course_id) {
                        that.$router.push({
                            name: 'courses.view',
                            params: { id: that.$route.params.course_id },
                            query: {
                                tab: 'student',
                                from: 'students.create',
                            },
                        });
                    }
                }
                /*for(var i = 0;i<response.data.length;i++){
                    if(!response.data[i].error){
                        that.manual_form.model.students.splice(i,1);
                    }else{
                        hasError = true;
                        if(response.data[i].status==409){
                            errors['students.'+i+'.email'] =['This email is already in use'];
                        }
                    }
                }*/
            });
            //check if firstname and lastname are complete
        },
        addMoreManual(idx) {
            var that = this;
            var columnHasData =
                idx == -1
                    ? true
                    : this.manual_form.model.students[idx].firstname != '' ||
                      this.manual_form.model.students[idx].lastname != '' ||
                      this.manual_form.model.students[idx].email != '' ||
                      this.manual_form.model.students[idx].studentID != '' ||
                      this.manual_form.model.students[idx].section != '' ||
                      this.manual_form.model.students[idx].team != '' ||
                      this.manual_form.model.students[idx].remarks != ''
                    ? true
                    : false;

            //return
            // if (
            //     idx + 1 == this.manual_form.model.students.length &&
            //     columnHasData
            // )

            if (idx + 1 == this.manual_form.model.students.length) {
                let section = this.auth.user().account.version != 'full' ? '1' : '';
                this.manual_form.model.students.push({
                    remarks: '',
                    firstname: '',
                    lastname: '',
                    displayName: '',
                    email: '',
                    studentID: '',
                    team: '',
                    section: section,
                    courseUuid: that.course.uuid,
                    _hide: false,
                });

                $('#new-row-' + idx + '-alert').attr('role', 'alert');
                $('#new-row-' + idx + '-alert').html('New Row added');
            }
        },
        deleteManual(idx) {
            this.manual_form.model.students.splice(idx, 1);
        },
        openBulkUpload() {
            var client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            var that = this;
            client
                .picker({
                    accept: ['.csv', 'text/csv'],
                    maxFiles: 1,
                    minFiles: 1,
                    uploadInBackground: false,
                    onFileUploadFinished: function (file) {
                        axios
                            .post('/students/store-uploaded', {
                                data: file,
                                courseUuid: that.course.uuid,
                            })
                            .then(function (response) {
                                that.upload_error = false;
                                if (that.linked_course_id) {
                                    that.manual_form.model.students = [];
                                    for (var i = 0; i < response.data.length; i++) {
                                        let student = response.data[i];
                                        let section = that.auth.user().account.version != 'full' ? '1' : student.section;
                                        that.manual_form.model.students.push({
                                            remarks: student.remarks,
                                            firstname: student.firstname,
                                            lastname: student.lastname,
                                            displayName: student.displayName,
                                            email: student.email,
                                            identity: student.identity,
                                            studentID: student.studentID,
                                            courseUuid: that.course.uuid,
                                            section: section,
                                            team: student.team,
                                            _hide: false,
                                        });
                                    }
                                    that.step++;
                                    //that.$router.push({name:'courses.view',params:{id:that.$route.params.course_id},query:{tab:'student'}});
                                }
                            })
                            .catch(function (errors) {
                                that.upload_error = _.get(errors, 'response.data.errors.data.0', 'Something went wrong, please try again later');
                            });
                    },
                })
                .open();
        },
    },
};
</script>
