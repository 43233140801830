<template>
    <div v-if="component_done_loading">
        <div class="password setPassword">
            <kr-field
                display="Enter Current Password"
                :form="form"
                name="old_password"
                :tooltip-message="
                    auth.credential().isUsingTemporaryPassword && !auth.credential().password_modal_shown
                        ? 'You recently requested a temporary password; please use that.'
                        : ''
                "
                :options="{
                    type: 'password',
                    placeholder: '',
                    hideHelpBlockIfNoErrors: true,
                    required: true,
                    newPasswordField: true,
                }"
            >
            </kr-field>
        </div>
        <div class="marginTop30 password setPassword">
            <kr-field
                display="Enter New Password"
                :form="form"
                name="password"
                :options="{
                    type: 'password',
                    placeholder: 'E.g. P@ssw0rd4321',
                    hideHelpBlockIfNoErrors: true,
                    required: true,
                    newPasswordField: true,
                    describedby: 'passwordHelp',
                }"
                @keyup="passwordStrength"
            >
            </kr-field>
        </div>

        <div class="visible-hidden" aria-live="polite">
            <template v-if="totalFlag == totalRequirements">
                {{ computedRequirementsCompleted }}
            </template>
            <template v-else> {{ totalFlag }} out of {{ totalRequirements }} requirements completed </template>
        </div>

        <div id="passwordHelp" class="marginTop10">
            <p class="control-label">Password must contain:</p>
            <ul class="listStyleNone padding0">
                <li id="lengthFlag" :class="{ correct: lengthFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !lengthFlag,
                            'fa-solid fa-circle-check': lengthFlag,
                        }"
                        :aria-label="lengthFlag ? 'Completed' : 'Not Complete'"
                        aria-describedby="lengthFlag"
                        role="img"
                    />
                    At least {{ minChars }} characters long
                </li>
                <li id="capitalFlag" :class="{ correct: capitalFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !capitalFlag,
                            'fa-solid fa-circle-check': capitalFlag,
                        }"
                        :aria-label="capitalFlag ? 'Completed' : 'Not Complete'"
                        aria-describedby="capitalFlag"
                        role="img"
                    />
                    At least one capital letter
                </li>
                <li id="lowerFlag" :class="{ correct: lowerFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !lowerFlag,
                            'fa-solid fa-circle-check': lowerFlag,
                        }"
                        :aria-label="lowerFlag ? 'Completed' : 'Not Complete'"
                        aria-describedby="lowerFlag"
                        role="img"
                    />
                    At least one lowercase letter
                </li>
                <li id="usernameFlag" :class="{ correct: usernameFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !usernameFlag,
                            'fa-solid fa-circle-check': usernameFlag,
                        }"
                        :aria-label="usernameFlag ? 'Completed' : 'Not Complete'"
                        aria-describedby="usernameFlag"
                        role="img"
                    />
                    Should not contain your username/email
                </li>
                <li id="numberFlag" :class="{ correct: numberFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !numberFlag,
                            'fa-solid fa-circle-check': numberFlag,
                        }"
                        :aria-label="numberFlag ? 'Completed' : 'Not Complete'"
                        aria-describedby="numberFlag"
                        role="img"
                    />
                    At least one number
                </li>
                <li id="symbolFlag" :class="{ correct: symbolFlag }">
                    <i
                        class="marginRight8"
                        :class="{
                            'fa-regular fa-circle-check': !symbolFlag,
                            'fa-solid fa-circle-check': symbolFlag,
                        }"
                        :aria-label="symbolFlag ? 'Completed' : 'Not Complete'"
                        aria-describedby="symbolFlag"
                        role="img"
                    />
                    At least one special character
                </li>
            </ul>
        </div>

        <span v-if="!isStrengthSufficient" class="password">
            <div class="form-group marginTop30 marginBottom30">
                <label for="retypeNewPasswordField" class="control-label">
                    <span>Retype New Password</span>
                    <span class="visible-hidden">Required</span>
                    <span class="important" aria-hidden="true">*</span>
                </label>
                <input
                    v-tooltip="'Insufficient Strength for new password'"
                    id="retypeNewPasswordField"
                    type="password"
                    class="form-control disabled hasTooltip"
                    placeholder="Re-enter password here"
                    aria-disabled="true"
                    readOnly
                />
            </div>
        </span>
        <span v-else class="password">
            <kr-field
                class="marginTop30"
                display="Retype New Password"
                :form="form"
                name="password_confirmation"
                aria-label="retype new password"
                :options="{
                    type: 'password',
                    placeholder: 'Re-enter password here',
                    required: true,
                    newPasswordField: true,
                }"
                @keyup="isConfirmationMatch"
            ></kr-field>
        </span>
    </div>
</template>

<script>
import KrAuth from '../../../components/auth/auth';
export default {
    props: ['form', 'options'],
    data() {
        let auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            usernameFlag: 1,
            capitalFlag: 0,
            lengthFlag: 0,
            numberFlag: 0,
            spaceFlag: 0,
            lowerFlag: 0,
            symbolFlag: 0,
            strengthLabel: '',
            openPopover: false,
            requireUppercase: true,
            requireLowercase: true,
            requireSpecialChar: true,
            requireNumber: true,
            requireSpace: false,
            requireLength: true,
            requireNotUsername: auth.isLoggedIn(),
            minChars: 12,
            maxChars: 30,
            isStrengthSufficient: false,
            confirmation_match_error_function: null,
            totalFlag: 0,
            totalRequirements: 0,
        };
    },
    created() {
        var that = this;
        this.confirmation_match_error_function = _.debounce(function (type = 'append') {
            if (type == 'append') {
                this.form.errors.append('password_confirmation', ["Passwords don't match"]);
            } else {
                this.form.errors.clear('password_confirmation');
            }
        }, 200);
        this.password_strength_error_function = _.debounce(function (type = 'append') {
            if (type == 'append') {
                //this.form.errors.append('password',['Password is too weak']);
            } else {
                this.form.errors.clear('password');
            }
        }, 200);
        //check if query in api url.
        //let url = _.get(this.$route,'query.api_url','');
        //url +='/security-settings';
        //axios.get(url).then(function(response){
        //let temp = response.data;
        // that.requireUppercase = temp.requireUppercase;
        // that.requireLowercase = temp.requireLowerCase;
        // that.requireSpecialChar = temp.requireSpecialChar;
        // that.requireNumber = temp.requireNumber;
        // that.requireSymbol = temp.requireSymbol;
        // that.minChars = temp.minChars;
        // that.maxChars = temp.maxChars;
        //that.component_done_loading = true;
        //});
        this.component_done_loading = true;
    },
    methods: {
        isConfirmationMatch() {
            var str = document.getElementById('password_confirmation').value;
            if (str != this.form.model.password) {
                this.confirmation_match_error_function();
                return false;
            }
            this.confirmation_match_error_function('clear');
            return true;
        },
        passwordStrength() {
            this.capitalFlag = 0;
            this.lengthFlag = 0;
            this.numberFlag = 0;
            this.spaceFlag = 0;
            this.lowerFlag = 0;
            this.symbolFlag = 0;
            this.usernameFlag = 0;
            var labels = ['', 'Weak', 'Fair', 'Almost', 'Pass'];
            var splChars = '*|,":<>[]{}`\';()@&$#%-!^_?/';
            var str = document.getElementById('password').value;
            if (str.length >= this.minChars) {
                this.lengthFlag = 1;
            }
            for (var i = 0; i < str.length; i++) {
                let c = str.charAt(i);
                if (c >= '0' && c <= '9') {
                    this.numberFlag = 1;
                } else if (splChars.indexOf(c) != -1) {
                    this.symbolFlag = 1;
                } else if (c === c.toUpperCase()) {
                    this.capitalFlag = 1;
                } else if (c === c.toLowerCase()) {
                    this.lowerFlag = 1;
                }
            }

            if (str.length > 0) {
                if (str.trim().length == str.length) {
                    this.spaceFlag = 1;
                    this.passwordCompleted++;
                }
                if (!this.auth.user().email || !str.toLowerCase().includes(this.auth.user().email.toLowerCase())) {
                    this.usernameFlag = 1;
                    this.passwordCompleted++;
                }
            }

            this.totalFlag = 0;
            this.totalRequirements = 0;

            if (this.requireUppercase) {
                this.totalFlag += this.capitalFlag;
                this.totalRequirements++;
            }
            if (this.requireNumber) {
                this.totalFlag += this.numberFlag;
                this.totalRequirements++;
            }
            if (this.requireLength) {
                this.totalFlag += this.lengthFlag;
                this.totalRequirements++;
            }
            if (this.requireSpace) {
                this.totalFlag += this.spaceFlag;
                this.totalRequirements++;
            }
            if (this.requireLowercase) {
                this.totalFlag += this.lowerFlag;
                this.totalRequirements++;
            }
            if (this.requireSpecialChar) {
                this.totalFlag += this.symbolFlag;
                this.totalRequirements++;
            }
            if (this.requireNotUsername) {
                this.totalFlag += this.usernameFlag;
                this.totalRequirements++;
            }

            if (this.totalFlag / this.totalRequirements == 0) {
                this.strengthLabel = labels[0];
            } else if (this.totalFlag / this.totalRequirements < 0.26) {
                this.strengthLabel = labels[1];
            } else if (this.totalFlag / this.totalRequirements < 0.51) {
                this.strengthLabel = labels[2];
            } else if (this.totalFlag / this.totalRequirements < 0.76) {
                this.strengthLabel = labels[3];
            } else if (this.totalFlag / this.totalRequirements == 1) {
                this.strengthLabel = labels[4];
            }

            if (this.totalFlag == this.totalRequirements) {
                this.password_strength_error_function('clear');
                this.isStrengthSufficient = true;
                return true;
            } else {
                if (str.length > 0) {
                    this.password_strength_error_function();
                } else {
                    this.password_strength_error_function('clear');
                }
            }
            this.isStrengthSufficient = false;
            return false;
        },
    },
};
</script>
<style scoped>
.correct {
    color: #3c7858;
}
</style>
