<template>
    <div v-if="component_done_loading">
        <div class="marginBottom20">
            <div class="dropdown">
                <button id="dropdownMenuButton" class="btn btn-success" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Publish Activity
                </button>

                <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                    <ul>
                        <li>
                            <button class="btn btn-default" @click.prevent="publish('course')" @keyup.space.prevent="publish('course')">
                                Publish Activity and Exit
                            </button>
                        </li>

                        <li>
                            <button class="btn btn-success" @click.prevent="publish('dashboard')" @keyup.space.prevent="publish('dashboard')">
                                Publish Activity and Go To Dashboard
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="pageTitle">
            <h1>Publishing</h1>
            <p class="col-md-6">
                On this page, choose the modality and set the duration for the activity.

                <template v-if="!['prework'].includes(activityObj.type)">
                    <template
                        v-if="
                            (['irat', 'iratntrat'].includes(activityObj.type) && activityObj.hasStudentAccommodations) ||
                            (['application'].includes(activityObj.type) &&
                                activityObj.others.applicationType == 'individual' &&
                                activityObj.hasStudentAccommodations)
                        "
                    >
                        <br />
                        <br />
                        <b>
                            For
                            <template v-if="['irat', 'iratntrat'].includes(activityObj.type)"> IRAT </template>
                            <template v-if="['application'].includes(activityObj.type) && activityObj.others.applicationType == 'individual'">
                                Application Individual
                            </template>
                            and has time limit Only
                        </b>

                        <br />
                        Publishing for Students with Special Accomodations You have the option to
                        <a
                            href="https://help.intedashboard.com/how-to-provide-extra-time-with-the-independent-timer-feature-for-students-with-special-accommodations"
                            target="newTab"
                        >
                            <i class="fas fa-info-circle" aria-hidden="true" />
                            add extra time
                        </a>
                        or
                        <a href="https://help.intedashboard.com/how-to-enable-early-start-for-students-with-special-accommodations" target="newTab">
                            <i class="fas fa-info-circle" aria-hidden="true" />
                            enable an early start
                        </a>
                        for students with special accommodations.
                    </template>
                </template>
            </p>
        </div>

        <kr-panel v-if="['prework'].includes(activityObj.type)" :with-footer="false">
            <template #title>
                <h2>Publish Prework Activity</h2>
            </template>
            <template #content>
                <div class="col-xs-12 col-md-6">
                    <div class="optionDiv">
                        <kr-radio-multiple
                            name="preworkType"
                            display="Modality"
                            :form="form"
                            :items="[
                                {
                                    value: 'asynchronous',
                                    description: 'Asynchronous',
                                },
                            ]"
                            item_value_idx="value"
                            item_desc_idx="description"
                            :options="{
                                required: true,
                            }"
                        />
                        <p class="fontSize14">
                            <template v-if="form.model.preworkType == 'asynchronous'">
                                <i> All students will take the test at a different time. </i>
                            </template>
                            <template v-if="form.model.preworkType == 'synchronous'">
                                <i> All students will take the test at the same time. </i>
                            </template>
                        </p>
                    </div>
                    <div v-if="form.model.preworkType == 'asynchronous'" class="marginTop40">
                        <div>
                            <label class="control-label"> Open Period<span class="important" aria-label="required">*</span> </label>
                            <p class="fontSize14 marginBottom10">
                                <i> Students will be able to enter and START this activity anytime within the open period. </i>
                            </p>
                        </div>
                        <div class="flexOnly">
                            <div class="paddingRight10">
                                <kr-date
                                    class="no-error"
                                    :form="form"
                                    name="preworkStartDate"
                                    display="Activity Start Date"
                                    :options="{
                                        startDate: form.model.preworkStartDate,
                                        maxDate: form.model.preworkEndDate,
                                        time: true,
                                        placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                    }"
                                />
                            </div>
                            <div>
                                <kr-date
                                    class="no-error"
                                    :form="form"
                                    name="preworkEndDate"
                                    display="Activity End Date"
                                    :options="{
                                        startDate: form.model.preworkEndDate,
                                        minDate: form.model.preworkStartDate,
                                        time: true,
                                        placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                    }"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>
        <kr-panel v-if="['irat', 'iratntrat'].includes(activityObj.type)" :with-footer="false">
            <template #title>
                <h2>Publish IRAT Activity</h2>
            </template>
            <template #content>
                <div class="flexSpaceBetween">
                    <div class="col-xs-12 col-md-6">
                        <div class="optionDiv">
                            <kr-radio-multiple
                                name="iratType"
                                display="Modality"
                                :form="form"
                                :items="[
                                    {
                                        value: 'asynchronous',
                                        description: 'Asynchronous',
                                        _disabled: auth.user().account.version == 'lite',
                                    },
                                    {
                                        value: 'synchronous',
                                        description: 'Synchronous',
                                    },
                                ]"
                                item_value_idx="value"
                                item_desc_idx="description"
                                :options="{
                                    required: true,
                                }"
                                @changed="changedType('irat')"
                            />
                            <div
                                v-if="auth.user().account.version == 'lite'"
                                class="alert alert-info alert-dismissible"
                                role="alert"
                                style="margin-top: 10px; margin-bottom: 10px"
                            >
                                Asynchronous activities are only available in our full version. Click here to upgrade.
                                <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
                            </div>
                            <p class="fontSize14">
                                <template v-if="form.model.iratType == 'asynchronous'">
                                    <i> All students will take the test at a different time. </i>
                                </template>
                                <template v-if="form.model.iratType == 'synchronous'">
                                    <i> All students will take the test at the same time. </i>
                                </template>
                            </p>
                        </div>
                        <div v-if="form.model.iratType == 'asynchronous'" class="marginTop40">
                            <div>
                                <label class="control-label"> Open Period<span class="important" aria-label="required">*</span> </label>
                                <p class="fontSize14 marginBottom10">
                                    <i>
                                        Students will be able to enter and
                                        <b>start</b> this activity anytime within the open period.
                                    </i>
                                </p>
                            </div>
                            <div class="flexOnly">
                                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                                <!--<div class="col-xs-12 col-md-6" style="padding-right:10px">
                                <kr-date :form="form" name="iratStartDate" :options="{minDate:activityObj.module.startDate,maxDate:form.model.iratEndDate?form.model.iratEndDate:activityObj.module.endDate,time:true,placeholder:'Select start date',hasLabel:false}"></kr-date>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <kr-date :form="form" name="iratEndDate" :options="{maxDate:activityObj.module.endDate,minDate:form.model.iratStartDate?form.model.iratStartDate:activityObj.module.startDate,time:true,placeholder:'Select end date',hasLabel:false}"></kr-date>
                                </div>-->

                                <div class="paddingRight10">
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="iratStartDate"
                                        display="Activity Start Date"
                                        :options="{
                                            startDate: form.model.iratStartDate,
                                            maxDate: form.model.iratEndDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                                <div>
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="iratEndDate"
                                        display="Activity End Date"
                                        :options="{
                                            startDate: form.model.iratEndDate,
                                            minDate: form.model.iratStartDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="marginTop40">
                            <label class="control-label"> Activity Duration<span class="important" aria-labe="required">*</span> </label>
                            <kr-toggle
                                name="iratHasTimeLimit"
                                display="Set a Time Limit"
                                :form="form"
                                class="marginBottom15"
                                :options="{
                                    reverse: true,
                                }"
                                @changed="changedNoTimeLimit('irat', 'timeLimitSwitch')"
                            />
                            <p v-if="form.model.iratType == 'asynchronous'" class="fontSize14 marginBottom10">
                                <i>
                                    When students enter and <b>start</b> this activity within the open period, they will only have the following allocated time
                                    to complete the activity.
                                </i>
                                <br />
                                <i>
                                    For example, time limit is 20 minutes and open period is 1 Jan to 5 Jan, if a student starts the activity on 4 Jan, they
                                    will have 20 minutes to complete it.
                                </i>
                            </p>
                            <div v-if="form.model.iratHasTimeLimit" class="flexOnly">
                                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="iratDurationDays"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Days </template>
                                    </kr-field>
                                </div>
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="iratDurationHours"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 23,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Hours </template>
                                    </kr-field>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="iratDurationMinutes"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 59,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Mins </template>
                                    </kr-field>
                                </div>
                            </div>
                        </div>

                        <div v-if="form.model.iratHasTimeLimit && activityObj.hasStudentAccommodations" class="marginTop40">
                            <label class="control-label"> Students with Accommodations </label>

                            <p v-if="accommodationsTypeSelected == 'Apply Extra Time'" class="fontSize14">
                                <i> <b>Add as Extra Time</b> allows the student designated extra time after the activity ends. </i>
                                <br v-if="form.model.iratType == 'synchronous'" />
                                <i v-if="form.model.iratType == 'synchronous'">
                                    If you prefer students to have extra time before the activity, select the
                                    <b>Enable Early</b>
                                </i>
                                <br v-if="form.model.iratType == 'synchronous'" />
                                <i v-if="form.model.iratType == 'synchronous'"> <b>Start</b> option instead. </i>
                            </p>

                            <div class="marginTop10">
                                <div class="marginBottom10">
                                    <div class="inlineFlex align-items">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-default"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                @click.prevent="isMoreOptionsOpened = !isMoreOptionsOpened"
                                            >
                                                <template v-if="form.model.applySettingsToActivity && accommodationsTypeSelected == 'Apply Extra Time'">
                                                    <i class="fas fa-universal-access color-purple fs-24px marginRight8" aria-hidden="true" />
                                                    Add As Extra Time
                                                    <template v-if="filteredStudents.length > 0"> ({{ filteredStudents.length }}) </template>
                                                </template>
                                                <template v-else-if="form.model.applySettingsToActivity && accommodationsTypeSelected == 'Apply Early Start'">
                                                    Enable Early Start
                                                    <template v-if="form.model.earlyStartStudents.length > 0">
                                                        ({{ form.model.earlyStartStudents.length }})
                                                    </template>
                                                </template>
                                                <template v-else-if="!form.model.applySettingsToActivity"> Don't Apply </template>
                                                <i
                                                    :class="{
                                                        'fa fa-caret-up': !isMoreOptionsOpened,
                                                        'fa fa-caret-down': isMoreOptionsOpened,
                                                    }"
                                                    class="marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    <li>
                                                        <a
                                                            class="primary-state flexSpaceBetween"
                                                            href="#"
                                                            @click.prevent="
                                                                openAccommodateModal();
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            <span> Add As Extra Time </span>
                                                            <i class="fas fa-universal-access color-purple fs-24px" aria-hidden="true" />
                                                        </a>
                                                    </li>
                                                    <hr v-if="form.model.iratType == 'synchronous'" />
                                                    <li v-if="form.model.iratType == 'synchronous'">
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="
                                                                openEarlyStartModal();
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            Enable Early Start
                                                        </a>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="
                                                                form.model.applySettingsToActivity = false;
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            Don't Apply
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <button
                                            v-if="form.model.applySettingsToActivity"
                                            class="btn btn-primary"
                                            @click="accommodationsTypeSelected == 'Apply Extra Time' ? openAccommodateModal() : openEarlyStartModal()"
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>

                                <div class="marginTop10 background-EFEFEF padding10 displayInlineBlock">
                                    <template v-if="form.model.applySettingsToActivity">
                                        <template v-if="accommodationsTypeSelected == 'Apply Extra Time'">
                                            <!-- <div
                                                class="inlineFlex align-items marginBottom10"
                                            >
                                                <label
                                                    class="control-label fontBlack margin0 paddingTop10"
                                                >
                                                    Applied for ({{
                                                        filteredStudents.length
                                                    }}) students
                                                </label>

                                                <button
                                                    class="btn btn-primary"
                                                    @click="openAccommodateModal"
                                                >
                                                    View
                                                </button>
                                            </div> -->

                                            <p class="fontSize14">
                                                <i> Only default accommodations set in Student Management can be applied here. </i>
                                                <br />
                                                <i> Further adjustments to add or remove extra time can be made after publishing. </i>
                                            </p>
                                        </template>
                                        <template v-else-if="accommodationsTypeSelected == 'Apply Early Start'">
                                            <!-- <div
                                                class="inlineFlex align-items marginBottom10"
                                            >
                                                <label
                                                    class="control-label fontBlack margin0 paddingTop10"
                                                >
                                                    Applied for ({{
                                                        selectedStudents.length
                                                    }}) students
                                                </label>

                                                <button
                                                    class="btn btn-primary"
                                                    @click="openEarlyStartModal()"
                                                >
                                                    View
                                                </button>
                                            </div> -->

                                            <div>
                                                <label class="control-label fontBlack">
                                                    Early Start Duration:
                                                    {{ form.model.earlyStartDurationInMinutes }}
                                                    Minutes
                                                </label>
                                            </div>

                                            <p class="fontSize14">
                                                <i> Early start allows selected students to access the activity for the above designated </i>
                                                <br />
                                                <i> duration, before it opens for all students. </i>
                                            </p>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <p class="fontSize14">
                                            <i> Default accommodations will not be applied for this activity. </i>
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div v-if="form.model.iratType == 'asynchronous'" class="panel-group marginTop10">
                                <div class="panel panel-default borderNone bs-0">
                                    <div class="panel-heading">
                                        <h3 class="panel-title">
                                            <button
                                                id="iratAccommodations-label"
                                                class="padding0 flexSpaceBetween fontSize18 width100"
                                                aria-expanded="false"
                                                data-target="#iratAccommodations"
                                                data-toggle="collapse"
                                                @click="showAccommodationExplanation = !showAccommodationExplanation"
                                            >
                                                Please take into account the remaining open period duration
                                                <i
                                                    class="fa marginLeft8"
                                                    :class="{
                                                        'fa-caret-down': !showAccommodationExplanation,
                                                        'fa-caret-up': showAccommodationExplanation,
                                                    }"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </h3>
                                    </div>
                                    <div id="iratAccommodations" aria-labelledby="iratAccommodations-label" class="panel-collapse collapse padding10">
                                        <div class="panel-body padding0 borderNone">
                                            <p class="fontSize14">
                                                <i> If the time left is insufficient, the activity will close before extra time is finished. </i>
                                                <br />
                                                <i> You are advised to set an appropriate open period as required. </i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="marginTop40 hidden-md hidden-lg">
                            <div class="flexSpaceBetween alignBaseline">
                                <label class="control-label" for="iratPassword"> Student's Access Password (Optional) </label>
                                <button
                                    class="padding0"
                                    @click.prevent="
                                        form.model.iratPassword = '';
                                        form.errors.clear('iratPassword');
                                    "
                                >
                                    <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                </button>
                            </div>
                            <div>
                                <kr-field
                                    class="no-error"
                                    :form="form"
                                    name="iratPassword"
                                    :options="{
                                        hasLabel: false,
                                    }"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 hidden-xs hidden-sm">
                        <label class="control-label d-block"> Publish Info </label>
                        <div class="borderBlack padding15 width100">
                            <div class="marginBottom15">
                                <label class="control-label"> Modality </label>
                                <p class="greyText capitalize">
                                    <i>{{ form.model.iratType }}</i>
                                </p>
                                <hr />
                            </div>

                            <div v-if="form.model.iratType == 'asynchronous'" class="marginBottom15">
                                <label class="control-label"> Open Period </label>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>Start</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.iratStartDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.iratStartDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>End</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.iratEndDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.iratEndDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <hr />
                            </div>

                            <div class="marginBottom15">
                                <label class="control-label"> Activity Duration </label>
                                <p class="greyText">
                                    <template v-if="form.model.iratHasTimeLimit">
                                        <i>
                                            <template v-if="form.model.iratDurationDays != 0">
                                                {{ form.model.iratDurationDays }}
                                                <template v-if="form.model.iratDurationDays > 1"> days </template>
                                                <template v-else> day </template>
                                            </template>

                                            <template v-if="form.model.iratDurationHours != 0">
                                                {{ form.model.iratDurationHours }}
                                                <template v-if="form.model.iratDurationHours > 1"> hours </template>
                                                <template v-else> hour </template>
                                            </template>

                                            <template v-if="form.model.iratDurationMinutes != 0">
                                                {{ form.model.iratDurationMinutes }}
                                                <template v-if="form.model.iratDurationMinutes > 1"> minutes </template>
                                                <template v-else> minute </template>
                                            </template>
                                        </i>
                                    </template>
                                    <template v-else> - </template>
                                </p>
                                <hr />
                            </div>

                            <div v-if="form.model.iratHasTimeLimit && activityObj.hasStudentAccommodations" class="marginBottom15">
                                <label class="control-label"> Students with Accommodations </label>

                                <p class="greyText">
                                    <i>
                                        <template v-if="form.model.applySettingsToActivity && form.model.allowSpecialAccommodations">
                                            Applied
                                            <template v-if="accommodationsTypeSelected == 'Apply Extra Time'">
                                                <template v-if="filteredStudents.length == 1">
                                                    ({{ filteredStudents.length }}
                                                    Student)
                                                </template>
                                                <template v-else>
                                                    ({{ filteredStudents.length }}
                                                    Students)
                                                </template>
                                                - Add as Extra Time
                                            </template>
                                            <template v-if="accommodationsTypeSelected == 'Apply Early Start' && form.model.earlyStartDurationInMinutes">
                                                <template v-if="form.model.earlyStartStudents.length == 1">
                                                    ({{ form.model.earlyStartStudents.length }}
                                                    Student)
                                                </template>
                                                <template v-else>
                                                    ({{ form.model.earlyStartStudents.length }}
                                                    Students)
                                                </template>
                                                - Early Start
                                                {{ form.model.earlyStartDurationInMinutes }}
                                                Minutes
                                            </template>
                                        </template>
                                        <template v-else> - </template>
                                    </i>
                                </p>
                                <!-- <div 
                                    v-if="filteredStudents.length > 0 && form.model.iratHasTimeLimit" 
                                    class="panel-group"
                                >
                                    <div class="panel panel-default borderNone">
                                        <div class="panel-heading">
                                        <h3 class="panel-title">
                                            <button 
                                                id="iratStudentList-label"
                                                class="padding0"
                                                aria-expanded="false"
                                                data-target="#iratStudentList"
                                                data-toggle="collapse"
                                                @click="showStudentList = !showStudentList"
                                            >
                                                <i 
                                                    class="fa marginRight8" 
                                                    :class="{'fa-caret-down' : !showStudentList, 'fa-caret-up': showStudentList}"
                                                    aria-hidden="true"
                                                ></i>
                                                List of Students
                                            </button>
                                        </h3>
                                        </div>
                                        <div 
                                            id="iratStudentList"
                                            aria-labelledby="iratStudentList-label" 
                                            class="panel-collapse collapse"
                                        >
                                            <div class="panel-body padding0">
                                                <table class="width100 br-0 bs-0 accommodationsStudentList">
                                                    <thead>
                                                        <th>Name</th>
                                                        <th>Accommodations</th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Mary Lim</td>
                                                            <td>Has 25% extra time</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p v-else class="greyText">
                                <i>Only allows with time limit</i>
                                </p> -->
                                <hr />
                            </div>

                            <div>
                                <div class="flexSpaceBetween alignBaseline">
                                    <label class="control-label" for="iratPasswordMd"> Student's Access Password (Optional) </label>
                                    <button
                                        class="padding0"
                                        @click.prevent="
                                            form.model.iratPassword = '';
                                            form.errors.clear('iratPassword');
                                        "
                                    >
                                        <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                    </button>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="iratPassword"
                                        :options="{
                                            id: 'iratPasswordMd',
                                            hasLabel: false,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>
        <kr-panel v-if="['trat', 'iratntrat'].includes(activityObj.type)" :with-footer="false">
            <template #title>
                <h2>Publish TRAT Activity</h2>
            </template>
            <template #content>
                <div class="flexSpaceBetween">
                    <div class="col-xs-12 col-md-6">
                        <div class="optionDiv">
                            <kr-radio-multiple
                                name="tratType"
                                display="Modality"
                                :form="form"
                                :items="[
                                    {
                                        value: 'asynchronous',
                                        description: 'Asynchronous',
                                        _disabled: auth.user().account.version == 'lite',
                                    },
                                    {
                                        value: 'synchronous',
                                        description: 'Synchronous',
                                    },
                                ]"
                                item_value_idx="value"
                                item_desc_idx="description"
                                :options="{
                                    required: true,
                                }"
                                @changed="changedType('trat')"
                            />
                            <div
                                v-if="auth.user().account.version == 'lite'"
                                class="alert alert-info alert-dismissible"
                                role="alert"
                                style="margin-top: 10px; margin-bottom: 10px"
                            >
                                Asynchronous activities are only available in our full version. Click here to upgrade.
                                <a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>
                            </div>
                            <p class="fontSize14">
                                <template v-if="form.model.tratType == 'asynchronous'">
                                    <i> All students will take the test at a different time. </i>
                                </template>
                                <template v-if="form.model.tratType == 'synchronous'">
                                    <i> All students will take the test at the same time. </i>
                                </template>
                            </p>
                        </div>
                        <div v-if="form.model.tratType == 'asynchronous'" class="marginTop40">
                            <div>
                                <label class="control-label"> Open Period<span class="important" aria-label="required">*</span> </label>
                                <p class="fontSize14 marginBottom10">
                                    <i>
                                        Students will be able to enter and
                                        <b>start</b> this activity anytime within the open period.
                                    </i>
                                </p>
                            </div>
                            <div class="flexOnly">
                                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                                <!--<div class="col-xs-12 col-md-6" style="padding-right:10px">
                                <kr-date :form="form" name="tratStartDate" :options="{minDate:activityObj.module.startDate,maxDate:form.model.tratEndDate?form.model.tratEndDate:activityObj.module.endDate,time:true,placeholder:'Select start date',hasLabel:false}"></kr-date>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <kr-date :form="form" name="tratEndDate" :options="{maxDate:activityObj.module.endDate,minDate:form.model.tratStartDate?form.model.tratStartDate:activityObj.module.startDate,time:true,placeholder:'Select end date',hasLabel:false}"></kr-date>
                                </div>-->

                                <div class="paddingRight10">
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="tratStartDate"
                                        display="Activity Start Date"
                                        :options="{
                                            startDate: form.model.tratStartDate,
                                            maxDate: form.model.tratEndDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                                <div>
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="tratEndDate"
                                        display="Activity End Date"
                                        :options="{
                                            startDate: form.model.tratEndDate,
                                            minDate: form.model.tratStartDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="marginTop40">
                            <label class="control-label"> Activity Duration<span class="important" aria-label="required">*</span> </label>
                            <kr-toggle
                                name="tratHasTimeLimit"
                                display="Set a Time Limit"
                                :form="form"
                                class="marginBottom15"
                                :options="{
                                    reverse: true,
                                }"
                                @changed="changedNoTimeLimit('trat')"
                            />
                            <p v-if="form.model.iratType == 'asynchronous'" class="fontSize14 marginBottom10">
                                <i>
                                    When students enter and <b>start</b> this activity within the open period, they will only have the following allocated time
                                    to complete the activity.
                                </i>
                                <br />
                                <i>
                                    For example, time limit is 20 minutes and open period is 1 Jan to 5 Jan, if a student starts the activity on 4 Jan, they
                                    will have 20 minutes to complete it.
                                </i>
                            </p>
                            <div v-if="form.model.tratHasTimeLimit" class="flexOnly">
                                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                                <!--<div class="col-xs-4 col-md-3" style="padding-right:10px">
                                <kr-field :form="form" name="tratDurationDays" :options="{type:'number',min:0}"><template #addon>Days</template></kr-field>
                                </div>-->
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="tratDurationDays"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Days </template>
                                    </kr-field>
                                </div>
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="tratDurationHours"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 23,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Hours </template>
                                    </kr-field>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="tratDurationMinutes"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 59,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Mins </template>
                                    </kr-field>
                                </div>
                            </div>
                        </div>
                        <div class="marginTop40 hidden-md hidden-lg">
                            <div class="flexSpaceBetween">
                                <label class="control-label" for="tratPassword"> Student's Access Password (Optional) </label>
                                <button
                                    @click.prevent="
                                        form.model.tratPassword = '';
                                        form.errors.clear('tratPassword');
                                    "
                                >
                                    <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                </button>
                            </div>
                            <div>
                                <kr-field
                                    class="no-error"
                                    :form="form"
                                    name="tratPassword"
                                    :options="{
                                        hasLabel: false,
                                    }"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 hidden-xs hidden-sm">
                        <label class="control-label d-block"> Publish Info </label>
                        <div class="borderBlack padding15 width100">
                            <div class="marginBottom20">
                                <label class="control-label"> Modality </label>
                                <p class="greyText capitalize">
                                    <i>{{ form.model.tratType }}</i>
                                </p>
                                <hr />
                            </div>

                            <div v-if="form.model.tratType == 'asynchronous'" class="marginBottom20">
                                <label class="control-label"> Open Period </label>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>Start</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.tratStartDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.tratStartDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>End</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.tratEndDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.tratEndDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <hr />
                            </div>

                            <div class="marginBottom20">
                                <label class="control-label"> Activity Duration </label>
                                <p class="greyText">
                                    <template v-if="form.model.tratHasTimeLimit">
                                        <i>
                                            <template v-if="form.model.tratDurationDays != 0">
                                                {{ form.model.tratDurationDays }}
                                                <template v-if="form.model.tratDurationDays > 1"> days </template>
                                                <template v-else> day </template>
                                            </template>

                                            <template v-if="form.model.tratDurationHours != 0">
                                                {{ form.model.tratDurationHours }}
                                                <template v-if="form.model.tratDurationHours > 1"> hours </template>
                                                <template v-else> hour </template>
                                            </template>

                                            <template v-if="form.model.tratDurationMinutes != 0">
                                                {{ form.model.tratDurationMinutes }}
                                                <template v-if="form.model.tratDurationMinutes > 1"> minutes </template>
                                                <template v-else> minute </template>
                                            </template>
                                        </i>
                                    </template>
                                    <template v-else> - </template>
                                </p>
                                <hr />
                            </div>

                            <div>
                                <div class="flexSpaceBetween alignBaseline">
                                    <label class="control-label" for="tratPasswordMd"> Student's Access Password (Optional) </label>
                                    <button
                                        class="padding0"
                                        @click.prevent="
                                            form.model.tratPassword = '';
                                            form.errors.clear('tratPassword');
                                        "
                                    >
                                        <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                    </button>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="tratPassword"
                                        :options="{
                                            id: 'tratPasswordMd',
                                            hasLabel: false,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>

        <kr-panel v-if="['application'].includes(activityObj.type)" :with-footer="false">
            <template #title>
                <h2>Publish Application Activity</h2>
            </template>
            <template #content>
                <div class="flexSpaceBetween">
                    <div class="col-xs-12 col-md-6">
                        <div class="optionDiv">
                            <kr-radio-multiple
                                name="applicationType"
                                display="Modality"
                                :form="form"
                                :items="[
                                    {
                                        value: 'asynchronous',
                                        description: 'Asynchronous',
                                    },
                                    {
                                        value: 'synchronous',
                                        description: 'Synchronous',
                                    },
                                ]"
                                item_value_idx="value"
                                item_desc_idx="description"
                                :options="{
                                    required: true,
                                }"
                                @changed="changedType('application')"
                            />
                            <p class="fontSize14">
                                <template v-if="form.model.applicationType == 'asynchronous'">
                                    <i> All students will take the test at a different time. </i>
                                </template>
                                <template v-if="form.model.applicationType == 'synchronous'">
                                    <i> All students will take the test at the same time. </i>
                                </template>
                            </p>
                        </div>
                        <div v-if="form.model.applicationType == 'asynchronous'" class="marginTop40">
                            <div>
                                <label class="control-label"> Open Period<span class="important" aria-label="required">*</span> </label>
                                <p class="fontSize14 marginBottom10">
                                    <i>
                                        Students will be able to enter and
                                        <b>start</b> this activity anytime within the open period.
                                    </i>
                                </p>
                            </div>
                            <div class="flexOnly">
                                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                                <!--<div class="col-xs-12 col-md-6" style="padding-right:10px">
                                <kr-date :form="form" name="applicationStartDate" :options="{minDate:activityObj.module.startDate,maxDate:form.model.applicationEndDate?form.model.applicationEndDate:activityObj.module.endDate,time:true,placeholder:'Select start date',hasLabel:false}"></kr-date>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <kr-date :form="form" name="applicationEndDate" :options="{maxDate:activityObj.module.endDate,minDate:form.model.applicationStartDate?form.model.applicationStartDate:activityObj.module.startDate,time:true,placeholder:'Select end date',hasLabel:false}"></kr-date>
                                </div>-->
                                <div class="paddingRight10">
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="applicationStartDate"
                                        display="Activity Start Date"
                                        :options="{
                                            startDate: form.model.applicationStartDate,
                                            maxDate: form.model.applicationEndDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                                <div>
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="applicationEndDate"
                                        display="Activity End Date"
                                        :options="{
                                            startDate: form.model.applicationEndDate,
                                            minDate: form.model.applicationStartDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="marginTop40">
                            <label class="control-label"> Activity Duration<span class="important" aria-label="required">*</span> </label>
                            <kr-toggle
                                name="applicationHasTimeLimit"
                                display="Set a Time Limit"
                                :form="form"
                                class="marginBottom15"
                                :options="{
                                    reverse: true,
                                }"
                                @changed="changedNoTimeLimit('application', 'timeLimitSwitch')"
                            />
                            <p v-if="form.model.applicationType == 'asynchronous'" class="fontSize14 marginBottom10">
                                <i>
                                    When students enter and <b>start</b> this activity within the open period, they will only have the following allocated time
                                    to complete the activity.
                                </i>
                                <i>
                                    For example, time limit is 20 minutes and open period is 1 Jan to 5 Jan, if a student starts the activity on 4 Jan, they
                                    will have 20 minutes to complete it.
                                </i>
                            </p>
                            <div v-if="form.model.applicationHasTimeLimit" class="flexOnly">
                                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                                <!--<div class="col-xs-4 col-md-3" style="padding-right:10px">
                                <kr-field :form="form" name="applicationDurationDays" :options="{type:'number',min:0}"><template #addon>Days</template></kr-field>
                                </div>-->
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="applicationDurationDays"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Days </template>
                                    </kr-field>
                                </div>
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="applicationDurationHours"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 23,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Hours </template>
                                    </kr-field>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="applicationDurationMinutes"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 59,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Mins </template>
                                    </kr-field>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="
                                form.model.applicationHasTimeLimit && activityObj.others.applicationType == 'individual' && activityObj.hasStudentAccommodations
                            "
                            class="marginTop40"
                        >
                            <label class="control-label"> Students with Accommodations </label>

                            <!-- <kr-toggle
                                name="applySettingsToActivity"
                                display="Apply to this Activity"
                                :form="form"
                                class="marginBottom15"
                                :options="{
                                    reverse: true,
                                }"
                            /> -->
                            <p v-if="accommodationsTypeSelected == 'Apply Extra Time'" class="fontSize14">
                                <i> <b>Add as Extra Time</b> allows the student designated extra time after the activity ends. </i>
                                <br v-if="form.model.applicationType == 'synchronous'" />
                                <i v-if="form.model.applicationType == 'synchronous'">
                                    If you prefer students to have extra time before the activity, select the
                                    <b>Enable Early</b>
                                </i>
                                <br v-if="form.model.applicationType == 'synchronous'" />
                                <i v-if="form.model.applicationType == 'synchronous'"> <b>Start</b> option instead. </i>
                            </p>

                            <div class="marginTop10">
                                <div class="marginBottom10">
                                    <div class="inlineFlex align-items">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-default"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                @click.prevent="isMoreOptionsOpened = !isMoreOptionsOpened"
                                            >
                                                <template v-if="form.model.applySettingsToActivity && accommodationsTypeSelected == 'Apply Extra Time'">
                                                    <i class="fas fa-universal-access color-purple fs-24px marginRight8" aria-hidden="true" />
                                                    Add As Extra Time
                                                    <template v-if="filteredStudents.length > 0"> ({{ filteredStudents.length }}) </template>
                                                </template>
                                                <template v-else-if="form.model.applySettingsToActivity && accommodationsTypeSelected == 'Apply Early Start'">
                                                    Enable Early Start
                                                    <template v-if="form.model.earlyStartStudents.length > 0">
                                                        ({{ form.model.earlyStartStudents.length }})
                                                    </template>
                                                </template>
                                                <template v-else-if="!form.model.applySettingsToActivity"> Don't Apply </template>
                                                <i
                                                    :class="{
                                                        'fa fa-caret-up': !isMoreOptionsOpened,
                                                        'fa fa-caret-down': isMoreOptionsOpened,
                                                    }"
                                                    class="marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    <li>
                                                        <a
                                                            class="primary-state flexSpaceBetween"
                                                            href="#"
                                                            @click.prevent="
                                                                openAccommodateModal();
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            <span> Add As Extra Time </span>
                                                            <i class="fas fa-universal-access color-purple fs-24px" aria-hidden="true" />
                                                        </a>
                                                    </li>
                                                    <hr v-if="form.model.applicationType == 'synchronous'" />
                                                    <li v-if="form.model.applicationType == 'synchronous'">
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="
                                                                openEarlyStartModal();
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            Enable Early Start
                                                        </a>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="
                                                                form.model.applySettingsToActivity = false;
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            Don't Apply
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <button
                                            v-if="form.model.applySettingsToActivity"
                                            class="btn btn-primary"
                                            @click="accommodationsTypeSelected == 'Apply Extra Time' ? openAccommodateModal() : openEarlyStartModal()"
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>

                                <div class="marginTop10 background-EFEFEF padding10 displayInlineBlock">
                                    <template v-if="form.model.applySettingsToActivity">
                                        <template v-if="accommodationsTypeSelected == 'Apply Extra Time'">
                                            <p class="fontSize14">
                                                <i> Only default accommodations set in Student Management can be applied here. </i>
                                                <br />
                                                <i> Further adjustments to add or remove extra time can be made after publishing. </i>
                                            </p>
                                        </template>
                                        <template v-else-if="accommodationsTypeSelected == 'Apply Early Start'">
                                            <div>
                                                <label class="control-label fontBlack">
                                                    Early Start Duration:
                                                    {{ form.model.earlyStartDurationInMinutes }}
                                                    Minutes
                                                </label>
                                            </div>

                                            <p class="fontSize14">
                                                <i> Early start allows selected students to access the activity for the above designated </i>
                                                <br />
                                                <i> duration, before it opens for all students. </i>
                                            </p>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <p class="fontSize14">
                                            <i> Default accommodations will not be applied for this activity. </i>
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div v-if="form.model.applicationType == 'asynchronous'" class="panel-group marginTop10">
                                <div class="panel panel-default borderNone bs-0">
                                    <div class="panel-heading">
                                        <h3 class="panel-title">
                                            <button
                                                id="applicationAccommodations-label"
                                                class="padding0 flexSpaceBetween fontSize18 width100"
                                                aria-expanded="false"
                                                data-target="#applicationAccommodations"
                                                data-toggle="collapse"
                                                @click="showAccommodationExplanation = !showAccommodationExplanation"
                                            >
                                                Please take into account the remaining open period duration
                                                <i
                                                    class="fa marginLeft8"
                                                    :class="{
                                                        'fa-caret-down': !showAccommodationExplanation,
                                                        'fa-caret-up': showAccommodationExplanation,
                                                    }"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </h3>
                                    </div>
                                    <div
                                        id="applicationAccommodations"
                                        aria-labelledby="applicationAccommodations-label"
                                        class="panel-collapse collapse padding10"
                                    >
                                        <div class="panel-body padding0 borderNone">
                                            <p class="fontSize14">
                                                <i> If the time left is insufficient, the activity will close before extra time is finished. </i>
                                                <br />
                                                <i> You are advised to set an appropriate open period as required. </i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="marginTop40 hidden-md hidden-lg">
                                <div class="flexSpaceBetween">
                                    <label class="control-label" for="applicationPassword"> Student's Access Password (Optional) </label>
                                    <button
                                        @click.prevent="
                                            form.model.applicationPassword = '';
                                            form.errors.clear('applicationPassword');
                                        "
                                    >
                                        <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                    </button>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="applicationPassword"
                                        :options="{
                                            hasLabel: false,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="marginTop40 hidden-md hidden-lg">
                            <div class="flexSpaceBetween">
                                <label class="control-label" for="applicationPassword"> Student's Access Password (Optional) </label>
                                <button
                                    @click.prevent="
                                        form.model.applicationPassword = '';
                                        form.errors.clear('applicationPassword');
                                    "
                                >
                                    <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                </button>
                            </div>
                            <div>
                                <kr-field
                                    class="no-error"
                                    :form="form"
                                    name="applicationPassword"
                                    :options="{
                                        hasLabel: false,
                                    }"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 hidden-xs hidden-sm">
                        <label class="control-label d-block"> Publish Info </label>
                        <div class="borderBlack padding15 width100">
                            <div class="marginBottom20">
                                <label class="control-label"> Modality </label>
                                <p class="greyText capitalize">
                                    <i>{{ form.model.applicationType }}</i>
                                </p>
                                <hr />
                            </div>

                            <div v-if="form.model.applicationType == 'asynchronous'" class="marginBottom20">
                                <label class="control-label"> Open Period </label>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>Start</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.applicationStartDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.applicationStartDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>End</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.applicationEndDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.applicationEndDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <hr />
                            </div>

                            <div class="marginBottom20">
                                <label class="control-label"> Activity Duration </label>
                                <p class="greyText">
                                    <template v-if="form.model.applicationHasTimeLimit">
                                        <i>
                                            <template v-if="form.model.applicationDurationDays != 0">
                                                {{ form.model.applicationDurationDays }}
                                                <template v-if="form.model.applicationDurationDays > 1"> days </template>
                                                <template v-else> day </template>
                                            </template>

                                            <template v-if="form.model.applicationDurationHours != 0">
                                                {{ form.model.applicationDurationHours }}
                                                <template v-if="form.model.applicationDurationHours > 1"> hours </template>
                                                <template v-else> hour </template>
                                            </template>

                                            <template v-if="form.model.applicationDurationMinutes != 0">
                                                {{ form.model.applicationDurationMinutes }}
                                                <template v-if="form.model.applicationDurationMinutes > 1"> minutes </template>
                                                <template v-else> minute </template>
                                            </template>
                                        </i>
                                    </template>
                                    <template v-else> - </template>
                                </p>
                                <hr />
                            </div>

                            <div
                                v-if="
                                    form.model.applicationHasTimeLimit &&
                                    activityObj.others.applicationType == 'individual' &&
                                    activityObj.hasStudentAccommodations
                                "
                                class="marginBottom20"
                            >
                                <label class="control-label"> Students with Accommodations </label>

                                <p class="greyText">
                                    <i>
                                        <template v-if="form.model.applySettingsToActivity && form.model.allowSpecialAccommodations">
                                            Applied
                                            <template v-if="accommodationsTypeSelected == 'Apply Extra Time'">
                                                <template v-if="filteredStudents.length == 1">
                                                    ({{ filteredStudents.length }}
                                                    Student)
                                                </template>
                                                <template v-else>
                                                    ({{ filteredStudents.length }}
                                                    Students)
                                                </template>
                                                - Add as Extra Time
                                            </template>
                                            <template v-if="accommodationsTypeSelected == 'Apply Early Start' && form.model.earlyStartDurationInMinutes">
                                                <template v-if="form.model.earlyStartStudents.length == 1">
                                                    ({{ form.model.earlyStartStudents.length }}
                                                    Student)
                                                </template>
                                                <template v-else>
                                                    ({{ form.model.earlyStartStudents.length }}
                                                    Students)
                                                </template>
                                                - Early Start
                                                {{ form.model.earlyStartDurationInMinutes }}
                                                Minutes
                                            </template>
                                        </template>
                                        <template v-else> - </template>
                                    </i>
                                </p>
                                <hr />
                            </div>

                            <div>
                                <div class="flexSpaceBetween alignBaseline">
                                    <label class="control-label" for="applicationPasswordMd"> Student's Access Password (Optional) </label>
                                    <button
                                        class="padding0"
                                        @click.prevent="
                                            form.model.applicationPassword = '';
                                            form.errors.clear('applicationPassword');
                                        "
                                    >
                                        <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                    </button>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="applicationPassword"
                                        :options="{
                                            id: 'applicationPasswordMd',
                                            hasLabel: false,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>
        <kr-panel v-if="['evaluation', 'michaelsenEvaluation'].includes(activityObj.type)" :with-footer="false">
            <template #title>
                <h2>Publish Evaluation Activity</h2>
            </template>
            <template #content>
                <div class="flexSpaceBetween">
                    <div class="col-xs-12 col-md-6">
                        <div class="optionDiv">
                            <kr-radio-multiple
                                name="evaluationType"
                                display="Modality"
                                :form="form"
                                :items="[
                                    {
                                        value: 'asynchronous',
                                        description: 'Asynchronous',
                                    },
                                    {
                                        value: 'synchronous',
                                        description: 'Synchronous',
                                    },
                                ]"
                                item_value_idx="value"
                                item_desc_idx="description"
                                :options="{
                                    required: true,
                                }"
                            />
                            <p class="fontSize14">
                                <template v-if="form.model.evaluationType == 'asynchronous'">
                                    <i> All students will take the test at a different time. </i>
                                </template>
                                <template v-if="form.model.evaluationType == 'synchronous'">
                                    <i> All students will take the test at the same time. </i>
                                </template>
                            </p>
                        </div>
                        <div v-if="form.model.evaluationType == 'asynchronous'" class="marginTop40">
                            <div>
                                <label class="control-label"> Open Period<span class="important" aria-label="required">*</span> </label>
                                <p class="fontSize14 marginBottom10">
                                    <i>
                                        Students will be able to enter and
                                        <b>start</b> this activity anytime within the open period.
                                    </i>
                                </p>
                            </div>
                            <div v-if="form.model.evaluationType == 'asynchronous'" class="flexOnly">
                                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                                <!--<div class="col-xs-12 col-md-6" style="padding-right:10px">
                                <kr-date :form="form" name="applicationStartDate" :options="{minDate:activityObj.module.startDate,maxDate:form.model.applicationEndDate?form.model.applicationEndDate:activityObj.module.endDate,time:true,placeholder:'Select start date',hasLabel:false}"></kr-date>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <kr-date :form="form" name="applicationEndDate" :options="{maxDate:activityObj.module.endDate,minDate:form.model.applicationStartDate?form.model.applicationStartDate:activityObj.module.startDate,time:true,placeholder:'Select end date',hasLabel:false}"></kr-date>
                                </div>-->
                                <div class="paddingRight10">
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="evaluationStartDate"
                                        display="Activity Start Date"
                                        :options="{
                                            startDate: form.model.evaluationStartDate,
                                            maxDate: form.model.evaluationEndDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                                <div>
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="evaluationEndDate"
                                        display="Activity End Date"
                                        :options="{
                                            startDate: form.model.evaluationEndDate,
                                            minDate: form.model.evaluationStartDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="marginTop40">
                            <label class="control-label"> Activity Duration<span class="important" aria-label="required">*</span> </label>
                            <kr-toggle
                                name="evaluationHasTimeLimit"
                                display="Set a Time Limit"
                                :form="form"
                                class="marginBottom15"
                                :options="{
                                    reverse: true,
                                }"
                                @changed="changedNoTimeLimit('evaluation', 'timeLimitSwitch')"
                            />
                            <div v-if="form.model.evaluationHasTimeLimit" class="flexOnly">
                                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                                <!--<div class="col-xs-4 col-md-3" style="padding-right:10px">
                                <kr-field :form="form" name="applicationDurationDays" :options="{type:'number',min:0}"><template #addon>Days</template></kr-field>
                                </div>-->
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="evaluationDurationDays"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Days </template>
                                    </kr-field>
                                </div>
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="evaluationDurationHours"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 23,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Hours </template>
                                    </kr-field>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="evaluationDurationMinutes"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 59,
                                            hasLabel: false,
                                        }"
                                    >
                                        <template #addon> Mins </template>
                                    </kr-field>
                                </div>
                            </div>
                        </div>

                        <div v-if="form.model.evaluationHasTimeLimit && activityObj.hasStudentAccommodations" class="marginTop40">
                            <label class="control-label"> Students with Accommodations </label>

                            <!-- <kr-toggle
                                name="applySettingsToActivity"
                                display="Apply to this Activity"
                                :form="form"
                                class="marginBottom15"
                                :options="{
                                    reverse: true,
                                }"
                            /> -->

                            <p v-if="accommodationsTypeSelected == 'Apply Extra Time'" class="fontSize14">
                                <i> <b>Add as Extra Time</b> allows the student designated extra time after the activity ends. </i>
                                <br v-if="form.model.evaluationType == 'synchronous'" />
                                <i v-if="form.model.evaluationType == 'synchronous'">
                                    If you prefer students to have extra time before the activity, select the
                                    <b>Enable Early</b>
                                </i>
                                <br v-if="form.model.evaluationType == 'synchronous'" />
                                <i v-if="form.model.evaluationType == 'synchronous'"> <b>Start</b> option instead. </i>
                            </p>

                            <div class="marginTop10">
                                <div class="marginBottom10">
                                    <div class="inlineFlex align-items">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-default"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                @click.prevent="isMoreOptionsOpened = !isMoreOptionsOpened"
                                            >
                                                <template v-if="form.model.applySettingsToActivity && accommodationsTypeSelected == 'Apply Extra Time'">
                                                    <i class="fas fa-universal-access color-purple fs-24px marginRight8" aria-hidden="true" />
                                                    Add As Extra Time
                                                    <template v-if="filteredStudents.length > 0"> ({{ filteredStudents.length }}) </template>
                                                </template>
                                                <template v-else-if="form.model.applySettingsToActivity && accommodationsTypeSelected == 'Apply Early Start'">
                                                    Enable Early Start
                                                    <template v-if="form.model.earlyStartStudents.length > 0">
                                                        ({{ form.model.earlyStartStudents.length }})
                                                    </template>
                                                </template>
                                                <template v-else-if="!form.model.applySettingsToActivity"> Don't Apply </template>
                                                <i
                                                    :class="{
                                                        'fa fa-caret-up': !isMoreOptionsOpened,
                                                        'fa fa-caret-down': isMoreOptionsOpened,
                                                    }"
                                                    class="marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    <li>
                                                        <a
                                                            class="primary-state flexSpaceBetween"
                                                            href="#"
                                                            @click.prevent="
                                                                openAccommodateModal();
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            <span> Add As Extra Time </span>
                                                            <i class="fas fa-universal-access color-purple fs-24px" aria-hidden="true" />
                                                        </a>
                                                    </li>
                                                    <hr v-if="form.model.evaluationType == 'synchronous'" />
                                                    <li v-if="form.model.evaluationType == 'synchronous'">
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="
                                                                openEarlyStartModal();
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            Enable Early Start
                                                        </a>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="
                                                                form.model.applySettingsToActivity = false;
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            Don't Apply
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <button
                                            v-if="form.model.applySettingsToActivity"
                                            class="btn btn-primary"
                                            @click="accommodationsTypeSelected == 'Apply Extra Time' ? openAccommodateModal() : openEarlyStartModal()"
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>

                                <div class="marginTop10 background-EFEFEF padding10 displayInlineBlock">
                                    <template v-if="form.model.applySettingsToActivity">
                                        <template v-if="accommodationsTypeSelected == 'Apply Extra Time'">
                                            <p class="fontSize14">
                                                <i> Only default accommodations set in Student Management can be applied here. </i>
                                                <br />
                                                <i> Further adjustments to add or remove extra time can be made after publishing. </i>
                                            </p>
                                        </template>
                                        <template v-else-if="accommodationsTypeSelected == 'Apply Early Start'">
                                            <div>
                                                <label class="control-label fontBlack">
                                                    Early Start Duration:
                                                    {{ form.model.earlyStartDurationInMinutes }}
                                                    Minutes
                                                </label>
                                            </div>

                                            <p class="fontSize14">
                                                <i> Early start allows selected students to access the activity for the above designated </i>
                                                <br />
                                                <i> duration, before it opens for all students. </i>
                                            </p>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <p class="fontSize14">
                                            <i> Default accommodations will not be applied for this activity. </i>
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div v-if="form.model.evaluationType == 'asynchronous'" class="panel-group marginTop10">
                                <div class="panel panel-default borderNone bs-0">
                                    <div class="panel-heading">
                                        <h3 class="panel-title">
                                            <button
                                                id="evaluationAccommodations-label"
                                                class="padding0 flexSpaceBetween fontSize18 width100"
                                                aria-expanded="false"
                                                data-target="#evaluationAccommodations"
                                                data-toggle="collapse"
                                                @click="showAccommodationExplanation = !showAccommodationExplanation"
                                            >
                                                Please take into account the remaining open period duration
                                                <i
                                                    class="fa marginLeft8"
                                                    :class="{
                                                        'fa-caret-down': !showAccommodationExplanation,
                                                        'fa-caret-up': showAccommodationExplanation,
                                                    }"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </h3>
                                    </div>
                                    <div
                                        id="evaluationAccommodations"
                                        aria-labelledby="evaluationAccommodations-label"
                                        class="panel-collapse collapse padding10"
                                    >
                                        <div class="panel-body padding0 borderNone">
                                            <p class="fontSize14">
                                                <i> If the time left is insufficient, the activity will close before extra time is finished. </i>
                                                <br />
                                                <i> You are advised to set an appropriate open period as required. </i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="marginTop30 hidden-md hidden-lg">
                                <div class="flexSpaceBetween">
                                    <label class="control-label" for="evaluationPassword"> Student's Access Password (Optional) </label>
                                    <button
                                        @click.prevent="
                                            form.model.evaluationPassword = '';
                                            form.errors.clear('evaluationPassword');
                                        "
                                    >
                                        <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                    </button>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="evaluationPassword"
                                        :options="{
                                            hasLabel: false,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="marginTop30 hidden-md hidden-lg">
                            <div class="flexSpaceBetween">
                                <label class="control-label" for="evaluationPassword"> Student's Access Password (Optional) </label>
                                <button
                                    @click.prevent="
                                        form.model.evaluationPassword = '';
                                        form.errors.clear('evaluationPassword');
                                    "
                                >
                                    <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                </button>
                            </div>
                            <div>
                                <kr-field
                                    class="no-error"
                                    :form="form"
                                    name="evaluationPassword"
                                    :options="{
                                        hasLabel: false,
                                    }"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 hidden-xs hidden-sm">
                        <label class="control-label d-block"> Publish Info </label>
                        <div class="borderBlack padding15 width100">
                            <div class="marginBottom20">
                                <label class="control-label"> Modality </label>
                                <p class="greyText capitalize">
                                    <i>{{ form.model.evaluationType }}</i>
                                </p>
                                <hr />
                            </div>

                            <div v-if="form.model.evaluationType == 'asynchronous'" class="marginBottom20">
                                <label class="control-label"> Open Period </label>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>Start</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.evaluationStartDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.evaluationStartDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>End</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.evaluationEndDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.evaluationEndDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <hr />
                            </div>

                            <div class="marginBottom20">
                                <label class="control-label"> Activity Duration </label>
                                <p class="greyText">
                                    <template v-if="form.model.evaluationHasTimeLimit">
                                        <i>
                                            <template v-if="form.model.evaluationDurationDays != 0">
                                                {{ form.model.evaluationDurationDays }}
                                                <template v-if="form.model.evaluationDurationDays > 1"> days </template>
                                                <template v-else> day </template>
                                            </template>

                                            <template v-if="form.model.evaluationDurationHours != 0">
                                                {{ form.model.evaluationDurationHours }}
                                                <template v-if="form.model.evaluationDurationHours > 1"> hours </template>
                                                <template v-else> hour </template>
                                            </template>

                                            <template v-if="form.model.evaluationDurationMinutes != 0">
                                                {{ form.model.evaluationDurationMinutes }}
                                                <template v-if="form.model.evaluationDurationMinutes > 1"> minutes </template>
                                                <template v-else> minute </template>
                                            </template>
                                        </i>
                                    </template>
                                    <template v-else> - </template>
                                </p>
                                <hr />
                            </div>

                            <div v-if="form.model.evaluationHasTimeLimit && activityObj.hasStudentAccommodations" class="marginBottom20">
                                <label class="control-label"> Students with Accommodations </label>

                                <p class="greyText">
                                    <i>
                                        <template v-if="form.model.applySettingsToActivity && form.model.allowSpecialAccommodations">
                                            Applied
                                            <template v-if="accommodationsTypeSelected == 'Apply Extra Time'">
                                                <template v-if="filteredStudents.length == 1">
                                                    ({{ filteredStudents.length }}
                                                    Student)
                                                </template>
                                                <template v-else>
                                                    ({{ filteredStudents.length }}
                                                    Students)
                                                </template>
                                                - Add as Extra Time
                                            </template>
                                            <template v-if="accommodationsTypeSelected == 'Apply Early Start' && form.model.earlyStartDurationInMinutes">
                                                <template v-if="form.model.earlyStartStudents.length == 1">
                                                    ({{ form.model.earlyStartStudents.length }}
                                                    Student)
                                                </template>
                                                <template v-else>
                                                    ({{ form.model.earlyStartStudents.length }}
                                                    Students)
                                                </template>
                                                - Early Start
                                                {{ form.model.earlyStartDurationInMinutes }}
                                                Minutes
                                            </template>
                                        </template>
                                        <template v-else> - </template>
                                    </i>
                                </p>
                                <hr />
                            </div>

                            <div>
                                <div class="flexSpaceBetween alignBaseline">
                                    <label class="control-label" for="evaluationPasswordMd"> Student's Access Password (Optional) </label>
                                    <button
                                        class="padding0"
                                        @click.prevent="
                                            form.model.evaluationPassword = '';
                                            form.errors.clear('evaluationPassword');
                                        "
                                    >
                                        <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                    </button>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="evaluationPassword"
                                        :options="{
                                            id: 'evaluationPasswordMd',
                                            hasLabel: false,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>
        <kr-panel v-if="['peerEvaluationV2'].includes(activityObj.type)" :with-footer="false">
            <template #title>
                <h2>Publish Evaluation Activity</h2>
            </template>
            <template #content>
                <div class="flexSpaceBetween">
                    <div class="col-xs-12 col-md-6">
                        <div class="optionDiv">
                            <kr-radio-multiple
                                name="peerEvaluationV2Type"
                                display="Modality"
                                :form="form"
                                :items="[
                                    {
                                        value: 'asynchronous',
                                        description: 'Asynchronous',
                                    },
                                    {
                                        value: 'synchronous',
                                        description: 'Synchronous',
                                    },
                                ]"
                                item_value_idx="value"
                                item_desc_idx="description"
                                :options="{
                                    required: true,
                                }"
                            />
                            <p class="fontSize14">
                                <template v-if="form.model.peerEvaluationV2Type == 'asynchronous'">
                                    <i> All students will take the test at a different time. </i>
                                </template>
                                <template v-if="form.model.peerEvaluationV2Type == 'synchronous'">
                                    <i> All students will take the test at the same time. </i>
                                </template>
                            </p>
                        </div>
                        <div v-if="form.model.peerEvaluationV2Type == 'asynchronous'" class="marginTop40">
                            <div>
                                <label class="control-label"> Open Period<span class="important" aria-label="required">*</span> </label>
                                <p class="fontSize14 marginBottom10">
                                    <i>
                                        Students will be able to enter and
                                        <b>start</b> this activity anytime within the open period.
                                    </i>
                                </p>
                            </div>
                            <div v-if="form.model.peerEvaluationV2Type == 'asynchronous'" class="flexOnly">
                                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                                <!--<div class="col-xs-12 col-md-6" style="padding-right:10px">
                                <kr-date :form="form" name="applicationStartDate" :options="{minDate:activityObj.module.startDate,maxDate:form.model.applicationEndDate?form.model.applicationEndDate:activityObj.module.endDate,time:true,placeholder:'Select start date',hasLabel:false}"></kr-date>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <kr-date :form="form" name="applicationEndDate" :options="{maxDate:activityObj.module.endDate,minDate:form.model.applicationStartDate?form.model.applicationStartDate:activityObj.module.startDate,time:true,placeholder:'Select end date',hasLabel:false}"></kr-date>
                                </div>-->
                                <div class="paddingRight10">
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="peerEvaluationV2StartDate"
                                        display="Activity Start Date"
                                        :options="{
                                            startDate: form.model.peerEvaluationV2StartDate,
                                            maxDate: form.model.peerEvaluationV2EndDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                                <div>
                                    <kr-date
                                        class="no-error"
                                        :form="form"
                                        name="peerEvaluationV2EndDate"
                                        display="Activity End Date"
                                        :options="{
                                            startDate: form.model.peerEvaluationV2EndDate,
                                            minDate: form.model.peerEvaluationV2StartDate,
                                            time: true,
                                            placeholder: 'Type YYYY-MM-DD, HH:MM:SS',
                                        }"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="marginTop40">
                            <label class="control-label"> Activity Duration<span class="important" aria-label="required">*</span> </label>
                            <kr-toggle
                                name="peerEvaluationV2HasTimeLimit"
                                display="Set a Time Limit"
                                :form="form"
                                class="marginBottom15"
                                :options="{
                                    reverse: true,
                                }"
                                @changed="changedNoTimeLimit('peerEvaluationV2', 'timeLimitSwitch')"
                            />
                            <div v-if="form.model.peerEvaluationV2HasTimeLimit" class="flexOnly">
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="peerEvaluationV2DurationDays"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            hasLabel: false,
                                            dateWidth: true,
                                        }"
                                    >
                                        <template #addon> Days </template>
                                    </kr-field>
                                </div>
                                <div class="marginRight10">
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="peerEvaluationV2DurationHours"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 23,
                                            hasLabel: false,
                                            dateWidth: true,
                                        }"
                                    >
                                        <template #addon> Hours </template>
                                    </kr-field>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="peerEvaluationV2DurationMinutes"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 59,
                                            hasLabel: false,
                                            dateWidth: true,
                                        }"
                                    >
                                        <template #addon> Mins </template>
                                    </kr-field>
                                </div>
                            </div>
                        </div>

                        <div v-if="form.model.peerEvaluationV2HasTimeLimit && activityObj.hasStudentAccommodations" class="marginTop40">
                            <label class="control-label"> Students with Accommodations </label>

                            <!-- <kr-toggle
                                name="applySettingsToActivity"
                                display="Apply to this Activity"
                                :form="form"
                                class="marginBottom15"
                                :options="{
                                    reverse: true,
                                }"
                            /> -->

                            <p v-if="accommodationsTypeSelected == 'Apply Extra Time'" class="fontSize14">
                                <i> <b>Add as Extra Time</b> allows the student designated extra time after the activity ends. </i>
                                <br v-if="form.model.peerEvaluationV2Type == 'synchronous'" />
                                <i v-if="form.model.peerEvaluationV2Type == 'synchronous'">
                                    If you prefer students to have extra time before the activity, select the
                                    <b>Enable Early</b>
                                </i>
                                <br v-if="form.model.peerEvaluationV2Type == 'synchronous'" />
                                <i v-if="form.model.peerEvaluationV2Type == 'synchronous'"> <b>Start</b> option instead. </i>
                            </p>

                            <div class="marginTop10">
                                <div class="marginBottom10">
                                    <div class="inlineFlex align-items">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-default"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                @click.prevent="isMoreOptionsOpened = !isMoreOptionsOpened"
                                            >
                                                <template v-if="form.model.applySettingsToActivity && accommodationsTypeSelected == 'Apply Extra Time'">
                                                    <i class="fas fa-universal-access color-purple fs-24px marginRight8" aria-hidden="true" />
                                                    Add As Extra Time
                                                    <template v-if="filteredStudents.length > 0"> ({{ filteredStudents.length }}) </template>
                                                </template>
                                                <template v-else-if="form.model.applySettingsToActivity && accommodationsTypeSelected == 'Apply Early Start'">
                                                    Enable Early Start
                                                    <template v-if="form.model.earlyStartStudents.length > 0">
                                                        ({{ form.model.earlyStartStudents.length }})
                                                    </template>
                                                </template>
                                                <template v-else-if="!form.model.applySettingsToActivity"> Don't Apply </template>
                                                <i
                                                    :class="{
                                                        'fa fa-caret-up': !isMoreOptionsOpened,
                                                        'fa fa-caret-down': isMoreOptionsOpened,
                                                    }"
                                                    class="marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                                <ul>
                                                    <li>
                                                        <a
                                                            class="primary-state flexSpaceBetween"
                                                            href="#"
                                                            @click.prevent="
                                                                openAccommodateModal();
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            <span> Add As Extra Time </span>
                                                            <i class="fas fa-universal-access color-purple fs-24px" aria-hidden="true" />
                                                        </a>
                                                    </li>
                                                    <hr v-if="form.model.peerEvaluationV2Type == 'synchronous'" />
                                                    <li v-if="form.model.peerEvaluationV2Type == 'synchronous'">
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="
                                                                openEarlyStartModal();
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            Enable Early Start
                                                        </a>
                                                    </li>
                                                    <hr />
                                                    <li>
                                                        <a
                                                            class="primary-state"
                                                            href="#"
                                                            @click.prevent="
                                                                form.model.applySettingsToActivity = false;
                                                                isMoreOptionsOpened = !isMoreOptionsOpened;
                                                            "
                                                        >
                                                            Don't Apply
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <button
                                            v-if="form.model.applySettingsToActivity"
                                            class="btn btn-primary"
                                            @click="accommodationsTypeSelected == 'Apply Extra Time' ? openAccommodateModal() : openEarlyStartModal()"
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>

                                <div class="marginTop10 background-EFEFEF padding10 displayInlineBlock">
                                    <template v-if="form.model.applySettingsToActivity">
                                        <template v-if="accommodationsTypeSelected == 'Apply Extra Time'">
                                            <p class="fontSize14">
                                                <i> Only default accommodations set in Student Management can be applied here. </i>
                                                <br />
                                                <i> Further adjustments to add or remove extra time can be made after publishing. </i>
                                            </p>
                                        </template>
                                        <template v-else-if="accommodationsTypeSelected == 'Apply Early Start'">
                                            <div>
                                                <label class="control-label fontBlack">
                                                    Early Start Duration:
                                                    {{ form.model.earlyStartDurationInMinutes }}
                                                    Minutes
                                                </label>
                                            </div>

                                            <p class="fontSize14">
                                                <i> Early start allows selected students to access the activity for the above designated </i>
                                                <br />
                                                <i> duration, before it opens for all students. </i>
                                            </p>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <p class="fontSize14">
                                            <i> Default accommodations will not be applied for this activity. </i>
                                        </p>
                                    </template>
                                </div>
                            </div>

                            <div v-if="form.model.peerEvaluationV2Type == 'asynchronous'" class="panel-group marginTop10">
                                <div class="panel panel-default borderNone bs-0">
                                    <div class="panel-heading">
                                        <h3 class="panel-title">
                                            <button
                                                id="peerEvaluationV2Accommodations-label"
                                                class="padding0 flexSpaceBetween fontSize18 width100"
                                                aria-expanded="false"
                                                data-target="#peerEvaluationV2Accommodations"
                                                data-toggle="collapse"
                                                @click="showAccommodationExplanation = !showAccommodationExplanation"
                                            >
                                                Please take into account the remaining open period duration
                                                <i
                                                    class="fa marginLeft8"
                                                    :class="{
                                                        'fa-caret-down': !showAccommodationExplanation,
                                                        'fa-caret-up': showAccommodationExplanation,
                                                    }"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </h3>
                                    </div>
                                    <div
                                        id="peerEvaluationV2Accommodations"
                                        aria-labelledby="peerEvaluationV2Accommodations-label"
                                        class="panel-collapse collapse padding10"
                                    >
                                        <div class="panel-body padding0 borderNone">
                                            <p class="fontSize14">
                                                <i> If the time left is insufficient, the activity will close before extra time is finished. </i>
                                                <br />
                                                <i> You are advised to set an appropriate open period as required. </i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="marginTop30 hidden-md hidden-lg">
                                <div class="flexSpaceBetween">
                                    <label class="control-label" for="peerEvaluationV2Password"> Student's Access Password (Optional) </label>
                                    <button
                                        @click.prevent="
                                            form.model.peerEvaluationV2Password = '';
                                            form.errors.clear('peerEvaluationV2Password');
                                        "
                                    >
                                        <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                    </button>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="peerEvaluationV2Password"
                                        :options="{
                                            hasLabel: false,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="marginTop30 hidden-md hidden-lg">
                            <div class="flexSpaceBetween">
                                <label class="control-label" for="peerEvaluationV2Password"> Student's Access Password (Optional) </label>
                                <button
                                    @click.prevent="
                                        form.model.peerEvaluationV2Password = '';
                                        form.errors.clear('peerEvaluationV2Password');
                                    "
                                >
                                    <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                </button>
                            </div>
                            <div>
                                <kr-field
                                    class="no-error"
                                    :form="form"
                                    name="peerEvaluationV2Password"
                                    :options="{
                                        hasLabel: false,
                                    }"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 hidden-xs hidden-sm">
                        <label class="control-label d-block"> Publish Info </label>
                        <div class="borderBlack padding15 width100">
                            <div class="marginBottom20">
                                <label class="control-label"> Modality </label>
                                <p class="greyText capitalize">
                                    <i>{{ form.model.peerEvaluationV2Type }}</i>
                                </p>
                                <hr />
                            </div>

                            <div v-if="form.model.peerEvaluationV2Type == 'asynchronous'" class="marginBottom20">
                                <label class="control-label"> Open Period </label>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>Start</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.peerEvaluationV2StartDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.peerEvaluationV2StartDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <p class="greyText">
                                    <i>
                                        <span class="displayInlineBlock width38px">
                                            <b>End</b>
                                        </span>
                                        {{ convertToReadableDate(form.model.peerEvaluationV2EndDate, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(form.model.peerEvaluationV2EndDate, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </i>
                                </p>
                                <hr />
                            </div>

                            <div class="marginBottom20">
                                <label class="control-label"> Activity Duration </label>
                                <p class="greyText">
                                    <template v-if="form.model.peerEvaluationV2HasTimeLimit">
                                        <i>
                                            <template v-if="form.model.peerEvaluationV2DurationDays != 0">
                                                {{ form.model.peerEvaluationV2DurationDays }}
                                                <template v-if="form.model.peerEvaluationV2DurationDays > 1"> days </template>
                                                <template v-else> day </template>
                                            </template>

                                            <template v-if="form.model.peerEvaluationV2DurationHours != 0">
                                                {{ form.model.peerEvaluationV2DurationHours }}
                                                <template v-if="form.model.peerEvaluationV2DurationHours > 1"> hours </template>
                                                <template v-else> hour </template>
                                            </template>

                                            <template v-if="form.model.peerEvaluationV2DurationMinutes != 0">
                                                {{ form.model.peerEvaluationV2DurationMinutes }}
                                                <template v-if="form.model.peerEvaluationV2DurationMinutes > 1"> minutes </template>
                                                <template v-else> minute </template>
                                            </template>
                                        </i>
                                    </template>
                                    <template v-else> - </template>
                                </p>
                                <hr />
                            </div>

                            <div v-if="form.model.peerEvaluationV2HasTimeLimit && activityObj.hasStudentAccommodations" class="marginBottom20">
                                <label class="control-label"> Students with Accommodations </label>

                                <p class="greyText">
                                    <i>
                                        <template v-if="form.model.applySettingsToActivity && form.model.allowSpecialAccommodations">
                                            Applied
                                            <template v-if="accommodationsTypeSelected == 'Apply Extra Time'">
                                                <template v-if="filteredStudents.length == 1">
                                                    ({{ filteredStudents.length }}
                                                    Student)
                                                </template>
                                                <template v-else>
                                                    ({{ filteredStudents.length }}
                                                    Students)
                                                </template>
                                                - Add as Extra Time
                                            </template>
                                            <template v-if="accommodationsTypeSelected == 'Apply Early Start' && form.model.earlyStartDurationInMinutes">
                                                <template v-if="form.model.earlyStartStudents.length == 1">
                                                    ({{ form.model.earlyStartStudents.length }}
                                                    Student)
                                                </template>
                                                <template v-else>
                                                    ({{ form.model.earlyStartStudents.length }}
                                                    Students)
                                                </template>
                                                - Early Start
                                                {{ form.model.earlyStartDurationInMinutes }}
                                                Minutes
                                            </template>
                                        </template>
                                        <template v-else> - </template>
                                    </i>
                                </p>
                                <hr />
                            </div>

                            <div>
                                <div class="flexSpaceBetween alignBaseline">
                                    <label class="control-label" for="peerEvaluationV2PasswordMd"> Student's Access Password (Optional) </label>
                                    <button
                                        class="padding0"
                                        @click.prevent="
                                            form.model.peerEvaluationV2Password = '';
                                            form.errors.clear('peerEvaluationV2Password');
                                        "
                                    >
                                        <i class="fa fa-repeat marginRight8" aria-hidden="true" />Clear
                                    </button>
                                </div>
                                <div>
                                    <kr-field
                                        class="no-error"
                                        :form="form"
                                        name="peerEvaluationV2Password"
                                        :options="{
                                            id: 'peerEvaluationV2PasswordMd',
                                            hasLabel: false,
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>
        <div class="marginTop10">
            <div class="dropdown dropup">
                <button id="dropdownMenuButton" class="btn btn-success" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Publish Activity
                </button>

                <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                    <ul>
                        <li>
                            <button class="btn btn-default" @click.prevent="publish('course')" @keyup.space.prevent="publish('course')">
                                Publish Activity and Exit
                            </button>
                        </li>

                        <li>
                            <button class="btn btn-success" @click.prevent="publish('dashboard')" @keyup.space.prevent="publish('dashboard')">
                                Publish Activity and Go To Dashboard
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            :id="activityObj.type + '-accommodationsModal'"
            class="modal primary-modal"
            :class="activityObj.type + '-accommodationsModal'"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="activityObj.type + '-accommodationsModal-title'"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="activityObj.type + '-accommodationsModal-title'" class="modal-title">Add as Extra Time</h2>
                    </div>
                    <div class="modal-body">
                        <h2 class="justifyLeft">Extra Time Accommodations</h2>
                        <p class="greyText">
                            This list shows students in this activity with default extra time accommodations.
                            <br />
                            Extra time will be applied after the activity’s duration ends. Further adjustments to add or remove can be made after publishing.
                        </p>
                        <br />
                        <h3>
                            <mark class="greyHighlight">
                                {{ activityObj.name }}
                            </mark>
                        </h3>

                        <table class="width100 accommodationsStudentList tableBodyFixedHeight marginTop30">
                            <thead>
                                <th>Students ({{ filteredStudents.length }})</th>
                                <th>Default Accommodations</th>
                            </thead>
                            <tbody>
                                <tr v-for="student in filteredStudents" :key="student.id">
                                    <td>
                                        <i class="fas fa-universal-access color-purple fs-24px marginRight8" aria-hidden="true" />
                                        {{ student.displayName }}
                                    </td>
                                    <td class="color-dark-orange">
                                        <b>
                                            Has
                                            <template v-if="student.accommodations.multiple == '1.25x'"> 25% </template>
                                            <template v-else-if="student.accommodations.multiple == '1.5x'"> 50% </template>
                                            <template v-else-if="student.accommodations.multiple == '1.75x'"> 75% </template>
                                            <template v-else> 100% </template>
                                            extra time
                                        </b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click="closeAddAsExtraTime()">Confirm</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="earlyStartModal"
            class="modal primary-modal earlyStartModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="earlyStartModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="earlyStartModal-title" class="modal-title">Enable Early Start</h2>
                    </div>

                    <div class="modal-body">
                        <h2 class="justifyLeft">Early Start Settings</h2>
                        <p class="greyText">
                            Early start allows some students to access the activity for a designated duration before it opens for all students.
                        </p>
                        <p class="greyText">Select the students who will start early.</p>
                        <br />

                        <div class="flexSpaceBetween flexWrap">
                            <h3 class="marginRight20">
                                <mark class="greyHighlight">
                                    {{ activityObj.name }}
                                </mark>
                            </h3>
                            <div class="form-group form-search has-feedback has-feedback-left maxWidth200px">
                                <input v-model="searchData.q" type="text" class="form-control" placeholder="Search..." @keyup="search()" />
                                <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                            </div>
                        </div>

                        <div class="marginTop10">
                            <table class="width100 accommodationsStudentList tableBodyFixedHeight">
                                <thead>
                                    <tr>
                                        <th class="width100px">Checkbox</th>
                                        <th>
                                            Students ({{ setSelectedStudents.length }}
                                            selected)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="!searchData.isSearching || (searchData.isSearching && student._searchDisplay)">
                                        <template v-for="student in students" :key="student.id">
                                            <tr>
                                                <td class="width100px">
                                                    <div class="checkboxField justifyCenter">
                                                        <input
                                                            :id="'enableEarlyStartModal-' + student.id"
                                                            v-model="setSelectedStudents"
                                                            type="checkbox"
                                                            :value="student.id"
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <label :for="'enableEarlyStartModal-' + student.id" class="flexOnly align-items fw-normal fs-20px">
                                                        <i
                                                            v-if="student.accommodations.multiple != null"
                                                            class="fas fa-universal-access color-purple fs-24px marginRight8"
                                                            role="image"
                                                            alt="Student with accommodations"
                                                            aria-label="Student with accommodations"
                                                        />
                                                        {{ student.displayName }}
                                                    </label>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <tr v-if="searchData.isSearching && searchData.matched == 0">
                                        <td colspan="10" style="text-align: center">No search result</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="inlineFlex align-items marginTop30">
                            <label for="setEarlyStartDurations" class="fs-29px marginRight15">
                                Set early start duration<span class="important" aria-label="required">*</span>
                            </label>

                            <div class="modifyNumberGroup">
                                <div v-tooltip="setSelectedStudents.length == 0 ? 'Please select at least 1 student' : ''" class="numberInput">
                                    <input
                                        id="setEarlyStartDurations"
                                        v-model="setEarlyStartDurationInMinutes"
                                        v-tooltip="setSelectedStudents.length == 0 ? 'Please select at least 1 student' : ''"
                                        :aria-disabled="setSelectedStudents.length == 0 ? true : false"
                                        type="number"
                                        class="form-control fixedWidthNumber"
                                        :class="{
                                            'disabled hasTooltip': setSelectedStudents.length == 0,
                                        }"
                                        :readonly="setSelectedStudents.length == 0"
                                        min="1"
                                        @keypress="isNumber"
                                    />
                                    <span class="quantity-nav">
                                        <div
                                            class="quantity-button quantity-up"
                                            :class="{
                                                disabled: setSelectedStudents.length == 0,
                                            }"
                                            @click="setSelectedStudents.length > 0 ? setEarlyStartDurationInMinutes++ : ''"
                                        >
                                            <i class="fas fa-plus" aria-hidden="true" />
                                        </div>
                                        <div
                                            class="quantity-button quantity-down"
                                            :class="{
                                                disabled: setSelectedStudents.length == 0 || setEarlyStartDurationInMinutes == 0,
                                            }"
                                            @click="
                                                setEarlyStartDurationInMinutes > 0 && setSelectedStudents.length > 0 ? setEarlyStartDurationInMinutes-- : ''
                                            "
                                        >
                                            <i class="fas fa-minus" aria-hidden="true" />
                                        </div>
                                    </span>
                                </div>

                                <div class="input-group-addon widthAuto flex align-items">Mins</div>
                            </div>
                        </div>

                        <!-- <div
                            v-if="
                                form.model.earlyStartStudents.length > 0 &&
                                form.model.earlyStartDurationInMinutes == 0
                            "
                            id="earlyStartDuration_error"
                            class="invalid-feedback"
                            role="alert"
                            aria-atomic="true"
                        >
                            <div class="d-flex align-items-center important">
                                <i
                                    class="fa fa-times-circle"
                                    aria-hidden="true"
                                />
                                <span class="marginLeft2">
                                    Value cannot be 0
                                </span>
                            </div>
                        </div> -->
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllAccommodationsModal()">Cancel</button>
                        <button
                            v-tooltip="
                                !canEnableEarlyStart
                                    ? setSelectedStudents.length == 0
                                        ? 'Please select at least 1 student and input duration'
                                        : 'Please input duration'
                                    : ''
                            "
                            class="btn btn-success"
                            :class="{
                                'disabled hasToolTip': !canEnableEarlyStart,
                            }"
                            @click="canEnableEarlyStart ? earlyStartPublishCloseModal() : ''"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="activityObj.type + '-accommodationsModalTimeLimit'"
            class="modal primary-modal"
            :class="activityObj.type + '-accommodationsModalTimeLimit'"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="activityObj.type + '-accommodationsModalTimeLimit-title'"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="activityObj.type + '-accommodationsModalTimeLimit-title'" class="modal-title">Students With Accommodations</h2>
                    </div>
                    <div class="modal-body">
                        <div class="paddingLeft20 paddingRight20">
                            <p>
                                <b> This activity has students with default accommodations. Choose how to apply them. </b>
                            </p>

                            <h3 class="marginTop10 fs-20px">
                                <mark class="greyHighlight">
                                    {{ activityObj.name }}
                                </mark>
                            </h3>

                            <label class="fs-28px d-block marginTop30"> Settings </label>

                            <div
                                class="marginBottom30"
                                :class="{
                                    dropup: !setApplySettingsToActivityModal,
                                    dropdown: setApplySettingsToActivityModal,
                                }"
                            >
                                <button
                                    class="btn btn-default height36px"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    @click.prevent="isMoreOptionsOpenedModal = !isMoreOptionsOpenedModal"
                                >
                                    <template v-if="setAccommodationsTypeSelected == 'Apply Extra Time'">
                                        <i class="fas fa-universal-access color-purple fs-24px marginRight8" aria-hidden="true" />
                                        Add As Extra Time
                                        <template v-if="filteredStudents.length > 0"> ({{ filteredStudents.length }}) </template>
                                    </template>
                                    <template v-else-if="setAccommodationsTypeSelected == 'Apply Early Start'">
                                        Enable Early Start
                                        <template v-if="setSelectedStudentsModal.length > 0"> ({{ setSelectedStudentsModal.length }}) </template>
                                    </template>
                                    <template v-else-if="!setApplySettingsToActivityModal"> Don't Apply </template>
                                    <i
                                        :class="{
                                            'fa fa-caret-up': !isMoreOptionsOpenedModal,
                                            'fa fa-caret-down': isMoreOptionsOpenedModal,
                                        }"
                                        class="marginLeft8"
                                        aria-hidden="true"
                                    />
                                </button>

                                <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                    <ul>
                                        <li>
                                            <a
                                                class="primary-state flexSpaceBetween"
                                                href="#"
                                                @click.prevent="
                                                    setAccommodationsTypeSelected = 'Apply Extra Time';
                                                    setApplySettingsToActivityModal = true;
                                                    isMoreOptionsOpenedModal = !isMoreOptionsOpenedModal;
                                                "
                                            >
                                                <span> Add As Extra Time </span>
                                                <i class="fas fa-universal-access color-purple fs-24px" aria-hidden="true" />
                                            </a>
                                        </li>
                                        <hr
                                            v-if="
                                                form.model.iratType == 'synchronous' ||
                                                form.model.applicationType == 'synchronous' ||
                                                form.model.evaluationType == 'synchronous'
                                            "
                                        />
                                        <li
                                            v-if="
                                                form.model.iratType == 'synchronous' ||
                                                form.model.applicationType == 'synchronous' ||
                                                form.model.evaluationType == 'synchronous'
                                            "
                                        >
                                            <a
                                                class="primary-state"
                                                href="#"
                                                @click.prevent="
                                                    setAccommodationsTypeSelected = 'Apply Early Start';
                                                    setApplySettingsToActivityModal = true;
                                                    isMoreOptionsOpenedModal = !isMoreOptionsOpenedModal;
                                                "
                                            >
                                                Enable Early Start
                                            </a>
                                        </li>
                                        <hr />
                                        <li>
                                            <a
                                                class="primary-state"
                                                href="#"
                                                @click.prevent="
                                                    setApplySettingsToActivityModal = false;
                                                    isMoreOptionsOpenedModal = !isMoreOptionsOpenedModal;
                                                "
                                            >
                                                Don't Apply
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="table-border-accommodations">
                            <template v-if="!setApplySettingsToActivityModal">
                                <p>
                                    <i> Default accommodations will not be applied for this activity. </i>
                                </p>
                            </template>

                            <template v-else>
                                <template v-if="setAccommodationsTypeSelected == 'Apply Extra Time'">
                                    <h2 class="d-flex fs-28px">
                                        Add As Extra Time
                                        <button v-tooltip="'Only default accommodations set in Student Management can be applied'" class="greyText">
                                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                                        </button>
                                    </h2>
                                    <p class="greyText">
                                        This list shows students in this activity with default extra time accommodations.
                                        <br />
                                        Extra time will be applied after the activity’s duration ends. Further adjustments to add or remove can be made after
                                        publishing.
                                    </p>
                                    <br />

                                    <table class="width100 accommodationsStudentList tableBodyFixedHeight">
                                        <thead>
                                            <th>Students ({{ filteredStudents.length }})</th>
                                            <th>Default Accommodations</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="student in filteredStudents" :key="student.id">
                                                <td>
                                                    <i class="fas fa-universal-access color-purple fs-24px marginRight8" aria-hidden="true" />
                                                    {{ student.displayName }}
                                                </td>
                                                <td class="color-dark-orange">
                                                    <b>
                                                        Has
                                                        <template v-if="student.accommodations.multiple == '1.25x'"> 25% </template>
                                                        <template v-else-if="student.accommodations.multiple == '1.5x'"> 50% </template>
                                                        <template v-else-if="student.accommodations.multiple == '1.75x'"> 75% </template>
                                                        <template v-else> 100% </template>
                                                        extra time
                                                    </b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                                <template v-else-if="setAccommodationsTypeSelected == 'Apply Early Start'">
                                    <h2 class="justifyLeft fs-28px">Enable Early Start</h2>
                                    <p class="greyText">
                                        Early start allows some students to access the activity for a designated duration before it opens for all students.
                                        <br />
                                        Select the students who will start early.
                                    </p>
                                    <div class="flexSpaceBetween flexWrap">
                                        <div class="form-group form-search has-feedback has-feedback-left maxWidth200px">
                                            <input
                                                v-model="searchData.q"
                                                type="text"
                                                class="form-control height36px"
                                                placeholder="Search..."
                                                @keyup="search()"
                                            />
                                            <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                                        </div>
                                    </div>

                                    <div class="marginTop10">
                                        <table class="width100 accommodationsStudentList tableBodyFixedHeight">
                                            <thead>
                                                <th class="width100px">Checkbox</th>
                                                <th>
                                                    Students ({{ setSelectedStudents.length }}
                                                    selected)
                                                </th>
                                            </thead>
                                            <tbody>
                                                <template v-for="student in students">
                                                    <tr v-if="!searchData.isSearching || (searchData.isSearching && student._searchDisplay)">
                                                        <td class="width100px">
                                                            <div class="checkboxField justifyCenter">
                                                                <input
                                                                    :id="'earlyStart-' + student.id"
                                                                    v-model="setSelectedStudents"
                                                                    type="checkbox"
                                                                    :value="student.id"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <label :for="'earlyStart-' + student.id" class="flexOnly align-items">
                                                                <i
                                                                    v-if="student.accommodations.multiple != null"
                                                                    class="fas fa-universal-access color-purple fs-24px marginRight8"
                                                                    role="image"
                                                                    alt="Student with accommodations"
                                                                    aria-label="Student with accommodations"
                                                                />
                                                                {{ student.displayName }}
                                                            </label>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="searchData.isSearching && searchData.matched == 0">
                                                        <td colspan="10" style="text-align: center">No Search Result</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="inlineFlex align-items marginTop30">
                                        <label for="setEarlyStartDurations" class="fs-29px marginRight15">
                                            Set early start duration<span class="important" aria-label="required">*</span>
                                        </label>

                                        <div class="modifyNumberGroup">
                                            <div v-tooltip="setSelectedStudents.length == 0 ? 'Please select at least 1 student' : ''" class="numberInput">
                                                <input
                                                    id="setEarlyStartDurations"
                                                    v-model="setEarlyStartDurationInMinutes"
                                                    v-tooltip="setSelectedStudents.length == 0 ? 'Please select at least 1 student' : ''"
                                                    :aria-disabled="setSelectedStudents.length == 0 ? true : false"
                                                    type="number"
                                                    class="form-control fixedWidthNumber"
                                                    :class="{
                                                        'disabled hasTooltip': setSelectedStudents.length == 0,
                                                    }"
                                                    :readonly="setSelectedStudents.length == 0"
                                                    min="1"
                                                    @keypress="isNumber"
                                                />
                                                <span class="quantity-nav">
                                                    <div
                                                        class="quantity-button quantity-up"
                                                        :class="{
                                                            disabled: setSelectedStudents.length == 0,
                                                        }"
                                                        @click="setSelectedStudents.length > 0 ? setEarlyStartDurationInMinutes++ : ''"
                                                    >
                                                        <i class="fas fa-plus" aria-hidden="true" />
                                                    </div>
                                                    <div
                                                        class="quantity-button quantity-down"
                                                        :class="{
                                                            disabled: setSelectedStudents.length == 0 || setEarlyStartDurationInMinutes == 0,
                                                        }"
                                                        @click="
                                                            setEarlyStartDurationInMinutes > 0 && setSelectedStudents.length > 0
                                                                ? setEarlyStartDurationInMinutes--
                                                                : ''
                                                        "
                                                    >
                                                        <i class="fas fa-minus" aria-hidden="true" />
                                                    </div>
                                                </span>
                                            </div>

                                            <div class="input-group-addon widthAuto flex align-items">Mins</div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllAccommodationsModal()">Cancel</button>
                        <button
                            v-tooltip="
                                setAccommodationsTypeSelected == 'Apply Early Start' && !canEnableEarlyStart
                                    ? setSelectedStudents.length == 0
                                        ? 'Please select at least 1 student and input duration'
                                        : 'Please input duration'
                                    : ''
                            "
                            class="btn btn-success"
                            :class="{
                                'disabled hasToolTip': setAccommodationsTypeSelected == 'Apply Early Start' && !canEnableEarlyStart,
                            }"
                            data-dismiss="modal"
                            @click="
                                setAccommodationsTypeSelected == 'Apply Early Start'
                                    ? canEnableEarlyStart
                                        ? earlyStartPublishCloseModal()
                                        : ''
                                    : closeAddAsExtraTime('accommodationsSettingModal')
                            "
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../../../../components/auth/auth';
import KrForm from '../../../../../../components/forms/form';
export default {
    props: ['activityObj'],
    data() {
        return {
            auth: new KrAuth(),
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    iratHasTimeLimit: '',
                    iratType: '',
                    iratStartDate: '',
                    iratEndDate: '',
                    iratDurationDays: '',
                    iratDurationHours: '',
                    iratDurationMinutes: '',
                    iratPassword: '',
                    iratAllowSpecialAccommodations: '',

                    tratHasTimeLimit: '',
                    tratType: '',
                    tratStartDate: '',
                    tratEndDate: '',
                    tratDurationDays: '',
                    tratDurationHours: '',
                    tratDurationMinutes: '',
                    tratPassword: '',

                    preworkType: '',
                    preworkStartDate: '',
                    preworkEndDate: '',

                    applicationHasTimeLimit: '',
                    applicationType: '',
                    applicationStartDate: '',
                    applicationEndDate: '',
                    applicationDurationDays: '',
                    applicationDurationHours: '',
                    applicationDurationMinutes: '',
                    applicationPassword: '',
                    applicationAllowSpecialAccommodations: '',

                    evaluationHasTimeLimit: '',
                    evaluationType: '',
                    evaluationStartDate: '',
                    evaluationEndDate: '',
                    evaluationDurationDays: '',
                    evaluationDurationHours: '',
                    evaluationDurationMinutes: '',
                    evaluationPassword: '',
                    evaluationAllowSpecialAccommodations: '',

                    peerEvaluationV2HasTimeLimit: '',
                    peerEvaluationV2Type: '',
                    peerEvaluationV2StartDate: '',
                    peerEvaluationV2EndDate: '',
                    peerEvaluationV2DurationDays: '',
                    peerEvaluationV2DurationHours: '',
                    peerEvaluationV2DurationMinutes: '',
                    peerEvaluationV2Password: '',
                    peerEvaluationV2AllowSpecialAccommodations: '',

                    allowSpecialAccommodations: '',
                    earlyStartDurationInMinutes: '',
                    earlyStartStudents: '',

                    applySettingsToActivity: '',
                },
                props: {
                    successMessage: false,
                },
            }),
            iratAndTratSamePassword: false,
            showStudentList: false,
            showAccommodationExplanation: false,
            students: [],
            accommodationsTypes: [
                {
                    index: 'extraTime',
                    label: 'Apply Extra Time',
                    description:
                        'Extra time for students with accommodations will be applied after the activity’s duration ends. Further adjustments to add or remove can be made after publishing.',
                },
                {
                    index: 'earlyStart',
                    label: 'Apply Early Start',
                    description:
                        'Early start allows students with accommodations to access the activity for the above designated duration, before it opens for all students. ',
                },
            ],
            accommodationsTypeSelected: null,
            setAccommodationsTypeSelected: null,
            setAccommodationsTypeSelectedModal: null,
            isMoreOptionsOpened: false,
            isMoreOptionsOpenedModal: false,
            setSelectedStudents: [],
            setSelectedStudentsModal: [],
            selectAllStudents: false,
            searchData: { q: '', isSearching: false, matched: 0 }, //search extended students on filteredStudents*
            updateFromModal: false,
            setEarlyStartDurationInMinutes: '',
            setApplySettingsToActivity: '',
            setApplySettingsToActivityModal: '',
        };
    },
    computed: {
        filteredStudents() {
            return this.students.filter((student) => student.accommodations.multiple !== null);
        },
        canEnableEarlyStart() {
            if (
                (this.setSelectedStudents.length > 0 && this.setEarlyStartDurationInMinutes > 0) ||
                (this.setSelectedStudentsModal.length > 0 && this.setEarlyStartDurationInMinutesModal > 0)
            ) {
                return true;
            }
            return false;
        },
    },
    watch: {
        'form.model.applySettingsToActivity'(newValue) {
            this.setApplySettingsToActivity = newValue;
            this.setApplySettingsToActivityModal = newValue;

            if (newValue == false) {
                this.accommodationsTypeSelected = '';
                this.form.model.allowSpecialAccommodations = false;
            } else {
                this.form.model.allowSpecialAccommodations = true;
            }
        },
        accommodationsTypeSelected(newValue) {
            this.setAccommodationsTypeSelected = newValue;
            this.setAccommodationsTypeSelectedModal = newValue;
        },
        'form.model.earlyStartStudents'(newValue) {
            this.setSelectedStudents = newValue;
            this.setSelectedStudentsModal = newValue;
        },
        'form.model.earlyStartDurationInMinutes'(newValue) {
            this.setEarlyStartDurationInMinutes = newValue;
            this.setEarlyStartDurationInMinutesModal = newValue;
        },
        'form.model.iratType'() {
            if (this.form.model.applySettingsToActivity) {
                this.accommodationsTypeSelected = 'Apply Extra Time';
                this.form.model.earlyStartDurationInMinutes = 0;
                this.form.model.earlyStartStudents = [];
            } else {
                this.accommodationsTypeSelected = null;
                this.form.model.earlyStartDurationInMinutes = 0;
                this.form.model.earlyStartStudents = [];
            }
        },
        'form.model.applicationType'() {
            if (this.form.model.applySettingsToActivity) {
                this.accommodationsTypeSelected = 'Apply Extra Time';
                this.form.model.earlyStartDurationInMinutes = 0;
                this.form.model.earlyStartStudents = [];
            } else {
                this.accommodationsTypeSelected = null;
                this.form.model.earlyStartDurationInMinutes = 0;
                this.form.model.earlyStartStudents = [];
            }
        },
        'form.model.evaluationType'() {
            if (this.form.model.applySettingsToActivity) {
                this.accommodationsTypeSelected = 'Apply Extra Time';
                this.form.model.earlyStartDurationInMinutes = 0;
                this.form.model.earlyStartStudents = [];
            } else {
                this.accommodationsTypeSelected = null;
                this.form.model.earlyStartDurationInMinutes = 0;
                this.form.model.earlyStartStudents = [];
            }
        },
        'form.model.peerEvaluationV2Type'() {
            if (this.form.model.applySettingsToActivity) {
                this.accommodationsTypeSelected = 'Apply Extra Time';
                this.form.model.earlyStartDurationInMinutes = 0;
                this.form.model.earlyStartStudents = [];
            } else {
                this.accommodationsTypeSelected = null;
                this.form.model.earlyStartDurationInMinutes = 0;
                this.form.model.earlyStartStudents = [];
            }
        },
    },
    mounted() {
        this.init();
        this.getAccommodations();
        this.component_done_loading = true;
    },
    methods: {
        changedType(testType) {
            if (testType == 'irat') {
                if (this.form.model.iratType == 'asynchronous') {
                    this.form.model.iratHasTimeLimit = false;
                } else {
                    this.form.model.iratHasTimeLimit = true;
                }
                this.changedNoTimeLimit('irat');
            } else if (testType == 'trat') {
                if (this.form.model.tratType == 'asynchronous') {
                    this.form.model.tratHasTimeLimit = false;
                } else {
                    this.form.model.tratHasTimeLimit = true;
                }
                this.changedNoTimeLimit('trat');
            } else if (testType == 'application') {
                if (this.form.model.applicationType == 'asynchronous') {
                    this.form.model.applicationHasTimeLimit = false;
                } else {
                    this.form.model.applicationHasTimeLimit = true;
                }
                this.changedNoTimeLimit('application');
            } else if (['evaluation', 'michaelsenEvaluation'].includes(testType)) {
                if (this.form.model.evaluationType == 'asynchronous') {
                    this.form.model.evaluationHasTimeLimit = false;
                } else {
                    this.form.model.evaluationHasTimeLimit = true;
                }
                this.changedNoTimeLimit('evaluation');
            }
        },
        changedNoTimeLimit(testType, switchType) {
            if (testType == 'irat') {
                if (this.form.model.iratHasTimeLimit == false) {
                    this.form.setDisabled('iratDurationDays', true);
                    this.form.setDisabled('iratDurationHours', true);
                    this.form.setDisabled('iratDurationMinutes', true);
                } else {
                    this.form.setDisabled('iratDurationDays', false);
                    this.form.setDisabled('iratDurationHours', false);
                    this.form.setDisabled('iratDurationMinutes', false);
                }

                if (this.form.model.iratHasTimeLimit && this.activityObj.hasStudentAccommodations) {
                    this.form.model.applySettingsToActivity = true;

                    if (switchType == 'timeLimitSwitch') {
                        $('.' + this.activityObj.type + '-accommodationsModalTimeLimit').modal('show');
                    }
                }
            } else if (testType == 'trat') {
                if (this.form.model.tratHasTimeLimit == false) {
                    this.form.setDisabled('tratDurationDays', true);
                    this.form.setDisabled('tratDurationHours', true);
                    this.form.setDisabled('tratDurationMinutes', true);
                } else {
                    this.form.setDisabled('tratDurationDays', false);
                    this.form.setDisabled('tratDurationHours', false);
                    this.form.setDisabled('tratDurationMinutes', false);
                }
            } else if (testType == 'application') {
                if (this.form.model.applicationHasTimeLimit == false) {
                    this.form.setDisabled('applicationDurationDays', true);
                    this.form.setDisabled('applicationDurationHours', true);
                    this.form.setDisabled('applicationDurationMinutes', true);
                } else {
                    this.form.setDisabled('applicationDurationDays', false);
                    this.form.setDisabled('applicationDurationHours', false);
                    this.form.setDisabled('applicationDurationMinutes', false);
                }

                if (
                    this.form.model.applicationHasTimeLimit &&
                    this.activityObj.others.applicationType == 'individual' &&
                    this.activityObj.hasStudentAccommodations
                ) {
                    this.form.model.applySettingsToActivity = true;

                    if (switchType == 'timeLimitSwitch') {
                        $('.' + this.activityObj.type + '-accommodationsModalTimeLimit').modal('show');
                    }
                }
            } else if (['evaluation', 'michaelsenEvaluation'].includes(testType)) {
                if (this.form.model.evaluationHasTimeLimit == false) {
                    this.form.setDisabled('evaluationDurationDays', true);
                    this.form.setDisabled('evaluationDurationHours', true);
                    this.form.setDisabled('evaluationDurationMinutes', true);
                } else {
                    this.form.setDisabled('evaluationDurationDays', false);
                    this.form.setDisabled('evaluationDurationHours', false);
                    this.form.setDisabled('evaluationDurationMinutes', false);
                }

                if (this.form.model.evaluationHasTimeLimit && this.activityObj.hasStudentAccommodations) {
                    this.form.model.applySettingsToActivity = true;

                    if (switchType == 'timeLimitSwitch') {
                        $('.' + this.activityObj.type + '-accommodationsModalTimeLimit').modal('show');
                    }
                }
            } else if (['peerEvaluationV2'].includes(testType)) {
                if (this.form.model.peerEvaluationV2HasTimeLimit == false) {
                    this.form.setDisabled('peerEvaluationV2DurationDays', true);
                    this.form.setDisabled('peerEvaluationV2DurationHours', true);
                    this.form.setDisabled('peerEvaluationV2DurationMinutes', true);
                } else {
                    this.form.setDisabled('peerEvaluationV2DurationDays', false);
                    this.form.setDisabled('peerEvaluationV2DurationHours', false);
                    this.form.setDisabled('peerEvaluationV2DurationMinutes', false);
                }

                if (this.form.model.peerEvaluationV2HasTimeLimit && this.activityObj.hasStudentAccommodations) {
                    this.form.model.applySettingsToActivity = true;

                    if (switchType == 'timeLimitSwitch') {
                        $('.' + this.activityObj.type + '-accommodationsModalTimeLimit').modal('show');
                    }
                }
            }
        },
        changeAllowSpecialAccommodations(testType) {
            if (testType == 'irat') {
                if (this.form.model.iratAllowSpecialAccommodations == true) {
                    this.form.model.iratAllowSpecialAccommodations == true;
                    this.form.model.allowSpecialAccommodations = true;
                } else {
                    this.form.model.iratAllowSpecialAccommodations == false;
                    this.form.model.allowSpecialAccommodations = false;
                }
            } else if (testType == 'application') {
                if (this.form.model.applicationAllowSpecialAccommodations == true) {
                    this.form.model.applicationAllowSpecialAccommodations == true;
                    this.form.model.allowSpecialAccommodations = true;
                } else {
                    this.form.model.applicationAllowSpecialAccommodations == false;
                    this.form.model.allowSpecialAccommodations = false;
                }
            } else if (testType == 'evaluation' || testType == 'michaelsenEvaluation') {
                if (this.form.model.evaluationAllowSpecialAccommodations == true) {
                    this.form.model.evaluationAllowSpecialAccommodations == true;
                    this.form.model.allowSpecialAccommodations = true;
                } else {
                    this.form.model.evaluationAllowSpecialAccommodations == false;
                    this.form.model.allowSpecialAccommodations = false;
                }
            } else if (testType == 'peerEvaluationV2') {
                if (this.form.model.peerEvaluationV2AllowSpecialAccommodations == true) {
                    this.form.model.peerEvaluationV2AllowSpecialAccommodations == true;
                    this.form.model.allowSpecialAccommodations = true;
                } else {
                    this.form.model.peerEvaluationV2AllowSpecialAccommodations == false;
                    this.form.model.allowSpecialAccommodations = false;
                }
            }
        },
        publish(event) {
            var that = this;
            if (['irat', 'trat', 'iratntrat'].includes(this.activityObj.type)) {
                /*if(this.form.model.iratType=='asynchronous'){
						this.form.model.iratPassword='';
					}
					if(this.form.model.tratType=='asynchronous'){
						this.form.model.tratPassword='';
					}*/
            }

            this.form.post('activities/' + this.activityObj.uuid + '/publish').then(function (response) {
                if (!response.krFormError) {
                    /*let tests = response.data.data;
						if(_.isArray(tests)){
							that.$emit('published',tests[0].uuid);
						}else{

						}*/
                    that.$emit('published', event);
                } else {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Please check and resave questions',
                    });
                    that.$emit('errors');
                }
            });
        },
        init() {
            this.setEarlyStartDurationInMinutes = 0;
            this.setEarlyStartDurationInMinutesModal = 0;

            if (this.activityObj.type == 'irat') {
                this.form.model.iratHasTimeLimit = _.get(this.activityObj, 'settings.irat.hasTimeLimit', true);
                this.form.model.iratType = _.get(this.activityObj, 'settings.irat.type', 'asynchronous');
                this.form.model.iratStartDate = _.get(this.activityObj, 'settings.irat.startDate', '');
                this.form.model.iratEndDate = _.get(this.activityObj, 'settings.irat.endDate', '');
                this.form.model.iratDurationDays = _.get(this.activityObj, 'settings.irat.durationDays');
                this.form.model.iratDurationHours = _.get(this.activityObj, 'settings.irat.durationHours');
                this.form.model.iratDurationMinutes = _.get(this.activityObj, 'settings.irat.durationMinutes');

                if (!this.form.model.iratDurationDays && !this.form.model.iratDurationHours && !this.form.model.iratDurationMinutes) {
                    this.form.model.iratDurationDays = _.get(this.activityObj, 'defaultIratSettings.durationDays', 0);
                    this.form.model.iratDurationHours = _.get(this.activityObj, 'defaultIratSettings.durationHours', 0);
                    this.form.model.iratDurationMinutes = _.get(this.activityObj, 'defaultIratSettings.durationMinutes', 20);
                }
                if (!this.form.model.iratStartDate) {
                    this.form.model.iratStartDate = this.activityObj.module.startDate;
                }
                if (!this.form.model.iratEndDate) {
                    this.form.model.iratEndDate = this.activityObj.module.endDate;
                }
                this.form.model.iratPassword = _.get(this.activityObj, 'settings.irat.password', '');

                if (this.form.model.iratHasTimeLimit == true && this.activityObj.hasStudentAccommodations) {
                    this.form.model.applySettingsToActivity = true;
                    this.accommodationsTypeSelected = 'Apply Extra Time';
                } else {
                    this.form.model.applySettingsToActivity = false;
                    this.accommodationsTypeSelected = null;
                }
            }
            if (this.activityObj.type == 'trat') {
                this.form.model.tratHasTimeLimit = _.get(this.activityObj, 'settings.trat.hasTimeLimit', true);
                this.form.model.tratType = _.get(this.activityObj, 'settings.trat.type', 'asynchronous');
                this.form.model.tratStartDate = _.get(this.activityObj, 'settings.trat.startDate', '');
                this.form.model.tratEndDate = _.get(this.activityObj, 'settings.trat.endDate', '');
                this.form.model.tratDurationDays = _.get(this.activityObj, 'settings.trat.durationDays');
                this.form.model.tratDurationHours = _.get(this.activityObj, 'settings.trat.durationHours');
                this.form.model.tratDurationMinutes = _.get(this.activityObj, 'settings.trat.durationMinutes');
                if (!this.form.model.tratDurationDays && !this.form.model.tratDurationHours && !this.form.model.tratDurationMinutes) {
                    this.form.model.tratDurationDays = _.get(this.activityObj, 'defaultTratSettings.durationDays', 0);
                    this.form.model.tratDurationHours = _.get(this.activityObj, 'defaultTratSettings.durationHours', 0);
                    this.form.model.tratDurationMinutes = _.get(this.activityObj, 'defaultTratSettings.durationMinutes', 20);
                }
                if (!this.form.model.tratStartDate) {
                    this.form.model.tratStartDate = this.activityObj.module.startDate;
                }
                if (!this.form.model.tratEndDate) {
                    this.form.model.tratEndDate = this.activityObj.module.endDate;
                }
                this.form.model.tratPassword = _.get(this.activityObj, 'settings.trat.password', '');
            }
            if (this.activityObj.type == 'iratntrat') {
                this.form.model.iratHasTimeLimit = _.get(this.activityObj, 'settings.irat.hasTimeLimit', true);
                this.form.model.iratType = _.get(this.activityObj, 'settings.irat.type', 'asynchronous');
                this.form.model.iratStartDate = _.get(this.activityObj, 'settings.irat.startDate', '');
                this.form.model.iratEndDate = _.get(this.activityObj, 'settings.irat.endDate', '');
                this.form.model.iratDurationDays = _.get(this.activityObj, 'settings.irat.durationDays');
                this.form.model.iratDurationHours = _.get(this.activityObj, 'settings.irat.durationHours');
                this.form.model.iratDurationMinutes = _.get(this.activityObj, 'settings.irat.durationMinutes');
                this.form.model.iratPassword = _.get(this.activityObj, 'settings.irat.password', '');

                if (this.form.model.iratHasTimeLimit == true && this.activityObj.hasStudentAccommodations) {
                    this.form.model.applySettingsToActivity = true;
                    this.accommodationsTypeSelected = 'Apply Extra Time';
                } else {
                    this.form.model.applySettingsToActivity = false;
                    this.accommodationsTypeSelected = null;
                }

                this.form.model.tratHasTimeLimit = _.get(this.activityObj, 'settings.trat.hasTimeLimit', true);
                this.form.model.tratType = _.get(this.activityObj, 'settings.trat.type', 'asynchronous');
                this.form.model.tratStartDate = _.get(this.activityObj, 'settings.trat.startDate', '');
                this.form.model.tratEndDate = _.get(this.activityObj, 'settings.trat.endDate', '');
                this.form.model.tratDurationDays = _.get(this.activityObj, 'settings.trat.durationDays');
                this.form.model.tratDurationHours = _.get(this.activityObj, 'settings.trat.durationHours');
                this.form.model.tratDurationMinutes = _.get(this.activityObj, 'settings.trat.durationMinutes');
                this.form.model.tratPassword = _.get(this.activityObj, 'settings.trat.password', '');
                if (!this.form.model.iratDurationDays && !this.form.model.iratDurationHours && !this.form.model.iratDurationMinutes) {
                    this.form.model.iratDurationDays = _.get(this.activityObj, 'defaultIratSettings.durationDays', 0);
                    this.form.model.iratDurationHours = _.get(this.activityObj, 'defaultIratSettings.durationHours', 0);
                    this.form.model.iratDurationMinutes = _.get(this.activityObj, 'defaultIratSettings.durationMinutes', 20);
                }

                if (!this.form.model.tratDurationDays && !this.form.model.tratDurationHours && !this.form.model.tratDurationMinutes) {
                    this.form.model.tratDurationDays = _.get(this.activityObj, 'defaultTratSettings.durationDays', 0);
                    this.form.model.tratDurationHours = _.get(this.activityObj, 'defaultTratSettings.durationHours', 0);
                    this.form.model.tratDurationMinutes = _.get(this.activityObj, 'defaultTratSettings.durationMinutes', 20);
                }

                if (!this.form.model.iratStartDate) {
                    this.form.model.iratStartDate = this.activityObj.module.startDate;
                }
                if (!this.form.model.iratEndDate) {
                    this.form.model.iratEndDate = this.activityObj.module.endDate;
                }
                if (!this.form.model.tratStartDate) {
                    this.form.model.tratStartDate = this.activityObj.module.startDate;
                }
                if (!this.form.model.tratEndDate) {
                    this.form.model.tratEndDate = this.activityObj.module.endDate;
                }
            }
            if (this.activityObj.type == 'prework') {
                this.form.model.preworkType = 'asynchronous';
                this.form.model.preworkStartDate = _.get(this.activityObj, 'settings.prework.startDate', '');
                this.form.model.preworkEndDate = _.get(this.activityObj, 'settings.prework.endDate', '');

                if (!this.form.model.preworkStartDate) {
                    this.form.model.preworkStartDate = this.activityObj.module.startDate;
                }
                if (!this.form.model.preworkEndDate) {
                    this.form.model.preworkEndDate = this.activityObj.module.endDate;
                }
            }
            if (this.activityObj.type == 'application') {
                this.form.model.applicationHasTimeLimit = _.get(this.activityObj, 'settings.application.hasTimeLimit', false);
                this.form.model.applicationType = _.get(this.activityObj, 'settings.application.type', 'asynchronous');
                this.form.model.applicationStartDate = _.get(this.activityObj, 'settings.application.startDate', '');
                this.form.model.applicationEndDate = _.get(this.activityObj, 'settings.application.endDate', '');
                this.form.model.applicationDurationDays = _.get(this.activityObj, 'settings.application.durationDays');
                this.form.model.applicationDurationHours = _.get(this.activityObj, 'settings.application.durationHours');
                this.form.model.applicationDurationMinutes = _.get(this.activityObj, 'settings.application.durationMinutes');

                if (!this.form.model.applicationDurationDays && !this.form.model.applicationDurationHours && !this.form.model.applicationDurationMinutes) {
                    this.form.model.applicationDurationDays = _.get(this.activityObj, 'defaultApplicationSettings.durationDays', 0);
                    this.form.model.applicationDurationHours = _.get(this.activityObj, 'defaultApplicationSettings.durationHours', 0);
                    this.form.model.applicationDurationMinutes = _.get(this.activityObj, 'defaultApplicationSettings.durationMinutes', 20);
                }

                if (!this.form.model.applicationStartDate) {
                    this.form.model.applicationStartDate = this.activityObj.module.startDate;
                }
                if (!this.form.model.applicationEndDate) {
                    this.form.model.applicationEndDate = this.activityObj.module.endDate;
                }
                this.form.model.applicationPassword = _.get(this.activityObj, 'settings.application.password', '');

                if (
                    this.form.model.applicationHasTimeLimit == true &&
                    this.activityObj.others.applicationType == 'individual' &&
                    this.activityObj.hasStudentAccommodations
                ) {
                    this.form.model.applySettingsToActivity = true;
                    this.accommodationsTypeSelected = 'Apply Extra Time';
                } else {
                    this.form.model.applySettingsToActivity = false;
                    this.accommodationsTypeSelected = null;
                }
            }
            if (['evaluation', 'michaelsenEvaluation'].includes(this.activityObj.type)) {
                this.form.model.evaluationType = _.get(this.activityObj, 'settings.evaluation.type', 'asynchronous');
                if (this.form.model.evaluationType == null) {
                    this.form.model.evaluationType = 'asynchronous';
                }
                this.form.model.evaluationHasTimeLimit = _.get(this.activityObj, 'settings.evaluation.hasTimeLimit', false);
                this.form.model.evaluationStartDate = _.get(this.activityObj, 'settings.evaluation.startDate', '');
                this.form.model.evaluationEndDate = _.get(this.activityObj, 'settings.evaluation.endDate', '');
                this.form.model.evaluationDurationDays = _.get(this.activityObj, 'settings.evaluation.durationDays', 0);
                this.form.model.evaluationDurationHours = _.get(this.activityObj, 'settings.evaluation.durationHours', 10);
                this.form.model.evaluationDurationMinutes = _.get(this.activityObj, 'settings.evaluation.durationMinutes', 0);

                if (!this.form.model.evaluationDurationDays && !this.form.model.evaluationDurationHours && !this.form.model.evaluationDurationMinutes) {
                    this.form.model.evaluationDurationDays = 7;
                }

                if (!this.form.model.evaluationStartDate) {
                    this.form.model.evaluationStartDate = this.activityObj.module.startDate;
                }
                if (!this.form.model.evaluationEndDate) {
                    this.form.model.evaluationEndDate = this.activityObj.module.endDate;
                }
                this.form.model.evaluationPassword = _.get(this.activityObj, 'settings.evaluation.password', '');

                if (this.form.model.evaluationHasTimeLimit == true && this.activityObj.hasStudentAccommodations) {
                    this.form.model.applySettingsToActivity = true;
                    this.accommodationsTypeSelected = 'Apply Extra Time';
                } else {
                    this.form.model.applySettingsToActivity = false;
                    this.accommodationsTypeSelected = null;
                }
            }
            if (['peerEvaluationV2'].includes(this.activityObj.type)) {
                this.form.model.peerEvaluationV2Type = _.get(this.activityObj, 'settings.peerEvaluationV2.type', 'asynchronous');
                if (this.form.model.peerEvaluationV2Type == null) {
                    this.form.model.peerEvaluationV2Type = 'asynchronous';
                }
                this.form.model.peerEvaluationV2HasTimeLimit = _.get(this.activityObj, 'settings.peerEvaluationV2.hasTimeLimit', false);
                this.form.model.peerEvaluationV2StartDate = _.get(this.activityObj, 'settings.peerEvaluationV2.startDate', '');
                this.form.model.peerEvaluationV2EndDate = _.get(this.activityObj, 'settings.peerEvaluationV2.endDate', '');
                this.form.model.peerEvaluationV2DurationDays = _.get(this.activityObj, 'settings.peerEvaluationV2.durationDays', 0);
                this.form.model.peerEvaluationV2DurationHours = _.get(this.activityObj, 'settings.peerEvaluationV2.durationHours', 10);
                this.form.model.peerEvaluationV2DurationMinutes = _.get(this.activityObj, 'settings.peerEvaluationV2.durationMinutes', 0);

                if (
                    !this.form.model.peerEvaluationV2DurationDays &&
                    !this.form.model.peerEvaluationV2DurationHours &&
                    !this.form.model.peerEvaluationV2DurationMinutes
                ) {
                    this.form.model.peerEvaluationV2DurationDays = 7;
                }

                if (!this.form.model.peerEvaluationV2StartDate) {
                    this.form.model.peerEvaluationV2StartDate = this.activityObj.module.startDate;
                }
                if (!this.form.model.peerEvaluationV2EndDate) {
                    this.form.model.peerEvaluationV2EndDate = this.activityObj.module.endDate;
                }
                this.form.model.peerEvaluationV2Password = _.get(this.activityObj, 'settings.peerEvaluationV2.password', '');

                if (this.form.model.peerEvaluationV2HasTimeLimit == true && this.activityObj.hasStudentAccommodations) {
                    this.form.model.applySettingsToActivity = true;
                    this.accommodationsTypeSelected = 'Apply Extra Time';
                } else {
                    this.form.model.applySettingsToActivity = false;
                    this.accommodationsTypeSelected = null;
                }
            }
            this.changedType(this.activityObj.type);
        },
        getAccommodations() {
            var that = this;

            axios.get(`courses/${this.activityObj.course.uuid}/accommodations`).then(function (response) {
                that.students = response.data.data;

                that.students.sort((a, b) => {
                    if (a.accommodations.multiple === null && b.accommodations.multiple === null) {
                        return 0;
                    } else if (a.accommodations.multiple === null) {
                        return 1;
                    } else if (b.accommodations.multiple === null) {
                        return -1;
                    } else {
                        return a.accommodations.multiple.localeCompare(b.accommodations.multiple);
                    }
                });
            });
        },
        openAccommodateModal() {
            $('.' + this.activityObj.type + '-accommodationsModal').modal('show');
        },
        openEarlyStartModal() {
            $('.earlyStartModal').modal('show');
        },
        toggleStudents() {
            this.setSelectedStudents = [];

            if (this.selectAllStudents) {
                for (var i = 0; i < this.students.length; i++) {
                    this.setSelectedStudents.push(this.students[i].id);
                }
            }
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        search() {
            if (this.searchData.q != '') {
                this.searchData.isSearching = true;
                this.searchData.matched = 0;
                for (var i = 0; i < this.students.length; i++) {
                    let student = this.students[i];
                    let haystack = student.displayName;
                    let doesContain = haystack.indexOf(this.searchData.q);
                    if (doesContain != -1) {
                        student._searchDisplay = true;
                        //Vue.set(student, '_searchDisplay', true);
                        this.searchData.matched++;
                    } else {
                        student._searchDisplay = false;
                        //Vue.set(student, '_searchDisplay', false);
                    }
                }
            } else {
                this.searchData.isSearching = false;
            }
        },
        closeAddAsExtraTime(type) {
            $('.modal').modal('hide');

            if (type == 'accommodationsSettingModal') {
                if (this.setApplySettingsToActivityModal) {
                    this.form.model.applySettingsToActivity = true;
                    this.accommodationsTypeSelected = 'Apply Extra Time';
                } else {
                    this.form.model.applySettingsToActivity = false;
                }
            } else {
                this.form.model.applySettingsToActivity = true;
                this.accommodationsTypeSelected = 'Apply Extra Time';
            }
        },
        earlyStartPublishCloseModal() {
            $('.modal').modal('hide');
            this.searchData.q = '';
            this.search();

            this.form.model.applySettingsToActivity = true;
            this.accommodationsTypeSelected = 'Apply Early Start';
            this.form.model.earlyStartStudents = this.setSelectedStudents;
            this.form.model.earlyStartDurationInMinutes = this.setEarlyStartDurationInMinutes;
        },
        closeAllAccommodationsModal() {
            this.searchData.q = '';
            this.search();
            $('.modal').modal('hide');

            this.setSelectedStudents = this.form.model.earlyStartStudents;
            this.setEarlyStartDurationInMinutes = this.form.model.earlyStartDurationInMinutes;
        },
    },
};
</script>

<style scoped lang="scss">
.input-group.date {
    margin-bottom: 0;
}

.width117px {
    width: 117px;
}

#earlyStartDurationInMinutes-div {
    width: fit-content;
}

.modifyNumberGroup {
    display: inline-flex;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        border-radius: 3px;
    }

    .numberInput {
        position: relative;
        display: inline-block;
    }

    .form-control {
        outline: 0;
        outline-offset: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        width: 64px;

        &.disabled {
            border: none;
            &.hasTooltip {
                pointer-events: inherit;
            }
        }
    }

    .quantity-nav {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 10px;
        color: #222;
        z-index: 4;
        height: 100%;
        max-height: 100%;

        .quantity-button {
            min-height: 18px;
            height: 50%;
            display: flex;
            align-items: center;
            width: 22px;
            justify-content: center;
            border: 1px solid #717171;
            background-image: linear-gradient(to bottom, #ffffff, #eff2f6);

            &:last-child {
                border-top: 1px solid #717171;
            }

            &.disabled {
                background: #efefef;
                color: #8b8b8f;
                cursor: not-allowed;
            }
        }
    }
}
</style>
