<template>
    <div ref="excalidraw" class="excalidraw">Loading...</div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { Excalidraw } from '@excalidraw/excalidraw';

const excalidraw = ref(null);
let root;
onMounted(() => {
    root = createRoot(excalidraw.value);
    root.render(
        React.createElement(Excalidraw, {
            // props
        })
    );
});
onUnmounted(() => {
    if (root) {
        root.unmount();
    }
});
</script>

<style>
.excalidraw {
    height: 100vh;
    width: 100vw;
}
.excalidraw .dropdown-menu {
    position: absolute;
    top: 100%;
    margin-top: 0.25rem;
    display: block !important;
}
</style>
