<template>
    <div>
        <div class="col-xs-12 right-container">
            <div class="stepsNaviFixed nav-tabsContainer">
                <div class="links">
                    <ul>
                        <li :class="{ active: tab == 'templates' }" @click.prevent="tab = 'templates'">
                            <a class="nav-link uppercase" data-toggle="tab" href="#templates"> Templates </a>
                        </li>
                        <li :class="{ active: tab == 'questions' }" @click.prevent="tab = 'questions'">
                            <a class="nav-link uppercase" data-toggle="tab" href="#questions"> Questions </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="tab-content">
                <div id="questions" class="tab-pane fade" :class="{ 'in active': tab == 'questions' }" role="tabpanel">
                    <div class="pageTitle">
                        <h1>Questions</h1>
                        <p class="col-md-6">
                            Questions are the foundation for tests and exercises. In this space, you will be able to create new questions for your activities,
                            import questions using simple templates and filter/view the questions you have already stored in your question bank.
                            <a href="https://help.intedashboard.com/how-do-i-add-questions-to-an-activity-using-the-question-bank-method" target="newTab">
                                <i class="fas fa-info-circle" aria-hidden="true" />
                                Basic Information on the InteDashboard Question Bank Feature
                            </a>
                        </p>
                    </div>

                    <questions-bank />
                </div>

                <div id="templates" class="tab-pane fade" :class="{ 'in active': tab == 'templates' }" role="tabpanel">
                    <div class="pageTitle">
                        <h1>Templates</h1>
                        <p class="col-md-6">
                            Templates are preset questions you can use to populate your activity. Click the Actions button to view or use the templates.
                        </p>
                    </div>

                    <templates-listing />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        'templates-listing': require(`./library-templates/index.vue`).default,
        'questions-bank': require(`./questions-bank/index.vue`).default,
    },
    data() {
        return {
            tab: 'templates',
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fa fa-list-ul"></i>&nbsp;Questions',
            breadcrumb: [{ name: 'Home' }, { name: 'Library', link: 'questions.index' }],
        });
    },
};
</script>
