x
<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div v-if="auth.user().roleId == 2 || isProfile" class="links">
            <ul role="tablist" aria-label="Tabs">
                <li class="active">
                    <a class="nav-link" data-toggle="tab" href="#student">
                        {{ $t('modules.students.view.studentDetailsTabLabel') }}
                    </a>
                </li>
                <li v-if="auth.user().roleId == 2">
                    <a class="nav-link" data-toggle="tab" href="#courses"> {{ $t('modules.students.view.coursesTabLabel') }} </a>
                </li>
                <li v-if="isProfile && APP_ENV !== 'production'">
                    <a class="nav-link" data-toggle="tab" href="#receipt">{{ $t('modules.students.view.receiptsTabLabel') }}</a>
                </li>
            </ul>
        </div>
        <div v-if="auth.user().roleId <= 2 || auth.user().uuid == student.uuid" class="tab-content">
            <div id="student" class="tab-pane fade in active">
                <div class="pageTitle">
                    <h1>Student Details</h1>
                </div>

                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-12 col-sm-6">
                            <template v-if="isProfile">
                                <h2>My Profile</h2>
                            </template>
                            <template v-else>
                                <h2>{{ student.displayName }} Profile</h2>
                            </template>
                        </div>

                        <div class="col-xs-12 col-sm-6" :class="{ textAlignRight: !isBelow768px, marginTop10: isBelow768px }">
                            <template v-if="!student.isSuspended">
                                <router-link
                                    v-if="isProfile"
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'profile.edit',
                                        params: $route.params.id,
                                    }"
                                    custom
                                >
                                    <button
                                        v-tooltip="auth.isImpersonation() ? 'Impersonation cannot edit profile' : ''"
                                        class="btn btn-default"
                                        :class="{ 'disabled hasTooltip': auth.isImpersonation() }"
                                        :aria-disabled="auth.isImpersonation()"
                                        @click="auth.isImpersonation() ? '' : navigate()"
                                    >
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" /> Edit
                                    </button>
                                </router-link>
                                <router-link
                                    v-else-if="!isFromCourse && auth.user().roleId == 2"
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'students.edit',
                                        params: { id: $route.params.id },
                                    }"
                                    custom
                                >
                                    <button
                                        v-tooltip="auth.isImpersonation() ? 'Impersonation cannot edit profile' : ''"
                                        class="btn btn-default"
                                        :class="{ 'disabled hasTooltip': auth.isImpersonation() }"
                                        :aria-disabled="auth.isImpersonation()"
                                        @click="auth.isImpersonation() ? '' : navigate()"
                                    >
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" /> Edit
                                    </button>
                                </router-link>
                                <router-link
                                    v-else-if="auth.user().roleId == 2"
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'courses.manage.students.edit',
                                        params: {
                                            course_id: $route.params.course_id,
                                            id: $route.params.id,
                                        },
                                    }"
                                    custom
                                >
                                    <button
                                        v-tooltip="auth.isImpersonation() ? 'Impersonation cannot edit profile' : ''"
                                        class="btn btn-default"
                                        :class="{ 'disabled hasTooltip': auth.isImpersonation() }"
                                        :aria-disabled="auth.isImpersonation()"
                                        @click="auth.isImpersonation() ? '' : navigate()"
                                    >
                                        <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" /> Edit
                                    </button>
                                </router-link>
                            </template>
                            <button
                                v-if="!student.isSuspended && !isProfile && student.email"
                                class="btn btn-default"
                                @click.prevent="openResetPasswordModal()"
                            >
                                <i class="fa fa-key marginRight8" aria-hidden="true"></i>{{ $t('modules.students.view.resetPasswordButtonLabel') }}
                            </button>
                        </div>
                    </template>
                    <template #content>
                        <div class="col-xs-12 col-md-6 viewFullDetails">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.fullnameLabel')) }}</label>
                                        <p class="form-control-static">
                                            {{ student.displayName }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.emailLabel')) }}</label>
                                        <p class="form-control-static">
                                            {{ student.email ? student.email : '-' }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.statusLabel')) }}</label>
                                        <p class="form-control-static align-items">
                                            <i
                                                class="fa fa-circle marginRight8"
                                                :class="{
                                                    suspendCircle: ['Lapsed', 'Expired', 'Suspended'].includes(student.status),
                                                    readOnlyCircle: ['Invite Sent', 'Not Yet Invited'].includes(student.status),
                                                    activeCircle: ['Active', 'Active (Not Yet Paid)', 'Active (Paid)'].includes(student.status),
                                                }"
                                                aria-hidden="true"
                                            >
                                            </i>
                                            {{ student.status }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.activatedOnLabel')) }}</label>
                                        <p class="form-control-static">
                                            <template v-if="student.dateActivated">
                                                {{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.createdByLabel')) }}</label>
                                        <p class="form-control-static">
                                            {{ student.createBy }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.createdOnLabel')) }}</label>
                                        <p class="form-control-static">
                                            <template v-if="student.dateCreated">
                                                {{ convertToReadableDate(student.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.accountLastLoginLabel')) }}</label>
                                        <p class="form-control-static">
                                            <template v-if="student.dateLastLogin">
                                                {{ convertToReadableDate(student.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.accountLastModifiedLabel')) }}</label>
                                        <p class="form-control-static">
                                            <template v-if="student.lastModified">
                                                {{ convertToReadableDate(student.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="isPayPlan()" class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.paymentStatusLabel')) }}</label>
                                        <p class="form-control-static">
                                            <template v-if="student.status != 'Active (Paid)'">
                                                <span style="color: #dc3545"> Not yet Paid </span>
                                                <router-link
                                                    v-if="auth.user().roleId == 2"
                                                    class="link"
                                                    :to="{
                                                        name: 'payments.gift',
                                                        params: {
                                                            id: student.uuid,
                                                        },
                                                    }"
                                                    >Gift Subscription</router-link
                                                >
                                            </template>
                                            <template v-else>
                                                <span style="color: #28a745">
                                                    Paid on
                                                    {{ convertToReadableDate(student.paidDate, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(student.paidDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </span>
                                            </template>
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.paymentMethodLabel')) }}</label>
                                        <p class="form-control-static">
                                            <template v-if="student.paymentMode">
                                                <template v-if="student.paymentMode == 'paypal' && student.amount != 0"> Credit Card </template>
                                                <template v-else-if="student.paymentMode == 'paypal' && student.amount == 0"> Activated by Superuser </template>
                                                <template v-else-if="student.paymentMode == 'access'">
                                                    Access Code
                                                    {{ student.accessCode }}
                                                </template>
                                                <template v-else>
                                                    {{ student.paymentMode }}
                                                </template>
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="student.isSuspended" class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.suspendedOnLabel')) }}</label>
                                        <p class="form-control-static">
                                            {{ convertToReadableDate(student.dateSuspended, 'DD MMM YYYY, hh:mm a').date }}
                                            ({{ convertToReadableDate(student.dateSuspended, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.originLabel')) }}</label>
                                        <p class="form-control-static">
                                            {{ student.origin }}
                                        </p>
                                    </div>
                                </div>
                                <div v-if="isPayPlan()" class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">{{ capitalize($t('modules.students.view.accountExpiryDateLabel')) }}</label>
                                        <p class="form-control-static">
                                            <template v-if="student.endDate">
                                                {{ convertToReadableDate(student.endDate, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(student.endDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </template>
                                            <template v-else> - </template>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>
                <div
                    v-if="auth.user().roleId == 2 || (auth.user().roleId == 1 && isPayPlan() && student.status != 'Active (Paid)')"
                    class="marginBottom30 flexRight"
                >
                    <div class="dropdown col-xs-2">
                        <button
                            id="viewDropdownMenuButton"
                            class="width100 flexSpaceBetween align-items"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ $t('modules.students.view.actionsDropdownLabel') }}
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                        </button>

                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewModuleDropdownMenuButton">
                            <ul>
                                <li v-if="!student.isSuspended && auth.user().roleId == 2">
                                    <a
                                        v-tooltip="student.hasTeacherAccount ? 'Has teacher account' : ''"
                                        class="danger-state"
                                        :class="{
                                            'disabled hasTooltip': student.hasTeacherAccount,
                                        }"
                                        href="#"
                                        :aria-disabled="student.hasTeacherAccount"
                                        @click.prevent="student.hasTeacherAccount ? '' : openSuspendModal()"
                                    >
                                        <span>
                                            <i class="fa fa-archive marginRight8" aria-hidden="true"></i>
                                            {{ $t('modules.students.view.suspendAccountButtonLabel') }}
                                        </span>
                                    </a>
                                </li>

                                <li v-else-if="student.isSuspended && auth.user().roleId == 2">
                                    <a class="primary-state" href="#" @click.prevent="openSuspendModal()">
                                        <span>
                                            <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>{{ $t('modules.students.view.reinstateButtonLabel') }}
                                        </span>
                                    </a>
                                </li>

                                <li v-else-if="auth.user().roleId == 1">
                                    <a class="primary-state" href="#" @click.prevent="openSetStudentAsPaidModal()">
                                        <span>
                                            <i class="fa fa-check-square-o marginRight8" aria-hidden="true"></i>
                                            {{ $t('modules.students.view.setStudentAsPaidButtonLabel') }}
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <kr-panel v-if="auth.user().roleId <= 2" :with-footer="false">
                    <template #title>
                        <div class="col-xs-12 col-sm-6">
                            <h2>Logs</h2>
                        </div>
                    </template>
                    <template #content>
                        <p v-if="logs.length == 0">No log</p>
                        <ul v-else class="logs-timeline">
                            <template v-for="(log, idx) in logs" :key="idx">
                                <li
                                    class="event completed"
                                    :data-date="
                                        convertToReadableDate(log.created_at, 'DD MMM YYYY, hh:mm a').date +
                                        ' ' +
                                        convertToReadableDate(log.created_at, 'DD MMM YYYY, hh:mm a').current_timezone
                                    "
                                >
                                    <div>
                                        <p>{{ log.description }}</p>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </template>
                </kr-panel>
            </div>

            <div v-if="auth.user().roleId == 2" id="courses" class="tab-pane fade in">
                <div class="overflowXinherit">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th colspan="12">All {{ student.courses.length }} Courses</th>
                                </tr>
                                <tr>
                                    <th>Course</th>
                                    <th style="width: 132px">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(course, c_idx) in student.courses">
                                    <td>
                                        <router-link
                                            v-tooltip="{
                                                content: 'View Course',
                                            }"
                                            tag="a"
                                            :to="{
                                                name: 'courses.view',
                                                params: {
                                                    id: course.uuid,
                                                },
                                            }"
                                            class="link"
                                            :aria-label="course.code ? course.code + ' - ' + course.name : course.name"
                                        >
                                            {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <div class="dropdown">
                                            <button
                                                id="viewDropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                aria-label="Actions"
                                            >
                                                {{ $t('modules.students.view.table.course.actionsDropdownLabel') }}
                                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                            </button>

                                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                                <ul>
                                                    <li>
                                                        <router-link
                                                            class="primary-state"
                                                            :to="{
                                                                name: 'courses.view',
                                                                params: {
                                                                    id: course.uuid,
                                                                },
                                                            }"
                                                        >
                                                            <span>
                                                                <i class="fas fa-eye marginRight8" aria-hidden="true"></i>
                                                                {{ $t('modules.students.view.table.course.viewButtonLabel') }}
                                                            </span>
                                                        </router-link>
                                                    </li>

                                                    <li>
                                                        <a class="danger-state" href="#" @click.prevent="removeStudentFromCourse(course, c_idx)">
                                                            <span>
                                                                <i class="fa fa-minus-circle marginRight8" aria-hidden="true"></i>
                                                                {{ $t('modules.students.view.table.course.disenrollStudentButtonLabel') }}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="student.courses.length == 0">
                                    <td colspan="3" style="text-align: center">No Data</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div v-if="isProfile && APP_ENV !== 'production'" id="receipt" class="tab-pane fade in">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">
                            <h2>Receipts</h2>
                        </div>
                    </template>
                    <template #content>
                        <kr-search
                            name="receipt_listing"
                            :url="{ url: '/profile/payment-plans' }"
                            :columns="{
                                user: 'display:Name|sortable:false|width:35%|mobile:hide',
                                startDate: 'display:Stare Date|sortable:false|width:30%',
                                endDate: 'display:End Date|sortable:false|width:30%',
                            }"
                            :options="{
                                trash_field: false,
                                suspended_field: false,
                                action_column: true,
                                search_field: false,
                                query_fields: query_fields,
                            }"
                            @mounted="
                                initStartDatepicker();
                                initEndDatepicker();
                            "
                        >
                            <template #lower-left>
                                <div class="form-group periodBars">
                                    <label class="col-form-label">VIEW PERIOD</label>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="start_log_daterange"
                                            placeholder="Select start period"
                                            aria-label="Select Start Period"
                                        />
                                    </div>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="end_log_daterange"
                                            placeholder="Select end period"
                                            aria-label="Select End Period"
                                        />
                                    </div>
                                </div>
                            </template>
                            <template #table-title="props">
                                <th colspan="6">All Receipts ( {{ props.model.from }}-{{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                            </template>
                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }}-{{ props.model.to }}
                                    of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>
                            <template #cdata="props">
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.user }}
                                </td>
                                <td>
                                    <template v-if="props.model.startDate"> {{ convertToReadableDate(props.model.startDate, 'LLLL').date }}</template>
                                    <template v-else>N/A</template>
                                </td>
                                <td>
                                    <template v-if="props.model.endDate"> {{ convertToReadableDate(props.model.endDate, 'LLLL').date }}</template>
                                    <template v-else>N/A</template>
                                </td>
                                <td>
                                    <button class="btn btn-primary" @click="downloadReceipt(props.model.uuid)">
                                        <i class="fa fa-dowload marginRight8" aria-hidden="true"></i>Download
                                    </button>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>

        <div v-if="auth.user().roleId == 3">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-12 col-sm-6">
                        <h2>{{ student.displayName }}</h2>
                        <p>{{ student.email ? student.email : '-' }}</p>
                    </div>

                    <div class="col-xs-12 col-sm-6" :class="{ textAlignRight: !isBelow768px, marginTop10: isBelow768px }">
                        <template v-if="auth.user().uuid == student.uuid">
                            <router-link
                                v-if="!isFromCourse"
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'students.edit',
                                    params: { id: $route.params.id },
                                }"
                                custom
                            >
                                <button class="btn btn-default" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                </button>
                            </router-link>
                            <router-link
                                v-else
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'courses.manage.students.edit',
                                    params: {
                                        course_id: $route.params.course_id,
                                        id: $route.params.id,
                                    },
                                }"
                                custom
                            >
                                <button
                                    v-tooltip="auth.isImpersonation() ? 'Impersonation cannot edit profile' : ''"
                                    class="btn btn-default"
                                    :class="{ 'disabled hasTooltip': auth.isImpersonation() }"
                                    :aria-disabled="auth.isImpersonation()"
                                    @click="auth.isImpersonation() ? '' : navigate()"
                                >
                                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" /> Edit
                                </button>
                            </router-link>
                        </template>

                        <router-link v-if="!isFromCourse" v-slot="{ navigate }" :to="{ name: 'students.index' }" custom>
                            <button class="btn btn-default" @click="navigate"><i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back</button>
                        </router-link>
                        <router-link
                            v-else
                            v-slot="{ navigate }"
                            :to="{
                                name: 'courses.view',
                                params: { id: $route.params.course_id },
                                query: { tab: 'student' },
                            }"
                            custom
                        >
                            <button class="btn btn-default" @click="navigate"><i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back</button>
                        </router-link>
                    </div>
                </template>
                <template #content>
                    <div class="row col-xs-12">
                        <div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th colspan="6">All {{ student.courses.length }} Courses</th>
                                        </tr>
                                        <tr>
                                            <th>Course</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="course in student.courses">
                                            <td>
                                                {{ course.code ? course.code + ' - ' + course.name : course.name }}
                                            </td>
                                        </tr>
                                        <tr v-if="student.courses.length == 0">
                                            <td colspan="2" style="text-align: center">No Data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>

        <div
            id="suspendModal"
            class="modal"
            :class="{ 'danger-modal': !student.isSuspended, 'warning-modal': student.isSuspended }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="suspendModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="studentSuspendModal-title" class="modal-title">
                            <template v-if="!student.isSuspended"> Suspend </template>
                            <template v-else> Reinstate </template>
                            Student
                        </h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="marginTop40">
                            <template v-if="!student.isSuspended"> Suspend </template>
                            <template v-else> Reinstate </template>
                        </h2>

                        <h3 class="marginBottom20 fw-normal capitalize">
                            {{ student.displayName }}
                        </h3>

                        <template v-if="!student.isSuspended">
                            <p class="marginTop20">Suspending this student will prevent them</p>

                            <p>from sign in to the system.</p>

                            <p class="marginTop20"><b>Note!</b> they will not receive any</p>

                            <p>notification regarding this action.</p>

                            <p class="marginTop20">
                                <b>Are you sure to suspend the student?</b>
                            </p>
                        </template>

                        <template v-else>
                            <p class="marginTop20">Reinstating this student will restore their</p>

                            <p>ability to sign in to the system.</p>

                            <p class="marginTop20"><b>Note!</b> they will not receive any</p>

                            <p>notification regarding this action.</p>

                            <p class="marginTop20">
                                <b>Are you sure to reinstate the student?</b>
                            </p>
                        </template>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">No, Cancel</button>
                        <button v-if="!student.isSuspended" class="btn btn-danger" data-dismiss="modal" @click.prevent="suspend()">
                            <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Suspend
                        </button>
                        <button v-else class="btn btn-success" data-dismiss="modal" @click.prevent="unsuspend()">Yes, Reinstate</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="studentAsPaidModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="studentAsPaidModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="studentAsPaidModal-title" class="modal-title">You are about to set this student as paid</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="optionDiv marginBottom30">
                                <kr-radio-multiple
                                    name="type"
                                    display="Paid Duration"
                                    :form="form"
                                    :items="paymentPlans"
                                    item_value_idx="value"
                                    item_desc_idx="description"
                                >
                                </kr-radio-multiple>
                            </div>
                        </div>
                        <div class="row marginTop10">
                            <div class="flex">
                                <button class="btn btn-success" data-dismiss="modal" aria-label="Confirm" @click="setAsPaid">Confirm</button>
                                <button class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <kr-reset-password></kr-reset-password>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            student: null,
            isFromCourse: false,
            query_fields: { dateFrom: '', dateTo: '' },
            course: {},
            APP_ENV: process.env.VUE_APP_ENV,
            form: new KrForm({
                fields: {
                    type: '',
                },
                props: {
                    successMessage: false,
                },
            }),
            paymentPlans: [],
            logs: [],
        };
    },
    computed: {
        isProfile() {
            return this.$route.params.id == this.auth.user().uuid;
        },
    },
    created() {
        if (this.$route.name == 'courses.manage.students.view') {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            });
            this.isFromCourse = true;
        } else {
            if (this.isProfile) {
                Events.fire('topbar_update', {
                    breadcrumb: [{ name: 'Home' }, { name: 'My Profile' }],
                });
            } else {
                Events.fire('topbar_update', {
                    breadcrumb: [{ name: 'Home' }, { name: 'Students', link: 'students.index' }, { name: 'View Student' }],
                });
            }
        }
        this.fetch();

        document.title = `${this.isProfile ? 'My Profile | InteDashboard | TBL Makes Teams Work' : 'Student Profile | InteDashboard | TBL Makes Teams Work'}`;
    },
    methods: {
        removeStudentFromCourse(course, course_idx) {
            var that = this;
            axios
                .post('students/disenroll', {
                    userUuid: this.student.uuid,
                    courseUuid: course.uuid,
                })
                .then(function (response) {
                    that.student.courses.splice(course_idx, 1);
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Student has been disenrolled',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
        },
        isPayPlan() {
            if (this.student.account.paymentMethod == 'Student-Paid' && !this.student.isGeneric && this.student.email != null) {
                return true;
            }
            return false;
        },
        downloadReceipt(id) {
            var that = this;
            axios({
                url: '/profile/payment-plans/' + id + '/receipt',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '(receipt)' + moment().format('YYYY_MM_DD') + '.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        openSetStudentAsPaidModal() {
            $('#studentAsPaidModal').modal('show');
        },
        openSuspendModal() {
            $('#suspendModal').modal('show');
        },
        setAsPaid() {
            var success = function (response) {
                this.student.status = 'Active (Paid)';
                this.student.paidDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Student has been set as paid',
                });
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            this.form.put(`users/${this.student.uuid}/paid`).then(function (response) {
                if (!response.krFormError) {
                    success(response);
                } else {
                    del();
                }
            });
        },
        suspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                this.student = response.data.data;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Student has been suspended',
                });
            }.bind(this);
            var del = function () {
                this.student.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.student.uuid + '/suspend', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        unsuspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                var that = this;
                this.student = response.data.data;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Student has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.student.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.student.uuid + '/reinstate', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        search() {
            Events.fire('log_listing_refresh');
        },
        initStartDatepicker() {
            var that = this;
            let options = {
                maxDate: that.query_fields.dateTo,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateFrom != '') {
                options.startDate = moment.utc(that.query_fields.dateFrom).format('YYYY-MM-DD');
            }
            $('input[name="start_log_daterange"]').daterangepicker(options);
            $('input[name="start_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateFrom', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="start_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
            $('input[name="start_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateFrom', '');
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
        },
        initEndDatepicker() {
            var that = this;
            let options = {
                minDate: that.query_fields.dateFrom,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateTo != '') {
                options.startDate = moment.utc(that.query_fields.dateTo).format('YYYY-MM-DD');
            }
            $('input[name="end_log_daterange"]').daterangepicker(options);
            $('input[name="end_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateTo', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="end_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
            $('input[name="end_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateTo', '');
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
        },
        openResetPasswordModal() {
            Events.fire('open-reset-password-modal', {
                user: this.student,
                organisation: this.student.account.organisationName,
            });
        },
        fetch() {
            let that = this;
            let calls = [axios.get('/users/' + that.$route.params.id)];
            if (!this.isFromCourse) {
                if (this.auth.user().roleId <= 2) {
                    calls.push(axios.get(`/students/${that.$route.params.id}/logs`));
                }
                axios.all(calls).then(
                    axios.spread(function (...response) {
                        that.student = response[0].data.data;
                        if (that.auth.user().roleId <= 2) {
                            that.logs = response[1].data.data;
                        }
                        if (that.$route.name == 'accounts.students.view') {
                            Events.fire('topbar_update', {
                                breadcrumb: [
                                    {
                                        name: that.$t('modules.accounts.listing.breadcrumbLabel'),
                                        link: 'accounts.index',
                                    },
                                    {
                                        name: that.student.account.organisationName,
                                        link: 'accounts.view',
                                        params: {
                                            id: that.student.account.uuid,
                                        },
                                        query: { tab: 'student' },
                                    },
                                    { name: that.$t('modules.students.view.breadcrumbLabel') },
                                ],
                            });
                        } else if (!that.isProfile) {
                            Events.fire('topbar_update', {
                                breadcrumb: [
                                    { name: 'Home' },
                                    {
                                        name: 'Students',
                                        link: 'students.index',
                                    },
                                    { name: that.student.displayName },
                                ],
                            });
                        }
                        if (that.isPayPlan()) {
                            that.form.model.type = that.student.account.paymentPlans[0].type;
                            for (var i = 0; i < that.student.account.paymentPlans.length; i++) {
                                that.paymentPlans.push({
                                    value: that.student.account.paymentPlans[i].type,
                                    description: that.student.account.paymentPlans[i].plan,
                                });
                            }
                        }
                        that.component_done_loading = true;
                    })
                );
            } else {
                calls.push(axios.get('/courses/' + this.$route.params.course_id));
                if (this.auth.user().roleId <= 2) {
                    calls.push(axios.get(`/students/${that.$route.params.id}/logs`));
                }
                axios.all(calls).then(
                    axios.spread(function (...response) {
                        that.student = response[0].data.data;
                        that.course = response[1].data.data;
                        if (that.auth.user().roleId <= 2) {
                            that.logs = response[2].data.data;
                        }
                        if (!that.isProfile) {
                            Events.fire('topbar_update', {
                                breadcrumb: [
                                    { name: 'Home' },
                                    { name: 'Courses', link: 'courses.index' },
                                    {
                                        name: that.course.name + (that.course.code ? ' (' + that.course.code + ')' : ''),
                                        link: 'courses.view',
                                        params: { id: that.course.uuid },
                                        query: { tab: 'student' },
                                    },
                                    { name: that.student.displayName },
                                ],
                            });
                        }
                        that.component_done_loading = true;
                        // Both requests are now complete
                    })
                );
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@media (min-width: 992px) {
    .periodBars {
        width: 524px;
    }
}
</style>
