<template>
    <div v-if="component_done_loading" id="account-create" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">EDIT NOTE</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button id="top-save-btn" class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Update" @click="submit()">
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <!--<router-link   id="top-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default " exact>
				            	Back
				            </router-link>-->
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="top-mobile-save-btn"
                                class="btn btn-success"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit()"
                            >
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                            <!--<router-link  id="top-mobile-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default " exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>-->
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <note-form :form="form" :account="account" :superusers="superusers"></note-form>
                        <div class="buttonGroup marginTop30">
                            <button id="bottom-save-btn" class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Update" @click="submit()">
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: $route.params.account_id },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
                <!-- <template slot="footer">
			      <div>
			        <button :disabled="(!canSend) ? true:undefined" class="btn pull-right btn-success" type="submit"@click="submit"> Create </button>
			      </div>  
			    </template> -->
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    notes: '',
                    type: 'default!:Basic',
                    status: 'default!:N/A',
                    customerAdvisorUuid: '',
                    date: '',
                    schoolYear: '',
                    callType: 'default!:Checkin',
                },
                props: {},
                urls: {
                    put: 'accounts/' + this.$route.params.account_id + '/notes/' + this.$route.params.id,
                },
            }),
            account: {},
            superusers: [],
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: 'Accounts', link: 'accounts.index' }],
        });
        this.fetch();
    },
    components: {
        'note-form': require(`./form-partials/partial.note.vue`).default,
    },
    methods: {
        submit() {
            var that = this;
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'accounts.view',
                        params: { id: that.$route.params.account_id },
                    });
                }
            });
        },
        fetch() {
            var that = this;
            axios
                .all([
                    axios.get('/accounts/' + this.$route.params.account_id),
                    axios.get('/accounts/' + this.$route.params.account_id + '/notes/' + this.$route.params.id),
                    axios.get('superusers'),
                ])
                .then(
                    axios.spread(function (account, notes, superusers) {
                        that.account = account.data.data;
                        that.superusers = superusers.data;
                        Events.fire('topbar_update', {
                            breadcrumb: [
                                { name: 'Home' },
                                { name: 'Accounts', link: 'accounts.index' },
                                {
                                    name: that.account.organisationName,
                                    link: 'accounts.view',
                                    params: {
                                        id: that.$route.params.account_id,
                                    },
                                },
                                { name: 'Edit Note' },
                            ],
                        });
                        that.form.setModel(notes.data.data);
                        that.component_done_loading = true;
                    })
                );
        },
    },
    computed: {
        canSend() {
            if (this.form.model.type == 'Basic' && !this.form.model.notes) {
                return false;
            } else if (this.form.model.type == 'Onboarding' && (!this.form.model.status || !this.form.model.customerAdvisorUuid || !this.form.model.notes)) {
                return false;
            } else if (
                this.form.model.type == 'Customer' &&
                (!this.form.model.status || !this.form.model.customerAdvisorUuid || !this.form.model.callType || !this.form.model.notes)
            ) {
                return false;
            }
            return true;
        },
    },
};
</script>
