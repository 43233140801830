<template>
    <!--PUT HTML HERE-->
    <div v-if="component_done_loading">
        <div class="col-xs-12 right-container">
            <div class="pageTitle">
                <h1>Gradebook</h1>
                <p class="col-md-6">You can view your grades for a specific activity once yours instructor has made them available.</p>
            </div>

            <template v-if="grades.length > 0">
                <kr-panel v-for="course in grades" :with-footer="false" :options="{ canToggle: true }">
                    <template #title>
                        <h2>{{ course.courseName }}</h2>
                    </template>

                    <template #content>
                        <kr-panel v-for="module in course.modules" :with-footer="false" :options="{ canToggle: true }">
                            <template #title>
                                <h3 class="margin0 fs-18px">
                                    <span class="visible-hidden">Module Name</span>
                                    {{ module.moduleName }}
                                    ({{ module.activities.length <= 1 ? module.activities.length + ' activity' : module.activities.length + ' activities' }})
                                </h3>
                            </template>

                            <template #content>
                                <div class="table-responsive">
                                    <table class="table subTable border-collapse">
                                        <caption>
                                            List of activities from
                                            {{
                                                module.moduleName
                                            }}
                                        </caption>

                                        <thead>
                                            <tr class="activityHeaders">
                                                <th><span class="visible-hidden">Activity</span> Name</th>
                                                <th><span class="visible-hidden">Activity</span> Type</th>
                                                <th><span class="visible-hidden">Activity</span> Date</th>
                                                <th><span class="visible-hidden">Activity</span> Grade</th>
                                            </tr>
                                        </thead>
                                        <template v-for="activity in module.activities">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {{ activity.testName }}
                                                    </td>
                                                    <td>
                                                        <template v-if="activity.type == 'prework'"> Prework </template>
                                                        <template v-if="activity.type == 'irat'"> IRAT </template>
                                                        <template v-if="activity.type == 'trat'"> TRAT </template>
                                                        <template v-if="activity.type == 'iratntrat'"> IRAT & TRAT </template>
                                                        <template v-if="activity.type == 'application'"> Application Case </template>
                                                        <template v-if="activity.type == 'evaluation'"> 360 Degree Evaluation </template>
                                                        <template v-if="activity.type == 'michaelsenEvaluation'">
                                                            Peer Evaluation: Michaelsen’s Method
                                                        </template>
                                                        <template v-if="activity.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                                                    </td>
                                                    <td>
                                                        {{ convertToReadableDate(activity.testTimeStarted, 'DD MMM YYYY').date }}
                                                    </td>
                                                    <td>
                                                        {{ activity.grade }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </table>
                                </div>
                            </template>
                        </kr-panel>
                    </template>
                </kr-panel>
            </template>
            <div v-else>
                <p v-if="responseStatus == 200">No grades available</p>
                <p v-else>We are still processing your grades. Pls refresh in a few minutes.</p>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            auth: auth,
            grades: [],
            responseStatus: -1,
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-chalkboard-teacher"></i>&nbsp;Gradebook',
            breadcrumb: [{ name: 'Home' }, { name: 'Gradebook', link: 'grades.index' }],
        });
        this.fetchGrades();
    },
    methods: {
        fetchGrades() {
            var that = this;
            axios.get('student/grades').then(function (response) {
                that.responseStatus = response.status;
                if (that.responseStatus != 202) {
                    that.grades = _.map(_.groupBy(response.data.data, 'courseUuid'), function (items, courseUuid) {
                        return {
                            courseUuid: courseUuid,
                            courseName: items[0].courseName,
                            modules: items,
                        };
                    });
                    for (var i = 0; i < that.grades.length; i++) {
                        that.grades[i].modules = _.map(_.groupBy(that.grades[i].modules, 'moduleUuid'), function (items, moduleUuid) {
                            return {
                                moduleUuid: moduleUuid,
                                moduleName: items[0].moduleName,
                                activities: items,
                            };
                        });
                    }
                }
                that.component_done_loading = true;
            });
        },
    },
};
</script>
<style scoped>
.subContent {
    padding: 0;
    border: 0;
    background: none;
}
.subTable {
    margin-top: 20px;
}
.subTable:first-child {
    margin-top: 0;
}

.subTable th {
    position: relative;
}

.subTable th button {
    right: 20px;
}

.table .subContentTable .table {
    margin-bottom: 20px;
}

.subContentTable:last-child .table {
    margin-bottom: 0;
}

.table .subContentTable tbody .table {
    margin-bottom: 0;
}
</style>
