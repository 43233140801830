<template>
    <div :id="question.uuid + '-question' + '-' + type" class="panel-div">
        <div class="flexSpaceBetween alignBaseline">
            <div>
                <h3 class="margin0 fs-18px">Question {{ question_idx + 1 }} Question Stem</h3>
                <span class="question displayInlineBlock" v-html="question.question"></span>
            </div>
            <div v-if="question.isRequired" class="statusTag statusTagDanger fontSize12 padding5">Required</div>
        </div>
        <template v-if="question.type == 'rating'">
            <rating
                :question_idx="question_idx"
                :test-obj="testObj"
                :question="question"
                :disabled="!canAnswer ? true : undefined"
                :type="type"
                @changed="debouncedUpdateAnswer"
            ></rating>
        </template>
        <template v-if="question.type == 'openended'">
            <openended
                :question_idx="question_idx"
                :test-obj="testObj"
                :question="question"
                :disabled="!canAnswer ? true : undefined"
                :type="type"
                @changed="debouncedUpdateAnswer"
            ></openended>
        </template>
    </div>
</template>
<script>
import { testMixins } from './../../../../../../../mixins/test';
export default {
    mixins: [testMixins],
    props: ['testObj', 'question_idx', 'question', 'type'],
    components: {
        rating: require(`./partials/rating.vue`).default,
        openended: require(`./partials/openended.vue`).default,
    },
    data() {
        return {
            debouncedUpdateAnswer: null,
            isQuestionCorrect: false,
        };
    },
    computed: {
        canAnswer() {
            if (this.isTestCompleted) {
                return false;
            }
            return true;
        },
    },
    created() {
        var that = this;
        if (!this.testObj._isPreview) {
            this.debouncedUpdateAnswer = _.debounce(
                function (data) {
                    var response = [];
                    for (var i = 0; i < data.length; i++) {
                        if (that.question.type == 'openended') {
                            let noOfWords = that.computeNoOfWords(data[i].response);
                            if (noOfWords < that.question.minimum || noOfWords > that.question.maximum) {
                                continue;
                            }
                        }
                        if (that.type == 'peer') {
                            response.push({
                                uuid: data[i].userPlacementTestUuid,
                                response: data[i].response,
                            });
                        } else if (that.type == 'team') {
                            response.push({
                                uuid: data[i].uuid,
                                response: data[i].response,
                            });
                        } else if (that.type == 'instructor') {
                            response.push({
                                uuid: data[i].uuid,
                                response: data[i].response,
                            });
                        }
                    }
                    if (response.length > 0) {
                        axios
                            .post(
                                '/student/responses',
                                {
                                    userPlacementTestUuid: that.testObj.uuid,
                                    activityQuestionUuid: that.question.uuid,
                                    response: response,
                                },
                                { _internal: { hasLoadingBar: false } }
                            )
                            .then(function (response) {
                                //console.log(response);
                            })
                            .catch(function (errors) {
                                //clean out
                                for (var i = 0; i < data.length; i++) {
                                    data[i].response = '';
                                }
                                that.$notify({
                                    group: 'form',
                                    type: 'error',
                                    title: 'Error',
                                    text: 'Something went wrong, please try again later',
                                });
                            });
                    }
                },
                1500,
                { trailing: true }
            );
        } else {
            this.debouncedUpdateAnswer = function () {
                return;
            };
        }
    },
    methods: {
        computeNoOfWords(str = '') {
            str = str ? str : '';
            var length = str.split(/[^\s]+/).length - 1;
            return length;
        },
    },
};
</script>

<style scoped>
.panel-div {
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}
</style>
