<template>
    <div v-if="component_done_loading" id="account-create" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">CREATE NOTE</div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <note-form :form="form" :account="account" :superusers="superusers"></note-form>
                        <div class="buttonGroup marginTop30">
                            <div class="buttonGroup">
                                <button class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Save" @click.prevent="submit">
                                    <i class="far fa-save marginRight8" aria-hidden="true"></i>Save
                                </button>
                                <router-link
                                    v-slot="{ navigate }"
                                    :to="{
                                        name: 'accounts.view',
                                        params: {
                                            id: $route.params.account_id,
                                        },
                                    }"
                                    custom
                                >
                                    <button class="btn btn-default" @click="navigate">Cancel</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- <template slot="footer">
			      <div>
			        <button :disabled="(!canSend) ? true:undefined" class="btn pull-right btn-success" type="submit"@click="submit"> Create </button>
			      </div>  
			    </template> -->
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    notes: '',
                    type: 'default!:Basic',
                    status: 'default!:N/A',
                    customerAdvisorUuid: '',
                    date: '',
                    schoolYear: '',
                    callType: 'default!:Checkin',
                },
                props: {},
                urls: {
                    post: 'accounts/' + this.$route.params.account_id + '/notes',
                },
            }),
            account: {},
            superusers: [],
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: 'Accounts', link: 'accounts.index' }],
        });
        this.fetch();
    },
    components: {
        'note-form': require(`./form-partials/partial.note.vue`).default,
    },
    methods: {
        submit() {
            var that = this;
            this.form.post().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'accounts.view',
                        params: { id: that.$route.params.account_id },
                    });
                }
            });
        },
        fetch() {
            var that = this;
            axios.all([axios.get('/accounts/' + this.$route.params.account_id), axios.get('superusers')]).then(
                axios.spread(function (account, superusers) {
                    that.account = account.data.data;
                    that.superusers = superusers.data;
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            { name: 'Accounts', link: 'accounts.index' },
                            {
                                name: that.account.organisationName,
                                link: 'accounts.view',
                                params: {
                                    id: that.$route.params.account_id,
                                },
                            },
                            { name: 'Create Note' },
                        ],
                    });
                    that.component_done_loading = true;
                })
            );
        },
    },
    computed: {
        canSend() {
            if (this.form.model.type == 'Basic' && !this.form.model.notes) {
                return false;
            } else if (this.form.model.type == 'Onboarding' && (!this.form.model.status || !this.form.model.customerAdvisorUuid || !this.form.model.notes)) {
                return false;
            } else if (
                this.form.model.type == 'Customer' &&
                (!this.form.model.status || !this.form.model.customerAdvisorUuid || !this.form.model.callType || !this.form.model.notes)
            ) {
                return false;
            }
            return true;
        },
    },
};
</script>
