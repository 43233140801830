<template>
    <div class="newPagination">
        <div class="inlineFlex">
            <a
                href="#"
                class="link borderRadius8"
                :class="{ disabled: !canChangeQuestion(previous_question_group_idx, previous_question_idx) }"
                :aria-label="!canChangeQuestion(previous_question_group_idx, previous_question_idx) ? 'Previous Question Disabled' : 'Previous Question'"
                :aria-disabled="canChangeQuestion(previous_question_group_idx, previous_question_idx)"
                @click.prevent="
                    !canChangeQuestion(previous_question_group_idx, previous_question_idx)
                        ? ''
                        : moveToDifferentQuestion(previous_question_group_idx, previous_question_idx)
                "
            >
                <i class="fas fa-arrow-left-long" aria-hidden="true" />
            </a>
            <div class="flexOnly align-items marginLeft10">
                <ul class="flexOnly">
                    <li
                        v-for="(question, idx) in pagination"
                        :key="'page-' + idx"
                        :class="{
                            active: question.question_group_idx == current_question_group_idx && question.question_idx == question.question_idx,
                        }"
                    >
                        <template v-if="question.isDot">
                            <a>...</a>
                        </template>
                        <template v-else>
                            <template v-if="canChangeQuestion(question.question_group_idx, question.question_idx)">
                                <a
                                    href="#"
                                    class="link"
                                    :aria-label="'page ' + question.label"
                                    @click.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx)"
                                >
                                    {{ question.label }}
                                </a>
                            </template>
                            <template v-else>
                                <a class="disabled" :aria-label="'disabled question ' + question.label" aria-disabled="true">
                                    {{ question.label }}
                                </a>
                            </template>
                        </template>
                    </li>
                </ul>
            </div>
            <a
                href="#"
                class="link borderRadius8"
                :class="{ disabled: !canChangeQuestion(next_question_group_idx, next_question_idx) }"
                :aria-label="!canChangeQuestion(next_question_group_idx, next_question_idx) ? 'Next Question Disabled' : 'Next Question'"
                :aria-disabled="canChangeQuestion(previous_question_group_idx, previous_question_idx)"
                @click.prevent="
                    !canChangeQuestion(next_question_group_idx, next_question_idx) ? '' : moveToDifferentQuestion(next_question_group_idx, next_question_idx)
                "
            >
                <i class="fas fa-arrow-right-long" aria-hidden="true" />
            </a>
        </div>
    </div>
</template>

<script>
import { testPaginationMixins } from './../../../../../../../../../../mixins/test';
export default {
    mixins: [testPaginationMixins],
    methods: {
        canChangeQuestion(question_group_idx, question_idx) {
            if (question_group_idx == this.current_question_group_idx && question_idx == this.current_question_idx) {
                return false;
            }
            return true;
        },
    },
};
</script>
