<template>
    <div>
        <template v-if="!values.activityUuid && props.mode == 'create'">
            <Field v-slot="{ field }" type="select" name="type">
                <div class="form-group marginBottom20">
                    <label class="form-label"> Activity Type </label>
                    <div class="form-group-select-wrapper form-dropdown">
                        <select v-bind="field" class="form-control">
                            <option value="" disabled>-</option>
                            <template v-for="(type, idx) in activityTypes">
                                <option :value="type.value" :disabled="type._disabled">{{ type.description }}</option>
                            </template>
                        </select>
                    </div>
                </div>
            </Field>
        </template>
        <template v-else>
            <div class="form-group">
                <label class="control-label">Activity Type</label>
                <p class="form-control-static">
                    <template v-if="values.type == 'irat'"> IRAT </template>
                    <template v-else-if="values.type == 'trat'">TRAT </template>
                    <template v-else-if="values.type == 'iratntrat'"> IRAT & TRAT </template>
                    <template v-else-if="values.type == 'application'"> Application </template>
                    <template v-else-if="values.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                    <template v-else-if="values.type == 'instructorEvaluation'"> Instructor Evaluation </template>
                </p>
            </div>
        </template>

        <Field v-if="values.type == 'peerEvaluationV2'" v-slot="{ field }" type="select" name="evaluationSettings.target">
            <div class="radioField-multipleRadio radioField marginBottom20">
                <fieldset>
                    <legend>
                        <span>Select who to evaluate</span>
                        <span class="visible-hidden">Required</span>
                        <span class="important" aria-hidden="true">*</span>
                    </legend>
                    <div class="newRadioField" role="group">
                        <div v-for="(item, idx) in evaluationTypes" :key="item.value" class="radioField flex alignFlexStart">
                            <div class="flex">
                                <input :id="item.value" v-bind="field" type="radio" :value="item.value" :checked="field.value == item.value" />
                                <label :for="item.value" v-html="item.description" />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </Field>

        <Field v-slot="{ field, errorMessage }" type="text" name="name">
            <div class="form-group" :class="{ 'has-error': errorMessage }">
                <label for="templateBasicInfoName" class="control-label">
                    Name
                    <span class="important" aria-hidden="true">*</span>
                    <span class="visible-hidden">Required</span>
                </label>
                <input
                    id="templateBasicInfoName"
                    v-bind="field"
                    class="form-control"
                    placeholder="Enter name"
                    @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                />
                <span v-if="errorMessage" class="help-block">
                    <strong>{{ errorMessage }}</strong>
                </span>
            </div>
        </Field>

        <Field v-slot="{ field }" type="text" name="description">
            <div class="form-group marginTop20">
                <label for="templateBasicInfoDescription" class="control-label"> Description </label>
                <textarea
                    id="templateBasicInfoDescription"
                    v-bind="field"
                    class="form-control"
                    placeholder="Enter description"
                    rows="5"
                    @keydown.ctrl.meta.space="onIOSEmojiPanelOpen($event)"
                />
            </div>
        </Field>
    </div>
</template>

<script setup>
import { defineProps, watch } from 'vue';
import { Field, FieldArray } from 'vee-validate';
import KrAuth from '../../../../../components/auth/auth';
const auth = new KrAuth();
const props = defineProps({
    values: {
        type: Object,
        default: () => ({}),
    },
    setFieldValue: {
        type: Function,
        default: () => {},
    },
    mode: {
        type: String,
        default: 'create',
    },
    modelModifiers: { default: () => ({}) },
});
const activityTypes = [
    { value: 'irat', description: 'IRAT only' },
    { value: 'trat', description: 'TRAT only' },
    { value: 'iratntrat', description: 'IRAT & TRAT' },
    {
        value: 'application',
        description: 'Application',
        _disabled: auth.user().account.version == 'lite',
    },
    {
        value: 'peerEvaluationV2',
        description: 'Peer Evaluation',
        _disabled: auth.user().account.version == 'lite',
        isNew: true,
    },
    {
        value: 'instructorEvaluation',
        description: 'Instructor Evaluation',
        _disabled: true,
        isComingSoon: true,
    },
];
const evaluationTypes = [
    { value: 'peers', description: 'Teammates Only' },
    { value: 'self', description: 'Self Only' },
    { value: 'all', description: 'Teammates and Self' },
];
function onIOSEmojiPanelOpen(e) {
    e.preventDefault();
}
</script>
