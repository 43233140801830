<template>
    <div
        ref="modal"
        class="modal warning-modal importTemplateModal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="importTemplateModal-title"
    >
        <div class="modal-dialog">
            <div v-if="loaded" class="preview modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="importTemplateModal-title" class="modal-title">Notice</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" width="50px" alt="" />

                    <h2 class="margin0 marginTop20">You are now importing template</h2>
                    <h2 class="margin0">
                        from
                        <template v-if="template.attributes.sameAccountImport">same</template>
                        <template v-else>another</template>
                        institution
                    </h2>
                    <br />

                    <table class="table">
                        <thead>
                            <tr>
                                <th colspan="12">Template Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="padding10 width100px">Template Name</th>
                                <td class="textAlignLeft">{{ template.name }}</td>
                            </tr>
                            <tr>
                                <th class="padding10 width100px">Number of Question/s</th>
                                <td class="textAlignLeft">{{ template.questionsCount }}</td>
                            </tr>
                            <tr>
                                <th class="padding10 width100px">Created by</th>
                                <td class="textAlignLeft">{{ template.owner }}</td>
                            </tr>
                            <tr>
                                <th class="padding10 width100px">Institution</th>
                                <td class="textAlignLeft">{{ template.attributes.organisationName }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <p>
                        <b>Continue?</b>
                        <!-- same institution -->
                        <!-- <b>Do you wish to duplicate it?</b> -->
                    </p>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Cancel</button>
                    <button class="btn btn-success" data-dismiss="modal" @click="submit">
                        Import
                        <!-- same institution -->
                        <!-- Duplicate -->
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['imported']);
import { notify } from '@kyvg/vue3-notification';
import { ref, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const modal = ref(null);
const template = ref(null);

const route = useRoute();
const router = useRouter();

const loaded = ref(false);

function openModal() {
    nextTick(() => {
        $(modal.value).modal('show');
    });
}
function closeModal() {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
}
function importTemplate(templateUuid, cluster) {
    template.value = { uuid: templateUuid, cluster: cluster };
    fetchDetails();
}
function fetchDetails() {
    axios
        .post('templates/import/details', {
            templateUuid: template.value.uuid,
            cluster: template.value.cluster,
        })
        .then(function (response) {
            template.value = { uuid: template.value.uuid, cluster: template.value.cluster, ...response.data.data };
            loaded.value = true;
            openModal();
        })
        .catch(function (errors) {});
}
function submit() {
    axios
        .post('templates/import', {
            templateUuid: template.value.uuid,
            cluster: template.value.cluster,
        })
        .then(function (response) {
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Template successfully imported',
            });
            emit('imported');
            closeModal();
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong with importing template. Please try again later',
            });
        });
}
defineExpose({ importTemplate });
</script>
