<template>
    <div v-if="componentDoneLoading" class="col-xs-12 right-container">
        <div class="pageTitle">
            <h1>Template Details</h1>
            <p class="col-md-6">
                <template v-if="formType != 'create' && template.defaultTemplate">
                    This is an InteDashboard template. You cannot edit this template. If you want to make changes, you can duplicate this template.
                    <a href="#" @click.prevent="copyTemplateComponent.copy(template)">
                        <i class="fa fa-files-o" aria-hidden="true" />
                        Make a Copy
                    </a>
                </template>
                <template v-else> Template Details enables you to view and modify the included questions and other template details. </template>
            </p>
        </div>

        <div class="flex">
            <div
                v-show="!isFullScreenManageQuestions"
                class="col-xs-12"
                :class="{
                    'col-md-4 col-lg-3': formType != 'create',
                    paddingRight10: !isMobileView && formType != 'create',
                    marginBottom20: isMobileView && formType != 'create',
                }"
            >
                <kr-panel>
                    <template #title>
                        <div :class="{ width100: !editMode }">
                            <h2 class="margin0">
                                <template v-if="formType == 'create'">Create Template</template>
                                <template v-else-if="editMode"> Update Template </template>
                                <template v-else-if="!editMode"> Template Details </template>
                            </h2>

                            <div v-if="(formType == 'view' && !template.defaultTemplate) || editMode" class="flexWrap">
                                <template v-if="editMode == false">
                                    <button class="btn btn-default marginRight20 marginTop10" @click="editMode = true">
                                        <i class="fas fa-pencil marginRight8" aria-hidden="true"></i> Edit
                                    </button>
                                    <button
                                        v-tooltip="template.questions.length == 0 ? 'No question in the Template' : 'Get Sharable Link'"
                                        class="btn btn-default margin0 marginTop10"
                                        :class="{
                                            'disabled hasToolTip': template.questions.length == 0,
                                        }"
                                        @click.prevent="template.questions.length == 0 ? '' : shareTemplateComponent.share(template)"
                                    >
                                        <i class="material-symbols-outlined marginRight8" aria-hidden="true"> link </i>
                                        Share
                                    </button>
                                </template>

                                <template v-else>
                                    <button class="btn btn-success marginRight20 marginTop10" @click="submit(values)">
                                        <i class="fas fa-save marginRight8" aria-hidden="true"></i> Save
                                    </button>
                                    <button class="btn btn-default margin0 marginTop10" @click="editMode = false">Cancel</button>
                                </template>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <div
                            v-if="formType == 'create' || editMode"
                            class="col-xs-12"
                            :class="{
                                'col-md-6': formType == 'create',
                            }"
                        >
                            <BasicInfoForm :values="values" :mode="formType" :set-field-value="setFieldValue"></BasicInfoForm>
                            <div v-if="formType == 'create'" class="buttonGroup marginTop30">
                                <button class="btn btn-success" :disabled="!(meta.valid && meta.dirty) ? true : undefined" @click="submit(values)">
                                    <i class="far fa-save marginRight8" aria-hidden="true"></i>
                                    <template v-if="formType == 'create'">Save</template>
                                    <template v-else>Update</template>
                                </button>
                                <router-link v-slot="{ navigate }" :to="{ name: 'questions.index' }" custom>
                                    <button class="btn btn-default" @click="navigate">Cancel</button>
                                </router-link>
                            </div>
                        </div>
                        <div v-else>
                            <div class="col-xs-12">
                                <div class="form-group">
                                    <label id="activityTypeLabelCreateTemplate" class="control-label">Activity Type</label>
                                    <p class="form-control-static" aria-labelledby="activityTypeLabelCreateTemplate">
                                        <template v-if="template.type == 'irat'"> IRAT </template>
                                        <template v-else-if="template.type == 'trat'">TRAT </template>
                                        <template v-else-if="template.type == 'iratntrat'"> IRAT & TRAT </template>
                                        <template v-else-if="template.type == 'application'"> Application </template>
                                        <template v-else-if="template.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                                        <template v-else-if="template.type == 'instructorEvaluation'"> Instructor Evaluation </template>
                                    </p>
                                </div>
                                <div v-if="template.type == 'peerEvaluationV2'" class="form-group marginTop20">
                                    <label id="selectWhoToEvaluateLabelCreateTemplate" class="control-label">Who to Evaluate</label>
                                    <p class="form-control-static" aria-labelledby="selectWhoToEvaluateLabelCreateTemplate">
                                        <template v-if="template.evaluationSettings.target == 'peers'"> Teammates Only </template>
                                        <template v-else-if="template.evaluationSettings.target == 'self'"> Self Only </template>
                                        <template v-else-if="template.evaluationSettings.target == 'all'"> Teammates and Self </template>
                                    </p>
                                </div>
                                <div class="form-group marginTop20">
                                    <label id="nameLabelCreateTemplate" class="control-label">Name</label>
                                    <p class="form-control-static" aria-labelledby="nameLabelCreateTemplate">
                                        {{ template.name }}
                                    </p>
                                </div>
                                <div class="form-group marginTop20">
                                    <label id="descriptionTypeLabelCreateTemplate" class="control-label">Description</label>
                                    <p class="form-control-static whiteSpacePreLine" aria-labelledby="descriptionTypeLabelCreateTemplate">
                                        <template v-if="template.description">
                                            {{ template.description }}
                                        </template>
                                        <template v-else> - </template>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>
            </div>
            <div v-if="template" class="col-xs-12" :class="{ 'col-md-8  col-lg-9': !isFullScreenManageQuestions, 'col-md-12': isFullScreenManageQuestions }">
                <ManageQuestions
                    v-if="template.type != 'peerEvaluationV2'"
                    :activity-obj="{ ...template, evaluationSettings: {} }"
                    @question-bank-opened="isFullScreenManageQuestions = true"
                    @question-bank-closed="isFullScreenManageQuestions = false"
                    @activity-updated="updateTemplate"
                >
                </ManageQuestions>
                <ManagePeerQuestions
                    v-else
                    :activity-obj="{ ...template, evaluationSettings: {} }"
                    @question-bank-opened="isFullScreenManageQuestions = true"
                    @question-bank-closed="isFullScreenManageQuestions = false"
                    @activity-updated="updateTemplate"
                >
                </ManagePeerQuestions>
            </div>
        </div>

        <CopyTemplateComponent ref="copyTemplateComponent"></CopyTemplateComponent>
        <ShareTemplateComponent ref="shareTemplateComponent"></ShareTemplateComponent>
    </div>
</template>
<script setup>
import { notify } from '@kyvg/vue3-notification';
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import BasicInfoForm from './form-partials/partial.basic_info.vue';
import ManageQuestions from './manageQuestions.vue';
import ManagePeerQuestions from './managePeerQuestions.vue';
import CopyTemplateComponent from './actions/copy.vue';
import ShareTemplateComponent from './actions/share.vue';

const copyTemplateComponent = ref(null);
const shareTemplateComponent = ref(null);
const schema = yup.object({
    type: yup.string().nullable(),
    name: yup.string().required(),
    description: yup.string().nullable(),
    evaluationSettings: yup.object().shape({
        target: yup.string().default('peers'),
    }),
});
var initialValues = ref({
    type: 'irat',
    name: '',
    description: '',
    evaluationSettings: {
        target: 'peers',
    },
});
const { values, meta, setValues, setFieldValue } = useForm({
    initialValues: initialValues,
    validationSchema: schema,
    keepValuesOnUnmount: true,
});
const componentDoneLoading = ref(false);
const route = useRoute();
const router = useRouter();
const formType = ref('create');
const editMode = ref(false);
const isFullScreenManageQuestions = ref(false);
if (route.name == 'templates.create') {
    formType.value = 'create';
    Events.fire('topbar_update', {
        title: '<i class="fas fa-list"></i>&nbsp;Templates',
        breadcrumb: [{ name: 'Home' }, { name: 'Templates', link: 'questions.index' }, { name: 'New Template' }],
    });
    componentDoneLoading.value = true;
} else if (route.name == 'templates.view') {
    formType.value = 'view';
    editMode.value = false;
}
const template = ref(null);
function submit(values) {
    if (formType.value == 'create') {
        axios
            .post('templates', values)
            .then(function (response) {
                template.value = response.data.data;
                template.value.type = values.type;
                notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Template successfully saved',
                });
                formType.value = 'view';
                router.push({ name: 'templates.view', params: { id: template.value.uuid } });
            })
            .catch(function (errors) {
                console.log(errors);
                notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong. Please try again later',
                });
            });
    } else {
        axios
            .patch(`templates/${route.params.id}`, values)
            .then(function (response) {
                notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Template successfully updated',
                });
                if (editMode.value) {
                    editMode.value = false;
                }
                template.value.name = values.name;
                template.value.description = values.description;
                template.value.evaluationSettings = values.evaluationSettings;
            })
            .catch(function (errors) {
                notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong. Please try again later',
                });
            });
    }
}
function hydrate(data) {
    if (!data) {
        setValues(initialValues.value);
    } else {
        setValues(data);
    }
}
function fetch() {
    const id = template.value ? template.value.uuid : route.params.id;
    axios
        .get(`templates/${id}`)
        .then(function (response) {
            template.value = response.data.data;
            template.value.type = template.value.activity_type;
            hydrate({
                name: template.value.name,
                description: template.value.description,
                type: template.value.activity_type,
                evaluationSettings: template.value.evaluationSettings,
            });

            Events.fire('topbar_update', {
                title: '<i class="fas fa-list"></i>&nbsp;Templates',
                breadcrumb: [{ name: 'Home' }, { name: 'Templates', link: 'questions.index' }, { name: template.value.name }],
            });
            componentDoneLoading.value = true;
        })
        .catch(function (errors) {});
}
function updateTemplate(data) {
    template.value = data;
    template.value.type = template.value.activity_type;
}
onMounted(() => {
    if (formType.value != 'create') {
        fetch();
    }
});
watch(
    () => route.name,
    () => {
        fetch();
    }
);
</script>
