<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="col-md-12 form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">
                        <h1>Edit Module</h1>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <module-form :form="form" :course="course"></module-form>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <div class="buttonGroup">
                            <button class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Update" @click.prevent="submit">
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'courses.view',
                                    params: { id: form.model.courseUuid },
                                    query: { tab: 'module' },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>

            <div class="viewDropdown flexRight">
                <div class="dropup col-xs-6 col-md-2">
                    <button
                        id="viewDropdownMenuButton"
                        class="width100 flexSpaceBetween align-items"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-label="More Actions"
                    >
                        More Actions
                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                        <ul>
                            <li v-if="!module.isArchived">
                                <a class="danger-state" href="#" @click.prevent="openArchiveModal()">
                                    <span> <i class="fa fa-trash marginRight8" aria-hidden="true"></i>Archive</span>
                                </a>
                            </li>
                            <li v-else>
                                <a class="primary-state" href="#" @click.prevent="reinstate()">
                                    <span> <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="suspendModuleModal"
            class="modal"
            :class="{
                'danger-modal': !module.isArchived,
                'warning-modal': module.isArchived,
            }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="suspendModuleModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="suspendModuleModal-title" class="modal-title">
                            <template v-if="!module.isArchived"> Archive </template>
                            <template v-else> Reinstate </template>
                        </h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h2 class="marginTop40">
                            <template v-if="!module.isArchived"> Archive </template>
                            <template v-else> Reinstate </template>
                        </h2>

                        <h2 class="marginBottom20 fw-normal">
                            {{ module.name }}
                        </h2>

                        <div class="col-xs-12 col-md-8 col-md-offset-2">
                            <template v-if="!course.isArchived">
                                <p>
                                    Are you sure you want to
                                    <br />
                                    archive this module?
                                </p>
                                <br />
                                <p class="fontSize14 greyText">
                                    <i>
                                        All associated activities will also be
                                        <br />
                                        hidden until you reinstate this module.
                                    </i>
                                </p>
                            </template>
                            <template v-else>
                                <p>
                                    Are you sure you want to
                                    <br />
                                    reinstate this module?
                                </p>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button
                            class="btn"
                            :class="{
                                'btn-danger': !module.isArchived,
                                'btn-success': module.isArchived,
                            }"
                            data-dismiss="modal"
                            @click.prevent="module.isArchived ? reinstate() : archive()"
                        >
                            <i v-if="!module.isArchived" class="fa fa-archive marginRight8" aria-hidden="true" />
                            <template v-if="!module.isArchived"> Yes, Archive </template>
                            <template v-else> Yes, Reinstate </template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            module: {},
            form: new KrForm({
                fields: {
                    courseUuid: '',
                    name: '',
                    //code:'',
                    description: '',
                    startDate: '',
                    endDate: '',
                    objectives: 'type!:array',
                    descriptionIsHTML: '',
                },
                props: {},
                urls: {
                    put: 'modules/' + this.$route.params.id,
                },
            }),
            course: {},
        };
    },
    components: {
        'module-form': require(`./form-partials/partial.module.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.name && this.form.model.startDate && this.form.model.endDate) {
                return true;
            }
            return false;
        },
    },
    created() {
        var that = this;
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }],
        });
        axios.get('/modules/' + that.$route.params.id).then(function (response) {
            //parse dates first
            that.form.setModel(response.data.data);
            that.module = response.data.data;
            that.course = that.module.course;
            that.form.model.courseUuid = that.course.uuid;
            that.form.model.descriptionIsHTML = false;
            Events.fire('topbar_update', {
                breadcrumb: [
                    { name: 'Home' },
                    { name: 'Courses', link: 'courses.index' },
                    {
                        name: that.course.name + '(' + that.course.code + ')',
                        link: 'courses.view',
                        params: { id: that.course.uuid },
                        query: { tab: 'module' },
                    },
                    { name: 'Edit Module' },
                ],
            });
            that.component_done_loading = true;
        });
    },
    methods: {
        openArchiveModal() {
            $('#suspendModuleModal').modal('show');
        },
        archive() {
            $('#suspendModuleModal').modal('hide');
            var success = function () {
                this.module.isArchived = true;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have sucessfully archived this module',
                });
                this.$router.push({
                    name: 'courses.view',
                    params: { id: this.module.courseUuid },
                });
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/modules/' + this.$route.params.id)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        reinstate() {
            $('#suspendModuleModal').modal('hide');
            var success = function () {
                this.module.isArchived = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Module has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/modules/' + this.$route.params.id)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        submit() {
            var that = this;
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: that.course.uuid },
                        query: { tab: 'module', moduleUuid: that.module.uuid },
                    });
                }
            });
        },
    },
};
</script>
