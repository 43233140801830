import { createRouter, createWebHistory } from 'vue-router';
import KrAuth from '../components/auth/auth';
let routes = [
    {
        path: '/',
        name: '/',
        component: require('../views/layouts/main/layout.vue').default,
        children: [
            {
                path: '/files/:filename',
                name: 'downloadables',
                component: require('../views/file.vue').default,
                meta: { auth: true },
            },
            {
                path: '/login',
                name: 'auth.login',
            },
            {
                path: 'password/reset/:token',
                name: 'auth.password.reset',
            },
            {
                path: 'launchexternal',
                name: 'launchexternal',
                component: require('../views/main/launch-external.vue').default,
                meta: { auth: true },
            },
            {
                path: 'invoice/:id',
                name: 'profile.invoice',
                component: require('../views/main/profile/invoice.vue').default,
                meta: { auth: true },
            },
            /*{
				path:'invoice2',
				name:'invoice2',
				component: require('../views/main/profile/receipt.vue').default
			},*/
            {
                path: 'payments/gift/:id',
                name: 'payments.gift',
                component: require('../views/payment/index.vue').default,
                meta: { auth: true },
            },
            {
                path: 'payments',
                name: 'payments',
                component: require('../views/payment/index.vue').default,
                meta: { auth: true },
            },
            {
                path: 'receipt',
                name: 'receipt',
                component: require('../views/payment/receipt.vue').default,
            },
            {
                path: 'checkout-success',
                name: 'checkout-success',
                meta: { auth: true },
                component: require('../views/payment/checkout-success.vue').default,
            },
            {
                path: 'activities/tests/:id/timer',
                name: 'activities.tests.timer',
                meta: { auth: true },
                component: require('../views/main/courses/modules/activities/dashboard/timer.vue').default,
            },
            {
                path: 'activities/tests/:id/questions',
                name: 'activities.tests.questions',
                meta: { auth: true },
                component: require('../views/main/courses/modules/activities/dashboard/questions.vue').default,
            },
            {
                path: 'tests/:id/conference',
                name: 'tests.conference',
                component: require('../views/zoom/index.vue').default,
                meta: { auth: true },
            },
            {
                path: '/in',
                component: require('../views/layouts/main/internal-layout.vue').default,
                children: [
                    {
                        path: 'settings',
                        name: 'settings.edit',
                        meta: {
                            auth: true,
                            title: 'Settings | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/settings/edit.vue').default,
                    },
                    {
                        path: 'settings/lti/create',
                        name: 'settings.lti.create',
                        meta: {
                            auth: true,
                            title: 'Settings | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/settings/lti/create.vue').default,
                    },
                    {
                        path: 'settings/lti/:id/edit',
                        name: 'settings.lti.edit',
                        meta: {
                            auth: true,
                            title: 'Settings | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/settings/lti/edit.vue').default,
                    },
                    {
                        path: 'profile',
                        name: 'profile.view',
                        meta: {
                            auth: true,
                            title: 'My Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/profile/index.vue').default,
                    },
                    {
                        path: 'profile/receipt',
                        name: 'profile.receipt',
                        meta: {
                            auth: true,
                            title: 'Receipt | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/profile/receipt.vue').default,
                    },
                    {
                        path: 'profile/edit',
                        name: 'profile.edit',
                        meta: {
                            auth: true,
                            title: 'Edit My Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/profile/edit.vue').default,
                    },
                    {
                        path: 'superusers',
                        name: 'superusers.index',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Superusers | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/superusers/index.vue').default,
                    },
                    {
                        path: 'superusers/create',
                        name: 'superusers.create',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Superusers Create | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/superusers/create.vue').default,
                    },
                    {
                        path: 'superusers/:id/edit',
                        name: 'superusers.edit',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Edit Superuser Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/superusers/edit.vue').default,
                    },
                    {
                        path: 'superusers/:id/view',
                        name: 'superusers.view',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Superuser Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/superusers/view.vue').default,
                    },
                    {
                        path: 'accounts',
                        name: 'accounts.index',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Accounts | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/index.vue').default,
                    },
                    {
                        path: 'report',
                        name: 'report',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Report | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/report.vue').default,
                    },
                    {
                        path: 'accounts/create',
                        name: 'accounts.create',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Create Account | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/create.vue').default,
                    },
                    {
                        path: 'accounts/:id/edit',
                        name: 'accounts.edit',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Edit Account Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/edit.vue').default,
                    },
                    {
                        path: 'accounts/:id/view',
                        name: 'accounts.view',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Accounts | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/view.vue').default,
                    },
                    {
                        path: 'accounts/:account_id/teachers/create',
                        name: 'accounts.teachers.create',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Create Teachers | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/teachers/create.vue').default,
                    },
                    {
                        path: 'accounts/teachers/:id/edit',
                        name: 'accounts.teachers.edit',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Edit Teacher Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/teachers/edit.vue').default,
                    },
                    {
                        path: 'accounts/teachers/:id/view',
                        name: 'accounts.teachers.view',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Teacher Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/teachers/view.vue').default,
                    },
                    {
                        path: 'accounts/:account_id/superadmins/create',
                        name: 'accounts.superadmins.create',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Create Superadmins | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/superadmin/create.vue').default,
                    },
                    {
                        path: 'accounts/superadmins/:id/view',
                        name: 'accounts.superadmins.view',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Super Admin Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/superadmin/view.vue').default,
                    },
                    {
                        path: 'accounts/superadmins/:id/edit',
                        name: 'accounts.superadmins.edit',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Edit Super Admin Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/superadmin/edit.vue').default,
                    },
                    {
                        path: 'accounts/:account_id/access-codes/create',
                        name: 'accounts.codes.create',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Access Code Create | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/codes/create.vue').default,
                    },
                    {
                        path: 'accounts/:id/usage',
                        name: 'accounts.usage.edit',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Edit Usage | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/usage/edit.vue').default,
                    },
                    {
                        path: 'accounts/:id/onboard',
                        name: 'accounts.onboard.edit',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Edit Onboard | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/onboard/edit.vue').default,
                    },

                    {
                        path: 'accounts/:account_id/notes/create',
                        name: 'accounts.notes.create',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Create Notes | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/notes/create.vue').default,
                    },
                    {
                        path: 'accounts/:account_id/notes/:id/edit',
                        name: 'accounts.notes.edit',
                        meta: {
                            auth: true,
                            role: 'Superuser',
                            title: 'Edit Notes | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/accounts/notes/edit.vue').default,
                    },
                    {
                        path: 'people',
                        name: 'people.index',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                        },
                        component: require('../views/main/people/index.vue').default,
                    },
                    {
                        path: 'teachers',
                        name: 'teachers.index',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Teachers | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/people/index.vue').default,
                    },
                    {
                        // path: "teachers/create",
                        path: 'people/teachers/create',
                        name: 'teachers.create',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Create Teachers | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/teachers/create.vue').default,
                    },
                    {
                        // path: "teachers/:id/edit",
                        path: 'people/teachers/:id/edit',
                        name: 'teachers.edit',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Edit Teacher Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/teachers/edit.vue').default,
                    },
                    {
                        // path: "teachers/:id/view",
                        path: 'people/teachers/:id/view',
                        name: 'teachers.view',
                        meta: { auth: true, role: ['Super Admin', 'Teacher'] },
                        component: require('../views/main/teachers/view.vue').default,
                    },
                    {
                        path: 'questions',
                        name: 'questions.index',
                        meta: {
                            auth: true,
                            title: 'Library | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/questions/index.vue').default,
                    },
                    {
                        path: 'students',
                        name: 'students.index',
                        meta: {
                            auth: true,
                            title: 'Students | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/people/index.vue').default,
                    },
                    {
                        // path: "students/create",
                        path: 'people/students/create',
                        name: 'students.create',
                        meta: {
                            auth: true,
                            title: 'Add Students| InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/students/create.vue').default,
                    },
                    {
                        // path: "students/:id/edit",
                        path: 'people/students/:id/edit',
                        name: 'students.edit',
                        meta: {
                            auth: true,
                            title: 'Edit Student Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/students/edit.vue').default,
                    },
                    {
                        // path: "students/:id/view",
                        path: 'people/students/:id/view',
                        name: 'students.view',
                        meta: { auth: true },
                        component: require('../views/main/students/view.vue').default,
                    },
                    {
                        path: 'accounts/students/:id/view',
                        name: 'accounts.students.view',
                        meta: {
                            auth: true,
                            title: 'Student Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/students/view.vue').default,
                    },
                    {
                        path: 'courses',
                        name: 'courses.index',
                        meta: {
                            auth: true,
                            role: ['Teacher', 'Super Admin'],
                            title: 'Courses | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/index.vue').default,
                    },
                    {
                        path: 'courses/create',
                        name: 'courses.create',
                        meta: {
                            auth: true,
                            role: ['Teacher', 'Super Admin'],
                            title: 'Create Courses | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/create.vue').default,
                    },
                    {
                        path: 'courses/:id/edit',
                        name: 'courses.edit',
                        meta: {
                            auth: true,
                            role: ['Teacher', 'Super Admin'],
                            title: 'Edit Course | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/edit.vue').default,
                    },
                    {
                        path: 'courses/:id/view',
                        name: 'courses.view',
                        meta: { auth: true, role: ['Teacher', 'Super Admin'] },
                        component: require('../views/main/courses/view.vue').default,
                    },
                    {
                        path: 'courses/:id/accommodations',
                        name: 'courses.accommodations',
                        meta: { auth: true, role: ['Teacher', 'Super Admin'] },
                        component: require('../views/main/courses/students/accommodation.vue').default,
                    },
                    {
                        path: 'courses/:course_id/students/create',
                        name: 'courses.manage.students.create',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                        },
                        component: require('../views/main/students/create.vue').default,
                    },
                    {
                        path: 'courses/:course_id/students/:id/edit',
                        name: 'courses.manage.students.edit',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Edit Student Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/students/edit.vue').default,
                    },
                    {
                        path: 'courses/:course_id/students/:id/view',
                        name: 'courses.manage.students.view',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Student Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/students/view.vue').default,
                    },
                    {
                        path: 'courses/:course_id/students/invite',
                        name: 'courses.manage.students.invite',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Invite Students | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/students/invite.vue').default,
                    },
                    {
                        path: 'courses/:id/teachers/create',
                        name: 'courses.manage.teachers.create',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Add Teachers | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/teachers/create.vue').default,
                    },
                    {
                        path: 'courses/:course_id/teachers/:id/view',
                        name: 'courses.manage.teachers.view',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Teacher Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/teachers/view.vue').default,
                    },
                    {
                        path: 'courses/:course_id/teachers/:id/edit',
                        name: 'courses.manage.teachers.edit',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Edit Teacher Profile | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/teachers/edit.vue').default,
                    },
                    {
                        path: 'courses/:course_id/modules/create',
                        name: 'courses.modules.create',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Add Module | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/modules/create.vue').default,
                    },
                    {
                        path: 'courses/modules/:id/edit',
                        name: 'courses.modules.edit',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Edit Module | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/modules/edit.vue').default,
                    },
                    {
                        path: 'courses/:course_id/modules/:module_id/activities/create',
                        name: 'courses.modules.activities.create',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Create Activity | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/modules/activities/cru.vue').default,
                    },
                    {
                        path: 'courses/modules/activities/:id/continue',
                        name: 'courses.modules.activities.continue',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Create Activity | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/modules/activities/cru.vue').default,
                    },
                    {
                        path: 'courses/modules/activities/:id/republish',
                        name: 'courses.modules.activities.republish',
                        meta: {
                            auth: true,
                            role: ['Super Admin', 'Teacher'],
                            title: 'Republish Activity | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/courses/modules/activities/cru.vue').default,
                    },
                    {
                        path: 'activities/tests/:id/dashboard',
                        name: 'activities.tests.dashboard',
                        meta: { auth: true, role: ['Super Admin', 'Teacher'] },
                        component: require('../views/main/courses/modules/activities/dashboard/index.vue').default,
                    },
                    {
                        path: 'activities/tests/:id/dashboard/trat-presentation',
                        name: 'activities.tests.dashboard.trat-presentation',
                        meta: { auth: true, role: ['Super Admin', 'Teacher'] },
                        component: require('../views/main/courses/modules/activities/dashboard/partials/trat/clarification/clarification.vue').default,
                    },
                    {
                        path: 'activities/tests/:id/dashboard/peer-evaluation',
                        name: 'activities.tests.dashboard.peer-evaluation',
                        meta: { auth: true, role: ['Super Admin', 'Teacher'] },
                        component: require('../views/main/courses/modules/activities/dashboard/index-new.vue').default,
                    },
                    {
                        path: 'grades',
                        name: 'grades.index',
                        meta: { auth: true },
                        component: require('../views/main/grades/index.vue').default,
                    },
                    {
                        path: 'tests',
                        name: 'tests.index',
                        meta: { auth: true },
                        component: require('../views/main/tests/index.vue').default,
                    },
                    {
                        path: 'tests/:id',
                        name: 'tests.proper',
                        meta: {
                            auth: true,
                            title: 'Test | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/test.vue').default,
                    },
                    {
                        path: 'tests/:id/discussion',
                        name: 'tests.discussion',
                        meta: {
                            auth: true,
                            title: 'Discussion | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/e-gallery-walk/index.vue').default,
                    },
                    {
                        path: 'tests/:id/clarification',
                        name: 'tests.clarification',
                        meta: {
                            auth: true,
                            title: 'Clarification | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/trat/clarification.vue').default,
                    },
                    {
                        path: 'tests/:id/prework',
                        name: 'tests.prework',
                        meta: {
                            auth: true,
                            title: 'Prework | InteDashboard | TBL Makes Teams Work',
                            previousRoute: null,
                        },
                        component: require('../views/main/tests/rat/prework/index.vue').default,
                    },
                    {
                        path: 'tests/:id/instruction',
                        name: 'tests.instruction',
                        meta: {
                            auth: true,
                            title: 'Instruction | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/instruction.vue').default,
                    },
                    {
                        path: 'tests/:id/trat-report',
                        name: 'tests.trat-report',
                        meta: {
                            auth: true,
                            title: 'TRAT Report | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/trat/report.vue').default,
                    },
                    {
                        path: 'tests/:id/irat-report',
                        name: 'tests.irat-report',
                        meta: {
                            auth: true,
                            title: 'IRAT Report | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/irat/report.vue').default,
                    },
                    {
                        path: 'tests/:id/evaluation-report',
                        name: 'tests.evaluation-report',
                        meta: {
                            auth: true,
                            title: 'Evaluation Report | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/evaluation/report.vue').default,
                    },
                    {
                        path: 'tests/:id/evaluation-report-v2',
                        name: 'tests.evaluation-report-v2',
                        meta: {
                            auth: true,
                            title: 'Evaluation Report | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/evaluation/reportV2.vue').default,
                    },
                    {
                        path: 'tests/:id/application-report',
                        name: 'tests.application-report',
                        meta: {
                            auth: true,
                            title: 'Application Report | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/application/report.vue').default,
                    },
                    //for irat trat application
                    {
                        path: 'tests/:id/preview-rat',
                        name: 'tests.preview.rat',
                        meta: {
                            auth: true,
                            title: 'Preview Test | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/test.vue').default,
                    },
                    {
                        path: 'tests/:id/preview-prework',
                        name: 'teachers.tests.preview.prework',
                        meta: {
                            auth: true,
                            title: 'Preview Prework | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/prework/index.vue').default,
                    },
                    {
                        path: 'teachers/tests/:id/preview-rat',
                        name: 'teachers.tests.preview.rat',
                        meta: {
                            auth: true,
                            title: 'Preview Test | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/test.vue').default,
                    },
                    {
                        path: 'tests/:id/preview-evaluation',
                        name: 'tests.preview.evaluation',
                        meta: {
                            auth: true,
                            title: 'Preview Evaluation | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/rat/test.vue').default,
                    },
                    {
                        path: 'teachers/tests/:id/preview-evaluation',
                        name: 'teachers.tests.preview.evaluation',
                        meta: {
                            auth: true,
                            title: 'Preview Evaluation | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/evaluation/evaluation.vue').default,
                    },
                    {
                        path: 'tests/:id/evaluation',
                        name: 'tests.evaluation',
                        meta: {
                            auth: true,
                            title: 'Evaluation | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/evaluation/evaluation.vue').default,
                    },
                    {
                        path: 'tests/:id/evaluation-v2',
                        name: 'tests.evaluation-v2',
                        meta: {
                            auth: true,
                            title: 'Evaluation | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/evaluation/evaluation-new.vue').default,
                    },
                    {
                        path: 'teachers/tests/:id/preview-evaluation-v2',
                        name: 'teachers.tests.preview.evaluation-v2',
                        meta: {
                            auth: true,
                            title: 'Preview Evaluation | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/tests/evaluation/evaluation-new.vue').default,
                    },
                    {
                        path: 'templates',
                        name: 'templates.index',
                        meta: {
                            auth: true,
                            title: 'Templates | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/questions/library-templates/index.vue').default,
                    },
                    {
                        path: 'templates/:id/share',
                        name: 'templates.share',
                        meta: {
                            auth: true,
                            title: 'Templates | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/questions/index.vue').default,
                    },
                    // {
                    //     path: 'templates/:id/view',
                    //     name: 'templates.view',
                    //     meta: {
                    //         auth: true,
                    //         title: 'Questions | InteDashboard | TBL Makes Teams Work',
                    //     },
                    //     component: require('../views/main/questions/library-templates/view.vue').default,
                    // },
                    // {
                    //     path: 'templates/create',
                    //     name: 'templates.create',
                    //     meta: {
                    //         auth: true,
                    //         title: 'Templates | InteDashboard | TBL Makes Teams Work',
                    //     },
                    //     component: require('../views/main/questions/library-templates/cru.vue').default,
                    // },
                    // {
                    //     path: 'templates/:id/edit',
                    //     name: 'templates.edit',
                    //     meta: {
                    //         auth: true,
                    //         title: 'Templates | InteDashboard | TBL Makes Teams Work',
                    //     },
                    //     component: require('../views/main/questions/library-templates/cru.vue').default,
                    // },
                    {
                        path: 'templates/create',
                        name: 'templates.create',
                        meta: {
                            auth: true,
                            title: 'Templates | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/questions/library-templates/cru.vue').default,
                    },
                    {
                        path: 'templates/:id/view',
                        name: 'templates.view',
                        meta: {
                            auth: true,
                            title: 'Templates | InteDashboard | TBL Makes Teams Work',
                        },
                        component: require('../views/main/questions/library-templates/cru.vue').default,
                    },
                ],
            },
        ],
    },
    {
        path: '/experimentals/excalidraw',
        name: 'experimentals.excalidraw.view',
        meta: {
            auth: true,
            title: 'Experimentals | InteDashboard | TBL Makes Teams Work',
        },
        component: require('../views/main/experimentals/excalidraw.vue').default,
    },
    {
        path: '/experimentals/tldraw',
        name: 'experimentals.tldraw.view',
        meta: {
            auth: true,
            title: 'Experimentals | InteDashboard | TBL Makes Teams Work',
        },
        component: require('../views/main/experimentals/tldraw.vue').default,
    },
    {
        path: '/404',
        name: 'errors.404',
        component: require('../views/errors/404.vue').default,
    },
    {
        path: '/403',
        name: 'errors.403',
        component: require('../views/errors/403.vue').default,
    },
    {
        path: '/500',
        name: 'errors.500',
        component: require('../views/errors/500.vue').default,
    },
    { path: '/:pathMatch(.*)*', redirect: '/404' },
];

let r = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkActiveClass: 'active',
    linkExactActiveClass: '',
});
r.beforeEach((to, from, next) => {
    $('.modal').modal('hide');
    if (!$.isNumeric(to.hash.split('#')[1])) {
        window.scrollTo(0, 0);
    }

    var auth = new KrAuth();
    document.title = to.meta.title || 'InteDashboard | TBL Makes Teams Work';

    if (!to.matched.some((record) => record.meta.auth)) {
        //unsecured
        if (auth.isLoggedIn()) {
            if (to.name == 'auth.password.reset' || (to.query.access_token && to.query.api_url)) {
                auth.logout();
            }
            if (to.name != 'errors.403' && to.name != 'errors.404' && to.name != 'errors.500') {
                if (auth.user().role == 'Superuser') {
                    next({ name: 'superusers.index' });
                }
                if (auth.user().role == 'Teacher') {
                    next({ name: 'courses.index' });
                }
                if (auth.user().role == 'Super Admin') {
                    next({ name: 'courses.index' });
                }
                if (auth.user().role == 'Student') {
                    /*if(!auth.user().isPaid){
	                  if(auth.user().account.paymentMethod=='Student'&&auth.user().account.type=='Paid'&&!auth.user().isGeneric&&auth.user().email!=null){
	                   	if(to.name=='payments'){
	                  		next();
	                  	}else{
	                  		next({name:'payments'});
	                  	}
	                  }else{
	                  	if(to.name=='tests.index'){
	                  		next();
	                  	}else{
	                  		next({name:'tests.index'});
	                  	}
	                  }
	                }else{
		                if(to.name=='tests.index'){
	                  		next();
	                  	}else{
	                  		next({name:'tests.index'});
	                  	}
	                }*/

                    if (to.name == 'tests.index') {
                        next();
                    } else {
                        next({ name: 'tests.index' });
                    }
                }
                if (auth.user().role == 'demo') {
                    next({ name: 'tests.index' });
                }
            }
        }
        next();
    } else if (to.matched.some((record) => record.meta.auth)) {
        //secured
        if (!auth.isLoggedIn()) {
            /*next({
		      	name: 'auth.login',
	    	})*/
        } else {
            if (to.matched.some((record) => record.meta.role && !record.meta.role.includes(auth.user().role))) {
                next({
                    name: 'errors.403',
                });
            } else {
                if (to.name == 'superusers.create') {
                    if (!auth.user().isDeletable) {
                        next();
                    } else {
                        next({ name: 'errors.403' });
                    }
                }
                if (to.name == 'superusers.edit') {
                    if (!auth.user().isDeletable || auth.user().uuid == to.params.id) {
                        next();
                    } else {
                        next({ name: 'errors.403' });
                    }
                }
                if (to.name == 'questions.index') {
                    if (auth.user().account.version == 'full') {
                        next();
                    } else {
                        next({ name: 'errors.403' });
                    }
                }
                //authenticated via role. push view
                next();
            }
        }
    }

    to.meta.previousRoute = from;
    next();
});
r.afterEach((to) => {
    //VueMouseflow.logRouteChange(to, { includeRouteParams: true });
});
export const router = r;
