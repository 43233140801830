<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-6">{{ $t('modules.teachers.create.headingLabel') }}</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="top-send-invitation-btn"
                                class="btn btn-primary"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Send Invitation"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane marginRight8" aria-hidden="true" />{{ $t('modules.teachers.create.sendButtonLabel') }}
                            </button>

                            <!--<button class="btn btn-default " :disabled="(!canSend) ? true:undefined" @click.prevent="openEmailSampleModal()">
				                Preview Email
				            </button>
				             <router-link v-if="!isFromAccount" :to="{name:'teachers.index'}" tag="button" class="btn btn-default " exact>
					            Back
				            </router-link>
				            <router-link v-else :to="{name:'accounts.view',params:{id:form.model.accountUuid}}" tag="button" class="btn btn-default " exact>
				            	Back
				            </router-link>-->
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                v-tooltip="{ content: 'Send Invitation' }"
                                class="btn btn-primary"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Send Invitation"
                                @click="submit"
                            >
                                <i class="fa fa-paper-plane" aria-hidden="true" />
                            </button>
                            <!--<button class="btn btn-default " :disabled="(!canSend) ? true:undefined" @click.prevent="openEmailSampleModal()">
				                <i class="fa fa-eye"></i>
				            </button>
				            <router-link v-if="!isFromAccount" :to="{name:'teachers.index'}" tag="button" class="btn btn-default " exact>
					            	<i class="fa fa-caret-left"></i>
				            </router-link>
				            <router-link v-else :to="{name:'accounts.view',params:{id:form.model.accountUuid}}" tag="button" class="btn btn-default " exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>	-->
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <teacher-form :form="form" mode="create" :is-set-account="isSetAccount" :accounts="accounts" />
                        <div class="profileBtns buttonGroup">
                            <button class="btn btn-primary" :disabled="!canSend ? true : undefined" aria-label="Send Invitation" @click="submit">
                                <i class="fa fa-paper-plane marginRight8" aria-hidden="true" />{{ $t('modules.teachers.create.sendButtonLabel') }}
                            </button>
                            <!--<button class="btn btn-default " :disabled="(!canSend) ? true:undefined" @click.prevent="openEmailSampleModal()" aria-label="Preview Email">
				                <i class="fa fa-envelope"></i>&nbsp;Preview Email
				            </button>-->
                            <router-link v-if="!isFromAccount" v-slot="{ navigate }" :to="{ name: 'teachers.index' }" custom>
                                <button class="btn btn-default" @click="navigate">{{ $t('modules.teachers.create.cancelButtonLabel') }}</button>
                            </router-link>
                            <router-link
                                v-else
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: form.model.accountUuid },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">{{ $t('modules.teachers.create.cancelButtonLabel') }}</button>
                            </router-link>
                        </div>
                    </div>
                </template>
                <!-- <template slot="footer">
			      <div>
			        <button :disabled="(!canSend) ? true:undefined" class="btn pull-right btn-success" type="submit"@click="submit"> Create </button>
			      </div>  
			    </template> -->
            </kr-panel>
        </div>
        <!-- <div class="page-title">
	        <div class="col-xs-12 col-md-6" style="margin-bottom:30px;">
	        	<router-link v-if="!isFromAccount":to="{name:'teachers.index'}">Teachers</router-link>
	        	<router-link v-else :to="{name:'accounts.view',params:{id:$route.params.account_id}}">Account</router-link>
	            / Add New Teacher
	            <h3 style="margin-bottom:0;">Add new Teacher</h3>
	        </div>
    	</div>
    	<div class="clearfix"></div>
    	<div class="col-md-12 form-div">
			<kr-panel>
				<template #title>
					<div class="x_title">
						NEW Teacher
					</div>	
				</template>
			    <template #content>
			          <teacher-form :form="form" mode="create" :isSetAccount="isSetAccount" :accounts="accounts"></teacher-form>
			    </template>

			    <template slot="footer">
			      <div class="col-xs-12">
			        <button :disabled="(!canSend) ? true:undefined" class="btn pull-right btn-success" type="submit"@click="submit"> Create </button>
			      </div>  
			    </template>
		    </kr-panel>
		</div> -->

        <div id="emailSampleModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="emailSampleModal-title">
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="emailSampleModal-title" class="modal-title">Preview Email</h2>
                    </div>
                    <div class="modal-body">
                        <div class="emailer">
                            <div class="emailerHeader">
                                <img
                                    height="auto"
                                    src="https://cdn.intedashboard.com/emails/logo/white_intedashboard.png"
                                    width="270"
                                    alt="Intedashboard Logo"
                                />
                            </div>
                            <div class="emailerContent">
                                <div>Activate Your InteDashboard Account</div>
                                <div>
                                    <p class="fontBold">Welcome to InteDashboard</p>
                                </div>
                                <div>
                                    <p>
                                        <span class="fontBold">{{ auth.user().displayName }}</span>
                                        has added you as a teacher in InteDashboard.
                                    </p>
                                </div>
                                <div>
                                    <p>InteDashboard is a new online platform created by a team from</p>
                                    <p>Duke-NUS Medical School exclusively for team-based learning (“TBL”) educators.</p>
                                </div>
                                <div>
                                    <p>InteDashboard automates TBL administration and provides real-time data analytics.</p>
                                </div>
                                <button
                                    v-tooltip="{
                                        content: 'Unable to click. For preview purposes only.',
                                    }"
                                    class="btn btn-primary marginBottom20"
                                    arial-label="Activate My Account"
                                >
                                    Activate My Account<i class="fa fa-caret-right marginLeft8" aria-hidden="true" />
                                </button>

                                <p class="textAlignLeft fontSize14 fontGrey">
                                    <b>Please take note:</b>
                                </p>
                                <p class="textAlignLeft fontSize14 fontGrey flexOnly">
                                    <b>•</b>&nbsp;The 'Activate my account' button will direct you to&nbsp;<a
                                        v-tooltip="{
                                            content: 'Unable to click. For preview purposes only.',
                                        }"
                                        class="link"
                                    >
                                        InteDashboard's main login page.
                                    </a>
                                </p>
                                <p class="textAlignLeft fontSize14 fontGrey flexOnly">
                                    <b>•</b>&nbsp;Please ensure that you are logged out of any other InteDashboard sessions (including any professional
                                    development workshop sessions) prior to account activation.
                                </p>
                            </div>
                            <div class="emailerSubContent">
                                <div>
                                    <div class="col-xs-12 col-md-6">
                                        <p class="marginBottom10">Help Center</p>
                                        <a class="fontBold link" href="mailto:support@intedashboard.com">support@intedashboard.com</a>
                                    </div>
                                </div>
                                <div>
                                    <p class="marginTop10 fontBold">Social Media</p>
                                    <div class="socialMed marginTop10">
                                        <div class="col-xs-1">
                                            <a href="https://www.linkedin.com/company/cognalearn/" target="_blank">
                                                <img height="12" src="https://cdn.intedashboard.com/emails/facebook.png" alt="facebook logo" />
                                            </a>
                                        </div>
                                        <div class="col-xs-1">
                                            <a href="https://twitter.com/InteDashboard" target="_blank">
                                                <img width="12" src="https://cdn.intedashboard.com/emails/twitter.png" alt="twitter logo" />
                                            </a>
                                        </div>
                                        <div class="col-xs-1">
                                            <a href="https://www.linkedin.com/company/cognalearn/" target="_blank">
                                                <img height="12" src="https://cdn.intedashboard.com/emails/linkedin.png" alt="linkedin logo" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="emailerFooter flexSpaceBetween">
                                <div>
                                    Copyright © CognaLearn Pte Ltd 2019
                                    {{ new Date().getFullYear() }}
                                </div>
                                <div class="fontBold flexOnly">
                                    <div>
                                        <a href="#">Terms & Condition</a>
                                    </div>
                                    <div class="marginLeft20">
                                        <a href="#">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    avatar: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    roleId: 'default!:3',
                    identity: '',
                    accountUuid: '',
                    canAddTeacher: '',
                    canUseExpressSignIn: '',
                    canViewQuestionBank: '',
                    academicDiscipline: 'sendNull!:false',
                    jobTitle: 'sendNull!:false',
                },
                props: {},
                urls: {
                    post: 'teachers',
                },
            }),
            accounts: [],
            auth: auth,
            isSetAccount: false,
            isFromAccount: false,
        };
    },
    components: {
        'teacher-form': require(`./form-partials/partial.teacher.vue`).default,
    },
    computed: {
        canSend() {
            if (!this.form.errors.any() && this.form.model.email && this.form.model.firstname && this.form.model.lastname && this.form.model.accountUuid) {
                return true;
            }
            return false;
        },
    },
    created() {
        var that = this;
        this.form.model.canAddTeacher = false;
        this.form.model.canUseExpressSignIn = false;
        this.form.model.canViewQuestionBank = true;
        if (this.$route.name == 'accounts.teachers.create') {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-chalkboard-teacher"></i>&nbsp;Teachers',
                breadcrumb: [
                    { name: 'Home' },
                    { name: this.$t('modules.accounts.view.breadcrumbLabel'), link: 'accounts.view', params: { id: this.$route.params.account_id } },
                    { name: this.$t('modules.teachers.create.breadcrumbLabel') },
                ],
            });
            this.isFromAccount = true;
            this.isSetAccount = true;
            this.form.model.accountUuid = this.$route.params.account_id;
        } else {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-chalkboard-teacher"></i>&nbsp;Teachers',
                breadcrumb: [
                    { name: 'Home' },
                    { name: this.$t('modules.teachers.listing.breadcrumbLabel'), link: 'teachers.index' },
                    { name: this.$t('modules.teachers.create.breadcrumbLabel') },
                ],
            });
        }
        if (this.auth.user().role == 'Super Admin' || this.auth.user().role == 'Teacher') {
            this.isSetAccount = true;
            this.form.model.accountUuid = this.auth.user().account.uuid;
        }
        /*axios.get('/accounts?limit=1000&page=1&sort=id&order=asc&isTrashed=0').then(function(response){
        		that.accounts = response.data.data;
        		that.component_done_loading = true;
        	});*/
        that.component_done_loading = true;
    },
    methods: {
        openEmailSampleModal() {
            $('#emailSampleModal').modal('show');
        },
        submit() {
            var that = this;
            this.form.model.identity = this.form.model.email;
            this.form.post().then(function (response) {
                if (!response.krFormError) {
                    if (!that.isFromAccount) {
                        that.$router.push({
                            name: 'teachers.view',
                            params: { id: response.data.data.uuid },
                        });
                    } else {
                        that.$router.push({
                            name: 'accounts.teachers.view',
                            params: { id: response.data.data.uuid },
                        });
                    }
                }
            });
        },
    },
};
</script>
<style scoped>
.modal {
    text-align: center;
    padding: 0 !important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.preview.modal-content {
    border: 3px solid #fab762;
}

.preview .modal-body {
    padding: 0;
}

.emailerHeader {
    padding: 30px;
    text-align: center;
    background-color: #495060;
}

.emailerContent {
    padding: 30px;
    text-align: center;
}

.emailerContent p {
    margin: 0;
}

.emailerContent div {
    margin: 20px;
}

.emailerContent div:first-child {
    font-weight: bold;
    color: #3c7858;
    font-size: 20px;
    margin: 0;
}

.emailerContent button {
    padding: 10px;
    width: 270px;
}

.emailerContent .btn {
    margin-left: 0;
}

.emailerSubContent {
    padding: 30px;
    background-color: #f8f8f8;
    text-align: center;
    font-size: 12px;
}

.emailerSubContent > div:first-child p {
    line-height: 1;
    font-weight: bold;
}

.lineBreak {
    background: #ebebeb;
    height: 2px;
    margin: 20px 0;
}

.socialMed {
    display: flex;
    justify-content: center;
}

.emailerFooter {
    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: #495060;
    padding: 10px;
}

.emailerFooter a,
.emailerFooter a:hover,
.emailerFooter a:focus,
.emailerFooter a:active {
    color: #fff;
}

@media (min-width: 992px) {
    .emailerSubContent > div:first-child,
    .emailerSubContent span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .emailerContent button {
        width: 270px;
    }
}

@media (max-width: 991px) {
    .emailerSubContent span {
        margin: 10px 0;
    }
}
</style>
