<template>
    <div class="create row">
        <div v-if="mode == 'create'" class="col-xs-12">
            <div class="marginBottom30">
                <label for="courseAddTeacher" class="control-label"> Search Teacher </label>
                <div class="form-group form-search has-feedback has-feedback-left marginBottom20">
                    <input
                        id="courseAddTeacher"
                        v-model="teacherSearchData.q"
                        type="text"
                        class="form-control"
                        placeholder="Search..."
                        aria-label="Search Teacher"
                        @keyup="searchCourseDropdown"
                    />
                    <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Teacher</th>
                            <th class="minWidth140px width140px maxWidth140px">
                                <div class="checkboxField justifyCenter align-items">
                                    <input id="addTeacherSelectAll" type="checkbox" :checked="isAllToggled" @click="toggleAll($event)" />
                                    <label for="addTeacherSelectAll" class="paddingTop2">
                                        <b>Select All</b>
                                    </label>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="not_in_teachers.length == 0">
                            <tr>
                                <td colspan="2" style="text-align: center">
                                    <b>No teachers to add</b>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <template v-if="teacherSearchData.isSearching">
                                <template v-if="resultQuery.length == 0">
                                    <tr>
                                        <td colspan="2" style="text-align: center">
                                            <b>Search not found</b>
                                        </td>
                                    </tr>
                                </template>
                                <template v-for="result in resultQuery" v-else>
                                    <tr>
                                        <td>{{ result.displayName }}</td>
                                        <td>
                                            <label class="checkboxField">
                                                <input v-model="form.model.userUuids" type="checkbox" :value="result.uuid" />
                                            </label>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                            <template v-else>
                                <template v-for="teacher in not_in_teachers">
                                    <tr>
                                        <td>{{ teacher.displayName }}</td>
                                        <td>
                                            <label class="checkboxField">
                                                <input v-model="form.model.userUuids" type="checkbox" :value="teacher.uuid" />
                                            </label>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
            <kr-select
                :form="form"
                display="Access"
                name="accountType"
                class="marginBottom30"
                :items="[
                    { value: 'Full Access', description: 'Full access' },
                    { value: 'Read Only', description: 'Read Only' },
                ]"
                item_value_idx="value"
                item_desc_idx="description"
            />
        </div>
        <div v-if="mode == 'update'" class="col-xs-12">
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        display="First Name"
                        :form="form"
                        name="firstname"
                        aria-label="first name"
                        :options="{ placeholder: 'Enter teacher’s first name', required: true }"
                    />
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        display="Last Name"
                        :form="form"
                        name="lastname"
                        aria-label="last name"
                        :options="{ placeholder: 'Enter teacher’s last name', required: true }"
                    />
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Email</label>
                <p class="form-control-static">
                    {{ form.model.email }}
                </p>
            </div>
            <kr-select
                v-if="form.model.accountType != 'Owner'"
                :form="form"
                display="Access"
                name="accountType"
                class="marginBottom30"
                :items="[
                    { value: 'Full Access', description: 'Full access' },
                    { value: 'Read Only', description: 'Read Only' },
                ]"
                item_value_idx="value"
                item_desc_idx="description"
            />
            <div v-else class="form-group">
                <label class="control-label">Account Type</label>
                <p class="form-control-static">
                    {{ form.model.accountType }}
                </p>
            </div>
        </div>
        <div v-if="mode == 'update'" class="col-xs-12 flex">
            <div class="col-xs-12 col-md-6">
                <kr-field display="Job Title" :form="form" name="jobTitle" aria-label="job title" :options="{ placeholder: 'Enter job title' }" />
            </div>
            <div class="col-xs-12 col-md-6">
                <kr-select
                    class="no-error"
                    display="Academic Discipline"
                    name="academicDiscipline"
                    :form="form"
                    :items="kr_var.academicDisciplines"
                    item_value_idx="value"
                    item_desc_idx="description"
                >
                    <template #option="props">
                        <option value="null" disabled selected>Select your option</option>
                        <template v-for="option in props.model" :key="option.value">
                            <option :value="option.value">
                                {{ option.description }}
                            </option>
                        </template>
                    </template>
                </kr-select>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['form', 'not_in_teachers', 'mode'],
    data() {
        return {
            teacherSearchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: {},
            },
            resultQuery: [],
        };
    },
    computed: {
        isAllToggled() {
            return this.form.model.userUuids.length == this.not_in_teachers.length && this.form.model.userUuids.length != 0;
        },
    },
    methods: {
        toggleAll(event) {
            if (this.isAllToggled) {
                this.form.model.userUuids = [];
            } else {
                this.form.model.userUuids = [];
                for (var i = 0; i < this.not_in_teachers.length; i++) {
                    this.form.model.userUuids.push(this.not_in_teachers[i].uuid);
                }
            }
        },
        searchCourseDropdown() {
            this.resultQuery = [];

            if (this.teacherSearchData.q.match(/[a-z]/i) != null) {
                this.teacherSearchData.isSearching = true;
                this.teacherSearchData.matched = 0;

                for (var i = 0; i < this.not_in_teachers.length; i++) {
                    this.not_in_teachers[i]._matched = 0;
                    let teacher = this.not_in_teachers[i];
                    let haystack = teacher.displayName.toLowerCase();
                    let doesContain = haystack.indexOf(this.teacherSearchData.q.toLowerCase());
                    if (doesContain != -1) {
                        teacher._searchDisplay = true;
                        this.teacherSearchData.matched++;
                        this.not_in_teachers[i]._matched++;

                        this.resultQuery.push(this.not_in_teachers[i]);
                    } else {
                        teacher._searchDisplay = false;
                    }
                }
            } else {
                this.teacherSearchData.isSearching = false;
            }
        },
    },
};
</script>
