<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-6">{{ $t('modules.accounts.superadmins.create.headingLabel') }}</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="top-send-invitation-btn"
                                class="btn btn-primary"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Add Super Admin"
                                @click="submit"
                            >
                                <i class="fa fa-floppy marginRight8" aria-hidden="true"></i>{{ $t('modules.accounts.superadmins.create.sendButtonLabel') }}
                            </button>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="top-send-invitation-btn"
                                class="btn btn-primary"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Add Super Admin"
                                @click="submit"
                            >
                                <i class="fa fa-floppy" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <superadmin-form :form="form" :teachers="teachers"></superadmin-form>
                        <div class="buttonGroup marginTop20">
                            <button class="btn btn-primary" :disabled="!canSend ? true : undefined" aria-label="Add Super Admin" @click="submit">
                                <i class="fa fa-floppy marginRight8" aria-hidden="true"></i>{{ $t('modules.accounts.superadmins.create.sendButtonLabel') }}
                            </button>
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: $route.params.account_id },
                                    query: { tab: 'superadmin' },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">{{ $t('modules.accounts.superadmins.create.cancelButtonLabel') }}</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    superadminUuid: '',
                },
            }),
            teachers: [],
            auth: auth,
        };
    },
    components: {
        'superadmin-form': require(`./form-partials/partial.superadmin.vue`).default,
    },
    computed: {
        canSend() {
            if (!this.form.errors.any() && this.form.model.superadminUuid) {
                return true;
            }
            return false;
        },
    },
    created() {
        Events.fire('topbar_update', {
            breadcrumb: [
                { name: 'Home' },
                {
                    name: 'Account',
                    link: 'accounts.view',
                    params: { id: this.$route.params.account_id },
                },
                { name: this.$t('modules.accounts.superadmins.create.breadcrumbLabel') },
            ],
        });
        this.form.model.superadminUuid = '';
        this.fetchTeachers();
    },
    methods: {
        fetchTeachers() {
            var that = this;
            axios.get('/accounts/' + this.$route.params.account_id + '/teachers').then(function (response) {
                that.teachers = response.data.data;
                that.component_done_loading = true;
            });
        },
        submit() {
            var that = this;
            let uuid = this.form.model.superadminUuid;
            this.form.post(`accounts/${this.$route.params.account_id}/superadmin`).then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'accounts.view',
                        params: { id: that.$route.params.account_id },
                        query: { tab: 'superadmin' },
                    });
                }
            });
        },
    },
};
</script>
