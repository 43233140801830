<template>
    <div v-if="component_done_loading" class="evaluationV2-report content0">
        <test-top-bar :test-obj="test" :options="{ forceHideTimer: true, isEvaluationScore: true }" />
        <main class="paddingBottom60 bg-white" :class="{ padding15: test.questions.length == 0 }">
            <template v-if="test.questions.length == 0">
                <p>
                    <i>At the moment, there aren't any questions available. Please consult with your instructor.</i>
                </p>
            </template>
            <template v-else v-for="(qGroup, key, qGroupIdx) in test.questions">
                <kr-panel v-if="qGroup[0].type == 'ratingV2'" :options="{ canToggle: false }">
                    <template #title>
                        <h2 class="marginBottom0">
                            <span class="visible-hidden">Criteria</span>
                            {{ qGroup[0].question.name }}
                        </h2>
                        <p v-if="qGroup[0].question.description" class="marginTop10 fw-normal">
                            <span class="visible-hidden">Criteria description</span>
                            {{ qGroup[0].question.description }}
                        </p>
                    </template>
                    <template #content>
                        <div class="flex align-items padding16 gap20">
                            <div class="report-card">
                                <div>
                                    <h3 class="fs-20px margin0">Total Points</h3>
                                </div>

                                <div>
                                    <p>{{ qGroup[0].summary.totalPoints }}</p>
                                </div>
                            </div>
                            <div class="report-card">
                                <div>
                                    <h3 class="fs-20px margin0">My Average</h3>
                                </div>

                                <div>
                                    <p>{{ qGroup[0].summary.average }}</p>
                                </div>
                            </div>
                            <div class="report-card">
                                <div>
                                    <h3 class="fs-20px margin0">Team Average</h3>
                                </div>

                                <div>
                                    <p>{{ qGroup[0].summary.teamAverage }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="evaluation-panel-content">
                            <template v-for="(stem, stemIdx) in qGroup[0].questionStems">
                                <div>
                                    <div>
                                        <div class="flexSpaceBetween flex align-items marginBottom10">
                                            <h3 class="fs-18px">
                                                <b>Question {{ stemIdx }}</b>
                                            </h3>

                                            <div>
                                                <span class="tableStatusTag whiteSpaceNowrap margin0">
                                                    <b>Rating</b>
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <h4 class="fs-18px margin0">
                                                <b>Question Stem</b>
                                            </h4>
                                            <div>
                                                <label class="displayInline fs-18px fw-normal"> {{ stem.stem }} </label>
                                                <template v-if="stem.isRequired">
                                                    <span class="visible-hidden">Required</span>
                                                    <span class="important" aria-hidden="true">*</span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hidden-xs table-responsive">
                                        <table class="table openendedV2-report-table">
                                            <caption>
                                                Rating Result Evaluation
                                            </caption>
                                            <thead>
                                                <tr>
                                                    <th class="minWidth250px width250px maxWidth250px" scope="col">Assessor</th>
                                                    <th class="minWidth100px width100px maxWidth100px" scope="col">Scale</th>
                                                    <th scope="col">Label</th>
                                                    <th v-if="ratingHaveDescription[qGroupIdx]" scope="col">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(student, studentIdx) in test.team">
                                                    <tr>
                                                        <td>
                                                            <div class="flexOnly align-items" :class="{ colorSelf: student.self }">
                                                                <div class="profile_image">
                                                                    <div class="badge_profile img-circle">
                                                                        <template v-if="student.displayName == 'Anonymous'">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="18"
                                                                                height="14"
                                                                                viewBox="0 0 18 14"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M15.1875 10.7198C15.1875 11.3421 14.9583 11.9426 14.5435 12.4066C14.1288 12.8706 13.5577 13.1656 12.9393 13.2352C12.3208 13.3048 11.6985 13.1441 11.191 12.7838C10.6835 12.4236 10.3266 11.889 10.1883 11.2823H7.81172C7.67342 11.889 7.31646 12.4236 6.809 12.7838C6.30154 13.144 5.67916 13.3047 5.06074 13.2351C4.44231 13.1655 3.8712 12.8706 3.45648 12.4066C3.04176 11.9426 2.81251 11.3421 2.81251 10.7198C2.81251 10.0974 3.04176 9.49691 3.45648 9.03292C3.8712 8.56892 4.44231 8.27396 5.06074 8.20437C5.67916 8.13479 6.30154 8.29546 6.809 8.6557C7.31646 9.01594 7.67342 9.55049 7.81172 10.1573H10.1883C10.3266 9.55048 10.6835 9.01592 11.191 8.65567C11.6985 8.29542 12.3208 8.13475 12.9393 8.20433C13.5577 8.27391 14.1288 8.56888 14.5435 9.03289C14.9583 9.4969 15.1875 10.0974 15.1875 10.7198ZM17.4375 5.9385H15.4828L12.1852 1.22756C12.0793 1.0779 11.9388 0.956165 11.7755 0.872823C11.6123 0.78948 11.4313 0.747021 11.248 0.749096C11.0647 0.751171 10.8847 0.797717 10.7234 0.884734C10.562 0.971751 10.4243 1.09664 10.3219 1.24866L9 3.2385L7.67812 1.24866C7.57573 1.09664 7.43796 0.971751 7.27664 0.884734C7.11533 0.797717 6.9353 0.751171 6.75202 0.749096C6.56874 0.747021 6.3877 0.78948 6.22446 0.872823C6.06122 0.956165 5.92065 1.0779 5.81484 1.22756L2.51719 5.9385H0.5625C0.413316 5.9385 0.270242 5.99777 0.164752 6.10325C0.0592632 6.20874 0 6.35182 0 6.501C0 6.65019 0.0592632 6.79326 0.164752 6.89875C0.270242 7.00424 0.413316 7.0635 0.5625 7.0635H17.4375C17.5867 7.0635 17.7298 7.00424 17.8352 6.89875C17.9407 6.79326 18 6.65019 18 6.501C18 6.35182 17.9407 6.20874 17.8352 6.10325C17.7298 5.99777 17.5867 5.9385 17.4375 5.9385Z"
                                                                                    fill="white"
                                                                                />
                                                                            </svg>
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ globalInitials(student.displayName) }}
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                                <p>
                                                                    <b>
                                                                        {{ student.displayName }}

                                                                        <template v-if="student.self"> (Me) </template>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {{ getStudentAnswer(stem.evaluations, student.id) }}
                                                        </td>
                                                        <td>
                                                            <template v-if="getStudentAnswer(stem.evaluations, student.id) != '-'">
                                                                <template
                                                                    v-if="
                                                                        getRatingObjectFromWeight(qGroup[0], getStudentAnswer(stem.evaluations, student.id))
                                                                            .label
                                                                    "
                                                                >
                                                                    {{
                                                                        getRatingObjectFromWeight(qGroup[0], getStudentAnswer(stem.evaluations, student.id))
                                                                            .label
                                                                    }}
                                                                </template>
                                                                <template v-else> - </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </td>
                                                        <td v-if="ratingHaveDescription[qGroupIdx]">
                                                            <template v-if="getStudentAnswer(stem.evaluations, student.id) != '-'">
                                                                <template
                                                                    v-if="
                                                                        getRatingObjectFromWeight(qGroup[0], getStudentAnswer(stem.evaluations, student.id))
                                                                            .rubric
                                                                    "
                                                                >
                                                                    {{
                                                                        getRatingObjectFromWeight(qGroup[0], getStudentAnswer(stem.evaluations, student.id))
                                                                            .rubric
                                                                    }}
                                                                </template>
                                                                <template v-else> - </template>
                                                            </template>
                                                            <template v-else> - </template>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="hidden-sm hidden-md hidden-lg">
                                        <template v-for="(student, studentIdx) in test.team">
                                            <div class="ratingV2-report-columns">
                                                <div class="flexSpaceBetween">
                                                    <div class="flexOnly align-items" :class="{ colorSelf: student.self }">
                                                        <div class="profile_image">
                                                            <div class="badge_profile img-circle">
                                                                <template v-if="student.displayName == 'Anonymous'">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="18"
                                                                        height="14"
                                                                        viewBox="0 0 18 14"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M15.1875 10.7198C15.1875 11.3421 14.9583 11.9426 14.5435 12.4066C14.1288 12.8706 13.5577 13.1656 12.9393 13.2352C12.3208 13.3048 11.6985 13.1441 11.191 12.7838C10.6835 12.4236 10.3266 11.889 10.1883 11.2823H7.81172C7.67342 11.889 7.31646 12.4236 6.809 12.7838C6.30154 13.144 5.67916 13.3047 5.06074 13.2351C4.44231 13.1655 3.8712 12.8706 3.45648 12.4066C3.04176 11.9426 2.81251 11.3421 2.81251 10.7198C2.81251 10.0974 3.04176 9.49691 3.45648 9.03292C3.8712 8.56892 4.44231 8.27396 5.06074 8.20437C5.67916 8.13479 6.30154 8.29546 6.809 8.6557C7.31646 9.01594 7.67342 9.55049 7.81172 10.1573H10.1883C10.3266 9.55048 10.6835 9.01592 11.191 8.65567C11.6985 8.29542 12.3208 8.13475 12.9393 8.20433C13.5577 8.27391 14.1288 8.56888 14.5435 9.03289C14.9583 9.4969 15.1875 10.0974 15.1875 10.7198ZM17.4375 5.9385H15.4828L12.1852 1.22756C12.0793 1.0779 11.9388 0.956165 11.7755 0.872823C11.6123 0.78948 11.4313 0.747021 11.248 0.749096C11.0647 0.751171 10.8847 0.797717 10.7234 0.884734C10.562 0.971751 10.4243 1.09664 10.3219 1.24866L9 3.2385L7.67812 1.24866C7.57573 1.09664 7.43796 0.971751 7.27664 0.884734C7.11533 0.797717 6.9353 0.751171 6.75202 0.749096C6.56874 0.747021 6.3877 0.78948 6.22446 0.872823C6.06122 0.956165 5.92065 1.0779 5.81484 1.22756L2.51719 5.9385H0.5625C0.413316 5.9385 0.270242 5.99777 0.164752 6.10325C0.0592632 6.20874 0 6.35182 0 6.501C0 6.65019 0.0592632 6.79326 0.164752 6.89875C0.270242 7.00424 0.413316 7.0635 0.5625 7.0635H17.4375C17.5867 7.0635 17.7298 7.00424 17.8352 6.89875C17.9407 6.79326 18 6.65019 18 6.501C18 6.35182 17.9407 6.20874 17.8352 6.10325C17.7298 5.99777 17.5867 5.9385 17.4375 5.9385Z"
                                                                            fill="white"
                                                                        />
                                                                    </svg>
                                                                </template>
                                                                <template v-else>
                                                                    {{ globalInitials(student.displayName) }}
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <b>
                                                                {{ student.displayName }}

                                                                <template v-if="student.self"> (Me) </template>
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>
                                                        {{ getStudentAnswer(stem.evaluations, student.id) }}

                                                        <template v-if="getStudentAnswer(stem.evaluations, student.id) != '-'">
                                                            <template
                                                                v-if="
                                                                    getRatingObjectFromWeight(qGroup[0], getStudentAnswer(stem.evaluations, student.id)).label
                                                                "
                                                            >
                                                                {{ getRatingObjectFromWeight(qGroup[0], getStudentAnswer(stem.evaluations, student.id)).label }}
                                                            </template>
                                                        </template>
                                                    </p>
                                                </div>
                                                <div v-if="ratingHaveDescription[qGroupIdx]">
                                                    <template v-if="getStudentAnswer(stem.evaluations, student.id) != '-'">
                                                        <p v-if="getRatingObjectFromWeight(qGroup[0], getStudentAnswer(stem.evaluations, student.id)).rubric">
                                                            {{ getRatingObjectFromWeight(qGroup[0], getStudentAnswer(stem.evaluations, student.id)).rubric }}
                                                        </p>
                                                        <template v-else> - </template>
                                                    </template>
                                                    <template v-else> - </template>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </kr-panel>

                <kr-panel v-if="qGroup[0].type == 'openendedV2'" :options="{ canToggle: false }">
                    <template #title>
                        <h2 class="marginBottom0">
                            <span class="visible-hidden">Criteria</span>
                            {{ qGroup[0].question.name }}
                        </h2>
                        <p v-if="qGroup[0].question.description" class="marginTop10 fw-normal">
                            <span class="visible-hidden">Criteria description</span>
                            {{ qGroup[0].question.description }}
                        </p>
                    </template>
                    <template #content>
                        <div class="evaluation-panel-content">
                            <template v-for="(stem, stemIdx) in qGroup[0].questionStems">
                                <div>
                                    <div>
                                        <div class="flexSpaceBetween flex align-items marginBottom10">
                                            <h3 class="fs-18px">
                                                <b>Question {{ stemIdx }}</b>
                                            </h3>

                                            <div>
                                                <span class="tableStatusTag whiteSpaceNowrap margin0">
                                                    <b>Openended</b>
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <h4 class="fs-18px margin0">
                                                <b>Question Stem</b>
                                            </h4>
                                            <div>
                                                <label class="displayInline fs-18px fw-normal"> {{ stem.stem }} </label>
                                                <template v-if="stem.isRequired">
                                                    <span class="visible-hidden">Required</span>
                                                    <span class="important" aria-hidden="true">*</span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hidden-xs table-responsive">
                                        <table class="table openendedV2-report-table">
                                            <caption>
                                                Openended Evaluation
                                            </caption>
                                            <thead>
                                                <tr>
                                                    <th class="minWidth250px width250px maxWidth250px" scope="col">Assessor</th>
                                                    <th scope="col">Response</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(student, studentIdx) in test.team">
                                                    <tr>
                                                        <td>
                                                            <div class="flexOnly align-items" :class="{ colorSelf: student.self }">
                                                                <div class="profile_image">
                                                                    <div class="badge_profile img-circle">
                                                                        <template v-if="student.displayName == 'Anonymous'">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="18"
                                                                                height="14"
                                                                                viewBox="0 0 18 14"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M15.1875 10.7198C15.1875 11.3421 14.9583 11.9426 14.5435 12.4066C14.1288 12.8706 13.5577 13.1656 12.9393 13.2352C12.3208 13.3048 11.6985 13.1441 11.191 12.7838C10.6835 12.4236 10.3266 11.889 10.1883 11.2823H7.81172C7.67342 11.889 7.31646 12.4236 6.809 12.7838C6.30154 13.144 5.67916 13.3047 5.06074 13.2351C4.44231 13.1655 3.8712 12.8706 3.45648 12.4066C3.04176 11.9426 2.81251 11.3421 2.81251 10.7198C2.81251 10.0974 3.04176 9.49691 3.45648 9.03292C3.8712 8.56892 4.44231 8.27396 5.06074 8.20437C5.67916 8.13479 6.30154 8.29546 6.809 8.6557C7.31646 9.01594 7.67342 9.55049 7.81172 10.1573H10.1883C10.3266 9.55048 10.6835 9.01592 11.191 8.65567C11.6985 8.29542 12.3208 8.13475 12.9393 8.20433C13.5577 8.27391 14.1288 8.56888 14.5435 9.03289C14.9583 9.4969 15.1875 10.0974 15.1875 10.7198ZM17.4375 5.9385H15.4828L12.1852 1.22756C12.0793 1.0779 11.9388 0.956165 11.7755 0.872823C11.6123 0.78948 11.4313 0.747021 11.248 0.749096C11.0647 0.751171 10.8847 0.797717 10.7234 0.884734C10.562 0.971751 10.4243 1.09664 10.3219 1.24866L9 3.2385L7.67812 1.24866C7.57573 1.09664 7.43796 0.971751 7.27664 0.884734C7.11533 0.797717 6.9353 0.751171 6.75202 0.749096C6.56874 0.747021 6.3877 0.78948 6.22446 0.872823C6.06122 0.956165 5.92065 1.0779 5.81484 1.22756L2.51719 5.9385H0.5625C0.413316 5.9385 0.270242 5.99777 0.164752 6.10325C0.0592632 6.20874 0 6.35182 0 6.501C0 6.65019 0.0592632 6.79326 0.164752 6.89875C0.270242 7.00424 0.413316 7.0635 0.5625 7.0635H17.4375C17.5867 7.0635 17.7298 7.00424 17.8352 6.89875C17.9407 6.79326 18 6.65019 18 6.501C18 6.35182 17.9407 6.20874 17.8352 6.10325C17.7298 5.99777 17.5867 5.9385 17.4375 5.9385Z"
                                                                                    fill="white"
                                                                                />
                                                                            </svg>
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ globalInitials(student.displayName) }}
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                                <p>
                                                                    <b>
                                                                        {{ student.displayName }}

                                                                        <template v-if="student.self"> (Me) </template>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td class="whiteSpaceBreakSpacesText">
                                                            {{ getStudentAnswer(stem.evaluations, student.id) }}
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="hidden-sm hidden-md hidden-lg">
                                        <template v-for="(student, studentIdx) in test.team">
                                            <div class="openendedV2-report-columns">
                                                <div class="flexSpaceBetween">
                                                    <div class="flexOnly align-items" :class="{ colorSelf: student.self }">
                                                        <div class="profile_image">
                                                            <div class="badge_profile img-circle">
                                                                <template v-if="student.displayName == 'Anonymous'">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="18"
                                                                        height="14"
                                                                        viewBox="0 0 18 14"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M15.1875 10.7198C15.1875 11.3421 14.9583 11.9426 14.5435 12.4066C14.1288 12.8706 13.5577 13.1656 12.9393 13.2352C12.3208 13.3048 11.6985 13.1441 11.191 12.7838C10.6835 12.4236 10.3266 11.889 10.1883 11.2823H7.81172C7.67342 11.889 7.31646 12.4236 6.809 12.7838C6.30154 13.144 5.67916 13.3047 5.06074 13.2351C4.44231 13.1655 3.8712 12.8706 3.45648 12.4066C3.04176 11.9426 2.81251 11.3421 2.81251 10.7198C2.81251 10.0974 3.04176 9.49691 3.45648 9.03292C3.8712 8.56892 4.44231 8.27396 5.06074 8.20437C5.67916 8.13479 6.30154 8.29546 6.809 8.6557C7.31646 9.01594 7.67342 9.55049 7.81172 10.1573H10.1883C10.3266 9.55048 10.6835 9.01592 11.191 8.65567C11.6985 8.29542 12.3208 8.13475 12.9393 8.20433C13.5577 8.27391 14.1288 8.56888 14.5435 9.03289C14.9583 9.4969 15.1875 10.0974 15.1875 10.7198ZM17.4375 5.9385H15.4828L12.1852 1.22756C12.0793 1.0779 11.9388 0.956165 11.7755 0.872823C11.6123 0.78948 11.4313 0.747021 11.248 0.749096C11.0647 0.751171 10.8847 0.797717 10.7234 0.884734C10.562 0.971751 10.4243 1.09664 10.3219 1.24866L9 3.2385L7.67812 1.24866C7.57573 1.09664 7.43796 0.971751 7.27664 0.884734C7.11533 0.797717 6.9353 0.751171 6.75202 0.749096C6.56874 0.747021 6.3877 0.78948 6.22446 0.872823C6.06122 0.956165 5.92065 1.0779 5.81484 1.22756L2.51719 5.9385H0.5625C0.413316 5.9385 0.270242 5.99777 0.164752 6.10325C0.0592632 6.20874 0 6.35182 0 6.501C0 6.65019 0.0592632 6.79326 0.164752 6.89875C0.270242 7.00424 0.413316 7.0635 0.5625 7.0635H17.4375C17.5867 7.0635 17.7298 7.00424 17.8352 6.89875C17.9407 6.79326 18 6.65019 18 6.501C18 6.35182 17.9407 6.20874 17.8352 6.10325C17.7298 5.99777 17.5867 5.9385 17.4375 5.9385Z"
                                                                            fill="white"
                                                                        />
                                                                    </svg>
                                                                </template>
                                                                <template v-else>
                                                                    {{ globalInitials(student.displayName) }}
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <b>
                                                                {{ student.displayName }}

                                                                <template v-if="student.self"> (Me) </template>
                                                            </b>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p>{{ getStudentAnswer(stem.evaluations, student.id) }}</p>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </template>
                </kr-panel>

                <kr-panel v-if="qGroup[0].type == 'pointDistribution'" :options="{ canToggle: false }">
                    <template #title>
                        <h2 class="marginBottom0">
                            <span class="visible-hidden">Criteria</span>
                            {{ qGroup[0].question.name }}
                        </h2>
                        <p v-if="qGroup[0].question.description" class="marginTop10 fw-normal">
                            <span class="visible-hidden">Criteria description</span>
                            {{ qGroup[0].question.description }}
                        </p>
                    </template>
                    <template #content>
                        <div class="evaluation-panel-content">
                            <div>
                                <div class="flexSpaceBetween flex align-items">
                                    <h3 class="fs-18px">
                                        <b>Question 1</b>
                                    </h3>

                                    <div>
                                        <span class="tableStatusTag whiteSpaceNowrap">
                                            <b>Point Distribution</b>
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div class="hidden-xs table-responsive">
                                        <table class="table openendedV2-report-table">
                                            <caption>
                                                Point Distribution Evaluation
                                            </caption>
                                            <thead>
                                                <tr>
                                                    <th class="minWidth250px width250px maxWidth250px" scope="col">Assessor</th>
                                                    <th scope="col">Points Received</th>
                                                    <th scope="col">Total Points</th>
                                                    <th scope="col">Average</th>
                                                    <th scope="col">Team Average</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(student, studentIdx) in test.team">
                                                    <tr>
                                                        <td>
                                                            <div class="flexOnly align-items" :class="{ colorSelf: student.self }">
                                                                <div class="profile_image">
                                                                    <div class="badge_profile img-circle">
                                                                        <template v-if="student.displayName == 'Anonymous'">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="18"
                                                                                height="14"
                                                                                viewBox="0 0 18 14"
                                                                                fill="none"
                                                                            >
                                                                                <path
                                                                                    d="M15.1875 10.7198C15.1875 11.3421 14.9583 11.9426 14.5435 12.4066C14.1288 12.8706 13.5577 13.1656 12.9393 13.2352C12.3208 13.3048 11.6985 13.1441 11.191 12.7838C10.6835 12.4236 10.3266 11.889 10.1883 11.2823H7.81172C7.67342 11.889 7.31646 12.4236 6.809 12.7838C6.30154 13.144 5.67916 13.3047 5.06074 13.2351C4.44231 13.1655 3.8712 12.8706 3.45648 12.4066C3.04176 11.9426 2.81251 11.3421 2.81251 10.7198C2.81251 10.0974 3.04176 9.49691 3.45648 9.03292C3.8712 8.56892 4.44231 8.27396 5.06074 8.20437C5.67916 8.13479 6.30154 8.29546 6.809 8.6557C7.31646 9.01594 7.67342 9.55049 7.81172 10.1573H10.1883C10.3266 9.55048 10.6835 9.01592 11.191 8.65567C11.6985 8.29542 12.3208 8.13475 12.9393 8.20433C13.5577 8.27391 14.1288 8.56888 14.5435 9.03289C14.9583 9.4969 15.1875 10.0974 15.1875 10.7198ZM17.4375 5.9385H15.4828L12.1852 1.22756C12.0793 1.0779 11.9388 0.956165 11.7755 0.872823C11.6123 0.78948 11.4313 0.747021 11.248 0.749096C11.0647 0.751171 10.8847 0.797717 10.7234 0.884734C10.562 0.971751 10.4243 1.09664 10.3219 1.24866L9 3.2385L7.67812 1.24866C7.57573 1.09664 7.43796 0.971751 7.27664 0.884734C7.11533 0.797717 6.9353 0.751171 6.75202 0.749096C6.56874 0.747021 6.3877 0.78948 6.22446 0.872823C6.06122 0.956165 5.92065 1.0779 5.81484 1.22756L2.51719 5.9385H0.5625C0.413316 5.9385 0.270242 5.99777 0.164752 6.10325C0.0592632 6.20874 0 6.35182 0 6.501C0 6.65019 0.0592632 6.79326 0.164752 6.89875C0.270242 7.00424 0.413316 7.0635 0.5625 7.0635H17.4375C17.5867 7.0635 17.7298 7.00424 17.8352 6.89875C17.9407 6.79326 18 6.65019 18 6.501C18 6.35182 17.9407 6.20874 17.8352 6.10325C17.7298 5.99777 17.5867 5.9385 17.4375 5.9385Z"
                                                                                    fill="white"
                                                                                />
                                                                            </svg>
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ globalInitials(student.displayName) }}
                                                                        </template>
                                                                    </div>
                                                                </div>
                                                                <p>
                                                                    <b>
                                                                        {{ student.displayName }}

                                                                        <template v-if="student.self"> (Me) </template>
                                                                    </b>
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td class="add-border">
                                                            {{ getStudentAnswer(qGroup[0].pointDistributions, student.id) }}
                                                        </td>
                                                        <template v-if="studentIdx == 0">
                                                            <td :rowspan="test.team.length">{{ qGroup[0].summary.totalPoints }}</td>
                                                            <td :rowspan="test.team.length">{{ qGroup[0].summary.average }}</td>
                                                            <td :rowspan="test.team.length">{{ qGroup[0].summary.teamAverage }}</td>
                                                        </template>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="hidden-sm hidden-md hidden-lg">
                                        <div class="pointDistribution-report-columns">
                                            <div>
                                                <div class="report-card">
                                                    <div>
                                                        <h4>Points Received</h4>
                                                    </div>

                                                    <div>
                                                        <template v-for="(student, studentIdx) in test.team">
                                                            <div class="pointDistribution-report-columns-name">
                                                                <div class="flexOnly align-items" :class="{ colorSelf: student.self }">
                                                                    <div class="profile_image">
                                                                        <div class="badge_profile img-circle">
                                                                            <template v-if="student.displayName == 'Anonymous'">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="18"
                                                                                    height="14"
                                                                                    viewBox="0 0 18 14"
                                                                                    fill="none"
                                                                                >
                                                                                    <path
                                                                                        d="M15.1875 10.7198C15.1875 11.3421 14.9583 11.9426 14.5435 12.4066C14.1288 12.8706 13.5577 13.1656 12.9393 13.2352C12.3208 13.3048 11.6985 13.1441 11.191 12.7838C10.6835 12.4236 10.3266 11.889 10.1883 11.2823H7.81172C7.67342 11.889 7.31646 12.4236 6.809 12.7838C6.30154 13.144 5.67916 13.3047 5.06074 13.2351C4.44231 13.1655 3.8712 12.8706 3.45648 12.4066C3.04176 11.9426 2.81251 11.3421 2.81251 10.7198C2.81251 10.0974 3.04176 9.49691 3.45648 9.03292C3.8712 8.56892 4.44231 8.27396 5.06074 8.20437C5.67916 8.13479 6.30154 8.29546 6.809 8.6557C7.31646 9.01594 7.67342 9.55049 7.81172 10.1573H10.1883C10.3266 9.55048 10.6835 9.01592 11.191 8.65567C11.6985 8.29542 12.3208 8.13475 12.9393 8.20433C13.5577 8.27391 14.1288 8.56888 14.5435 9.03289C14.9583 9.4969 15.1875 10.0974 15.1875 10.7198ZM17.4375 5.9385H15.4828L12.1852 1.22756C12.0793 1.0779 11.9388 0.956165 11.7755 0.872823C11.6123 0.78948 11.4313 0.747021 11.248 0.749096C11.0647 0.751171 10.8847 0.797717 10.7234 0.884734C10.562 0.971751 10.4243 1.09664 10.3219 1.24866L9 3.2385L7.67812 1.24866C7.57573 1.09664 7.43796 0.971751 7.27664 0.884734C7.11533 0.797717 6.9353 0.751171 6.75202 0.749096C6.56874 0.747021 6.3877 0.78948 6.22446 0.872823C6.06122 0.956165 5.92065 1.0779 5.81484 1.22756L2.51719 5.9385H0.5625C0.413316 5.9385 0.270242 5.99777 0.164752 6.10325C0.0592632 6.20874 0 6.35182 0 6.501C0 6.65019 0.0592632 6.79326 0.164752 6.89875C0.270242 7.00424 0.413316 7.0635 0.5625 7.0635H17.4375C17.5867 7.0635 17.7298 7.00424 17.8352 6.89875C17.9407 6.79326 18 6.65019 18 6.501C18 6.35182 17.9407 6.20874 17.8352 6.10325C17.7298 5.99777 17.5867 5.9385 17.4375 5.9385Z"
                                                                                        fill="white"
                                                                                    />
                                                                                </svg>
                                                                            </template>
                                                                            <template v-else>
                                                                                {{ globalInitials(student.displayName) }}
                                                                            </template>
                                                                        </div>
                                                                    </div>
                                                                    <p>
                                                                        <b>
                                                                            {{ student.displayName }}

                                                                            <template v-if="student.self"> (Me) </template>
                                                                        </b>
                                                                    </p>
                                                                </div>

                                                                <div>
                                                                    <p>
                                                                        {{ getStudentAnswer(qGroup[0].pointDistributions, student.id) }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="report-card">
                                                    <div>
                                                        <h4>Total Points</h4>
                                                    </div>

                                                    <div>
                                                        <p>{{ qGroup[0].summary.totalPoints }}</p>
                                                    </div>
                                                </div>
                                                <div class="report-card marginTop20">
                                                    <div>
                                                        <h4>Average</h4>
                                                    </div>

                                                    <div>
                                                        <p>{{ qGroup[0].summary.average }}</p>
                                                    </div>
                                                </div>
                                                <div class="report-card marginTop20">
                                                    <div>
                                                        <h4>Team Average</h4>
                                                    </div>

                                                    <div>
                                                        <p>{{ qGroup[0].summary.teamAverage }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>
            </template>
        </main>
    </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useNotification } from '@kyvg/vue3-notification';

import { ref, computed, onMounted, onUnmounted } from 'vue';
import testTopBar from './../rat/partials/topbarV2.vue';

const route = useRoute();
const router = useRouter();
const notify = useNotification();

const echoChannels = [];
const component_done_loading = ref(false);
const test = ref({});

const processEchoListener = () => {
    let h1 = (e) => {
        if (e.test.uuid != that.test.uuid) {
            return false;
        }

        test.value.allowStudentsToViewAnswer = e.test.value.allowStudentsToViewAnswer;
        test.value.allowStudentsToViewScore = e.test.value.allowStudentsToViewScore;
        test.value.allowStudentsToPreviewQuestions = e.test.value.allowStudentsToPreviewQuestions;
        test.value.allowStudentsToViewPafScores = e.test.value.allowStudentsToViewPafScores;
        test.value.allowStudentsToViewSapaScores = e.test.value.allowStudentsToViewSapaScores;
        let oldAnonymity = test.value.enableAnonymity;
        test.value.enableAnonymity = e.test.value.enableAnonymity;
        if (!test.value.allowStudentsToViewScore) {
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Teacher has disabled viewing of test results.',
            });
            router.push({ name: 'tests.index' });
        }
        if (oldAnonymity != test.value.enableAnonymity) {
            fetchTest().then(function (response) {
                test.value = response.data.data;
            });
        }
    };
    let c1 = window.Echo.private(`activity.${test.value.activityUuid}.student`).listen('TestVisibilityUpdated', h1);
    echoChannels.push({
        channel: c1,
        event: 'TestVisibilityUpdated',
        handler: h1,
    });
};

const fetchTest = () => {
    return axios.get(`student-tests/${route.params.id}/peer-evaluation/reports`);
};

const getRatingObjectFromWeight = (question, weight) => {
    let idx = _.findIndex(question.ratingSettings, function (o) {
        return o.weight == weight;
    });
    if (idx != -1) {
        return question.ratingSettings[idx];
    } else return null;
};

const getStudentAnswer = (answerList, giverId, type = 'answer') => {
    let idx = _.findIndex(answerList, function (o) {
        return o.giverId == giverId;
    });
    if (idx != -1) {
        if (type == 'object') {
            return answerList[idx];
        } else {
            return answerList[idx].rating ? answerList[idx].rating : answerList[idx].response ? answerList[idx].response : answerList[idx].point;
        }
    } else {
        if (type == 'object') {
            return null;
        } else {
            return '-';
        }
    }
};

const ratingHaveDescription = computed(() => {
    return Object.keys(test.value.questions).map((key) => {
        const question = test.value.questions[key][0];

        if (question.type == 'ratingV2') {
            for (var i = 0; i < question.ratingSettings.length; i++) {
                if (question.ratingSettings[i].rubric) {
                    return true;
                }
            }
        }

        return false;
    });
});

onMounted(() => {
    $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
    fetchTest()
        .then((response) => {
            test.value = response.data.data;
            processEchoListener();
            component_done_loading.value = true;
        })
        .catch((errors) => {});
});

onUnmounted(() => {
    if ($(window).width() > 991) {
        $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
    } else {
        $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
    }
});
</script>
