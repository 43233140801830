<template>
    <div :class="[$style.wrap, { noScrollBar: !setScrollBar, withScrollBar: setScrollBar }]">
        <div
            ref="scrollContainer"
            class="scroll-container"
            :class="$style['scroll-container']"
            :style="{ width, height }"
            @scroll.passive="toggleShadow"
            @scroll="onScroll"
        >
            <slot />
            <span :class="[$style['shadow-top'], shadow.top && $style['is-active']]" />
            <span class="shadow-right" :class="[$style['shadow-right'], shadow.right && $style['is-active']]" />
            <span :class="[$style['shadow-bottom'], shadow.bottom && $style['is-active']]" />
            <span :class="[$style['shadow-left'], shadow.left && $style['is-active']]" />
        </div>
    </div>
</template>

<script>
function newResizeObserver(callback) {
    if (typeof ResizeObserver === 'undefined') return;
    return new ResizeObserver(_.debounce((e) => e.map(callback), 500));
}

export default {
    props: ['options'],
    data() {
        return {
            width: undefined,
            height: undefined,
            shadow: {
                top: false,
                right: false,
                bottom: false,
                left: false,
            },
            setScrollBar: false,
        };
    },
    mounted() {
        const scrollContainerObserver = newResizeObserver(this.toggleShadow);
        if (scrollContainerObserver) {
            scrollContainerObserver.observe(this.$refs.scrollContainer);
            // Cleanup when the component is destroyed.
            this.$once('hook:destroyed', () => scrollContainerObserver.disconnect());
        }

        const wrapObserver = newResizeObserver(this.calcDimensions);
        if (wrapObserver) {
            wrapObserver.observe(this.$el);
            // Cleanup when the component is destroyed.
            this.$once('hook:destroyed', () => wrapObserver.disconnect());
        }

        this.setScrollBar = _.get(this.options, 'setScrollBar', false);
    },
    methods: {
        async calcDimensions() {
            this.width = undefined;
            this.height = undefined;
            await this.$nextTick();

            this.width = `100%`;
            this.height = `${this.$el.clientHeight}px`;
        },
        // Check if shadows are needed.
        toggleShadow() {
            if (this.$refs.scrollContainer) {
                const hasHorizontalScrollbar = this.$refs.scrollContainer.clientWidth < this.$refs.scrollContainer.scrollWidth;
                const hasVerticalScrollbar = this.$refs.scrollContainer.clientHeight < this.$refs.scrollContainer.scrollHeight;

                const scrolledFromLeft = this.$refs.scrollContainer.offsetWidth + this.$refs.scrollContainer.scrollLeft;
                const scrolledFromTop = this.$refs.scrollContainer.offsetHeight + this.$refs.scrollContainer.scrollTop;

                const scrolledToTop = this.$refs.scrollContainer.scrollTop === 0;
                const scrolledToRight = scrolledFromLeft >= this.$refs.scrollContainer.scrollWidth;
                const scrolledToBottom = scrolledFromTop >= this.$refs.scrollContainer.scrollHeight;
                const scrolledToLeft = this.$refs.scrollContainer.scrollLeft === 0;

                this.shadow.top = hasVerticalScrollbar && !scrolledToTop;
                this.shadow.right = hasHorizontalScrollbar && !scrolledToRight;
                this.shadow.bottom = hasVerticalScrollbar && !scrolledToBottom;
                this.shadow.left = hasHorizontalScrollbar && !scrolledToLeft;
            }
        },
        onScroll(event) {
            this.$emit('scrollEvent', event.target.scrollLeft);
        },
    },
};
</script>

<style lang="scss" module>
.wrap {
    position: relative;
}

.scroll-container {
    overflow: auto;

    table {
        margin-bottom: 0;
    }
}

.shadow-top,
.shadow-right,
.shadow-bottom,
.shadow-left {
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
}

.shadow-top {
    top: 0;
}

.shadow-bottom {
    bottom: 0;
    transform: rotate(180deg);
}

.shadow-right,
.shadow-left {
    top: 0;
    bottom: 0;
    width: 15px;
    background-image: linear-gradient(90deg, rgba(0, 0, 20, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
}

.shadow-right {
    right: 0;
    transform: rotate(180deg);
}

.shadow-left {
    left: 0;
}

.is-active {
    opacity: 1;
}
</style>

<style lang="scss">
.withScrollBar {
    .scroll-container {
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            width: 5px;
            background: #d8d8d8;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #989b9c;
            border-radius: 2px;
        }
    }
}

.noScrollBar {
    .scroll-container {
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
</style>
