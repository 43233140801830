<template>
    <div id="profile-edit">
        <div v-if="component_done_loading" class="col-xs-12 right-container" :class="{ isStudent: auth.user().role == 'Student' }">
            <div class="form-div">
                <kr-panel>
                    <template #title>
                        <div class="col-xs-6">
                            <h1>Edit My Profile</h1>
                        </div>
                    </template>
                    <template #content>
                        <div class="col-xs-12">
                            <basic-info-form :form="form" :is-pronoun-enabled="isPronounEnabled" @selected="onSelected"></basic-info-form>

                            <div class="profileBtns buttonGroup marginTop30">
                                <button
                                    id="bottom-save-btn"
                                    v-tooltip="{
                                        content: !canSend ? 'Please verify that your New Password and Retype Password match to ensure they are the same.' : '',
                                    }"
                                    class="btn btn-success"
                                    :class="{
                                        'disabled hasToolTip': !canSend,
                                    }"
                                    @click="submit()"
                                >
                                    <i class="fa fa-check marginRight8" aria-hidden="true"></i>
                                    Update
                                </button>
                                <router-link v-slot="{ navigate }" :to="{ name: 'profile.view' }" custom>
                                    <button class="btn btn-default" @click="navigate">Cancel</button>
                                </router-link>
                            </div>
                        </div>
                    </template>
                </kr-panel>
            </div>
        </div>
        <div
            v-if="component_done_loading"
            id="profile_confirm_modal"
            class="modal default-modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="profileConfirmModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="profileConfirmModal-title" class="modal-title">Save Changes</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <h2>You have made changes to the profile</h2>
                        <p>Do you want to proceed?</p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">No, Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="submit">Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from './../../../components/forms/form';
import KrAuth from './../../../components/auth/auth';
import { useAuthUserStore } from '@/stores/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            authStore: useAuthUserStore(),
            auth: auth,
            form: new KrForm({
                fields: {
                    avatar: '',
                    pronoun: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    displayName: '',
                    old_password: 'sendNull!:false',
                    password: 'sendNull!:false',
                    password_confirmation: 'sendNull!:false',
                    identity: '',
                    academicDiscipline: 'sendNull!:false',
                    jobTitle: 'sendNull!:false',
                },
                urls: {
                    put: '/profile',
                },
                props: {
                    successMessage: false,
                },
            }),
            user: {},
            colorPicked: null,
            isPronounEnabled: false,
        };
    },
    components: {
        'basic-info-form': require(`./form-partials/partial.basic_info.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.email && !this.form.model.lastname) {
                return false;
            }
            if (
                (!this.form.errors.any() &&
                    this.form.model.firstname &&
                    this.form.model.old_password &&
                    this.form.model.password &&
                    this.form.model.password_confirmation) ||
                !this.form.model.password
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        Events.fire('topbar_update', {
            breadcrumb: [{ name: 'Home' }, { name: 'Edit My Profile' }],
        });
        this.fetchUser();
    },
    beforeUnmount() {
        Events.fire('profileURL_close');
    },
    methods: {
        openConfirmModal() {
            $('#profile_confirm_modal').modal('show');
        },
        fetchUser() {
            var that = this;
            axios.get('/profile').then(function (response) {
                that.user = response.data.data;
                that.form.setModel(that.user);
                if (that.user.hasOwnProperty('pronoun')) {
                    that.isPronounEnabled = true;
                }
                that.component_done_loading = true;
            });
        },
        submit() {
            var that = this;
            let displayName =
                this.form.model.firstname +
                (this.form.model.lastname ? ' ' + this.form.model.lastname : '') +
                (that.user.pronoun !== undefined ? (that.form.model.pronoun ? ` (${that.form.model.pronoun})` : '') : '');
            that.form.model.displayName = this.form.model.firstname + (this.form.model.lastname ? ' ' + this.form.model.lastname : '');
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    let profile = that.auth.user();
                    profile.avatar = that.form.model.avatar;
                    profile.firstname = that.form.model.firstname;
                    profile.lastname = that.form.model.lastname;
                    profile.email = that.form.model.email;
                    profile.displayName = displayName;
                    profile.jobTitle = that.form.model.jobTitle;
                    profile.academicDiscipline = that.form.model.academicDiscipline;
                    profile.pronoun = that.form.model.pronoun;
                    that.authStore.client.profile = profile;
                    //that.$store.commit('profile', profile);
                    that.$notify({
                        group: 'profile-update-success',
                        title: 'Awesome, ' + that.auth.user().firstname + (that.auth.user().lastname ? ' ' + that.auth.user().lastname : '') + '!',
                        text: 'Your profile has been updated successfully',
                        duration: 2000,
                    });
                    that.$router.push({ name: 'profile.view' });

                    if (that.form.model.password_confirmation) {
                        that.$store.dispatch('updatePasswordModalShown', true);
                    }
                }
            });

            if (this.colorPicked == '#17244A') {
                document.documentElement.style.setProperty('--dark-highlight-color', '#fff');
            } else {
                document.documentElement.style.setProperty('--dark-highlight-color', '#222');
            }

            localStorage.setItem('highlightColorLocal', this.colorPicked);
            document.documentElement.style.setProperty('--highlight-color', this.colorPicked);
        },
        /*
    		fetchData () {
    			var onSuccess= function(response){
    				this.form.setModel(response.data.data);
    				this.component_done_loading  = true;
    				console.log(response.data.data);
    			}.bind(this);
    			axios.get('users/'+this.$route.params.id).
    			then(function(response){onSuccess(response)});
    		}
    		*/
        onSelected(value) {
            this.colorPicked = value;
        },
    },
};
</script>
