<template>
    <div class="padding0">
        <div>
            <h2 class="marginBottom20">
                <template v-if="values.type == 'ratingV2'"> Rating </template>

                <template v-else-if="values.type == 'openendedV2'"> Open-ended </template>

                <template v-else-if="values.type == 'pointDistribution'"> Point Distribution </template>
            </h2>
            <Field v-slot="{ errorMessage, field }" type="text" name="question.name">
                <div class="form-group" :class="{ 'has-error': !!errorMessage }">
                    <label class="control-label">
                        <span>Criteria</span>
                        <span class="visible-hidden">Required</span>
                        <span class="important" aria-hidden="true">*</span>
                    </label>

                    <template v-if="values.type == 'pointDistribution'">
                        <input v-bind="field" placeholder="Type criteria  i.e. Quantitative Feedback" class="form-control" />
                    </template>
                    <template v-else-if="values.type == 'ratingV2'">
                        <input v-bind="field" placeholder="Type criteria  i.e. Cooperative Learning Skills" class="form-control" />
                    </template>
                    <template v-else>
                        <input v-bind="field" placeholder="Type criteria  i.e. Qualitative Feedback" class="form-control" />
                    </template>

                    <span v-if="!!errorMessage" class="help-block">{{ errorMessage }} </span>
                </div>
            </Field>
            <Field v-slot="{ field }" type="text" name="question.description">
                <div class="form-group marginTop20">
                    <label class="control-label">
                        <span>Description</span>
                    </label>
                    <textarea v-bind="field" class="form-control" placeholder="Type description" />
                </div>
            </Field>
        </div>

        <hr :class="{ marginBottom0: values.type == 'pointDistribution' }" />

        <div v-if="['ratingV2', 'openendedV2'].includes(values.type)">
            <div class="flexSpaceBetween align-items marginBottom20">
                <h5>
                    <b>
                        <template v-if="values.type == 'ratingV2'"> Rating </template>
                        <template v-else-if="values.type == 'openendedV2'"> Open-ended </template>
                        Questions
                    </b>
                </h5>
                <Field
                    v-if="target != 'self'"
                    v-slot="{ field }"
                    type="checkbox"
                    name="settings.answerQuestionsForEachTeammate"
                    :value="true"
                    :unchecked-value="false"
                >
                    <div class="checkboxField">
                        <input id="answerForEachTeammate" v-bind="field" type="checkbox" />
                        <label for="answerForEachTeammate" class="fw-normal"> Answer questions for each teammate </label>
                    </div>
                </Field>
            </div>
            <div>
                <FieldArray v-slot="{ fields, push, remove }" name="questionStems">
                    <div class="newQuestionModalTable">
                        <table class="width100 borderNone">
                            <thead>
                                <tr>
                                    <th colspan="2"></th>
                                    <th colspan="3">Question Stem</th>
                                    <th>Required</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(stem, idx) in fields" :key="stem.key">
                                    <tr :class="{ notFirstRow: idx != 0 }">
                                        <td>
                                            <button>
                                                <i class="fas fa-grip-vertical" aria-hidden="true" />
                                            </button>
                                        </td>

                                        <td>
                                            <label class="control-label marginRight8 fw-normal paddingTop5"> {{ idx + 1 }}. </label>
                                        </td>

                                        <td>
                                            <Field v-slot="{ errorMessage, field }" type="text" :name="`questionStems[${idx}].stem`">
                                                <div class="form-group" :class="{ 'has-error': !!errorMessage }">
                                                    <input
                                                        v-bind="field"
                                                        type="text"
                                                        class="form-control"
                                                        placeholder="Type question here e.g. Rate this teammate's leadership skills."
                                                        :data-required="values.questionStems[idx].isRequired"
                                                    />
                                                </div>
                                            </Field>
                                        </td>

                                        <td>
                                            <button
                                                class="btn btn-default deleteBtn"
                                                :disabled="values.questionStems.length < 2 ? true : undefined"
                                                @click.prevent="remove(idx)"
                                            >
                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                            </button>
                                        </td>

                                        <td>
                                            <button
                                                class="btn btn-default primaryHover"
                                                :disabled="values.questionStems.length !== idx + 1 ? true : undefined"
                                                @click.prevent="push({ stem: '', isRequired: true })"
                                            >
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </td>

                                        <td>
                                            <Field
                                                v-slot="{ field }"
                                                type="checkbox"
                                                :name="`questionStems[${idx}].isRequired`"
                                                :value="true"
                                                :unchecked-value="false"
                                            >
                                                <div class="flex align-items toggle-display">
                                                    <label class="form-switch">
                                                        <input v-bind="field" type="checkbox" />
                                                        <span class="slider round" />
                                                    </label>
                                                </div>
                                            </Field>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </FieldArray>
            </div>
        </div>

        <hr v-if="['ratingV2', 'openendedV2'].includes(values.type)" :class="{ marginBottom0: values.type == 'ratingV2' }" />

        <div v-if="values.type == 'ratingV2'">
            <div class="rating-wrapper-column">
                <div class="paddingTop16 paddingBottom16 paddingRight24">
                    <h5>
                        <b> Rating Content </b>
                    </h5>
                    <div class="rating-flex-layout">
                        <FieldArray ref="ratingsArray" v-slot="{ fields, push, remove }" name="ratingSettings">
                            <div v-for="(label, idx) in fields" :key="label.key">
                                <div class="textAlignCenter">
                                    <div class="dropdown">
                                        <button class="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Actions">
                                            <i class="fa-solid fa-grip" aria-hidden="true"></i>
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <a
                                                        class="primary-state"
                                                        href="#"
                                                        @click.prevent="
                                                            push({
                                                                label: '',
                                                                weight: fields.length + 1,
                                                                rubric: '',
                                                            })
                                                        "
                                                    >
                                                        <span> <i class="fa fa-plus marginRight8" aria-hidden="true" /> Add Range </span>
                                                    </a>
                                                </li>
                                                <hr />
                                                <li>
                                                    <a
                                                        class="danger-state"
                                                        href="#"
                                                        @click.prevent="
                                                            remove(idx);
                                                            updateRange();
                                                        "
                                                    >
                                                        <span> <i class="fa fa-trash marginRight8" aria-hidden="true" /> Delete Range </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="newRadioField rating-scale-wrapper">
                                    <div class="radioField">
                                        <input id="radio-label" type="radio" name="ratingV2" disabled />
                                    </div>
                                    <label for="radio-label" class="rating-label">
                                        {{ label.value.weight }}
                                    </label>
                                    <Field v-slot="{ errorMessage, field }" type="text" :name="`ratingSettings[${idx}].label`">
                                        <div class="form-group" :class="{ 'has-error': !!errorMessage }">
                                            <input v-bind="field" type="text" class="form-control" placeholder="Type Rating Label e.g. Agree" />
                                        </div>
                                    </Field>
                                    <Field v-slot="{ errorMessage, field }" type="text" :name="`ratingSettings[${idx}].rubric`">
                                        <div class="form-group marginTop20" :class="{ 'has-error': !!errorMessage }">
                                            <textarea v-bind="field" rows="3" class="form-control" placeholder="Type Rating description (Optional)" />
                                        </div>
                                    </Field>
                                </div>
                            </div>
                        </FieldArray>
                    </div>
                </div>

                <div class="border-left-table-column-outline">
                    <h5>
                        <b> Rating Settings </b>
                    </h5>

                    <label class="control-label marginTop10"> Starting Number </label>
                    <div class="form-group">
                        <div class="form-dropdown viewFilter marginLeft0">
                            <select v-model="startingNumber" class="form-control" @change="changedStartingNumber">
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>

                    <label class="control-label marginTop20"> Range </label>
                    <div class="form-group">
                        <div class="form-dropdown viewFilter marginLeft0">
                            <select v-model="range" class="form-control" @change="changedRange">
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="values.type == 'openendedV2'">
            <h5 class="marginBottom20">
                <b> Settings </b>
            </h5>

            <div class="marginBottom20">
                <label class="control-label"> Set Min/Max Words for Responses </label>

                <div class="flexOnly">
                    <Field v-slot="{ errorMessage, field }" type="number" name="minimum">
                        <div class="numberInput minWidth73px" :class="{ 'has-error': errorMessage }">
                            <input
                                v-bind="field"
                                type="number"
                                class="form-control"
                                :min="0"
                                step="1"
                                maxlength="3"
                                onKeyPress="if(this.value.length==3) return false;"
                                aria-label="Minimum Words"
                                placeholder="Set Minimum"
                            />
                            <span class="red">{{ errorMessage }}</span>
                        </div>
                    </Field>

                    <Field v-slot="{ errorMessage, field }" type="number" name="maximum">
                        <div class="numberInput minWidth73px marginLeft20" :class="{ 'has-error': errorMessage }">
                            <input
                                v-bind="field"
                                type="number"
                                class="form-control"
                                max="2000"
                                step="1"
                                maxlength="3"
                                onKeyPress="if(this.value.length==3) return false;"
                                aria-label="Maximum Words"
                                placeholder="Set Maximum"
                            />
                            <span class="red">{{ errorMessage }}</span>
                        </div>
                    </Field>
                </div>
            </div>
            <Field
                v-if="displayCanAllowStudentRichTextResponsesField"
                v-slot="{ field }"
                type="checkbox"
                name="canAllowStudentRichTextResponses"
                :value="true"
                :unchecked-value="false"
            >
                <div class="paddingBottom20">
                    <div class="flex align-items toggle-display">
                        <div class="flexOnly justifyCenter marginRight10">
                            <label class="form-switch">
                                <input v-bind="field" type="checkbox" />
                                <span class="slider round" />
                            </label>
                        </div>
                        <div>
                            <label class="control-label fontBlack margin0 fw-normal"> Allow Rich Text Responses </label>
                        </div>
                    </div>
                </div>
            </Field>
        </div>

        <div v-else-if="values.type == 'pointDistribution'">
            <div class="flexOnly">
                <div class="border-right-table-column-outline paddingTop16 paddingBottom16 paddingRight24">
                    <h4>
                        <b> Point Distribution Breakdown Per Team </b>
                    </h4>

                    <template v-if="pointDistributionTeams.length > 1">
                        <label class="control-label"> Select Section </label>
                        <div class="dropdown new-custom-search-select width150px d-block marginBottom20">
                            <button id="viewDropdownMenuButton" class="btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Section {{ sectionNumber }}
                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-new width100" aria-labelledby="viewDropdownMenuButton">
                                <ul role="listbox" class="maxHeight255px overflowAuto">
                                    <li v-for="(team, idx) in pointDistributionTeams" role="option" class="paddingTop10 paddingBottom10 withHover">
                                        <button
                                            type="button"
                                            @click="
                                                sectionNumber = team[0].section;
                                                sectionIdx = idx;
                                            "
                                        >
                                            Section {{ team[0].section }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </template>

                    <div v-if="!doAllTeamsMeetCriteria" class="alert alert-danger-new marginBottom20">
                        <i class="fa-solid fa-circle-exclamation marginRight8" aria-hidden="true"></i>
                        Teams must have at least 3 members
                    </div>

                    <div class="pointDistributionTable">
                        <table>
                            <thead>
                                <tr>
                                    <th>Section</th>
                                    <th class="minWidth120px maxWidth120px width120px">Team</th>
                                    <th>Team Size</th>
                                    <th class="minWidth110px maxWidth110px width110px">
                                        <label class="word-wrap-break-word fontSize16">
                                            Points To <br />
                                            Distribute
                                        </label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(team, team_idx) in pointDistributionTeams[sectionIdx]" :key="team_idx">
                                    <td>
                                        {{ team.section }}
                                    </td>
                                    <td>
                                        {{ team.name }}
                                    </td>
                                    <td
                                        :class="{
                                            'flexSpaceBetween error': team.members.length < 3,
                                        }"
                                    >
                                        {{ team.members.length }}

                                        <i v-if="team.members.length < 3" class="fa-solid fa-circle-exclamation" aria-hidden="true" />
                                    </td>
                                    <td>
                                        <template v-if="pointCalculation(team.members.length) > 0">
                                            {{ pointCalculation(team.members.length) }}
                                        </template>
                                        <template v-else> 0 </template>
                                    </td>
                                </tr>
                                <tr v-if="pointDistributionTeams.length == 0">
                                    <td colspan="4" style="text-align: center">NO TEAM</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="paddingTop16 paddingBottom16 paddingLeft24">
                    <h4>
                        <b> Point Distribution settings </b>
                    </h4>
                    <Field v-slot="{ field }" type="checkbox" name="isRequired" :value="true" :unchecked-value="false">
                        <div class="paddingBottom20">
                            <div class="flex align-items toggle-display">
                                <div class="flexOnly justifyCenter marginRight10">
                                    <label class="form-switch">
                                        <input id="isRequired" v-bind="field" type="checkbox" />
                                        <span class="slider round" />
                                    </label>
                                </div>
                                <div>
                                    <label for="isRequired" class="control-label fontBlack margin0 fw-normal"> Required </label>
                                </div>
                            </div>
                        </div>
                    </Field>

                    <Field v-slot="{ field, value }" name="settings.pointDistributionStyle">
                        <div>
                            <label class="control-label">
                                <span>Point Distribution Type</span>
                            </label>
                            <div class="radioField">
                                <div class="newRadioField" role="group">
                                    <div v-for="(item, idx) in pointDistributionStyle" :key="item.description" class="radioField flex alignFlexStart">
                                        <div class="flex">
                                            <input
                                                :id="item.value + '-style-option'"
                                                name="settings.pointDistributionStyle"
                                                v-bind="field"
                                                type="radio"
                                                :value="item.value"
                                                :checked="item.value == field.value"
                                                @change="
                                                    value == 'fink'
                                                        ? setFieldValue('settings.pointsAllocatedPerMember', 100)
                                                        : setFieldValue('settings.pointsAllocatedPerMember', 10);
                                                    changePointDistributionType();
                                                "
                                            />
                                            <label :for="item.value + '-style-option'"> {{ item.description }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Field>

                    <label class="control-label marginTop20">
                        <template v-if="values.settings.pointDistributionStyle == 'michaelsen'"> How many points to allocated per teammate? </template>
                        <template v-else> How many points to distribute? </template>
                    </label>

                    <div class="numberInput-step-new">
                        <Field v-slot="{ field }" type="number" name="settings.pointsAllocatedPerMember">
                            <button
                                type="button"
                                class="btn btn-outline-dark"
                                aria-label="Minus Points Allocated"
                                @click="setFieldValue('settings.pointsAllocatedPerMember', field.value - 1)"
                            >
                                <i class="fas fa-minus" aria-hidden="true"></i>
                            </button>
                            <input v-bind="field" type="number" class="form-control marginLeft10" />
                            <button
                                type="button"
                                class="btn btn-outline-dark marginLeft10"
                                aria-label="Add Points Allocated"
                                @click="setFieldValue('settings.pointsAllocatedPerMember', field.value + 1)"
                            >
                                <i class="fas fa-plus" aria-hidden="true"></i>
                            </button>
                        </Field>
                    </div>

                    <label class="control-label marginTop20"> Point Distribution Requirements </label>
                    <div class="newRadioField">
                        <div class="checkboxField">
                            <Field v-slot="{ field }" type="checkbox" name="settings.shouldDistributeAllPoints" :value="true" :unchecked-value="false">
                                <input id="allPoints" v-bind="field" type="checkbox" />
                                <label for="allPoints" class="fw-normal paddingTop5"> Must distribute all points </label>
                            </Field>
                        </div>
                        <div v-if="values.settings.pointDistributionStyle == 'michaelsen'" class="checkboxField">
                            <Field
                                v-slot="{ field }"
                                type="checkbox"
                                name="settings.shouldGiveOneTeammateAboveTenPoints"
                                :value="true"
                                :unchecked-value="false"
                            >
                                <input id="above10" v-bind="field" type="checkbox" />
                                <label for="above10" class="fw-normal paddingTop5">
                                    Must give points above {{ values.settings.pointsAllocatedPerMember }}
                                </label>
                            </Field>
                        </div>
                        <div v-if="values.settings.pointDistributionStyle == 'michaelsen'" class="checkboxField">
                            <Field
                                v-slot="{ field }"
                                type="checkbox"
                                name="settings.shouldGiveOneTeammateBelowTenPoints"
                                :value="true"
                                :unchecked-value="false"
                            >
                                <input id="below10" v-bind="field" type="checkbox" />
                                <label for="below10" class="fw-normal paddingTop5">
                                    Must give a point below {{ values.settings.pointsAllocatedPerMember }} &nbsp;<b>(0 is allowed)</b>
                                </label>
                            </Field>
                        </div>
                        <div class="checkboxField">
                            <Field
                                v-slot="{ field }"
                                type="checkbox"
                                name="settings.shouldDistributePointsWithoutRepeatingValues"
                                :value="true"
                                :unchecked-value="false"
                            >
                                <input id="disableRepeatedValue" v-bind="field" type="checkbox" />
                                <label for="disableRepeatedValue" class="fw-normal paddingTop5"> Must distribute points without repeating values </label>
                            </Field>
                        </div>
                        <div class="checkboxField">
                            <Field v-slot="{ field }" type="checkbox" name="settings.allowToGiveThemselvesPoints" :value="true" :unchecked-value="false">
                                <input id="pointToSelf" v-bind="field" type="checkbox" :disabled="target == 'peers'" />
                                <label for="pointToSelf" class="fw-normal paddingTop5"> Give points to self &nbsp;<b>(0 is allowed)</b></label>
                            </Field>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="marginTop0" />

        <div>
            <h5>
                <b> Additional </b>
            </h5>

            <Field v-slot="{ field }" type="text" name="remarks">
                <div class="form-group">
                    <label class="control-label">
                        <span>Question Notes - Viewable to teachers only (optional)</span>
                    </label>
                    <textarea v-bind="field" class="form-control" />
                </div>
            </Field>
        </div>
    </div>
</template>

<script>
import { Field, FieldArray, ErrorMessage } from 'vee-validate';
export default {
    components: {
        Field,
        FieldArray,
        ErrorMessage,
    },
    props: ['displayCanAllowStudentRichTextResponsesField', 'values', 'setFieldValue', 'target', 'pointDistributionTeams'],
    data() {
        let pointDistributionStyle = [
            { value: 'michaelsen', description: 'Michaelsen Style' },
            { value: 'fink', description: 'Fink Style' },
        ];
        return {
            pointDistributionStyle: pointDistributionStyle,
            range: 5,
            startingNumber: 1,
            sectionNumber: 1,
            sectionIdx: 0,
        };
    },
    computed: {
        doAllTeamsMeetCriteria() {
            if (this.pointDistributionTeams.length > 0) {
                var outcome = this.pointDistributionTeams[0].some((key) => key.members.length < 3);
                return !outcome;
            } else {
                return false;
            }
        },
    },
    watch: {
        'values.ratingSettings': {
            handler: function () {
                if (this.values.type == 'ratingV2' && this.values.ratingSettings) {
                    this.startingNumber = this.values.ratingSettings[0].weight;
                    this.range = this.values.ratingSettings.length;
                }
            },
            deep: true,
        },
    },
    methods: {
        changedRange() {
            let length = this.values.ratingSettings.length;
            if (length < this.range) {
                for (var i = 0; i < this.range; i++) {
                    if (i < length) {
                        let value = _.cloneDeep(this.values.ratingSettings[i]);
                        value.weight = i + parseInt(this.startingNumber);
                        this.$refs.ratingsArray.update(i, value);
                    } else {
                        this.$refs.ratingsArray.push({
                            weight: i + parseInt(this.startingNumber),
                            label: '',
                            rubric: '',
                        });
                    }
                }
            } else if (length > this.range) {
                for (var i = length - 1; i >= this.range; i--) {
                    this.$refs.ratingsArray.remove(i);
                }
            }
        },
        changedStartingNumber() {
            for (var i = 0; i < this.values.ratingSettings.length; i++) {
                let value = _.cloneDeep(this.values.ratingSettings[i]);
                value.weight = i + parseInt(this.startingNumber);
                this.$refs.ratingsArray.update(i, value);
            }
        },
        changePointDistributionType() {
            if (this.values.settings.pointDistributionStyle == 'fink') {
                this.setFieldValue('settings.shouldGiveOneTeammateAboveTenPoints', false);
                this.setFieldValue('settings.shouldGiveOneTeammateBelowTenPoints', false);
            }
        },
        pointCalculation(noOfTeammates) {
            var pointsAllocatedPerMember = this.values.settings.pointsAllocatedPerMember;

            if (this.values.settings.pointDistributionStyle == 'michaelsen') {
                if (this.values.settings.allowToGiveThemselvesPoints) {
                    return pointsAllocatedPerMember * noOfTeammates > 0 ? pointsAllocatedPerMember * noOfTeammates : 0;
                } else {
                    return pointsAllocatedPerMember * (noOfTeammates - 1) > 0 ? pointsAllocatedPerMember * (noOfTeammates - 1) : 0;
                }
            } else {
                return pointsAllocatedPerMember;
            }
        },
        updateRange() {
            if (this.values.ratingSettings && this.values.ratingSettings.length > 0) {
                this.range = this.values.ratingSettings.length;

                for (var i = 0; i < this.range; i++) {
                    let value = _.cloneDeep(this.values.ratingSettings[i]);
                    value.weight = i + parseInt(this.startingNumber);
                    this.$refs.ratingsArray.update(i, value);
                }
            }
        },
    },
};
</script>
