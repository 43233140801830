x
<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-6">{{ $t('modules.accounts.superadmins.edit.headingLabel') }}</div>
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <superadmin-form :form="form"></superadmin-form>
                        <div class="profileBtns buttonGroup">
                            <button id="bottom-save-btn" class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Update" @click="submit()">
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>{{ $t('modules.accounts.superadmins.edit.updateButtonLabel') }}
                            </button>
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: teacher.account.uuid },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">{{ $t('modules.accounts.superadmins.edit.cancelButtonLabel') }}</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            teacher: {},
            form: new KrForm({
                fields: {
                    avatar: '',
                    firstname: '',
                    lastname: '',
                    displayName: '',
                    email: '',
                    roleId: 'default!:3',
                    password: 'sendNull!:false',
                    password_confirmation: 'sendNull!:false',
                    identity: '',
                    canAddTeacher: '',
                    canUseExpressSignIn: '',
                },
                props: {},
                urls: {
                    put: '/teachers/' + this.$route.params.id,
                },
            }),
        };
    },
    components: {
        'superadmin-form': require(`./form-partials/partial.superadmin-edit.vue`).default,
    },
    computed: {
        canSend() {
            if (
                (!this.form.errors.any() &&
                    this.form.model.email &&
                    this.form.model.firstname &&
                    this.form.model.lastname &&
                    this.form.model.password &&
                    this.form.model.password_confirmation) ||
                !this.form.model.password
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        if (this.$route.name == 'accounts.teachers.edit') {
            this.isFromAccount = true;
        }
        this.fetch();
    },
    methods: {
        fetch() {
            var that = this;
            let route = '/users';
            axios.get(route + '/' + that.$route.params.id).then(function (user) {
                that.teacher = user.data.data;
                that.form.setModel(user.data.data);
                Events.fire('topbar_update', {
                    breadcrumb: [
                        { name: 'Home' },
                        {
                            name: 'Account',
                            link: 'accounts.view',
                            params: { id: user.data.data.account.uuid },
                        },
                        { name: that.$t('modules.accounts.superadmins.edit.breadcrumbLabel') },
                    ],
                });
                that.component_done_loading = true;
                // Both requests are now complete
            });
        },
        submit() {
            var that = this;
            this.form.model.displayName = this.form.model.firstname + (this.form.model.lastname ? ' ' + this.form.model.lastname : '');
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'accounts.superadmins.view',
                        params: { id: that.$route.params.id },
                    });
                }
            });
        },
    },
};
</script>
