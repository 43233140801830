<template>
    <div>
        <kr-file-picture
            display="Institution Thumbnail"
            :form="form"
            name="coverImage"
            :options="{ width: '600', height: '600' }"
            @changed="$emit('instantUpdate')"
        />

        <kr-toggle
            class="marginTop20"
            :options="{ reverse: true, onNoff: true }"
            name="settings.pronounsEnabled"
            display="Enable pronouns"
            :form="form"
            @changed="$emit('instantUpdate')"
        />
    </div>
</template>
<script>
export default {
    props: ['form'],
};
</script>
<style scoped></style>
