<template>
    <div class="create row flex">
        <div class="panelTitle">
            <h3 class="fs-20px margin0">2-Factor Authentication (2FA)</h3>
            <p>
                2FA is used for email/password sign in only. When enabled, users will receive a 6-digit pin via email following email/password verification to
                complete their sign in.
            </p>
        </div>
        <div>
            <kr-toggle
                :options="{ reverse: true, onNoff: true }"
                name="settings.mfaForTeachers"
                display="2FA for instructors"
                :form="form"
                @changed="$emit('instantUpdate')"
            />
            <kr-toggle
                :options="{ reverse: true, onNoff: true }"
                name="settings.mfaForStudents"
                display="2FA for Learners"
                :form="form"
                class="marginTop15"
                @changed="$emit('instantUpdate')"
            />
        </div>
    </div>
</template>
<script>
export default {
    props: ['form'],
};
</script>
<style scoped></style>
