<template>
    <div ref="tldraw" class="tldraw">Loading...</div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { Tldraw } from 'tldraw';

import 'tldraw/tldraw.css';

const tldraw = ref(null);
let root;
onMounted(() => {
    root = createRoot(tldraw.value);
    root.render(
        React.createElement(Tldraw, {
            persistenceKey: 'tldraw',
        })
    );
});
onUnmounted(() => {
    if (root) {
        root.unmount();
    }
});
</script>

<style>
.tldraw {
    height: 100vh;
    width: 100vw;
}
</style>
