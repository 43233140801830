<template>
    <nav class="newPagination" aria-label="Pagination">
        <ul class="inlineFlex">
            <li>
                <template
                    v-if="
                        !canChangeQuestion(previous_question_group_idx, previous_question_idx) || (current_question_group_idx == 0 && current_question_idx == 0)
                    "
                >
                    <a v-if="pagination.length > 1" href="#" class="disabled" v-tooltip="'Previous Question Disabled'" aria-label="Previous Question Disabled">
                        <i class="fas fa-arrow-left-long" aria-hidden="true" />
                    </a>
                </template>
                <template v-else>
                    <a
                        v-if="pagination.length > 1"
                        href=""
                        class="link borderRadius8"
                        v-tooltip="'Previous Question'"
                        aria-label="'Previous Question'"
                        @click.prevent="moveToDifferentQuestion(previous_question_group_idx, previous_question_idx, 'scrollToQns')"
                    >
                        <i class="fas fa-arrow-left-long" aria-hidden="true" />
                    </a>
                </template>
            </li>
            <li
                v-for="(question, idx) in pagination"
                :key="'page-' + idx"
                :class="{
                    active: question.question_group_idx == current_question_group_idx && question.question_idx == current_question_idx,
                    first: question.question_idx == 0,
                    last: idx == pagination.length - 1 || pagination[idx + 1].question_group_idx != question.question_group_idx,
                }"
            >
                <template v-if="question.isDot">
                    <a>...</a>
                </template>
                <template v-else>
                    <template v-if="canChangeQuestion(question.question_group_idx, question.question_idx)">
                        <a
                            href=""
                            class="link"
                            :aria-label="'question ' + question.label"
                            :aria-current="question.question_group_idx == current_question_group_idx && question.question_idx == current_question_idx"
                            @click.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx, 'scrollToQns')"
                            @keyup.space.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx, 'scrollToQns')"
                        >
                            {{ question.label }}
                        </a>
                    </template>
                    <template v-else>
                        <a class="disabled" :aria-label="question.label">{{ question.label }}</a>
                    </template>
                </template>
            </li>

            <li>
                <template
                    v-if="
                        !canChangeQuestion(next_question_group_idx, next_question_idx) ||
                        (current_question_group_idx == pagination[pagination.length - 1].question_group_idx &&
                            current_question_idx == pagination[pagination.length - 1].question_idx)
                    "
                >
                    <a v-if="pagination.length > 1" href="#" class="disabled" v-tooltip="'Next Question Disabled'" aria-label="'Next Question Disabled'">
                        <i class="fas fa-arrow-right-long" aria-hidden="true" />
                    </a>
                </template>
                <template v-else>
                    <a
                        v-if="pagination.length > 1"
                        href="#"
                        class="link borderRadius8"
                        aria-label="Next Question"
                        @click.prevent="moveToDifferentQuestion(next_question_group_idx, next_question_idx, 'scrollToQns')"
                    >
                        <i class="fas fa-arrow-right-long" aria-hidden="true" />
                    </a>
                </template>
            </li>
        </ul>
    </nav>
</template>

<script>
import { testPaginationMixins } from './../../../../../mixins/test';
export default {
    mixins: [testPaginationMixins],
};
</script>
