<template>
    <div>
        <div>
            <h3 class="margin0 fs-18px">Question Stem</h3>
            <kr-math
                :input="question.question"
                :safe="!question.question.questionIsHTML"
                class="question"
                :can-highlight="true"
                :question-uuid="question.uuid"
            />
        </div>

        <template v-if="question.attachments && question.attachments.length != 0">
            <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
            <div class="textLink">
                <template v-for="(file, idx) in question.attachments" :key="'mcq-' + idx + '-' + file.filename">
                    <div class="flex">
                        <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                        <template
                            v-if="
                                (testObj.type == 'application' && (!isTestCompleted || (isTestCompleted && testObj.allowStudentsToViewAttachments))) ||
                                testObj.type != 'application'
                            "
                        >
                            <template v-if="isPdf(file)">
                                <template v-if="testObj.settings.allowPdfDownload">
                                    <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                        {{ file.filename }}
                                    </a>
                                </template>
                                <template v-else>
                                    <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{ file.filename }}</a>
                                </template>
                            </template>
                            <template v-else>
                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                            </template>
                        </template>
                        <template v-else>
                            <a v-tooltip="{ content: 'Viewing disabled' }" class="textLink disabled" :aria-label="file.filename">{{ file.filename }} </a>
                        </template>
                    </div>
                </template>
            </div>
        </template>

        <fieldset class="marginTop20 matching-display-question">
            <legend>Question {{ getQuestionNo(question.group - 1, question.order - 1) }} matching (one-to-one)</legend>

            <div v-if="!isMobileView" ref="matchContainerStudentTest" class="flexSpaceBetween position-relative zIndex2 gap10rem">
                <div ref="promptsGroup" class="width40">
                    <div class="textAlignCenter">
                        <label class="control-label capitalize">{{ question.options.labels.prompt }}</label>
                    </div>

                    <div
                        v-for="(prompt, idx) in promptsList"
                        :key="'match-prompt-' + prompt.key"
                        :ref="promptsColumn"
                        v-tooltip="disabled ? 'Only Team Leader can change the answer' : ''"
                        :data-key="prompt.key"
                        class="match-options"
                        :class="{
                            'match-selected': selectedPrompt === prompt.key,
                            'match-linked': hasAnswer('promptsColumn', prompt.key),
                            'match-isDisabled hasTooltip': disabled,
                        }"
                        role="checkbox"
                        :aria-checked="selectedPrompt === prompt.key"
                        tabindex="0"
                        @click.prevent="!disabled ? selectItem('promptsColumn', prompt.key) : ''"
                        @keyup.space="!disabled ? selectItem('promptsColumn', prompt.key) : ''"
                    >
                        <label :id="idx + 1 + '-student-prompt-content'" class="position-relative">
                            <span class="position-absolute circle">
                                <span class="visible-hidden capitalize">{{ question.options.labels.prompt }}</span>
                                <b> {{ idx + 1 }} </b>
                            </span>
                            {{ prompt.content }}
                        </label>
                    </div>
                </div>

                <div ref="answersGroup" class="width40">
                    <div class="textAlignCenter">
                        <label class="control-label capitalize">{{ question.options.labels.answer }}</label>
                    </div>

                    <div
                        v-for="(ans, idx) in answersList"
                        :key="'match-answer-' + ans.key"
                        :ref="answersColumn"
                        v-tooltip="disabled ? 'Only Team Leader can change the answer' : ''"
                        :data-key="ans.key"
                        class="match-options right"
                        :class="{
                            'match-selected': selectedAnswer === ans.key,
                            'match-linked': hasAnswer('answersColumn', ans.key),
                            'match-isDisabled hasTooltip': disabled,
                        }"
                        role="checkbox"
                        :aria-checked="selectedAnswer === ans.key"
                        tabindex="0"
                        @click.prevent="!disabled ? selectItem('answersColumn', ans.key) : ''"
                        @keyup.space="!disabled ? selectItem('answersColumn', ans.key) : ''"
                    >
                        <label :id="idx + 1 + '-' + ans.key" class="position-relative">
                            <span class="position-absolute circle">
                                <span class="visible-hidden capitalize">{{ question.options.labels.answer }}</span>
                                <template v-if="filterChoice(ans.key) != -1">
                                    <b>{{ filterChoice(ans.key) + 1 }}</b>
                                </template>
                                <template v-else> 0 </template>
                            </span>
                            {{ ans.content }}
                        </label>
                    </div>
                </div>
                <canvas ref="matchCanvasStudentTest"></canvas>
            </div>

            <template v-for="(prompt, idx) in promptsList" v-else>
                <div class="sequencing-div-student" :class="{ filled: answer[idx].answer.length > 0, displayGrid: !isMobileView, disabled: disabled }">
                    <div :class="{ 'grid-order-2': !isMobileView }">
                        <label class="control-label capitalize">{{ question.options.labels.prompt }} {{ idx + 1 }}</label>
                        <p :id="'matching-one-to-one-student-' + (idx + 1)">{{ prompt.content }}</p>
                    </div>

                    <div :class="{ 'grid-order-1': !isMobileView, marginTop20: isMobileView }">
                        <label :for="'matching-one-to-one-student-select-' + (idx + 1)" class="control-label capitalize">
                            <template v-if="disabled">
                                Selected {{ question.options.labels.answer }} for {{ question.options.labels.prompt }} {{ idx + 1 }}
                            </template>
                            <template v-else> Select the {{ question.options.labels.answer }} for {{ question.options.labels.prompt }} {{ idx + 1 }} </template>
                        </label>
                        <div class="form-dropdown" :class="{ 'disabled ': disabled }">
                            <select
                                :id="'matching-one-to-one-student-select-' + (idx + 1)"
                                v-tooltip="disabled ? 'Only Team Leader can change the answer' : ''"
                                class="form-control"
                                :class="{ 'disabled hasTooltip': disabled }"
                                :aria-disabled="disabled"
                                @change="handleChange($event, prompt.key)"
                            >
                                <option value="" :disabled="disabled" :selected="answer[idx].answer.length == 0">
                                    Select the {{ question.options.labels.answer }}
                                </option>
                                <template v-for="(ans, ansIdx) in answersList">
                                    <option :value="ans.key" :disabled="disabled" :selected="answer[idx].answer.includes(ans.key)">
                                        {{ ans.content }}
                                    </option>
                                </template>
                            </select>
                        </div>
                    </div>
                </div>
            </template>
        </fieldset>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick, computed, onUnmounted } from 'vue';

const props = defineProps({
    testObj: {
        type: Object,
        default: null,
    },
    question: {
        type: Object,
        default: null,
    },
    answer: {
        type: Array,
        default: function () {
            return [];
        },
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    isTestCompleted: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:answer']);

const answers = computed({
    // Use computed to wrap the object
    get: () => props.answer,
    set: (value) => emit('update:answer', value),
});

const filteringOptions = (options, type) => {
    let arr = [];
    for (var i = 0; i < options.length; i++) {
        Object.keys(options[i]).forEach((key) => {
            if (options[i][key].type == type) {
                arr.push({ ...options[i][key], key: key });
            }
        });
    }
    return arr;
};

const getQuestionNo = (question_group_idx, question_idx) => {
    let count = 0;
    for (var i = 0; i < question_group_idx; i++) {
        count += props.testObj.questions[i].length;
    }
    count += question_idx + 1;
    return count;
};

const filterChoice = (idx) => {
    return answers.value.findIndex((o) => o.answer.includes(idx));
};

const promptsGroupRefs = ref([]);
const answersGroupRefs = ref([]);

const promptsColumn = (el) => promptsGroupRefs.value.push(el);
const answersColumn = (el) => answersGroupRefs.value.push(el);

const matchCanvasStudentTest = ref(null);
const matchContainerStudentTest = ref(null);

const selectedPrompt = ref(null);
const selectedAnswer = ref(null);

const lineStyling = (ctx, startX, startY, endX, endY, style) => {
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);

    if (props.disabled) {
        ctx.strokeStyle = '#6c6c6c';
    } else {
        ctx.strokeStyle = '#761FBB';
    }

    ctx.lineWidth = 2;

    switch (style) {
        case 'solid':
            ctx.setLineDash([]);
            break;
        case 'dotted':
            ctx.setLineDash([2, 4]);
            break;
        case 'dashed':
            ctx.setLineDash([8, 4]);
            break;
    }

    ctx.stroke();
};

const drawLines = () => {
    if (window.innerWidth >= 992 && props.question.options.matchingType == 'one-to-one') {
        if (matchCanvasStudentTest.value && matchContainerStudentTest.value) {
            const ctx = matchCanvasStudentTest.value.getContext('2d');
            ctx.clearRect(0, 0, matchCanvasStudentTest.value.width, matchCanvasStudentTest.value.height);

            const matchContainerRect = matchContainerStudentTest.value.getBoundingClientRect();

            answers.value.forEach((match, index) => {
                const prompt = promptsGroupRefs.value.find((el) => el && el.dataset.key === match.prompt);

                if (prompt) {
                    const promptRect = prompt.getBoundingClientRect();

                    match.answer.forEach((key) => {
                        const answer = answersGroupRefs.value.find((el) => el && el.dataset.key === key);

                        if (answer) {
                            const answerRect = answer.getBoundingClientRect();

                            const startX = promptRect.right - matchContainerRect.left;
                            const startY = promptRect.top + promptRect.height / 2 - matchContainerRect.top;

                            const endX = answerRect.left - matchContainerRect.left;
                            const endY = answerRect.top + answerRect.height / 2 - matchContainerRect.top;

                            const style = index % 3 === 0 ? 'solid' : index % 3 === 1 ? 'dotted' : 'dashed';
                            lineStyling(ctx, startX, startY, endX, endY, style);
                        }
                    });
                }
            });
        }
    }
};

const canvasDimension = () => {
    if (window.innerWidth >= 992 && props.question.options.matchingType == 'one-to-one') {
        const matchContainerElement = matchContainerStudentTest.value;

        if (matchContainerElement) {
            matchCanvasStudentTest.value.width = matchContainerElement.clientWidth;
            matchCanvasStudentTest.value.height = matchContainerElement.clientHeight;
        }
    }
};

const checkAnswersArray = () => {
    if (selectedPrompt.value !== null && selectedAnswer.value !== null) {
        answers.value.forEach((match) => {
            if (match.prompt === selectedPrompt.value) {
                match.answer = [selectedAnswer.value];
            } else {
                match.answer = match.answer.filter((ansIdx) => ansIdx !== selectedAnswer.value);
            }
        });

        drawLines();

        selectedPrompt.value = null;
        selectedAnswer.value = null;
    }
};

const selectItem = (group, index) => {
    if (group === 'promptsColumn') {
        selectedPrompt.value = selectedPrompt.value === index ? null : index;
    } else if (group === 'answersColumn') {
        selectedAnswer.value = selectedAnswer.value === index ? null : index;
    }

    checkAnswersArray();
};

const hasAnswer = (group, index) => {
    if (group === 'promptsColumn') {
        return answers.value.some((match) => match.prompt === index && match.answer.length > 0);
    } else if (group === 'answersColumn') {
        return answers.value.some((match) => match.answer.includes(index));
    }
    return false;
};

const handleChange = (event, promptIdx) => {
    selectedPrompt.value = promptIdx;

    if (props.testObj._isTeacherPreview) {
        answersList.value.findIndex((o) => {
            if (o.key === event.target.value) {
                o = null;
            }
        });
    } else {
        props.question.options.matches.answers.findIndex((o) => {
            if (o.key === event.target.value) {
                o = null;
            }
        });
    }

    selectedAnswer.value = event.target.value;

    checkAnswersArray();
};

const handleResize = () => {
    if (window.innerWidth >= 992 && props.question.options.matchingType == 'one-to-one') {
        nextTick(() => {
            setTimeout(() => {
                canvasDimension();
                drawLines();
            }, 50);
        });
    }
};

const promptsList = ref([]);
const answersList = ref([]);

const initialize = () => {
    if (props.testObj._isTeacherPreview) {
        for (var i = 0; i < props.question.options.matches.length; i++) {
            var answersGroup = props.question.options.matches[i].answer;
            answersGroup.forEach((answer, idx) => {
                answersList.value.push({ ...answersGroup[idx], key: answer.key, content: answer.content });
            });

            var promptsGroup = props.question.options.matches[i].prompt;
            promptsList.value.push({ ...promptsGroup, key: promptsGroup.key, content: promptsGroup.content });
        }
    } else {
        promptsList.value = props.question.options.matches.prompts;
        answersList.value = props.question.options.matches.answers;
    }
};

onMounted(() => {
    initialize();

    handleResize();

    window.addEventListener('resize', handleResize);
});

watch(
    () => props.question,
    (question) => {
        handleResize();
    }
);

watch([matchContainerStudentTest, matchCanvasStudentTest], () => {
    handleResize();
});

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});
</script>
