<template>
    <div>
        <main class="col-xs-12 right-container" :class="{ isStudent: auth.user().role == 'Student' }">
            <div role="alert" aria-live="assertive" class="visible-hidden">You are now at Dashboard Page</div>
            <div class="stepsNaviFixed nav-tabsContainer">
                <nav class="links">
                    <ul>
                        <li
                            :class="{ active: tab == 'ongoing' }"
                            @click="
                                tab = 'ongoing';
                                query_fields.status = '';
                                searchModule();
                            "
                        >
                            <a class="nav-link uppercase" data-toggle="tab" href="#ongoing"> Ongoing </a>
                        </li>
                        <li
                            :class="{ active: tab == 'completed' }"
                            @click="
                                tab = 'completed';
                                query_fields.status = 'completed';
                                searchModule();
                            "
                        >
                            <a class="nav-link uppercase" data-toggle="tab" href="#completed"> Completed </a>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="pageTitle">
                <h1>
                    {{ tab == 'ongoing' ? 'Ongoing Activities' : 'Completed Activities' }}
                </h1>
                <p class="col-md-6">
                    <template v-if="tab == 'ongoing'">
                        Ongoing activities include those that are still in progress or haven't been completed yet. If you've already submitted your activity or
                        it ended before submission, you can find them in the <b>Completed tab</b>.
                    </template>
                    <template v-if="tab == 'completed'">
                        Completed activities include those you've already submitted or that ended before submission. If you're still working on an activity or
                        it hasn't ended yet, you can find them in the <b>Ongoing tab</b>.
                    </template>
                </p>
            </div>

            <kr-search
                name="activities_listing"
                :url="{ url: '/student/tests' }"
                :options="{
                    trash_field: false,
                    search_field: false,
                    thead: false,
                    top_bar: false,
                    table_background: false,
                    table_tabs: true,
                    query_fields: query_fields,
                    table: false,
                }"
                class="overflowXinherit"
                @retrieve-data="getTestData"
            >
                <template v-if="false" #tabs>
                    <nav class="stepsNaviFixed nav-tabsContainer">
                        <div class="links">
                            <ul>
                                <li
                                    :class="{
                                        active: query_fields.isArchived == 0,
                                    }"
                                    @click="
                                        query_fields.isArchived = 0;
                                        searchModule();
                                    "
                                    @keyup.enter="
                                        query_fields.isArchived = 0;
                                        searchModule();
                                    "
                                >
                                    <a
                                        id="Active-tab"
                                        class="nav-link"
                                        :class="{
                                            active: query_fields.isArchived == 0,
                                        }"
                                        data-toggle="tab"
                                        href=""
                                        role="tab"
                                        aria-controls="Active"
                                        aria-selected="true"
                                    >
                                        Active
                                    </a>
                                </li>
                                <li
                                    :class="{
                                        active: query_fields.isArchived == 1,
                                    }"
                                    @click="
                                        query_fields.isArchived = 1;
                                        searchModule();
                                    "
                                    @keyup.enter="
                                        query_fields.isArchived = 1;
                                        searchModule();
                                    "
                                >
                                    <a
                                        id="Archived-tab"
                                        class="nav-link"
                                        :class="{
                                            active: query_fields.isArchived == 1,
                                        }"
                                        data-toggle="tab"
                                        href=""
                                        role="tab"
                                        aria-controls="Archived"
                                        aria-selected="true"
                                    >
                                        Archived
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </template>

                <template #table-title="props">
                    <div class="d-block marginTop20">
                        <div class="tab-buttons">
                            <div
                                class="button"
                                :class="{
                                    active: query_fields.isArchived == 0,
                                }"
                                style="cursor: pointer"
                                @click="
                                    query_fields.isArchived = 0;
                                    searchModule();
                                "
                            >
                                Active
                            </div>
                            <div
                                class="button"
                                :class="{
                                    active: query_fields.isArchived == 1,
                                }"
                                style="cursor: pointer"
                                @click="
                                    query_fields.isArchived = 1;
                                    searchModule();
                                "
                            >
                                Archived
                            </div>
                        </div>
                    </div>
                </template>
                <template #ndata="props">
                    <p>
                        <template v-if="testSearchData.data.settings.defaultMessagesSettings">
                            <kr-math :input="testSearchData.data.settings.defaultMessagesSettings" />
                        </template>
                        <i v-else>
                            <template v-if="tab == 'ongoing'">
                                Please be patient. Your activities will appear once your instructors have published them.
                            </template>
                            <template v-if="tab == 'completed'">
                                Your completed page is currently empty. Get started by completing some activities to see updates here.
                            </template>
                        </i>
                    </p>
                </template>
                <template #newData="props">
                    <template v-if="testSearchData.data.data.length == 0">
                        <p>
                            <template v-if="testSearchData.data.settings.defaultMessagesSettings">
                                <kr-math :input="testSearchData.data.settings.defaultMessagesSettings" />
                            </template>
                            <i v-else>
                                <template v-if="tab == 'ongoing'">
                                    Please be patient. Your activities will appear once your instructors have published them.
                                </template>
                                <template v-if="tab == 'completed'">
                                    Your completed page is currently empty. Get started by completing some activities to see updates here.
                                </template>
                            </i>
                        </p>
                    </template>
                    <div v-else class="new-studentTest-index-table-container">
                        <div
                            v-for="(module, moduleIdx) in testSearchData.data.data"
                            :key="'module-' + module.uuid"
                            class="table-responsive new-studentTest-index-table"
                        >
                            <table class="table">
                                <caption>
                                    List of Activities from
                                    {{
                                        module.name
                                    }}
                                </caption>
                                <thead>
                                    <tr class="test-customize-header borderNone">
                                        <th colspan="12" class="borderNone">
                                            <h2 class="fs-24px"><span class="visible-hidden">Module Name</span> {{ module.name }}</h2>
                                            <h3 class="fs-22px fw-normal">
                                                <span class="visible-hidden">Course Name</span>
                                                {{ module.courseName }}
                                                {{ module.courseCode ? `(${module.courseCode})` : '' }}
                                            </h3>
                                            <button
                                                class="btn btn-default margin0"
                                                @click.prevent="openCourseDetailsModal(testSearchData.data.data[moduleIdx])"
                                            >
                                                <i class="fa-solid fa-book marginRight8" aria-hidden="true"></i>Course Details
                                            </button>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" class="hidden-xs hidden-sm" style="width: 170px"><span class="visible-hidden">Activity</span> Type</th>
                                        <th scope="col" class="hidden-md hidden-lg" style="width: 142px">
                                            <span class="visible-hidden">Activity</span> Type & Status
                                        </th>
                                        <th scope="col" class="hidden-xs hidden-sm" style="width: calc(100% - 170px - 155px - 500px)">
                                            <span class="visible-hidden">Activity</span> Name
                                        </th>
                                        <th scope="col" class="hidden-md hidden-lg" style="width: calc(100% - 142px - 285px)">
                                            <span class="visible-hidden">Activity</span> Name
                                        </th>
                                        <th scope="col" class="hidden-xs hidden-sm minWidth175px width175px maxWidth175px">
                                            <span class="visible-hidden">Activity</span> Status
                                        </th>
                                        <th scope="col" class="hidden-xs hidden-sm minWidth500px width500px maxWidth500px">Actions</th>
                                        <th scope="col" class="hidden-md hidden-lg" style="width: 285px">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(activity, activityIdx) in module.activities">
                                        <tr v-for="(model, idx) in activity.tests" :key="'tbody_' + model.uuid" class="test-listing-row">
                                            <td>
                                                <div class="hidden-xs hidden-sm">
                                                    <template v-if="model.type == 'prework'"> Prework </template>
                                                    <template v-if="model.type == 'irat'"> IRAT </template>
                                                    <template v-if="model.type == 'trat'"> TRAT </template>
                                                    <template v-if="model.type == 'iratntrat'"> IRAT & TRAT </template>
                                                    <template v-if="model.type == 'application'"> Application Case </template>
                                                    <template v-if="model.type == 'evaluation'"> 360 Degree Evaluation </template>
                                                    <template v-if="model.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                                    <template v-if="model.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                                                </div>

                                                <div class="hidden-md hidden-lg">
                                                    <div class="borderGrey fontSize12" aria-hidden="true">
                                                        <template v-if="model.type == 'prework'"> Prework </template>
                                                        <template v-if="model.type == 'irat'"> IRAT </template>
                                                        <template v-if="model.type == 'trat'"> TRAT </template>
                                                        <template v-if="model.type == 'iratntrat'"> IRAT & TRAT </template>
                                                        <template v-if="model.type == 'application'"> Application </template>
                                                        <template v-if="model.type == 'evaluation'"> 360 Degree Evaluation </template>
                                                        <template v-if="model.type == 'peerEvaluationV2'"> Peer Evaluation </template>
                                                    </div>

                                                    <test-status class="marginTop20" :test="model" role="student" />
                                                </div>
                                            </td>

                                            <td scope="row">
                                                <p>
                                                    {{ activity.name }}
                                                    <template v-if="model.settings.allowSafeExamBrowserOnly"> (Safe Exam Browser Only) </template>
                                                </p>
                                            </td>

                                            <td class="hidden-xs hidden-sm">
                                                <test-status :test="model" role="student" />
                                            </td>
                                            <td class="td-btn">
                                                <div class="hidden-xs studentIndexGrid">
                                                    <div>
                                                        <div
                                                            v-if="
                                                                model.type != 'prework' ||
                                                                (['ongoing', 'paused'].includes(model.status) &&
                                                                    !isTestCompleted(model) &&
                                                                    model.type == 'prework') ||
                                                                (['not started', 'scheduled'].includes(model.status) &&
                                                                    model.allowStudentsToPreviewQuestions) ||
                                                                (isTestCompleted(model) && model.allowStudentsToViewAnswer) ||
                                                                ((['irat', 'evaluation', 'michaelsenEvaluation', 'peerEvaluationV2'].includes(model.type) ||
                                                                    (model.type == 'application' &&
                                                                        model.others.applicationType == 'individual' &&
                                                                        model.others.isApplicationGraded) ||
                                                                    ((model.type == 'trat' ||
                                                                        (model.type == 'application' &&
                                                                            model.others.applicationType == 'team' &&
                                                                            model.others.isApplicationGraded)) &&
                                                                        model.testStudent.myProgression.userTestStatus != null)) &&
                                                                    (['completed', 'ended'].includes(model.status) || isTestCompleted(model)) &&
                                                                    model.allowStudentsToViewScore)
                                                            "
                                                            class="dropdown"
                                                            :class="{
                                                                dropup: activityIdx + idx + 1 == testSearchData.data.data.length,
                                                            }"
                                                        >
                                                            <button
                                                                id="viewDropdownMenuButton"
                                                                class="btn"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                @click="dropdownToggle(moduleIdx, $event.target)"
                                                            >
                                                                View
                                                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                                            </button>
                                                            <div
                                                                class="dropdown-menu dropdown-menu-right dropdown-menu-new"
                                                                aria-labelledby="dropdownMenuButton"
                                                            >
                                                                <ul class="testULLength">
                                                                    <template v-if="model.type != 'prework'">
                                                                        <li>
                                                                            <router-link
                                                                                :to="{
                                                                                    name: 'tests.instruction',
                                                                                    params: {
                                                                                        id: model.testStudent.uuid,
                                                                                    },
                                                                                }"
                                                                                class="default-state"
                                                                            >
                                                                                <span>
                                                                                    <i class="fas fa-book marginRight8" aria-hidden="true" />Instruction
                                                                                </span>
                                                                            </router-link>
                                                                        </li>
                                                                    </template>

                                                                    <template
                                                                        v-if="
                                                                            ['ongoing', 'paused'].includes(model.status) &&
                                                                            !isTestCompleted(model) &&
                                                                            model.type == 'prework'
                                                                        "
                                                                    >
                                                                        <li>
                                                                            <a class="default-state" href="#" @click="startTest(model)">
                                                                                <span> <i class="fa fa-book marginRight8" aria-hidden="true" />Prework </span>
                                                                            </a>
                                                                        </li>
                                                                    </template>

                                                                    <template
                                                                        v-if="
                                                                            ['not started', 'scheduled'].includes(model.status) &&
                                                                            model.allowStudentsToPreviewQuestions
                                                                        "
                                                                    >
                                                                        <li>
                                                                            <a class="default-state" href="#" @click="startTest(model, true)">
                                                                                <span>
                                                                                    <i class="fas fa-eye marginRight8" aria-hidden="true" />Preview Questions
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    </template>

                                                                    <template v-if="isTestCompleted(model) && model.allowStudentsToViewAnswer">
                                                                        <li>
                                                                            <a class="default-state" href="#" @click="startTest(model)">
                                                                                <span>
                                                                                    <i class="fas fa-eye marginRight8" aria-hidden="true" />
                                                                                    <template
                                                                                        v-if="
                                                                                            model.type == 'trat' ||
                                                                                            (model.type == 'application' &&
                                                                                                model.others.applicationType == 'team')
                                                                                        "
                                                                                        >Team Answers</template
                                                                                    >
                                                                                    <template v-else>My Answers</template>
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    </template>

                                                                    <template
                                                                        v-if="
                                                                            (['irat', 'evaluation', 'michaelsenEvaluation', 'peerEvaluationV2'].includes(
                                                                                model.type
                                                                            ) ||
                                                                                (model.type == 'application' &&
                                                                                    model.others.applicationType == 'individual' &&
                                                                                    model.others.isApplicationGraded) ||
                                                                                ((model.type == 'trat' ||
                                                                                    (model.type == 'application' &&
                                                                                        model.others.applicationType == 'team' &&
                                                                                        model.others.isApplicationGraded)) &&
                                                                                    model.testStudent.myProgression.userTestStatus != null)) &&
                                                                            (['completed', 'ended'].includes(model.status) || isTestCompleted(model)) &&
                                                                            model.allowStudentsToViewScore
                                                                        "
                                                                    >
                                                                        <li>
                                                                            <a class="default-state" href="#" @click="viewReport(model)">
                                                                                <span>
                                                                                    <i class="fas fa-chart-bar marginRight8" aria-hidden="true" />
                                                                                    <template
                                                                                        v-if="
                                                                                            ['evaluation', 'michaelsenEvaluation', 'peerEvaluationV2'].includes(
                                                                                                model.type
                                                                                            )
                                                                                        "
                                                                                        >My Evaluations</template
                                                                                    >
                                                                                    <template v-else>My Scores</template>
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="hidden-xs hidden-sm flexOnly marginLeft20">
                                                        <template
                                                            v-if="
                                                                (['not started', 'scheduled', 'ongoing', 'paused'].includes(model.status) ||
                                                                    isTimerExtended(model)) &&
                                                                !isTestCompleted(model) &&
                                                                model.type != 'prework'
                                                            "
                                                        >
                                                            <template v-if="model.settings.type == 'asynchronous' && model.settings.hasTimeLimit">
                                                                <button
                                                                    class="btn btn-success"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="openAsyncModal(model)"
                                                                >
                                                                    Enter<i class="fa fa-arrow-right marginLeft8" aria-hidden="true" />
                                                                </button>
                                                            </template>
                                                            <template v-else>
                                                                <button
                                                                    class="btn btn-success"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="openLoadingModal(model)"
                                                                >
                                                                    Enter<i class="fa fa-arrow-right marginLeft8" aria-hidden="true" />
                                                                </button>
                                                            </template>
                                                        </template>
                                                        <template v-if="model.type == 'application' && !['not started', 'scheduled'].includes(model.status)">
                                                            <router-link
                                                                v-if="model.presentationMode"
                                                                v-slot="{ navigate }"
                                                                :to="{
                                                                    name: 'tests.discussion',
                                                                    params: {
                                                                        id: model.testStudent.uuid,
                                                                    },
                                                                }"
                                                                custom
                                                            >
                                                                <button
                                                                    class="btn btn-outline-success marginLeft20"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="navigate"
                                                                >
                                                                    <template v-if="!['cancelled', null].includes(model.eGalleryWalkStatus)">
                                                                        <i class="fa-solid fa-check-to-slot marginRight8" aria-hidden="true" />Join e-Gallery
                                                                        Walk
                                                                    </template>
                                                                    <template v-else>
                                                                        <i class="fas fa-chalkboard-teacher marginRight8" aria-hidden="true" />Join Discussion
                                                                    </template>
                                                                </button>
                                                            </router-link>
                                                        </template>

                                                        <template
                                                            v-if="
                                                                model.type == 'trat' &&
                                                                ['ongoing', 'paused', 'completed', 'ended'].includes(model.status) &&
                                                                model.settings.allowTeamClarifications &&
                                                                model.allowStudentsToViewAnswer
                                                            "
                                                        >
                                                            <router-link
                                                                v-slot="{ navigate }"
                                                                :to="{
                                                                    name: 'tests.clarification',
                                                                    params: {
                                                                        id: model.testStudent.uuid,
                                                                    },
                                                                }"
                                                                custom
                                                            >
                                                                <button
                                                                    class="btn btn-outline-success marginLeft20"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="navigate"
                                                                >
                                                                    <i class="fas fa-hand-paper marginRight8" aria-hidden="true" />Join Clarifications
                                                                </button>
                                                            </router-link>
                                                        </template>
                                                    </div>

                                                    <div class="hidden-md hidden-lg flexOnly">
                                                        <template
                                                            v-if="
                                                                (['not started', 'scheduled', 'ongoing', 'paused'].includes(model.status) ||
                                                                    isTimerExtended(model)) &&
                                                                !isTestCompleted(model) &&
                                                                model.type != 'prework'
                                                            "
                                                        >
                                                            <template v-if="model.settings.type == 'asynchronous' && model.settings.hasTimeLimit">
                                                                <button
                                                                    v-tooltip="'Enter Activity'"
                                                                    class="btn btn-success marginLeft20"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="openAsyncModal(model)"
                                                                >
                                                                    <i class="fa fa-arrow-right" aria-hidden="true" />
                                                                </button>
                                                            </template>
                                                            <template v-else>
                                                                <button
                                                                    v-tooltip="'Enter Activity'"
                                                                    class="btn btn-success marginLeft20"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="openLoadingModal(model)"
                                                                >
                                                                    <i class="fa fa-arrow-right" aria-hidden="true" />
                                                                </button>
                                                            </template>
                                                        </template>

                                                        <template v-if="model.type == 'application' && !['not started', 'scheduled'].includes(model.status)">
                                                            <router-link
                                                                v-if="model.presentationMode"
                                                                v-slot="{ navigate }"
                                                                v-tooltip="{
                                                                    content: !['cancelled', null].includes(model.eGalleryWalkStatus)
                                                                        ? 'Join e-Gallery Walk'
                                                                        : 'Join Discussion',
                                                                }"
                                                                :to="{
                                                                    name: 'tests.discussion',
                                                                    params: {
                                                                        id: model.testStudent.uuid,
                                                                    },
                                                                }"
                                                                custom
                                                            >
                                                                <button class="btn btn-outline-success" :disabled="lapsed ? true : undefined" @click="navigate">
                                                                    <template v-if="!['cancelled', null].includes(model.eGalleryWalkStatus)">
                                                                        <i class="fa-solid fa-check-to-slot" aria-hidden="true" />
                                                                    </template>
                                                                    <template v-else>
                                                                        <i class="fas fa-chalkboard-teacher" aria-hidden="true" />
                                                                    </template>
                                                                </button>
                                                            </router-link>
                                                        </template>

                                                        <template
                                                            v-if="
                                                                model.type == 'trat' &&
                                                                ['ongoing', 'paused', 'completed', 'ended'].includes(model.status) &&
                                                                model.settings.allowTeamClarifications &&
                                                                model.allowStudentsToViewAnswer
                                                            "
                                                        >
                                                            <router-link
                                                                v-slot="{ navigate }"
                                                                v-tooltip="{
                                                                    content: 'Join Clarifications',
                                                                }"
                                                                :to="{
                                                                    name: 'tests.clarification',
                                                                    params: {
                                                                        id: model.testStudent.uuid,
                                                                    },
                                                                }"
                                                                custom
                                                            >
                                                                <button class="btn btn-outline-success" :disabled="lapsed ? true : undefined" @click="navigate">
                                                                    <i class="fas fa-hand-paper" aria-hidden="true" />
                                                                </button>
                                                            </router-link>
                                                        </template>
                                                    </div>
                                                </div>

                                                <div class="hidden-sm hidden-md hidden-lg">
                                                    <div>
                                                        <div
                                                            v-if="
                                                                model.type != 'prework' ||
                                                                (['ongoing', 'paused'].includes(model.status) &&
                                                                    !isTestCompleted(model) &&
                                                                    model.type == 'prework') ||
                                                                (['not started', 'scheduled'].includes(model.status) &&
                                                                    model.allowStudentsToPreviewQuestions) ||
                                                                (isTestCompleted(model) && model.allowStudentsToViewAnswer) ||
                                                                ((['irat', 'trat', 'evaluation', 'michaelsenEvaluation', 'peerEvaluationV2'].includes(
                                                                    model.type
                                                                ) ||
                                                                    (model.type == 'application' && model.others.isApplicationGraded)) &&
                                                                    (['completed', 'ended'].includes(model.status) || isTestCompleted(model)) &&
                                                                    model.allowStudentsToViewScore)
                                                            "
                                                            class="dropdown"
                                                            :class="{
                                                                dropup: activityIdx + idx + 1 == testSearchData.data.data.length,
                                                            }"
                                                        >
                                                            <button
                                                                id="viewDropdownMenuButton"
                                                                class="btn"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                @click="dropdownToggle(moduleIdx, $event.target)"
                                                            >
                                                                View
                                                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                                            </button>
                                                            <div
                                                                class="dropdown-menu dropdown-menu-right dropdown-menu-new"
                                                                aria-labelledby="dropdownMenuButton"
                                                            >
                                                                <ul class="testULLength">
                                                                    <template v-if="model.type != 'prework'">
                                                                        <li>
                                                                            <router-link
                                                                                :to="{
                                                                                    name: 'tests.instruction',
                                                                                    params: {
                                                                                        id: model.testStudent.uuid,
                                                                                    },
                                                                                }"
                                                                                class="default-state"
                                                                            >
                                                                                <span>
                                                                                    <i class="fas fa-book marginRight8" aria-hidden="true" />Instruction
                                                                                </span>
                                                                            </router-link>
                                                                        </li>
                                                                    </template>

                                                                    <template
                                                                        v-if="
                                                                            ['ongoing', 'paused'].includes(model.status) &&
                                                                            !isTestCompleted(model) &&
                                                                            model.type == 'prework'
                                                                        "
                                                                    >
                                                                        <li>
                                                                            <a class="default-state" href="#" @click="startTest(model)">
                                                                                <span> <i class="fa fa-book marginRight8" aria-hidden="true" />Prework </span>
                                                                            </a>
                                                                        </li>
                                                                    </template>

                                                                    <template
                                                                        v-if="
                                                                            ['not started', 'scheduled'].includes(model.status) &&
                                                                            model.allowStudentsToPreviewQuestions
                                                                        "
                                                                    >
                                                                        <li>
                                                                            <a class="default-state" href="#" @click="startTest(model, true)">
                                                                                <span>
                                                                                    <i class="fas fa-eye marginRight8" aria-hidden="true" />Preview Questions
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    </template>

                                                                    <template v-if="isTestCompleted(model) && model.allowStudentsToViewAnswer">
                                                                        <li>
                                                                            <a class="default-state" href="#" @click="startTest(model)">
                                                                                <span>
                                                                                    <i class="fas fa-eye marginRight8" aria-hidden="true" />
                                                                                    <template
                                                                                        v-if="
                                                                                            model.type == 'trat' ||
                                                                                            (model.type == 'application' &&
                                                                                                model.others.applicationType == 'team')
                                                                                        "
                                                                                        >Team Answers</template
                                                                                    >
                                                                                    <template v-else>My Answers</template>
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    </template>

                                                                    <template
                                                                        v-if="
                                                                            ([
                                                                                'irat',
                                                                                'trat',
                                                                                'evaluation',
                                                                                'michaelsenEvaluation',
                                                                                'peerEvaluationV2',
                                                                            ].includes(model.type) ||
                                                                                (model.type == 'application' && model.others.isApplicationGraded)) &&
                                                                            (['completed', 'ended'].includes(model.status) || isTestCompleted(model)) &&
                                                                            model.allowStudentsToViewScore
                                                                        "
                                                                    >
                                                                        <li>
                                                                            <a class="default-state" href="#" @click="viewReport(model)">
                                                                                <span>
                                                                                    <i class="fas fa-chart-bar marginRight8" aria-hidden="true" />My Scores
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    </template>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <template
                                                            v-if="
                                                                (['not started', 'scheduled', 'ongoing', 'paused'].includes(model.status) ||
                                                                    isTimerExtended(model)) &&
                                                                !isTestCompleted(model) &&
                                                                model.type != 'prework'
                                                            "
                                                        >
                                                            <template v-if="model.settings.type == 'asynchronous' && model.settings.hasTimeLimit">
                                                                <button
                                                                    v-tooltip="'Enter Activity'"
                                                                    class="btn btn-success marginLeft0 marginTop10 d-block"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="openAsyncModal(model)"
                                                                >
                                                                    <i class="fa fa-arrow-right" aria-hidden="true" />
                                                                </button>
                                                            </template>
                                                            <template v-else>
                                                                <button
                                                                    v-tooltip="'Enter Activity'"
                                                                    class="btn btn-success marginLeft0 marginTop10 d-block"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="openLoadingModal(model)"
                                                                >
                                                                    <i class="fa fa-arrow-right" aria-hidden="true" />
                                                                </button>
                                                            </template>
                                                        </template>

                                                        <template v-if="model.type == 'application' && !['not started', 'scheduled'].includes(model.status)">
                                                            <router-link
                                                                v-if="model.presentationMode"
                                                                v-slot="{ navigate }"
                                                                v-tooltip="{
                                                                    content: !['cancelled', null].includes(model.eGalleryWalkStatus)
                                                                        ? 'Join e-Gallery Walk'
                                                                        : 'Join Discussion',
                                                                }"
                                                                :to="{
                                                                    name: 'tests.discussion',
                                                                    params: {
                                                                        id: model.testStudent.uuid,
                                                                    },
                                                                }"
                                                                custom
                                                            >
                                                                <button
                                                                    class="btn btn-outline-success marginLeft0 marginTop10 d-block"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="navigate"
                                                                >
                                                                    <template v-if="!['cancelled', null].includes(model.eGalleryWalkStatus)">
                                                                        <i class="fa-solid fa-check-to-slot" aria-hidden="true" />
                                                                    </template>
                                                                    <template v-else>
                                                                        <i class="fas fa-chalkboard-teacher" aria-hidden="true" />
                                                                    </template>
                                                                </button>
                                                            </router-link>
                                                        </template>

                                                        <template
                                                            v-if="
                                                                model.type == 'trat' &&
                                                                ['ongoing', 'paused', 'completed', 'ended'].includes(model.status) &&
                                                                model.settings.allowTeamClarifications &&
                                                                model.allowStudentsToViewAnswer
                                                            "
                                                        >
                                                            <router-link
                                                                v-slot="{ navigate }"
                                                                v-tooltip="{
                                                                    content: 'Join Clarifications',
                                                                }"
                                                                :to="{
                                                                    name: 'tests.clarification',
                                                                    params: {
                                                                        id: model.testStudent.uuid,
                                                                    },
                                                                }"
                                                                custom
                                                            >
                                                                <button
                                                                    class="btn btn-outline-success marginLeft0 marginTop10 d-block"
                                                                    :disabled="lapsed ? true : undefined"
                                                                    @click="navigate"
                                                                >
                                                                    <i class="fas fa-hand-paper" aria-hidden="true" />
                                                                </button>
                                                            </router-link>
                                                        </template>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </kr-search>
        </main>

        <div id="loadingScreenModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="loadingScreenModal-title">
            <div class="modal-dialog blue-modal-new">
                <div class="modal-content">
                    <div class="modal-body textAlignCenter">
                        <div class="marginBottom20">
                            <h2 id="loadingScreenModal-title">Remember to save your answer</h2>
                            <p class="loadingAnimation">Loading</p>
                        </div>

                        <img
                            src="/video/desktop_loading_screen_finalize.gif"
                            alt="step 1 select answer step 2 tap save answer button below step 3 save answer button transitions to Next button"
                            width="100%"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            id="asyncMessageModal"
            class="modal warning-modal"
            :class="{ isStudent: auth.user().role == 'Student' }"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="asyncMessageModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="asyncMessageModal-title" class="modal-title">Important</h2>
                    </div>

                    <div class="modal-body textAlignCenter">
                        <h2 class="textAlignCenter">This activity has a designated time limit</h2>

                        <p v-if="isTest != ''" class="textAlignCenter fw-normal marginBottom30">
                            <b>
                                Time Limit:
                                <span class="timer">
                                    <template v-if="isTest.testStudent.extraTimeValues != null">
                                        {{ parseInt(isTest.testStudent.extraTimeValues.durationDays) + parseInt(isTest.settings.durationDays) }}
                                        Day(s)
                                        {{ parseInt(isTest.testStudent.extraTimeValues.durationHours) + parseInt(isTest.settings.durationHours) }}
                                        Hour(s)
                                        {{ parseInt(isTest.testStudent.extraTimeValues.durationMinutes) + parseInt(isTest.settings.durationMinutes) }}
                                        Minute(s)
                                        {{ isTest.testStudent.extraTimeValues.durationSeconds }}
                                        Second(s)
                                    </template>
                                    <template v-else>
                                        {{ isTest.settings.durationDays }}
                                        Day(s)
                                        {{ isTest.settings.durationHours }}
                                        Hour(s)
                                        {{ isTest.settings.durationMinutes }}
                                        Minute(s)
                                    </template>
                                </span>
                            </b>
                        </p>

                        <img src="/img/warning.png" width="50" alt="" />

                        <p class="textAlignCenter fw-normal">You may start this asynchronous activity anytime.</p>

                        <p class="textAlignCenter fw-normal marginBottom40">
                            <b class="important">However you must complete within the time limit.</b>
                        </p>

                        <p class="textAlignCenter fw-normal">
                            <b>The timer will start instantly.</b>
                        </p>

                        <p class="textAlignCenter fw-normal marginBottom30 fs-16">
                            If you are ready to begin, click the “Yes, start activity” button.
                            <br />
                            Otherwise, you may choose to cancel.
                        </p>

                        <p>
                            <i>Remember to manage your time effectively.</i>
                            <br />
                            <i>Good luck!</i>
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal">No, come back later</button>

                        <button class="btn btn-success" data-dismiss="modal" @click.prevent="openLoadingModal(isTest)">Yes, start activity</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'courseDetailsModal'"
            class="modal default-modal courseDetailsModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="courseDetailsModal-title"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="courseDetailsModal-title" class="modal-title">Course Details</h2>
                    </div>

                    <div class="modal-body">
                        <label class="control-label">Course Name</label>
                        <p v-if="courseDetails.courseName" class="whiteSpaceBreakSpacesText fs-18px">
                            {{ courseDetails.courseName }}
                        </p>
                        <p v-else class="fs-18px">-</p>

                        <label class="control-label marginTop20">Course Code</label>
                        <p v-if="courseDetails.courseCode" class="whiteSpaceBreakSpacesText fs-18px">
                            {{ courseDetails.courseCode }}
                        </p>
                        <p v-else class="fs-18px">-</p>

                        <label class="control-label marginTop20">Course Overview</label>
                        <p v-if="courseDetails.courseDescription == null" class="fs-18px">-</p>
                        <p v-else class="whiteSpaceBreakSpacesText fs-18px" v-html="courseDetails.courseDescription.description"></p>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { TriggerOpTypes } from 'vue';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        var that = this;
        return {
            auth: auth,
            isBusy: false,
            testSearchData: {
                meta: { paginator: { total: 0 } },
                data: { data: { data: [] } },
                query: { isTrashed: '0' },
            },
            accessibility: {
                selectedRow: false,
                selectedButton: false,
            },
            query_fields: { isArchived: '0', status: '' },
            documentTitle: document.title,
            isTest: '',
            tab: 'ongoing',
            courseDetails: {},
        };
    },
    computed: {
        lapsed() {
            //return false;
            if (['Lapsed', 'Expired'].includes(this.auth.user().status) && this.auth.user().account.paymentMethod == 'Student-Paid') {
                return true;
            }
            return false;
        },
    },
    watch: {
        documentTitle: {
            immediate: true,
            handler() {
                var tabName = this.query_fields.isArchived == 0 ? 'Active' : this.query_fields.isArchived == 1 ? 'Archived' : '';
                document.title = `Activities | ${tabName} | InteDashboard | TBL Makes Teams Work`;
            },
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-pencil-ruler"></i>&nbsp;Activities',
            breadcrumb: [{ name: 'Home' }, { name: 'Activities' }],
        });
        this.processEchoListener();
    },
    mounted() {
        document.onreadystatechange = () => {
            if (document.readyState == 'complete') {
                $('.new-studentTest-index-table').each(function (i, obj) {
                    $(this)
                        .find('.test-listing-row')
                        .each(function (j, object) {
                            if ($(object).find('.testULLength li').length > 0) {
                                $(object).find('.dropdown').show();
                            } else {
                                $(object).find('.dropdown').hide();
                            }
                        });
                });
            }
        };
    },
    methods: {
        processEchoListener() {
            var that = this;
            if (!window.Echo) {
                return;
            }
            let h1 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            if (e.test.uuid == test.uuid) {
                                let old_status = test.status;
                                test.status = e.test.status;
                                test.settings.startTime = e.test.settings.startTime;
                                test.settings.endTime = e.test.settings.endTime;
                                test.settings.diffInSeconds = e.test.settings.diffInSeconds;
                                if (
                                    (['ended', 'completed'].includes(old_status) && test.status == 'ongoing') ||
                                    (!['finished', 'incomplete'].includes(this.getStudentTestStatus(test)) && ['ended', 'completed'].includes(test.status))
                                ) {
                                    Events.fire('activities_listing_refresh');
                                }
                                that.sortByTimeStarted();
                                return;
                            }
                        }
                    }
                }
                Events.fire('activities_listing_refresh');
            };
            let c1 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            test.myProgression = test.myProgression || {};
                            if (e.testUuid != test.uuid) {
                                continue;
                            }
                            if (test.type == 'trat' || (test.type == 'application' && _.get(test, 'others.applicationType') == 'team')) {
                                if (e.teamUuid == test.testStudent.myProgression.team.uuid) {
                                    test.testStudent.myProgression.userTestStatus = e.userPlacementTests.status;
                                    //test.canStartTrat = e.userPlacementTests.canStartTrat;
                                    break;
                                }
                            }
                            if (test.type == 'evaluation') {
                                test.myProgression.userTestStatus = e.userPlacementTests.status;
                                break;
                            }
                        }
                    }
                }
                that.sortByTimeStarted();
            };
            let c2 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('StudentTestStatusUpdated', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'StudentTestStatusUpdated',
                handler: h2,
            });

            let h3 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = activity.tests.length - 1; k >= 0; k--) {
                            let test = activity.tests[k];
                            if (e.testUuids.includes(test.uuid)) {
                                activity.tests.splice(k, 1);
                            }
                        }
                    }
                }
                that.sortByTimeStarted();
            };
            let c3 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('ActivityUnpublished', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'ActivityUnpublished',
                handler: h3,
            });

            let h4 = (e) => {
                Events.fire('activities_listing_refresh');
            };
            let c4 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('ActivityPublished', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'ActivityPublished',
                handler: h4,
            });

            let h5 = (e) => {
                let found = false;

                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = activity.tests.length - 1; k >= 0; k--) {
                            let test = activity.tests[k];
                            if (e.test.uuid == test.uuid) {
                                test.allowStudentsToViewAnswer = e.test.allowStudentsToViewAnswer;
                                test.allowStudentsToViewScore = e.test.allowStudentsToViewScore;
                                test.allowStudentsToPreviewQuestions = e.test.allowStudentsToPreviewQuestions;
                                if (!e.test.isPublished) {
                                    activity.tests.splice(k, 1);
                                }
                                found = true;
                                break;
                            }
                        }
                    }
                }
                if (!found && e.test.isPublished) {
                    Events.fire('activities_listing_refresh');
                } else {
                    that.sortByTimeStarted();
                }
            };
            let c5 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('TestVisibilityUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'TestVisibilityUpdated',
                handler: h5,
            });

            let h6 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            if (e.test.uuid == test.uuid) {
                                let old_status = test.status;
                                test.settings.startDate = e.test.settings.startTime;
                                test.settings.endDate = e.test.settings.endTime;
                                test.settings.startTime = e.test.settings.startTime;
                                test.settings.endTime = e.test.settings.endTime;
                                test.settings.diffInSeconds = e.test.settings.diffInSeconds;
                                test.status = e.test.status;
                                if (
                                    (['ended', 'completed'].includes(old_status) && test.status == 'ongoing') ||
                                    (!['finished', 'incomplete'].includes(this.getStudentTestStatus(test)) && ['ended', 'completed'].includes(test.status))
                                ) {
                                    Events.fire('activities_listing_refresh');
                                }
                                return;
                            }
                        }
                    }
                }
                that.sortByTimeStarted();
            };
            let c6 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('TestPeriodUpdated', h6);
            this.echoChannels.push({
                channel: c6,
                event: 'TestPeriodUpdated',
                handler: h6,
            });

            let h7 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = activity.tests.length - 1; k >= 0; k--) {
                            let test = activity.tests[k];
                            if (e.testUuids.includes(test.uuid)) {
                                activity.tests.splice(k, 1);
                            }
                        }
                    }
                }
                that.sortByTimeStarted();
            };
            let c7 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('ActivityDeleted', h7);
            this.echoChannels.push({
                channel: c7,
                event: 'ActivityDeleted',
                handler: h7,
            });
            let h8 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            if (e.testUuid == test.uuid) {
                                test.eGalleryWalkStatus = e.activityQuestion.eGalleryStatus;
                            }
                        }
                    }
                }
            };
            let c8 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('DiscussionQuestionStatusUpdated', h8);
            this.echoChannels.push({
                channel: c8,
                event: 'DiscussionQuestionStatusUpdated',
                handler: h8,
            });
            let h9 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            if (e.testUuid == test.uuid) {
                                test.presentationMode = e.presentationMode;
                            }
                        }
                    }
                }
            };
            let c9 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('PresentationModeStatusUpdated', h9);
            this.echoChannels.push({
                channel: c9,
                event: 'PresentationModeStatusUpdated',
                handler: h9,
            });
            let h10 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            if (e.userPlacementTestUuid == test.testStudent.uuid) {
                                test.testStudent.expectedEndingTime = e.expectedEndingTime;
                                /*Vue.set(
                                    test.testStudent,
                                    'expectedEndingTime',
                                    e.expectedEndingTime
                                );*/
                                test.testStudent.myProgression.userTestStatus = e.userPlacementTestStatus;
                                /*Vue.set(
                                    test.testStudent.myProgression,
                                    'userTestStatus',
                                    e.userPlacementTestStatus
                                );*/
                                return;
                            }
                        }
                    }
                }
            };
            let c10 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('StudentTimeExtended', h10);
            this.echoChannels.push({
                channel: c10,
                event: 'StudentTimeExtended',
                handler: h10,
            });
            let h11 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            if (e.userPlacementTestUuid == test.testStudent.uuid) {
                                test.testStudent.myProgression.userTestStatus = e.userPlacementTestStatus;
                                /*Vue.set(
                                    test.testStudent.myProgression,
                                    'userTestStatus',
                                    e.userPlacementTestStatus
                                );*/
                                return;
                            }
                        }
                    }
                }
            };
            let c11 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('StudentTestEndedWithExtraTime', h11);
            this.echoChannels.push({
                channel: c11,
                event: 'StudentTestEndedWithExtraTime',
                handler: h11,
            });
            let h12 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            if (e.testUuid == test.uuid && test.testStudent.canEarlyStart) {
                                test.testStudent.expectedEndingTime = e.expectedEndingTime;
                                test.testStudent.canEarlyStart = true;
                                test.earlyStartTestStarted = e.earlyStartTestStarted;
                                /*Vue.set(
                                    test.testStudent,
                                    'expectedEndingTime',
                                    e.expectedEndingTime
                                );
                                Vue.set(
                                    test.testStudent,
                                    'canEarlyStart',
                                    true
                                );
                                Vue.set(
                                    test,
                                    'earlyStartTestStarted',
                                    e.earlyStartTestStarted
                                );*/
                                return;
                            }
                        }
                    }
                }
            };
            let c12 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('EarlyTestStarted', h12);
            this.echoChannels.push({
                channel: c12,
                event: 'EarlyTestStarted',
                handler: h12,
            });
            let h13 = (e) => {
                for (var i = 0; i < that.testSearchData.data.data.length; i++) {
                    let module = that.testSearchData.data.data[i];
                    module.activities = module.activities ? module.activities : [];
                    for (var j = 0; j < module.activities.length; j++) {
                        let activity = module.activities[j];
                        for (var k = 0; k < activity.tests.length; k++) {
                            let test = activity.tests[k];
                            if (e.testUuid == test.uuid && test.testStudent.canEarlyStart) {
                                test.testStudent.expectedEndingTime = null;
                                /*Vue.set(
                                    test.testStudent,
                                    'expectedEndingTime',
                                    null
                                );*/
                                return;
                            }
                        }
                    }
                }
            };
            let c13 = window.Echo.private(`account.${that.auth.user().account.uuid}.student`).listen('EarlyTestEnded', h13);
            this.echoChannels.push({
                channel: c13,
                event: 'EarlyTestEnded',
                handler: h13,
            });
        },
        // listingAccessibility(event) {
        //   var that = this;
        //   let rows = $(".test-listing-row");
        //   let btnDiv = null;
        //   let btns = [];
        //   if (that.accessibility.selectedRow !== false) {
        //     btnDiv = $(
        //       "#row-" + Math.abs(that.accessibility.selectedRow % rows.length)
        //     ).find(".td-btn")[0];
        //     btns = $(btnDiv).find("button");
        //     btns = btns.splice(0, btns.length / 2);
        //   }
        //   switch (event.srcKey) {
        //     case "up":
        //     case "down":
        //       if (that.accessibility.selectedButton !== false) {
        //         $(
        //           btns[Math.abs(that.accessibility.selectedButton % btns.length)]
        //         ).removeClass("selected");
        //       }
        //       that.accessibility.selectedButton = false;
        //       //console.log('exit-0')
        //       if (rows.length === 0) {
        //         return;
        //       }
        //       if (that.accessibility.selectedRow !== false) {
        //         $(
        //           rows[Math.abs(that.accessibility.selectedRow % rows.length)]
        //         ).removeClass("selected");
        //       }

        //       if (event.srcKey == "up") {
        //         if (that.accessibility.selectedRow === false) {
        //           that.accessibility.selectedRow = 0;
        //         } else {
        //           that.accessibility.selectedRow--;
        //         }

        //         //console.log('exit-4')
        //       } else if (event.srcKey == "down") {
        //         if (that.accessibility.selectedRow === false) {
        //           that.accessibility.selectedRow = 0;
        //         } else {
        //           that.accessibility.selectedRow++;
        //         }
        //       }
        //       $([document.documentElement, document.body]).animate(
        //         {
        //           scrollTop:
        //             $(
        //               rows[Math.abs(that.accessibility.selectedRow) % rows.length]
        //             ).offset().top - 200,
        //         },
        //         200
        //       );
        //       $(
        //         rows[Math.abs(that.accessibility.selectedRow) % rows.length]
        //       ).addClass("selected");
        //       break;

        //     case "left":
        //     case "right":
        //       if (that.accessibility.selectedRow === false) {
        //         return;
        //       }
        //       if (btns.length === 0) {
        //         return;
        //       }
        //       if (that.accessibility.selectedButton !== false) {
        //         $(
        //           btns[Math.abs(that.accessibility.selectedButton % btns.length)]
        //         ).removeClass("selected");
        //       }
        //       if (event.srcKey == "left") {
        //         if (that.accessibility.selectedButton === false) {
        //           that.accessibility.selectedButton = 0;
        //         } else {
        //           that.accessibility.selectedButton--;
        //         }

        //         //console.log('exit-4')
        //       } else if (event.srcKey == "right" || event.srcKey == "Tab") {
        //         if (that.accessibility.selectedButton === false) {
        //           that.accessibility.selectedButton = 0;
        //         } else {
        //           that.accessibility.selectedButton++;
        //         }
        //       }
        //       //console.log($(btns[Math.abs(that.accessibility.selectedButton%btns.length)]))
        //       $(
        //         btns[Math.abs(that.accessibility.selectedButton % btns.length)]
        //       ).addClass("selected");
        //       break;
        //     case "enter":
        //       if (that.accessibility.selectedButton === false) {
        //         return;
        //       }
        //       $(
        //         btns[Math.abs(that.accessibility.selectedButton % btns.length)]
        //       ).click();
        //       break;
        //   }
        // },
        sortByModule(data) {
            let m = [];
            for (var i = 0; i < data.length; i++) {
                let idx = _.findIndex(m, function (o) {
                    return o.uuid == data[i].module.uuid;
                });
                if (idx == -1) {
                    let c = _.clone(data[i].module);
                    c.tests = [];
                    c.tests.push(_.clone(data[i]));
                    m.push(c);
                } else {
                    m[idx].tests.push(data);
                }
            }
            return m;
        },
        getTestData(data) {
            this.testSearchData = data.data;
            let modules = this.testSearchData.data.data;
            for (var i = modules.length - 1; i >= 0; i--) {
                let module = modules[i];
                module.activities = module.activities ? module.activities : [];
                for (var j = module.activities.length - 1; j >= 0; j--) {
                    let activity = module.activities[j];
                    if (activity.tests.length == 0) {
                        module.activities.splice(j, 1);
                    }
                }
                if (module.activities.length == 0) {
                    modules.splice(i, 1);
                }
            }
            this.sortByTimeStarted();
        },
        sortByTimeStarted() {
            let modules = this.testSearchData.data.data;
            for (var i = modules.length - 1; i >= 0; i--) {
                let module = modules[i];
                module.activities = module.activities ? module.activities : [];
                for (var j = 0; j < module.activities.length; j++) {
                    let activity = module.activities[j];
                    for (var k = 0; k < activity.tests.length; k++) {
                        if (!module._timeStarted) {
                            module._timeStarted = 1;
                        }
                        if (activity.tests[k].timeStarted) {
                            if (module._timeStarted) {
                                if (module._timeStarted < activity.tests[k].timeStarted) {
                                    module._timeStarted = activity.tests[k].timeStarted;
                                }
                            } else {
                                module._timeStarted = activity.tests[k].timeStarted;
                            }
                        }
                    }
                }
            }
            this.testSearchData.data.data = _.orderBy(this.testSearchData.data.data, ['_timeStarted'], ['desc']);
        },
        isTestEntered(test) {
            return ['entered'].includes(_.get(test, 'testStudent.myProgression.userTestStatus'));
        },
        isTestStarted(test) {
            return ['started', 'ongoing'].includes(_.get(test, 'testStudent.myProgression.userTestStatus'));
        },
        isTimerExtended(test) {
            return _.get(test, 'testStudent.expectedEndingTime', false);
        },
        isTestCompleted(test) {
            if (
                (['completed', 'ended'].includes(test.status) && !this.isTimerExtended(test)) ||
                ['finished', 'incomplete'].includes(_.get(test, 'testStudent.myProgression.userTestStatus')) ||
                ((test.type == 'trat' || (test.type == 'application' && test.others.applicationType == 'team')) &&
                    ['submitted'].includes(_.get(test, 'testStudent.myProgression.teamStatus')))
            ) {
                return true;
            }
            return false;
        },
        startTest(test, isPreview = false) {
            var processTest = function () {
                if (!isPreview) {
                    if (test.type == 'prework') {
                        this.$router.push({
                            name: 'tests.prework',
                            params: { id: test.testStudent.uuid },
                        });
                    }
                    if (test.type == 'irat') {
                        this.$router.push({
                            name: 'tests.proper',
                            params: { id: test.testStudent.uuid },
                        });
                    }
                    if (test.type == 'trat') {
                        this.$router.push({
                            name: 'tests.proper',
                            params: { id: test.testStudent.uuid },
                        });
                    }
                    if (test.type == 'application') {
                        this.$router.push({
                            name: 'tests.proper',
                            params: { id: test.testStudent.uuid },
                        });
                    }
                    if (test.type == 'evaluation' || test.type == 'michaelsenEvaluation') {
                        this.$router.push({
                            name: 'tests.evaluation',
                            params: { id: test.testStudent.uuid },
                        });
                    }
                    if (test.type == 'peerEvaluationV2') {
                        this.$router.push({
                            name: 'tests.evaluation-v2',
                            params: { id: test.testStudent.uuid },
                        });
                    }
                } else {
                    if (['irat', 'trat', 'application'].includes(test.type)) {
                        this.$router.push({
                            name: 'tests.preview.rat',
                            params: { id: test.testStudent.uuid },
                        });
                    } else if (test.type == 'evaluation') {
                        this.$router.push({
                            name: 'tests.preview.evaluation',
                            params: { id: test.testStudent.uuid },
                        });
                    }
                }
            }.bind(this);
            //console.log(test);
            if (isPreview || test.type == 'prework') {
                processTest();
            } else if (['not started', 'scheduled'].includes(test.status) && !_.get(test, 'settings.allowSafeExamBrowserOnly')) {
                axios.put('student/tests/' + test.testStudent.uuid + '/enter').then(function () {
                    processTest();
                });
            } else {
                /*else if(['ongoing'].includes(test.status)&&!this.isTestStarted(test)){
                axios.put('student/tests/'+test.uuid+'/start').then(function(){
                    processTest();
                })
            }*/
                processTest();
            }
        },
        viewReport(test) {
            if (test.type == 'irat') {
                this.$router.push({
                    name: 'tests.irat-report',
                    params: { id: test.testStudent.uuid },
                });
            } else if (test.type == 'trat') {
                this.$router.push({
                    name: 'tests.trat-report',
                    params: { id: test.testStudent.uuid },
                });
            } else if (test.type == 'application') {
                this.$router.push({
                    name: 'tests.application-report',
                    params: { id: test.testStudent.uuid },
                });
            } else if (test.type == 'evaluation' || test.type == 'michaelsenEvaluation') {
                this.$router.push({
                    name: 'tests.evaluation-report',
                    params: { id: test.testStudent.uuid },
                });
            } else if (test.type == 'peerEvaluationV2') {
                this.$router.push({
                    name: 'tests.evaluation-report-v2',
                    params: { id: test.testStudent.uuid },
                });
            }
        },
        getDueDate(test) {
            if (test.type == 'prework' || test.type == 'evaluation' || test.type == 'michaelsenEvaluation') {
                let endDate = _.get(test, 'settings.endDate');
                if (endDate) {
                    return (
                        this.convertToReadableDate(endDate, 'DD MMM YYYY, hh:mm a').date +
                        ' (' +
                        this.convertToReadableDate(endDate, 'DD MMM YYYY, hh:mm a').current_timezone +
                        ')'
                    );
                } else {
                    return '';
                }
            } else '';
        },
        getStudentTestStatus(test) {
            return _.get(test, 'testStudent.myProgression.userTestStatus', 'not started');
        },
        searchModule() {
            Events.fire('activities_listing_refresh');
        },
        dropdownToggle(moduleIdx, button) {
            const buttonPosition = button.getBoundingClientRect().top;

            const tableHeight = this.testSearchData.data.data.map((_, moduleIdx) => this.$el.querySelectorAll('table')[moduleIdx].clientHeight)[moduleIdx];

            const tablePosition = this.testSearchData.data.data.map(
                (_, moduleIdx) => this.$el.querySelectorAll('table')[moduleIdx].getBoundingClientRect().top
            )[moduleIdx];

            if (Math.ceil(tableHeight + tablePosition) - 200 > Math.ceil(buttonPosition)) {
                $('.dropdown').removeClass('dropup');
            } else {
                $('.dropdown').addClass('dropup');
            }
        },
        openLoadingModal(test) {
            var that = this;

            if (['not started', 'scheduled'].includes(test.status) && !_.get(test, 'settings.allowSafeExamBrowserOnly')) {
                this.startTest(test);
            } else {
                if (test.testStudent.myProgression.userTestStatus == null && !['evaluation', 'michaelsenEvaluation'].includes(test.type)) {
                    $('#loadingScreenModal').modal('show');

                    window.setTimeout(function () {
                        $('#loadingScreenModal').modal('hide');
                        that.startTest(test);
                    }, 4100);
                } else {
                    this.startTest(test);
                }
            }
        },
        openAsyncModal(test) {
            this.isTest = test;

            if (['not started', 'scheduled'].includes(test.status) && !_.get(test, 'settings.allowSafeExamBrowserOnly')) {
                this.startTest(test);
            } else {
                if (test.testStudent.myProgression.userTestStatus == null) {
                    $('#asyncMessageModal').modal('show');
                } else {
                    this.startTest(test);
                }
            }
        },
        openCourseDetailsModal(module) {
            this.courseDetails = module;
            $('#courseDetailsModal').modal('show');
        },
    },
};
</script>

<style lang="scss" scoped>
.test-listing-row.selected {
    background-color: rgba(0, 255, 0, 0.3);
}
.btn.selected {
    background-color: yellow !important;
    color: black !important;
}

.new-studentTest-index-table-container {
    min-height: calc(100vh - 100px);
    .new-studentTest-index-table {
        margin-bottom: 30px;
        overflow: auto;
    }
}

.studentIndexGrid {
    display: flex;
    align-items: center;

    .btn {
        flex: auto;
    }
}

.marginLeft32px {
    margin-left: 32px;
}

.marginRight32px {
    margin-right: 32px;
}
</style>
