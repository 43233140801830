x
<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-12 col-sm-6">
                        <h1>Edit Teacher Profile</h1>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <teacher-form :form="form"></teacher-form>
                        <div class="profileBtns buttonGroup">
                            <button id="bottom-save-btn" class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Update" @click="submit()">
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link
                                v-if="!isFromAccount"
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'teachers.view',
                                    params: $route.params.id,
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                            <router-link
                                v-else
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: teacher.account.uuid },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            isSetAccount: true,
            isFromAccount: false,
            accounts: [],
            teacher: {},
            form: new KrForm({
                fields: {
                    avatar: '',
                    firstname: '',
                    lastname: '',
                    displayName: '',
                    email: '',
                    roleId: 'default!:3',
                    password: 'sendNull!:false',
                    password_confirmation: 'sendNull!:false',
                    identity: '',
                    canAddTeacher: '',
                    canUseExpressSignIn: '',
                    canViewQuestionBank: '',
                    academicDiscipline: 'sendNull!:false',
                    jobTitle: 'sendNull!:false',
                },
                props: {},
                urls: {
                    put: '/teachers/' + this.$route.params.id,
                },
            }),
        };
    },
    components: {
        'teacher-form': require(`./form-partials/partial.teacher.vue`).default,
    },
    computed: {
        canSend() {
            if (
                (!this.form.errors.any() &&
                    this.form.model.email &&
                    this.form.model.firstname &&
                    this.form.model.lastname &&
                    this.form.model.password &&
                    this.form.model.password_confirmation) ||
                !this.form.model.password
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        if (this.$route.name == 'accounts.teachers.edit') {
            this.isFromAccount = true;
        }
        this.fetch();
    },
    methods: {
        fetch() {
            var that = this;
            let route = '/teachers';
            if (that.$route.name == 'accounts.teachers.edit') {
                route = '/users';
            }
            axios.get(route + '/' + that.$route.params.id).then(function (user) {
                that.teacher = user.data.data;
                that.form.setModel(user.data.data);
                if (that.$route.name == 'accounts.teachers.edit') {
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            {
                                name: 'Account',
                                link: 'accounts.view',
                                params: { id: user.data.data.account.uuid },
                            },
                            { name: 'Edit Teacher' },
                        ],
                    });
                } else {
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            { name: 'Teachers', link: 'teachers.index' },
                            {
                                name: user.data.data.displayName,
                                link: 'teachers.view',
                                params: { id: user.data.data.uuid },
                            },
                            { name: 'Edit' },
                        ],
                    });
                }
                that.component_done_loading = true;
                // Both requests are now complete
            });
        },
        submit() {
            var that = this;
            this.form.model.displayName = this.form.model.firstname + (this.form.model.lastname ? ' ' + this.form.model.lastname : '');
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    if (!that.isFromAccount) {
                        that.$router.push({
                            name: 'teachers.view',
                            params: { id: that.$route.params.id },
                        });
                    } else {
                        that.$router.push({
                            name: 'accounts.teachers.view',
                            params: { id: that.$route.params.id },
                        });
                    }
                }
            });
        },
    },
};
</script>
