<template>
    <kr-panel v-if="component_done_loading" :with-footer="false">
        <template #title>
            <div class="col-xs-12 flexSpaceBetween align-items">
                <div class="col-xs-6">
                    <h2>LTI 1.3 SETTINGS</h2>
                </div>
            </div>
        </template>
        <template #content>
            <div class="col-xs-12 col-md-3 enrollmentSetting w-auto">
                <kr-select
                    class="no-error"
                    display="COURSE CONTEXT"
                    name="context_id"
                    :form="form"
                    :items="contexts"
                    item_value_idx="value"
                    item_desc_idx="description"
                    @changed="updateSettings"
                >
                    <template #option="props">
                        <option :value="null" disabled>Select an Option</option>
                        <template v-for="context in props.model">
                            <option :value="context.id">{{ context.title }} - {{ context.label }}</option>
                        </template>
                    </template>
                </kr-select>
            </div>
            <div class="col-xs-12 col-md-3 enrollmentSetting w-auto inlineFlex marginTop20">
                <div class="col-xs-10">
                    <kr-select
                        class="no-error"
                        display="TEACHER SYNC"
                        name="type"
                        :form="newForm"
                        :items="types"
                        item_value_idx="value"
                        item_desc_idx="description"
                    >
                        <template #option="props">
                            <option :value="null" disabled>Select Role</option>
                            <template v-for="type in props.model">
                                <option :value="type.value">{{ type.description }}</option>
                            </template>
                        </template>
                    </kr-select>
                </div>
                <div class="marginLeft20" style="padding-top: 16px">
                    <button class="btn btn-default marginTop10 marginLeft10" style="height: 44px" :disabled="!newForm.model.type" @click="syncTeachersFromLMS">
                        Sync
                    </button>
                </div>
            </div>
        </template>
    </kr-panel>
</template>
<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    context_id: '',
                },
            }),
            newForm: new KrForm({
                fields: {
                    type: '',
                },
            }),
            contexts: [],
            types: [
                { value: 'instructor', description: 'Instructor' },
                { value: 'administrator', description: 'Administrator' },
                { value: 'mentor', description: 'Mentor' },
                { value: 'grader', description: 'Grader' },
                { value: 'guest_learner', description: 'Guest Learner' },
            ],
        };
    },
    created() {
        var that = this;
        this.form.model.context_id = this.course.context_id;
        axios.get(`/lti/1p3/courses/${this.course.uuid}/contexts`).then(function (response) {
            that.contexts = response.data.data;
            if (that.contexts.length != 0) {
                that.component_done_loading = true;
            }
        });
    },
    methods: {
        updateSettings() {
            var that = this;
            axios
                .post(`/lti/1p3/courses/${this.course.uuid}/contexts`, {
                    context_id: this.form.model.context_id,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Context has been updated',
                    });
                })
                .catch(function (error) {
                    (that.form.model.context_id = null),
                        that.$notify({
                            group: 'form',
                            type: 'error',
                            title: 'Error',
                            text: 'Something went wrong, please try again later ',
                        });
                });
        },
        syncTeachersFromLMS() {
            var that = this;
            axios
                .post(`/lti/1p3/courses/${this.course.uuid}/teachers`, { type: this.newForm.model.type })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'teachers have been synched',
                    });
                })
                .catch(function (error) {
                    (that.form.model.context_id = null),
                        that.$notify({
                            group: 'form',
                            type: 'error',
                            title: 'Error',
                            text: 'Something went wrong, please try again later ',
                        });
                });
        },
    },
};
</script>
