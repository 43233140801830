<template>
    <div
        ref="modal"
        class="modal danger-modal deleteTemplateModal"
        data-backdrop="static"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteTemplateModal-title"
    >
        <div class="modal-dialog">
            <div v-if="loaded" class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 class="modal-title">Delete Template</h2>
                </div>
                <div class="modal-body textAlignCenter">
                    <img src="/img/warning.png" width="10%" class="warning-icon" alt=" " />

                    <h2 class="margin0 marginTop40">Delete</h2>

                    <h3 class="fw-normal">{{ template.name }}</h3>

                    <p class="marginTop20">You are about to remove</p>
                    <p>this from Templates.</p>

                    <p class="marginTop20">
                        This template and
                        <b class="important">all</b> contents
                    </p>

                    <p>will be <b class="important">delete permanently</b>.</p>

                    <br />
                    <p>
                        <b> Do you want to proceed? </b>
                    </p>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-outline-default" data-dismiss="modal" @click.prevent="closeAllModal()">Go Back</button>
                    <button class="btn btn-danger" @click="submit">Yes, Delete</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['deleted']);
import { notify } from '@kyvg/vue3-notification';
import { ref, nextTick } from 'vue';
const modal = ref(null);
const template = ref(null);

const loaded = ref(false);

function openModal() {
    nextTick(() => {
        $(modal.value).modal('show');
    });
}
function closeModal() {
    nextTick(() => {
        $(modal.value).modal('hide');
    });
}
function deleteTemplate(data = {}) {
    template.value = data;
    loaded.value = true;
    openModal();
}
function submit() {
    axios
        .delete(`templates/${template.value.uuid}`)
        .then(function (response) {
            emit('deleted');
            notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Template successfully deleted',
            });
            closeModal();
        })
        .catch(function (errors) {
            notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again later',
            });
            closeModal();
        });
}
defineExpose({ deleteTemplate });
</script>
