<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <h1>Add Teacher</h1>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <teacher-form :form="form" mode="create" :not_in_teachers="not_in_teachers"></teacher-form>
                        <div class="buttonGroup">
                            <button class="btn btn-success" :disabled="!canSend ? true : undefined" aria-label="Confirm" @click="submit">
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Confirm
                            </button>
                            <router-link
                                v-slot="{ navigate }"
                                :to="{
                                    name: 'courses.view',
                                    params: { id: $route.params.id },
                                    query: { tab: 'permission' },
                                }"
                                custom
                            >
                                <button class="btn btn-default" @click="navigate">Cancel</button>
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            auth: auth,
            form: new KrForm({
                fields: {
                    userUuids: 'type!:array',
                    accountType: 'default!:Full Access',
                },
                urls: {
                    post: '/courses/' + this.$route.params.id + '/users',
                },
            }),
            not_in_teachers: [],
            course: {},
        };
    },
    components: {
        'teacher-form': require(`./form-partials/partial.teacher.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.userUuids.length != 0 && this.form.model.accountType) {
                return true;
            }
            return false;
        },
    },
    created() {
        var that = this;
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
        });

        axios.all([axios.get('/courses/' + this.$route.params.id), axios.get('/courses/' + this.$route.params.id + '/users/not-in')]).then(
            axios.spread(function (course, teachers) {
                that.course = course.data.data;
                that.not_in_teachers = teachers.data.data;
                Events.fire('topbar_update', {
                    title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
                    breadcrumb: [
                        { name: 'Home' },
                        { name: 'Courses', link: 'courses.index' },
                        {
                            name: that.course.name + (that.course.code ? ' (' + that.course.code + ')' : ''),
                            link: 'courses.view',
                            params: { id: that.course.uuid },
                            query: { tab: 'permission' },
                        },
                        { name: 'Add Teacher' },
                    ],
                });
                that.component_done_loading = true;
            })
        );
    },
    methods: {
        submit() {
            var that = this;
            this.form.post().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: that.$route.params.id },
                        query: { tab: 'permission' },
                    });
                }
            });
        },
    },
};
</script>
<style scoped></style>
