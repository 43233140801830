<template>
    <div>
        <div :class="{ 'radioField-multipleRadio': !radioButtonVertical, radioField: radioButtonVertical }">
            <fieldset class="newRadioField smaller-ver">
                <legend>
                    <template v-if="display">
                        <span v-html="display"></span>
                        <template v-if="required">
                            <span class="visible-hidden">Required</span>
                            <span class="important" aria-hidden="true">*</span>
                        </template>
                    </template>
                    <template v-else>
                        {{ label }}
                    </template>
                </legend>

                <div v-if="radioButtonVertical" v-for="(item, idx) in items" :key="idx + '-radio-' + id" class="radioField flexOnly align-items">
                    <input
                        :id="id + '-' + item[item_value_idx]"
                        v-model="value"
                        type="radio"
                        :value="item[item_value_idx]"
                        :disabled="item._disabled"
                        @change="emit"
                        @keydown="form.errors.clear($event.target.name)"
                    />
                    <div class="flexOnly align-items">
                        <label :for="id + '-' + item[item_value_idx]" class="width100" v-html="item[item_desc_idx]" />
                        <span
                            class="badge badge-pill badge-info marginLeft5 marginTop5"
                            :class="{
                                newBadge: item.isNew,
                                betaBadge: item.isBeta,
                                legacyBadge: item.isLegacy || item.isComingSoon,
                            }"
                        >
                            <template v-if="item.isNew"> New </template>
                            <template v-else-if="item.isBeta"> BETA </template>
                            <template v-else-if="item.isLegacy"> Legacy </template>
                            <template v-else-if="item.isComingSoon"> Coming Soon </template>
                        </span>
                    </div>
                </div>

                <div v-else class="flex">
                    <div v-for="(item, idx) in items" :key="idx + '-radio-' + id" class="flex alignFlexStart marginBottom10 marginRight20">
                        <div class="flex">
                            <input
                                :id="id + '-' + item[item_value_idx]"
                                v-model="value"
                                type="radio"
                                :value="item[item_value_idx]"
                                :disabled="item._disabled"
                                @change="emit"
                                @keydown="form.errors.clear($event.target.name)"
                            />
                            <label :for="id + '-' + item[item_value_idx]" v-html="item[item_desc_idx]" class="fw-normal"> </label>
                        </div>

                        <span v-if="item.isNew" class="badge badge-pill badge-info marginLeft5 newBadge"> New </span>

                        <span v-if="item.isBeta" class="badge badge-pill badge-info marginLeft5 betaBadge"> BETA </span>

                        <span v-if="item.isLegacy" class="badge badge-pill badge-info marginLeft5 legacyBadge"> Legacy </span>

                        <span v-if="item.isComingSoon" class="badge badge-pill badge-info marginLeft5 legacyBadge"> Coming Soon </span>
                    </div>
                </div>
            </fieldset>
        </div>

        <span v-show="form.errors.has(name)" class="help-block">
            <strong>{{ form.errors.get(name) }}</strong>
        </span>
    </div>
</template>
<script>
export default {
    props: ['display', 'form', 'items', 'name', 'item_value_idx', 'item_desc_idx', 'options', 'label'],
    data() {
        return {
            value: null,
            id: '',
            required: false,
            radioButtonVertical: false,
        };
    },
    watch: {
        'form.model': {
            deep: true,
            handler(value, oldValue) {
                this.value = _.get(this.form.model, this.name, '');
            },
        },
    },
    created() {
        this.value = _.get(this.form.model, this.name);
        this.id = _.get(this, 'options.id', this.name);

        this.init();
    },
    methods: {
        emit(emit) {
            _.set(this.form.model, this.name, this.value);
            this.form.errors.clear(this.name);
            this.$emit('changed');
        },
        init() {
            //required
            var required = _.get(this, 'options.required', false);
            if (required) {
                this.required = required;
            }

            var radioButtonVertical = _.get(this, 'options.radioButtonVertical', false);
            if (radioButtonVertical) {
                this.radioButtonVertical = radioButtonVertical;
            }
        },
    },
};
</script>
