<template>
    <div>
        <kr-field
            :form="form"
            display="Show the following to students when there are no activities:"
            name="settings.defaultMessagesSettings.message"
            :options="{
                placeholder: 'test',
                htmlEditor: true,
                showError: false,
            }"
            @changed="$emit('debouncedUpdate')"
        />
    </div>
</template>
<script>
export default {
    props: ['form'],
};
</script>
