<template>
    <div>
        <div class="row">
            <div v-if="displayIsRequiredField" class="marginBottom30">
                <kr-toggle name="isRequired" display="Require Students To Answer This Question" :form="form" @changed="changedIsRequired()"></kr-toggle>
            </div>

            <div class="marginBottom30">
                <kr-field
                    display="Question Stem"
                    :form="form"
                    name="question"
                    :options="{
                        id: form.model.target + 'question',
                        htmlEditor: true,
                        htmlEditorOptions: { min_height: 400 },
                        showError: false,
                    }"
                    aria-label="Question Stem"
                ></kr-field>
            </div>

            <div class="marginBottom30">
                <kr-radio-multiple
                    :form="form"
                    name="type"
                    display="Question Type <span class='important' aria-label='required'>*</span>"
                    :items="types"
                    item_value_idx="value"
                    item_desc_idx="description"
                    :options="{ id: form.model.target + 'type' }"
                    label="Question Type please select at least 1 option"
                    @changed="changedType()"
                ></kr-radio-multiple>
            </div>

            <div id="sr-message-manage-question" aria-live="polite" class="sr-only"></div>

            <fieldset v-if="form.model.type == 'mcqs' || form.model.type == 'mcqm' || form.model.type == 'binary'" class="marginBottom30">
                <legend>
                    <b>Answer Choices</b>
                    <span class="important" aria-label="required">*</span>
                    <template v-if="form.model.type == 'mcqm'"> &nbsp; (Select 1 or more correct answer choices) </template>
                </legend>

                <span v-show="form.errors.has('options.correct')" class="help-block">
                    <strong>{{ form.errors.get('options.correct') }}</strong>
                </span>

                <template v-for="(option, idx) in form.model.options">
                    <div
                        :id="'choice-' + (idx + 1) + '-group'"
                        class="optionDiv"
                        :draggable="true"
                        tabIndex="-1"
                        :aria-label="'Answer Choices ' + (idx + 1) + ' of ' + form.model.options.length"
                        @drop="dropToOption($event, idx)"
                        @dragover="allowDropToOption"
                        @dragleave="leaveDragFromOption"
                        @dragstart="dragOption($event, idx)"
                        @drag="dragFunction"
                        @dragend="stopScroll = true"
                    >
                        <div class="flexOnly alignFlexStart">
                            <button
                                v-if="!isMobileView"
                                v-tooltip="'Drag Choice ' + (idx + 1)"
                                class="dots marginRight10"
                                :aria-label="'Drag Choice ' + (idx + 1)"
                            >
                                <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                            <div
                                :class="{
                                    'radio-custom': form.model.type == 'mcqs' || form.model.type == 'binary',
                                    'checkbox-custom': form.model.type == 'mcqm',
                                }"
                                class="marginRight10"
                            >
                                <label>
                                    <input
                                        v-model="option.isCorrect"
                                        v-tooltip="
                                            option.isCorrect
                                                ? 'Remove Choice ' + (idx + 1) + ' as correct answer'
                                                : 'Set Choice ' + (idx + 1) + ' as correct answer'
                                        "
                                        type="checkbox"
                                        @change="toggleIsCorrect(idx)"
                                    />
                                </label>
                            </div>
                            <div>
                                <kr-field
                                    :form="form"
                                    :name="'options.' + idx + '.content'"
                                    :options="{
                                        id: form.model.target + 'options.' + idx + '.content',
                                        placeholder: 'Please enter an answer choice',
                                        htmlEditor: true,
                                        hasLabel: false,
                                    }"
                                ></kr-field>
                            </div>
                            <div v-if="form.model.type != 'binary' && !isMobileView" class="marginLeft20 minWidth108px">
                                <template v-if="form.model.options.length > 2">
                                    <button
                                        v-tooltip="'Delete Choice ' + (idx + 1)"
                                        class="btn btn-default deleteBtn"
                                        :aria-label="'Delete Choice ' + (idx + 1)"
                                        @click.prevent="form.model.options[idx].content == '' ? removeOption(idx) : removeMessage(idx)"
                                    >
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                </template>

                                <button
                                    v-if="idx == form.model.options.length - 1"
                                    v-tooltip="'Add Choice ' + (idx + 2)"
                                    class="btn btn-default primaryHover"
                                    :aria-label="'Add Choice ' + (idx + 2)"
                                    @click.prevent="addOption()"
                                >
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                        <div v-if="form.model.type != 'binary' && isMobileView" class="marginBottom20 paddingLeft32">
                            <template v-if="form.model.options.length > 2">
                                <button
                                    class="btn btn-default deleteBtn"
                                    @click.prevent="form.model.options[idx].content == '' ? removeOption(idx) : removeMessage(idx)"
                                >
                                    <i class="fa fa-trash marginRight8" aria-hidden="true"></i> Delete Choice {{ idx + 1 }}
                                </button>
                            </template>

                            <button v-if="idx == form.model.options.length - 1" class="btn btn-default primaryHover" @click.prevent="addOption()">
                                <i class="fa fa-plus marginRight8" aria-hidden="true"></i> Add Choice {{ idx + 2 }}
                            </button>
                        </div>
                        <div
                            v-if="idx == option_idx"
                            :id="'choice-' + (idx + 1) + '-warning-dialog'"
                            class="marginTop10 marginBottom30"
                            :class="{
                                paddingLeft75: !isMobileView,
                                paddingLeft32: isMobileView,
                            }"
                            tabindex="-1"
                        >
                            <p>Are you sure you want to delete this answer choice {{ idx + 1 }}?</p>
                            <div class="marginTop10">
                                <button class="btn btn-default" @click.prevent="option_idx = -1">No, Cancel</button>
                                <button
                                    class="btn btn-danger"
                                    @click.prevent="
                                        removeOption(option_idx);
                                        option_idx = -1;
                                    "
                                >
                                    Yes, Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </fieldset>

            <div v-if="['mcqs', 'mcqm'].includes(form.model.type)" class="marginBottom30 displayInlineBlock" tabindex="5">
                <kr-toggle
                    name="retainLastOptionSequence"
                    display="Freeze Last Option Choice In Case Of Randomized Answer Choices"
                    :form="form"
                    :options="{
                        setWidth: true,
                    }"
                >
                </kr-toggle>
            </div>
            <div v-if="['mcqs', 'mcqm', 'openended'].includes(form.model.type) && displayIsElaborationRequiredField" class="marginBottom30" tabindex="5">
                <kr-toggle
                    name="isElaborationRequired"
                    :display="'Is Elaboration Required? ' + (!isInActivity ? '(This only applies for Application Case)' : '')"
                    :form="form"
                >
                </kr-toggle>
            </div>
        </div>

        <div v-if="form.model.type == 'rating'">
            <div class="marginBottom20">
                <label class="control-label">
                    <span>SET RATING SCALE</span>
                </label>
                <div class="flex align-items">
                    <div class="ratingScaleWidth">
                        <!-- <input type="range" min="2" max="10" v-model="form.model.range" class="form-control" @change="changedRange()"/> -->
                        <input
                            :id="form.model.target + 'range'"
                            v-model="form.model.range"
                            aria-hidden="true"
                            type="range"
                            min="2"
                            max="11"
                            class="sliders"
                            aria-label="Rating Scale"
                            @change="changedRange()"
                        />
                    </div>
                    <div class="width95px">
                        <kr-field
                            :form="form"
                            name="range"
                            :options="{
                                id: form.model.target + 'range',
                                type: 'number',
                                min: 2,
                                max: 11,
                                hasLabel: false,
                                showError: false,
                            }"
                            aria-label="Set Rating Scale"
                            @changed="changedRange()"
                        ></kr-field>
                    </div>
                </div>
            </div>
            <div>
                <div class="flexOnly">
                    <div class="ratingTextDiv">
                        <label class="control-label">
                            <span>TEXT LABEL</span>
                        </label>
                    </div>
                    <div class="ratingWeightDiv">
                        <label class="control-label">
                            <span>WEIGHT</span>
                        </label>
                    </div>
                    <div class="ratingRubricDiv">
                        <label class="control-label">
                            <span>RUBRIC (optional)</span>
                        </label>
                    </div>
                </div>
                <template v-for="(label, idx) in form.model.ratingSettings">
                    <div class="flexOnly">
                        <div class="ratingTextDiv">
                            <kr-field
                                :form="form"
                                :name="'ratingSettings.' + idx + '.label'"
                                :aria-label="'row ' + idx + ' label'"
                                :options="{
                                    id: form.model.target + 'ratingSettings.' + idx + '.label',
                                    hasLabel: false,
                                }"
                            ></kr-field>
                        </div>
                        <div class="ratingWeightDiv paddingLeft10 paddingRight10">
                            <kr-field
                                :form="form"
                                :name="'ratingSettings.' + idx + '.weight'"
                                :aria-label="'row ' + idx + ' weight'"
                                :options="{
                                    id: form.model.target + 'ratingSettings.' + idx + '.weight',
                                    type: 'number',
                                    min: 0,
                                    hasLabel: false,
                                    decimal: 2,
                                }"
                            ></kr-field>
                        </div>
                        <div class="col-xs-5">
                            <kr-field
                                :form="form"
                                :name="'ratingSettings.' + idx + '.rubric'"
                                :aria-label="'row ' + idx + ' rubric'"
                                :options="{
                                    id: form.model.target + 'ratingSettings.' + idx + '.rubric',
                                    hasLabel: false,
                                    placeholder: 'Describe your text label here',
                                }"
                            ></kr-field>
                        </div>
                        <!-- <div class="marginLeft20 buttonGroupInline">
	                        	<template v-if="idx==form.model.ratingSettings.length-1">
									<button v-tooltip="addRow" class="btn btn-default primaryHover " @click.prevent="addRange()">
										<i class="fa fa-plus"></i>
									</button>
								</template>
								<template v-if="form.model.ratingSettings.length>=2">
									<button v-tooltip="removeRow" class="btn btn-default deleteBtn " @click.prevent="deleteRange(idx)">
										<i class="fa fa-trash"></i>
									</button>
								</template>
	                       	</div> -->
                    </div>
                </template>
            </div>
        </div>

        <div v-if="form.model.type == 'openended'">
            <div v-if="form.model.target != 'peer'" class="questionsTopic marginTop0 marginBottom30">
                <label class="control-label">
                    <span>
                        ANSWER KEYWORDS -
                        <span class="fontSize12"> Enter keyword and press ENTER to save it </span>
                    </span>
                </label>
                <div class="questionsTopicCons">
                    <div v-for="(tag, idx) in form.model.tags" class="questionsTopicLabel marginBottom10 questionsSubs">
                        <span> <i class="fa fa-tag marginRight8" aria-hidden="true"></i>{{ tag }} </span>
                        <i class="fa fa-times marginLeft8" aria-hidden="true" @click.prevent="deleteTag(idx)"></i>
                    </div>
                    <input
                        :id="form.model.target + 'addTagField'"
                        class="form-control"
                        type="text"
                        placeholder="Keyword"
                        aria-label="Enter keyword and press ENTER to save it"
                        @keyup.enter="
                            addTag(getFieldValue(form.model.target + 'addTagField'));
                            clearFieldValue(form.model.target + 'addTagField');
                        "
                    />
                </div>
            </div>
            <!--<div class="openEndedPoints marginTop30">
					<kr-field display="MIN. WORDS (optional)"
				    :form="form" name="minimum" :options="{id:form.model.target+'minimum',type:'number',min:0,max:form.model.maximum}"></kr-field>
				</div>
				<div class="openEndedPoints">
					<kr-field display="MAX. WORDS (optional)"
				    :form="form" name="maximum" :options="{id:form.model.target+'maximum',type:'number',min:form.model.minimum}"></kr-field>
				</div>
				-->
            <div class="marginBottom30">
                <label class="control-label">
                    <span>Minimum and Maximum Words</span>
                </label>
                <!-- <div class="range-slider-minimum">
                    <input
                        v-model="sliderMin"
                        type="number"
                        :min="0"
                        :max="form.model.maximum"
                        step="1"
                        maxlength="3"
                        onKeyPress="if(this.value.length==3) return false;"
                        aria-label="Minimum Words"
                    />
                    <div class="range-slides" aria-hidden="true">
                        <input id="left_slider" v-model="sliderMin" type="range" :min="0" max="2000" step="1" aria-hidden="true" />
                        <div class="range-color" aria-hidden="true"></div>
                        <input id="right_slider" v-model="sliderMax" type="range" :min="0" max="2000" step="1" aria-hidden="true" />
                    </div>
                    <input v-model="sliderMax" type="number" :min="form.model.minimum >= 20 ? form.model.minimum : 20" max="2000" maxlength="3" step="1" />
                    <input
                        v-model="sliderMax"
                        type="number"
                        :min="form.model.minimum >= 3 ? form.model.minimum : 3"
                        max="2000"
                        step="1"
                        maxlength="3"
                        onKeyPress="if(this.value.length==3) return false;"
                        aria-label="Minimum Words"
                    />
                </div> -->

                <div class="flexOnly">
                    <div>
                        <kr-field
                            class="maxWidth100px marginRight20"
                            :form="form"
                            name="minimum"
                            aria-label="Minimum Words"
                            on-key-press="if(this.value.length==3) return false;"
                            :options="{
                                placeholder: 'Set Minimum',
                                type: 'number',
                                min: 0,
                                max: form.model.maximum,
                                hasLabel: false,
                                showError: false,
                                maxLength: 3,
                            }"
                        />
                    </div>
                    <div>
                        <kr-field
                            class="maxWidth100px"
                            :form="form"
                            name="maximum"
                            aria-label="Maximum Words"
                            on-key-press="if(this.value.length==3) return false;"
                            :options="{
                                placeholder: 'Set Maximum',
                                type: 'number',
                                min: form.model.minimum >= 3 ? form.model.minimum : 3,
                                max: 2000,
                                hasLabel: false,
                                showError: false,
                                maxLength: 3,
                            }"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="form.model.type == 'vsa'">
            <div>
                <kr-field
                    display="Measurement Unit"
                    :form="form"
                    name="vsaSettings.label"
                    aria-label="Measurement Unit"
                    :options="{ placeholder: 'e.g. metres' }"
                ></kr-field>
            </div>
            <table class="table hidden-xs hidden-sm" tabindex="0">
                <caption class="visible-hidden">
                    Correct Answers Table
                </caption>
                <thead>
                    <tr>
                        <th colspan="12" aria-hidden="true">Correct Answer(s)</th>
                    </tr>
                    <tr>
                        <th>
                            <label class="control-label capitalize m-0">
                                Fill in ONE answer per row
                                <span class="visible-hidden">Required</span>
                                <span class="important" aria-hidden="true">*</span>
                            </label>
                        </th>
                        <th class="width140px">
                            <p class="visible-hidden">Actions</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(correctAnswer, idx) in form.model.vsaSettings.correctAnswers" :key="'correct_answers_' + idx + '_' + updated">
                        <td>
                            <kr-field
                                :form="form"
                                :name="`vsaSettings.correctAnswers.${idx}`"
                                :options="{
                                    hasLabel: false,
                                    showError: false,
                                }"
                                :display="`Row ${idx} Correct Answer`"
                                @changed="form.model.vsaSettings.correctAnswers.splice()"
                            ></kr-field>

                            <div v-if="'correct_answer_vsa_' + idx == option_idx" class="marginTop10">
                                <div class="flex align-items">
                                    <div>Are you sure you want to delete this as correct answer?</div>
                                    <div class="flex marginLeft10">
                                        <button
                                            class="btn btn-danger"
                                            aria-label="Yes, Delete"
                                            @click.prevent="
                                                deleteCorrectAnswer(option_idx);
                                                option_idx = -1;
                                            "
                                        >
                                            Yes, Delete
                                        </button>
                                        <button class="btn btn-default" aria-label="No, Cancel" @click.prevent="option_idx = -1">No, Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td aria-describedby="action_header">
                            <div class="buttonGroupInline">
                                <button
                                    v-if="form.model.vsaSettings.correctAnswers.length > 1"
                                    v-tooltip="'Delete Correct Answer'"
                                    class="btn btn-default deleteBtn"
                                    :aria-label="`Delete row ${idx}`"
                                    @click.prevent="option_idx = 'correct_answer_vsa_' + idx"
                                >
                                    <i class="fas fa-trash" aria-hidden="true"></i>
                                </button>

                                <button
                                    v-if="idx == form.model.vsaSettings.correctAnswers.length - 1"
                                    v-tooltip="'Add new row for correct answer'"
                                    class="btn btn-default primaryHover"
                                    :aria-label="'Add Row ' + (idx + 1)"
                                    @click.prevent="addMoreCorrectAnswer()"
                                >
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="table hidden-xs hidden-sm" tabindex="0">
                <caption class="visible-hidden">
                    Wrong Answers Table
                </caption>
                <thead>
                    <tr>
                        <th colspan="12" aria-hidden="true">Wrong Answer(s)</th>
                    </tr>
                    <tr>
                        <th>
                            <label class="control-label capitalize m-0"> Fill in ONE answer per row </label>
                        </th>
                        <th class="width140px">
                            <p class="visible-hidden">Actions</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(wrongAnswer, idx) in form.model.vsaSettings.wrongAnswers" :key="'wrong_answers_' + idx + '_' + updated">
                        <td>
                            <kr-field
                                :form="form"
                                :name="`vsaSettings.wrongAnswers.${idx}`"
                                :options="{
                                    hasLabel: false,
                                    showError: false,
                                }"
                                :display="`Row ${idx} Wrong Answer`"
                                @changed="form.model.vsaSettings.wrongAnswers.splice()"
                            ></kr-field>

                            <div v-if="'wrong_answer_vsa_' + idx == option_idx" class="marginTop10">
                                <div class="flex align-items">
                                    <div>Are you sure you want to delete this as wrong answer?</div>
                                    <div class="flex marginLeft10">
                                        <button
                                            class="btn btn-danger"
                                            aria-label="Yes, Delete"
                                            @click.prevent="
                                                deleteWrongAnswer(option_idx);
                                                option_idx = -1;
                                            "
                                        >
                                            Yes, Delete
                                        </button>
                                        <button class="btn btn-default" aria-label="No, Cancel" @click.prevent="option_idx = -1">No, Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td aria-describedby="action_header">
                            <div class="buttonGroupInline">
                                <button
                                    v-if="form.model.vsaSettings.wrongAnswers.length > 1"
                                    v-tooltip="'Delete Wrong Answer'"
                                    class="btn btn-default deleteBtn"
                                    :aria-label="`Delete Row ${idx}`"
                                    @click.prevent="option_idx = 'wrong_answer_vsa_' + idx"
                                >
                                    <i class="fas fa-trash" aria-hidden="true"></i>
                                </button>

                                <button
                                    v-if="idx == form.model.vsaSettings.wrongAnswers.length - 1"
                                    v-tooltip="'Add new row for wrong answer'"
                                    class="btn btn-default primaryHover"
                                    :aria-label="'Add Row ' + (idx + 1)"
                                    @click.prevent="addMoreWrongAnswer()"
                                >
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="alert alert-warning marginBottom20 flexOnly align-items">
                <i class="fas fa-exclamation-circle marginRight5" aria-hidden="true"></i>
                Answers not defined above are marked as wrong
            </div>
        </div>

        <matchComponent v-if="form.model.type == 'matching'" ref="matchingForm" v-model:options="form.model.options" @modal_idx="modal_id"></matchComponent>
        <sequenceComponent v-if="form.model.type == 'sequence'" v-model:options="form.model.options" @modal_idx="modal_id"></sequenceComponent>

        <div>
            <kr-file-multiple :form="form" name="attachments" display="Attachments (optional)" @preview-attachment="showAttachment"></kr-file-multiple>
        </div>

        <div v-if="['mcqs'].includes(form.model.type)" class="faculityNotes">
            <kr-field
                display="Hint (Appears when student selects wrong answer if Hints And Explanations option is enabled)"
                :form="form"
                name="hint"
                aria-label="Hint"
                :options="{
                    id: form.model.target + 'hint',
                    htmlEditor: true,
                    htmlEditorOptions: {
                        plugins: 'advlist autolink link lists charmap preview autoresize code',
                        toolbar2:
                            'undo redo | styleselect | bold italic removeformat underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect | link ',
                    },
                    showError: false,
                }"
            ></kr-field>
        </div>

        <div v-if="['mcqs'].includes(form.model.type)" class="faculityNotes">
            <kr-field
                display="Explanation (Appears when student selects correct answer if Hints and Explanations option is enabled"
                :form="form"
                name="explanation"
                aria-label="Explanation"
                :options="{
                    id: form.model.target + 'explanation',
                    htmlEditor: true,
                    htmlEditorOptions: {
                        plugins: 'advlist autolink link lists charmap preview autoresize code',
                        toolbar2:
                            'undo redo | styleselect | bold italic removeformat underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect | link ',
                    },
                    showError: false,
                }"
            ></kr-field>
        </div>

        <!-- <div
      class="qns_allAttachments"
      v-if="
        form.model.type == 'openended' && displayCanAllowStudentAttachmentsField
      "
    >
      <kr-toggle
        name="canAllowStudentAttachments"
        display="ALLOW STUDENTS TO UPLOAD FILES"
        :form="form"
      >
      </kr-toggle>
    </div> -->

        <div v-if="form.model.type == 'openended' && displayCanAllowStudentRichTextResponsesField" class="qns_allAttachments">
            <kr-toggle name="canAllowStudentRichTextResponses" display="Allow Rich Text Responses" :form="form"> </kr-toggle>
        </div>

        <div v-if="form.model.target != 'peer'" class="difficultyLevel">
            <label class="control-label">
                Difficulty Level
                <i
                    id="difficultyLvl"
                    v-tooltip.right="{
                        content: difficultyLevels,
                        classes: 'difficultyLvl',
                    }"
                    class="fa fa-info-circle"
                ></i>
            </label>
            <kr-field
                :form="form"
                name="difficultyLevel"
                aria-label="set difficulty level"
                :options="{
                    id: form.model.target + 'difficultyLevel',
                    type: 'number',
                    min: 1,
                    max: 6,
                    hasLabel: false,
                    showError: false,
                }"
            >
            </kr-field>
        </div>

        <div class="questionsTopic">
            <label class="control-label" aria-label="Enter topic and press ENTER to save it">
                <span> Topic (Enter topic and press ENTER to save it) </span>
            </label>
            <div class="questionsTopicCons">
                <div v-for="(topic, idx) in form.model.topics" class="questionsTopicLabel marginBottom10 questionsSubs" :aria-label="topic">
                    <span> <i class="fa fa-tag marginRight8" aria-hidden="true"></i>{{ topic }} </span>
                    <i class="fa fa-times marginLeft8" aria-hidden="true" @click.prevent="deleteTopic(idx)"></i>
                </div>
                <input
                    :id="form.model.target + 'addTopicField'"
                    v-model="topic"
                    class="form-control widthAuto"
                    type="text"
                    placeholder="Topic"
                    aria-label="Enter topic and press ENTER to save it"
                    @keyup.enter="
                        addTopic(topic);
                        topic = '';
                        clearFieldValue(form.model.target + 'addTopicField');
                        getTopicList();
                    "
                />
                <!--
                <vue-simple-suggest
                    v-model="topic"
                    display-attribute="topic"
                    value-attribute="topic"
                    :debounce="150"
                    :list="getTopicList"
                    aria-label="Enter topic and press ENTER to save it"
                >
                    <input
                        :id="form.model.target + 'addTopicField'"
                        class="form-control"
                        type="text"
                        placeholder="Topic"
                        aria-label="Enter topic and press ENTER to save it"
                        @keyup.enter="
                            addTopic(topic);
                            topic = '';
                            clearFieldValue(form.model.target + 'addTopicField');
                        "
                    />
                </vue-simple-suggest>-->
                <!-- <div
          style="margin-top: 10px; display: inline-flex; align-items: center"
        >
          <div style="margin-left: 10px">
            <button
              class="btn addCircleBtn"
              style="border-radius: 50%"
              @click.prevent="addTopic()"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div> -->
            </div>
        </div>
        <div class="faculityNotes">
            <kr-field
                display="Question Notes - Viewable to teachers only (optional)"
                :form="form"
                name="remarks"
                :options="{
                    id: form.model.target + 'remarks',
                    type: 'text-area',
                }"
            ></kr-field>
        </div>
        <div v-if="displayAddToQuestionBankField" class="marginBottom30 displayInlineBlock">
            <kr-toggle
                :tooltip-content="form.model.referenceQuestionUuid ? 'This question is in Question Bank ' : ''"
                name="addToQuestionBank"
                display="Add To Question Bank"
                :form="form"
                :disabled="(form.model.referenceQuestionUuid ? true : false) ? true : undefined"
            >
            </kr-toggle>
        </div>

        <div id="previewModal" class="modal default-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="previewModal-title">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="previewModal-title" class="modal-title">Preview Attachment</h2>
                    </div>
                    <div class="modal-body" style="height: 75vh">
                        <div class="row" style="height: 100%">
                            <div class="col-xs-12" style="text-align: center; height: 100%">
                                <iframe :src="preview_url" width="100%" height="100%"></iframe>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from './../../../../components/auth/auth.js';
import matchComponent from './question-type-partials/matching';
import sequenceComponent from './question-type-partials/sequence';

export default {
    components: {
        matchComponent,
        sequenceComponent,
    },
    props: [
        'modal_id',
        'form',
        'question',
        'question_no_label',
        'allowedTypes',
        'displayAddToQuestionBankField',
        'displayIsRequiredField',
        'displayCanAllowStudentAttachmentsField',
        'displayCanAllowStudentRichTextResponsesField',
        'displayIsElaborationRequiredField',
    ],
    data() {
        return {
            auth: new KrAuth(),
            preview_url: '',
            difficultyLevels: `
                          <div class="textAlignLeft marginBottom5">
                            Levels
                          </div>
                          <div class="flexSpaceBetween margin2">
                            <div class="lightGreen">1</div>
                            <div class="green">2</div>
                            <div class="yellow">3</div>
                            <div class="orange">4</div>
                            <div class="red">5</div>
                            <div class="maroon">6</div>
                          </div>
                          <div class="marginTop5 flexOnly align-items">
                            Easy <div class="marginLeft10 marginRight10 lvlBreak"></div> Hard
                          </div>
                        `,
            option_idx: -1,
            topic: '',
            stopScroll: true,
            updated: 0,

            //minAngle: 10,
            //maxAngle: 30
        };
    },
    computed: {
        isInActivity() {
            if (this.$route.name != 'questions.index') {
                return true;
            }
            return false;
        },
        hasSelectedAnswerChoice() {
            if (['mcqs', 'mcqm'].includes(this.form.model.type)) {
                for (var i = 0; i < this.form.model.options.length; i++) {
                    if (this.form.model.options[i].isCorrect) {
                        return true;
                    }
                }
            }
            return false;
        },
        types() {
            var ret = [];
            let allTypes = [
                { value: 'mcqs', description: 'MCQ (single correct answer)' },
                { value: 'mcqm', description: 'MCQ (multiple correct answer)' },
                { value: 'rating', description: 'Rating Scale' },
                { value: 'openended', description: 'Free response' },
                {
                    value: 'vsa',
                    description: 'VSA (very short answer)',
                },
                { value: 'matching', description: 'Matching', isBeta: true },
                { value: 'sequence', description: 'Sequence', isBeta: true },
            ];
            /*if (
        process.env.VUE_APP_ENV == "beta" ||
        process.env.VUE_APP_ENV == "staging" ||
        (process.env.VUE_APP_ENV == "production" &&
          [
            "08299f88-6223-49aa-84e7-1958d703de6e",
            "8aa59c83-fe72-4f77-afb3-3c3f252febe3",
            "8726d872-408c-4932-8e78-809ccd1a9a1a",
            "c5a47ef7-eba8-4d7c-805c-3b6cc6abb53c",
          ].includes(this.auth.user().account.uuid))
      ) {
        allTypes.push({
          value: "vsa",
          description: "VSA (very short answer)",
          isBeta: true,
        });
      }*/

            if (this.allowedTypes == '') {
                if (!this.form.model.type) {
                    this.form.model.type = 'mcqs';
                    this.changedType();
                }
                return allTypes;
            }

            var allowedTypes = this.allowedTypes.split(',');

            for (var i = 0; i < allowedTypes.length; i++) {
                let option = _.find(allTypes, function (o) {
                    return o.value == allowedTypes[i];
                });

                if (option) {
                    ret.push(option);
                }
            }
            var that = this;
            if (
                _.findIndex(ret, function (o) {
                    return o.value == that.form.model.type;
                }) == -1
            ) {
                this.form.model.type = ret[0].value;
                this.changedType();
            }
            return ret;
        },
        sliderMin: {
            get: function () {
                var val = parseInt(this.form.model.minimum);
                return val;
            },
            set: function (val) {
                val = parseInt(val);
                if (val > this.form.model.maximum) {
                    this.form.model.minimum = this.form.model.maximum;
                    this.sliderMin = this.form.model.maximum;
                } else {
                    this.form.model.minimum = val;
                }
                this.calcTheWidth();
            },
        },
        sliderMax: {
            get: function () {
                var val = parseInt(this.form.model.maximum);
                return val;
            },
            set: function (val) {
                val = parseInt(val);
                if (val < this.form.model.minimum) {
                    this.form.model.maximum = this.form.model.minimum;
                    this.sliderMax = this.form.model.minimum;
                } else {
                    if (isNaN(val)) {
                        val = 3;
                        this.sliderMax = 3;
                        this.form.model.maximum = 3;
                    } else if (val > 2000) {
                        val = 2000;
                        this.sliderMax = 2000;
                        this.form.model.maximum = 2000;
                    } else if (val < 3) {
                        val = 3;
                        this.sliderMax = 3;
                        this.form.model.maximum = 3;
                    } else {
                        this.form.model.maximum = val;
                    }
                }
                this.calcTheWidth();
            },
        },
        filteredTopics() {
            return this.form.model.topics.filter((topic) => topic && topic.trim() !== '');
        },
    },
    watch: {
        form: {
            handler: function () {
                /*Force*/
                this.form.model.questionIsHTML = true;
            },
            deep: true,
        },
        'form.model.range': {
            handler: function () {
                this.colorSlider();
            },
            deep: true,
        },
    },
    beforeUnmount() {
        $('#previewModal').modal('hide');
    },
    mounted() {
        var that = this;
        Vue.nextTick(function () {
            that.calcTheWidth();
        });
    },
    methods: {
        dragFunction(e) {
            var that = this;
            var scroll = function (step) {
                var scrollY = $(`#${that.modal_id} .modal`).scrollTop();
                $(`#${that.modal_id} .modal`).scrollTop(scrollY + step);
                if (!that.stopScroll) {
                    setTimeout(function () {
                        scroll(step);
                    }, 20);
                }
            };
            this.stopScroll = true;

            if (e.clientY < 150) {
                this.stopScroll = false;
                scroll(-1);
            }
            if (e.clientY > $(window).height() - 150) {
                this.stopScroll = false;
                scroll(1);
            }
        },
        getTopicList() {
            return axios('/accounts/' + this.auth.user().account.uuid + '/topics?q=' + this.topic, { _internal: { hasLoadingBar: false } })
                .then((res) => res.data.data)
                .catch((err) => console.error(err));
        },
        array_move(arr, old_index, new_index) {
            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr; // for testing
        },
        leaveDragFromOption(event) {
            var target = $(event.target).closest('.optionDiv')[0];
            if ($(this.currentDraggedItem).hasClass('optionDiv')) {
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
            }
        },
        dropToOption(event, idx) {
            //alert(JSON.stringify(event));
            var data = JSON.parse(event.dataTransfer.getData('text'));
            if (data.from == 'option') {
                var target = $(event.target).closest('.optionDiv')[0];
                $(target).removeClass('selected-bottom');
                $(target).removeClass('selected-top');
                this.array_move(this.form.model.options, data.idx, idx);

                for (var i = 0; i < this.form.model.options.length; i++) {
                    let key = String.fromCharCode(65 + i);
                    this.form.model.options[i].key = key;
                }
                //alert(idx +':' +data.idx);
                //this.moveQuestionOrder(data.question.group-1,qGroupIdx,data.question.order-1,question_idx);
                /*if($(target).hasClass('selected-bottom')){
            alert('selected bottom');

            $(target).removeClass('selected-bottom');
          }
          if($(target).hasClass('selected-top')){
            alert('selected top' +question_idx)
            this.moveQuestionOrder(data.question.group-1,qGroupIdx,data.question.order-1,question_idx);
            $(target).removeClass('selected-top');
          }

          /*let question = data.question;
          let old_idx = _.findIndex(this.questionGroups[question.group - 1],function(o){
            return o.uuid ==question.uuid;
          });
          this.questionGroups[question.group - 1].splice(old_idx,1);
          question.order = this.questionGroups[qGroupIdx].length+1;
          question.group = qGroupIdx +1;
          this.questionGroups[qGroupIdx].push(question);*/
            }
            //clear draggedItem
            this.currentDraggedItem = null;
        },
        allowDropToOption(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
            //window.requestAnimationFrame(function(){
            //qgroup to qgroup sorting.
            if ($(this.currentDraggedItem).hasClass('optionDiv')) {
                var target = $(event.target).closest('.optionDiv')[0];
                if (!target.isSameNode(this.currentDraggedItem)) {
                    var bounding = target.getBoundingClientRect();
                    var offset = bounding.y + bounding.height / 2;
                    if (event.clientY - offset > 0) {
                        $(target).removeClass('selected-top');
                        $(target).addClass('selected-bottom');
                    } else {
                        $(target).removeClass('selected-bottom');
                        $(target).addClass('selected-top');
                    }
                }
            }
        },
        dragOption(event, idx, options) {
            event.dataTransfer.setData('text', JSON.stringify({ from: 'option', idx: idx }));
            event.dataTransfer.dropEffect = 'move';
            this.currentDraggedItem = $(event.target).closest('.optionDiv')[0];
        },
        clearFieldValue(id) {
            $('#' + id).val('');
        },
        getFieldValue(id) {
            return $('#' + id).val();
        },
        showAttachment(url) {
            //this.preview_url = url;
            //$('#previewModal').modal('show');
        },
        colorSlider() {
            var that = this;
            if ($('#' + that.form.model.target + 'range') == null) {
                return false;
            }
            Vue.nextTick(function () {
                let input = $('#' + that.form.model.target + 'range')[0];
                var value = (input.value - input.min) / (input.max - input.min);
                input.style.backgroundImage = [
                    '-webkit-gradient(',
                    'linear, ',
                    'left top, ',
                    'right top, ',
                    'color-stop(' + value + ', #0071be), ',
                    'color-stop(' + value + ', #c5d0de)',
                    ')',
                ].join('');
            });
        },
        changedIsRequired() {
            if (this.form.model.isRequired) {
                this.form.model.minimum = 1;
            } else {
                this.form.model.minimum = 0;
            }
        },
        changedRange() {
            let labels = {
                2: ['Disagree', 'Agree'],
                3: ['Disagree', 'Neither agree nor disagree', 'Agree'],
                4: ['Strongly Disagree', 'Disagree', 'Agree', 'Strongly Agree'],
                5: ['Strongly Disagree', 'Disagree', 'Neither agree nor disagree', 'Agree', 'Strongly agree'],
                6: ['Very Strongly Disagree', 'Strongly Disagree', 'Disagree', 'Agree', 'Strongly Agree', 'Very Strongly Agree'],
                7: ['Very Strongly Disagree', 'Strongly Disagree', 'Disagree', 'Neither agree nor disagree', 'Agree', 'Strongly Agree', 'Very Strongly Agree'],
                8: ['Very Strongly Disagree', '', '', 'Disagree', 'Agree', '', '', 'Strongly Agree'],
                9: ['Very Strongly Disagree', '', '', '', 'Neither agree nor disagree', '', '', '', 'Very Strongly Agree'],
                10: ['Very Strongly Disagree', '', '', '', 'Disagree', 'Agree', '', '', '', 'Very Strongly Agree'],
                11: ['Very Strongly Disagree', '', '', '', '', 'Neither agree nor disagree', '', '', '', '', 'Very Strongly agree'],
            };

            this.form.model.ratingSettings = [];
            let hasDefaultLabel = false;
            if (this.form.model.range < 12 && this.form.model.range > 1) {
                hasDefaultLabel = true;
            }
            for (var i = 0; i < this.form.model.range; i++) {
                let label = '';
                if (hasDefaultLabel) {
                    label = labels[this.form.model.range][i];
                }
                this.form.model.ratingSettings.push({
                    label: label,
                    weight: i + 1,
                    rubric: '',
                });
            }
        },
        changedType() {
            this.form.model.ratingSettings = [];

            if (this.form.model.type == 'mcqs' || this.form.model.type == 'mcqm' || this.form.model.type == 'binary') {
                if (!_.isEmpty(this.form.model.options)) {
                    for (var i = 0; i < this.form.model.options.length; i++) {
                        this.form.model.options[i].isCorrect = false;
                    }
                    return;
                }
                this.form.model.options = [];
                if (this.form.model.type == 'mcqs' || this.form.model.type == 'mcqm') {
                    this.addOption({ isCorrect: false });
                    this.addOption();
                    this.addOption();
                    this.addOption();
                    this.form.model.canAllowStudentAttachments = false;
                    this.form.model.canAllowStudentRichTextResponses = false;
                }
                if (this.form.model.type == 'binary') {
                    this.addOption({ isCorrect: false });
                    this.addOption();
                    this.form.model.options[0].key = 'A';
                    this.form.model.options[0].content = 'true';
                    this.form.model.options[0].isCorrect = false;
                    this.form.model.options[1].key = 'B';
                    this.form.model.options[1].content = 'false';
                }
            }
            if (this.form.model.type == 'openended') {
                this.form.model.options = [];
                this.form.model.tags = [];
                this.form.model.minimum = this.form.model.isRequired ? 1 : 0;
                this.form.model.maximum = 100;
                this.form.model.canAllowStudentAttachments = false;
                this.form.model.canAllowStudentRichTextResponses = true;
            }
            if (this.form.model.type == 'rating') {
                this.form.model.canAllowStudentAttachments = false;
                this.form.model.options = [];
                let label = ['Strongly disagree', 'Disagree', 'Neither agree nor disagree', 'Agree', 'Strongly agree'];
                this.form.model.range = 5;
                for (var i = 0; i < this.form.model.range; i++) {
                    this.form.model.ratingSettings.push({
                        label: label[i],
                        weight: i,
                        rubric: '',
                    });
                }
            }
            if (this.form.model.type == 'vsa') {
                this.form.model.options = [];
                this.form.model.tags = [];
                this.form.model.minimum = this.form.model.isRequired ? 1 : 0;
                this.form.model.maximum = 100;
                this.form.model.canAllowStudentAttachments = false;
                this.form.model.canAllowStudentRichTextResponses = true;
                this.form.model.vsaSettings.label = '';
                this.form.model.vsaSettings.correctAnswers = [];
                this.form.model.vsaSettings.wrongAnswers = [];
                this.addMoreCorrectAnswer(-1);
                this.addMoreWrongAnswer(-1);
            }
            if (this.form.model.type == 'matching') {
                this.form.model.options = {
                    matchingType: '',
                    matches: [
                        {
                            prompt: {
                                content: '',
                            },
                            answer: [
                                {
                                    content: '',
                                },
                            ],
                        },
                    ],
                    labels: { prompt: '', answer: '' },
                };
            }
            if (this.form.model.type == 'sequence') {
                this.form.model.options = [
                    { order: 1, content: '' },
                    { order: 2, content: '' },
                    { order: 3, content: '' },
                    { order: 4, content: '' },
                ];
            }
        },
        toggleIsCorrect(idx) {
            var that = this;
            if (this.form.model.type == 'mcqs' || this.form.model.type == 'binary') {
                //limit to only 1 correct answer
                if (this.form.model.options[idx].isCorrect) {
                    let temp_idx = _.findIndex(this.form.model.options, function (o) {
                        return o.isCorrect && o.key != that.form.model.options[idx].key;
                    });
                    if (temp_idx != -1 && temp_idx != idx) {
                        this.form.model.options[temp_idx].isCorrect = false;
                    }
                }
            }
            if (this.form.model.type == 'mcqm') {
                //anything goes
            }
        },
        addOption(options = {}) {
            let length = this.form.model.options.length == 0 ? 1 : this.form.model.options.length + 1;
            let key = String.fromCharCode(65 + length - 1);
            this.form.model.options.push({
                key: key,
                tags: [],
                content: '',
                remarks: '',
                isCorrect: options.isCorrect ? true : false,
                contentIsHTML: true,
            });

            $('#sr-message-manage-question').text('');
            const message = 'Added choice ' + this.form.model.options.length;
            setTimeout(() => {
                $('#sr-message-manage-question').text(message);
            }, 100);
            this.$nextTick(() => {
                $('#choice-' + this.form.model.options.length + '-group').focus();
            });
        },
        removeOption(idx) {
            $('#sr-message-manage-question').text('');

            const message = 'Removed Choice ' + (idx + 1);

            setTimeout(() => {
                $('#sr-message-manage-question').text(message);
            }, 100);

            this.form.model.options.splice(idx, 1);
            for (var i = 0; i < this.form.model.options.length; i++) {
                this.form.model.options[i].key = String.fromCharCode(65 + i);
            }
        },
        removeMessage(idx) {
            $('#sr-message-manage-question').text('');

            const message = 'Choice ' + (idx + 1) + ' dialog appear';

            setTimeout(() => {
                $('#sr-message-manage-question').text(message);
            }, 100);

            this.option_idx = idx;

            this.$nextTick(() => {
                $('#choice-' + (idx + 1) + '-warning-dialog').focus();
            });
        },
        addTag(data = '') {
            this.form.model.tags.push(data);
        },
        deleteTag(idx) {
            this.form.model.tags.splice(idx, 1);
        },
        addTopic(data = '') {
            var justSpacingOnly = /^\s*$/.test(data);
            if (!justSpacingOnly) {
                this.form.model.topics.push(data);
            }
        },
        deleteTopic(idx) {
            this.form.model.topics.splice(idx, 1);
        },
        addRange() {
            this.form.model.ratingSettings.push({
                label: '',
                weight: this.form.model.ratingSettings.length + 1,
                rubric: '',
            });
            this.form.model.range = this.form.model.ratingSettings.length;
        },
        deleteRange(idx) {
            this.form.model.ratingSettings.splice(idx, 1);
            this.form.model.range = this.form.model.ratingSettings.length;
        },
        calcTheWidth() {
            var sliderX;
            sliderX = parseFloat(100 / 2000);

            var leftSliderY = sliderX * this.sliderMin;
            $('.range-color').css('left', leftSliderY + '%');

            var rightSliderY = sliderX * this.sliderMax;
            $('.range-color').css('width', rightSliderY - leftSliderY + '%');
            $('.range-color').css('max-width', '99.8%');
        },
        addMoreCorrectAnswer(idx) {
            if (idx == -1 && this.form.model.vsaSettings.correctAnswers.length == 0) {
                this.form.model.vsaSettings.correctAnswers.push('');
            } else if (this.form.model.vsaSettings.correctAnswers[idx] != '' && this.form.model.vsaSettings.correctAnswers.length == idx + 1) {
                this.form.model.vsaSettings.correctAnswers.push('');
            }

            if (idx == undefined) {
                this.form.model.vsaSettings.correctAnswers.push('');
            }

            this.$forceUpdate();
        },
        deleteCorrectAnswer(idx) {
            idx = idx.split('correct_answer_vsa_');

            if (this.form.model.vsaSettings.correctAnswers.length > idx[1]) {
                this.form.model.vsaSettings.correctAnswers.splice(idx[1], 1);
                this.$forceUpdate();
                this.updated++;
            }
        },
        addMoreWrongAnswer(idx) {
            if (idx == -1 && this.form.model.vsaSettings.wrongAnswers.length == 0) {
                this.form.model.vsaSettings.wrongAnswers.push('');
            } else if (this.form.model.vsaSettings.wrongAnswers[idx] != '' && this.form.model.vsaSettings.wrongAnswers.length == idx + 1) {
                this.form.model.vsaSettings.wrongAnswers.push('');
            }

            if (idx == undefined) {
                this.form.model.vsaSettings.wrongAnswers.push('');
            }

            this.$forceUpdate();
        },
        deleteWrongAnswer(idx) {
            idx = idx.split('wrong_answer_vsa_');

            if (this.form.model.vsaSettings.wrongAnswers.length > idx[1]) {
                this.form.model.vsaSettings.wrongAnswers.splice(idx[1], 1);
                this.$forceUpdate();
                this.updated++;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.qns_Qns,
.qnsBuilderQnsOptions,
.qns_Attachment,
.qns_allAttachments,
.difficultyLevel,
.questionsTopic,
.faculityNotes {
    margin-top: 30px;
}

.hoverTooltip {
    width: 200px;
    color: #000;
    text-align: center;
    padding: 5px;
    border-radius: 3px;
    background: #fff;
    border: solid 1px #e69618;
}

.tags {
    width: auto;
    padding: 10px;
    height: 36px;
    border-radius: 3px;
    background-color: rgba(71, 166, 71, 0.1);
}

.questionsTopicLabel {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.questionsTopicLabel span {
    line-height: 1;
}

.questionsTopicLabel .fa-times {
    font-size: 12px;
    color: #717171;
}

.questionsTopic.marginTop0 {
    margin-top: 0;
}

@media (min-width: 992px) {
    .questionsTopicCons {
        display: flex;
        flex-wrap: wrap;
    }

    .questionsTopicLabel,
    .questionsTopicCons input {
        min-width: 150px;
    }

    .questionsSubs {
        margin-right: 20px;
    }
}

@media (max-width: 991px) {
    .questionsTopicLabel,
    .questionsTopicCons input {
        width: 100%;
    }

    .questionsSubs {
        margin-top: 10px;
    }

    .questionsSubs:first-child {
        margin-top: 0;
    }
}

.modal .modal-body .row:last-child {
    margin-top: 20px;
}

.modal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
.modal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
</style>

<style lang="scss">
.difficultyLvl .lightGreen {
    background: #3c7858;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .green {
    background: #869b0f;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .yellow {
    background: #e6bd18;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .orange {
    background: #c27c23;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .red {
    background: #ca3333;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .maroon {
    background: #99001c;
    color: #fff;
    padding: 2px 10px;
    line-height: 1;
    font-size: 10px;
}

.difficultyLvl .margin2 div {
    margin: 0 2px;
}

.difficultyLvl .margin2 div:first-child {
    margin-left: 0;
}

.lvlBreak {
    height: 3px;
    background: #000;
    min-width: 100px;
}

.has-error .mce-panel {
    border: 1px solid #ca3333;
}

.radio-custom.has-error input[type='checkbox'],
.checkbox-custom.has-error input[type='checkbox'] {
    border: 2px solid #ca3333;
}

input[type='range'] {
    -webkit-appearance: none;
}

input[type='range']:focus {
    outline: none;
}

.sliders {
    position: inherit;
    width: 100%;
    height: 10px;
    background: #c5d0de;
    outline: none;
    border-radius: 20px !important;
}

.sliders::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 36px;
    height: 36px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    cursor: pointer;
}

.sliders::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 36px;
    height: 36px;
    border-radius: 3px;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    cursor: pointer;
}

/** FF*/
.sliders::-moz-range-progress {
    position: inherit;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    outline: none;
    border-radius: 20px !important;
    background-color: #0071be;
}

.sliders::-ms-fill-lower {
    position: inherit;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    outline: none;
    border-radius: 20px !important;
    background-color: #0071be;
}

.sliders::-moz-range-track {
    position: inherit;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #c5d0de;
    outline: none;
    border-radius: 20px !important;
}

.sliders::-ms-fill-upper {
    position: inherit;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #c5d0de;
    outline: none;
    border-radius: 20px !important;
}

input[type='range']::-moz-focus-outer {
    border: 0;
}

.ratingScaleWidth {
    width: calc(100% - 95px);
    padding-right: 50px;
}

.noError .help-block {
    display: none;
}

.range-slider-minimum {
    margin: auto;
    text-align: center;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    .range-slides {
        position: relative;
        width: calc(100% - 120px);
        height: 8px;
    }

    input[type='range'] {
        position: absolute;
        left: 0;
        bottom: 0;
    }

    input[type='number'],
    input[type='number']:active,
    input[type='number']:focus,
    input[type='text']:focus {
        border: 1px solid #cde;
        text-align: center;
        line-height: 1;
        width: 55px;
        height: 36px;
        padding: 10px;
        -moz-appearance: textfield;

        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            margin-left: 5px;
        }
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type='number']:invalid,
    input[type='number']:out-of-range {
        border: 2px solid #ff6347;
    }

    input[type='range'] {
        width: 100%;
    }

    input[type='range']:focus {
        outline: none;
    }

    input[type='range']:focus::-webkit-slider-runnable-track {
    }

    input[type='range'] {
        &:first-child {
            &:before {
                background: #fff;
            }
        }
    }

    input[type='range']:focus::-ms-fill-lower {
        background: #2497e3;
    }

    input[type='range']:focus::-ms-fill-upper {
        background: #2497e3;
    }

    input[type='range']::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        border-radius: 1px;
        box-shadow: none;
        border: 0;
    }

    input[type='range']::-webkit-slider-thumb {
        z-index: 2;
        position: relative;
        -webkit-box-shadow: 0px 0px 0px #000;
        box-shadow: 0px 0px 0px #000;
        border: 1px solid #cacaca;
        height: 30px;
        width: 30px;
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -14px;
    }

    input[type='range']::-moz-range-thumb {
        z-index: 2;
        position: relative;
        -webkit-box-shadow: 0px 0px 0px #000;
        box-shadow: 0px 0px 0px #000;
        border: 1px solid #cacaca;
        height: 30px;
        width: 30px;
        border-radius: 4px;
        background: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -14px;
    }

    .range-color {
        position: absolute;
        height: 5px;
        background: #0071be;
        z-index: 1;
        top: 2px;
    }
}

// .radioField {
//     label {
//         width: inherit;
//     }
// }
</style>
