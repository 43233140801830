<template>
    <div id="app">
        <router-view />
        <div id="loading" class="hide">
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0"
                y="0"
                width="150"
                height="150"
                xml:space="preserve"
            >
                <pattern id="water" width=".25" height="2" patternContentUnits="objectBoundingBox">
                    <path fill="#fff" d="M0.25,1H0c0,0,0-0.659,0-0.916c0.083-0.303,0.158,0.334,0.25,0C0.25,0.327,0.25,1,0.25,1z" />
                </pattern>
                <g>
                    <path
                        id="logo"
                        transform="scale(.28,.28)"
                        d="M244 2.42438C207.913 7.16269 174.977 15.5219 144 35.6952C70.9486 83.269 33.8911 176.469 56.1543 261C71.638 319.79 111.552 360.857 149.725 406C165.252 424.362 179.071 444.758 185.964 468C189.517 479.983 186.732 500.378 195.278 509.891C201.512 516.832 215.541 514 224 514L287 514C295.608 514 307.89 516.22 315.999 513.107C329.807 507.804 326.53 482.835 329.475 471C333.466 454.962 340.842 440.414 350.427 427C356.801 418.081 366.117 409.075 370.467 399C375.098 388.273 369.234 375.189 358 371.8C337.282 365.551 322.543 393.009 312.721 407C304.741 418.366 297.141 430.427 293.63 444C291.462 452.378 291.758 467.425 285.721 473.852C282.08 477.729 276.846 477 272 477C260.628 477 246.022 479.464 235.001 476.682C225.731 474.342 225.926 459.586 224.4 452C219.858 429.435 206.916 410.924 193.113 393C171.654 365.137 145.495 341.418 126.004 312C90.7079 258.727 79.825 189.736 112.424 132C120.921 116.951 131.139 101.859 144.001 90.1698C187.719 50.437 246.607 34.0499 304 50.4275C360.122 66.4426 405.301 113.447 419.373 170C424.147 189.185 427.041 211.83 420.971 231C404.231 283.875 336.451 298.303 299.09 258C289.825 248.005 284.348 235.288 281.805 222C280.444 214.89 281.31 207.742 278.081 201C272.875 190.129 259.213 186.396 249.015 192.613C228.818 204.926 242.397 242.761 250.258 259C271.798 303.493 321.219 329.256 370 321.725C382.473 319.8 394.884 316.013 406 309.971C469.711 275.342 475.584 203.523 454.667 141C426.636 57.2139 334.285 -9.4303 244 2.42438M252 93.4244C193.155 101.151 143.8 140.951 141.039 204C139.842 231.343 148.246 257.772 164.039 280C170.492 289.082 180.358 301.082 193 298.316C204.828 295.727 212.231 283.544 207.517 272C202.474 259.65 190.643 250.813 185.453 238C172.038 204.884 185.612 165.509 215 145.749C252.884 120.276 305.95 134.542 326.741 175C334.397 189.9 331.249 206.569 337.533 220.999C341.561 230.247 352.666 234.298 362 230.867C385.21 222.334 371.944 178.839 364.691 163C345.833 121.814 299.442 87.1953 252 93.4244z"
                    />
                </g>
                <mask id="logo-mask">
                    <use x="0" y="0" xlink:href="#logo" opacity="1" fill="#ffffff" />
                </mask>
                <g id="logo-eff">
                    <use x="0" y="0" xlink:href="#logo" fill="#69a8c9" />
                    <rect mask="url(#logo-mask)" class="logo-water-fill" fill="url(#water)" x="-300" y="100" width="1200" height="150" opacity="0.3">
                        <animate attributeType="xml" attributeName="x" from="-300" to="0" repeatCount="indefinite" dur="3s" />
                    </rect>

                    <rect mask="url(#logo-mask)" class="logo-water-fill" fill="url(#water)" y="95" width="1600" height="150" opacity="0.3">
                        <animate attributeType="xml" attributeName="x" from="-400" to="0" repeatCount="indefinite" dur="4s" />
                    </rect>

                    <rect mask="url(#logo-mask)" class="logo-water-fill" fill="url(#water)" y="105" width="800" height="150" opacity="0.3">
                        <animate attributeType="xml" attributeName="x" from="-200" to="0" repeatCount="indefinite" dur="2.4s" />
                    </rect>

                    <rect mask="url(#logo-mask)" class="logo-water-fill" fill="url(#water)" y="105" width="2000" height="150" opacity="0.3">
                        <animate attributeType="xml" attributeName="x" from="-500" to="0" repeatCount="indefinite" dur="3.8s" />
                    </rect>
                </g>
                <use xlink:href="#logo-eff" opacity="0.9" />
            </svg>
        </div>
        <div id="view-pdf-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" aria-labelledby="view-pdf-modal-title">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="view-pdf-modal-title" class="modal-title"><i class="fa fa-arrows marginRight8" aria-hidden="true" />View PDF</h2>
                    </div>

                    <div v-if="attachment" class="modal-body">
                        <pdf-viewer :file-url="attachment.url" :can-download="canDownload" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'App',
    data() {
        return {
            attachment: null,
            canDownload: false,
        };
    },
    updated() {
        // Remove loading state
        //setTimeout(() => document.body.classList.remove('app-loading'), 1)
    },
    mounted() {
        var that = this;
        Events.listen('view-pdf-modal', function (attachment, options) {
            that.attachment = attachment;
            that.canDownload = _.get(options, 'canDownload', false);
            $('#view-pdf-modal').modal('show');
        });
        this.$nextTick(function () {
            $('#view-pdf-modal .modal-dialog').draggable({
                handle: '.modal-header',
            });
        });
    },
};
</script>
<style src="./sass/app.scss" lang="scss"></style>
