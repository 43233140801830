<template>
    <div v-if="component_done_loading" class="test" :class="{ disabledImagePointerEvents: !test.settings.enableImagePointerEvents }">
        <test-topbar :test-obj="test" :options="{ forceHideTimer: true }" />

        <main class="col-xs-12 right-container">
            <div v-if="test.others.applicationType == 'team'" class="col-xs-12 marginBottom20">
                <team-leadership :test-obj="test" @is-team-leader="getIsTeamLeader" />
            </div>

            <div class="nav-tabsContainer">
                <nav class="links">
                    <ul>
                        <li class="uppercase" :class="{ active: tab == 'leaderboard' }" @click="tab = 'leaderboard'">
                            <a class="nav-link" data-toggle="tab" href="#leaderboard" aria-label="Leaderboard">Leaderboard</a>
                        </li>
                        <li v-if="test.presentationMode" class="uppercase" :class="{ active: tab == 'question' }" @click="tab = 'question'">
                            <a class="nav-link" data-toggle="tab" href="#questions" aria-label="Questions">Questions</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="row tab-content">
                <div v-if="tab == 'leaderboard' && test.leaderboard" id="leaderboard" class="tab-pane fade in active">
                    <test-timer-progress :test-obj="test" />
                </div>
                <div v-if="test.presentationMode && tab == 'question' && test.questions" id="questions" class="tab-pane fade in active">
                    <div v-if="component_done_loading" class="gradient panel">
                        <div class="panel-heading flexSpaceBetween">
                            <h2 class="margin0 fs-18px">Questions</h2>
                        </div>
                        <div class="panel-body">
                            <div class="col-xs-12 col-md-7 marginBottom30">
                                <question-pagination :test-obj="test" />
                            </div>
                            <div class="col-xs-12">
                                <question-groups :test-obj="test" />
                            </div>
                            <div class="col-xs-12 col-md-7 marginTop30">
                                <question-pagination :test-obj="test" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import KrAuth from '../../../../../components/auth/auth';
export default {
    props: ['testObj'],
    data() {
        return {
            auth: new KrAuth(),
            component_done_loading: false,
            screen: {},
            test: {},
            debouncedFetchTest: null,
            debouncedFetchScreen: null,
            tab: 'leaderboard',
            isTeamLeader: false,
        };
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');

        this.debouncedFetchScreen = _.debounce(
            function () {
                that.fetchScreen().then(function (response) {
                    let a = {};
                    _.merge(a, that.test, response.data.data);
                    that.test = a;
                });
            },
            1000,
            { maxWait: 2000 }
        );

        this.debouncedFetchTest = _.debounce(
            function () {
                that.fetchTest().then(function (response) {
                    let a = {};
                    _.merge(a, that.test, response.data.data);
                    that.test = a;
                    that.test.questions = response.data.data.questions;
                });
            },
            1000,
            { maxWait: 2000 }
        );
        if (that.test.presentationMode) {
            that.tab = 'question';
        }
        this.fetch().then(function (response) {
            let a = {};
            _.merge(a, that.test, response.data.data);
            that.test = a;
            if (that.test.presentationMode) {
                that.tab = 'question';
            }
            if (['Lapsed', 'Expired'].includes(that.auth.user().status) && that.auth.user().account.paymentMethod == 'Student-Paid') {
                that.$router.push({ name: 'errors.403' });
            }
            that.component_done_loading = true;

            document.title = `Discussion | ${that.test.name} | Application | InteDashboard | TBL Makes Teams Work`;

            that.processEchoListener();
        });
    },
    methods: {
        processEchoListener() {
            var that = this;
            let h1 = (e) => {
                that.test.myProgression.startTime = e.test.settings.startTime;
                that.test.myProgression.endTime = e.test.settings.endTime;
                let old_status = that.test.status;
                that.test.status = e.test.status;

                if (['ended', 'completed'].includes(old_status) && that.test.status == 'ongoing') {
                    that.debouncedFetchScreen();
                }
                if (['ended', 'completed'].includes(that.test.status)) {
                    that.debouncedFetchTest();
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has unpublished this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c2 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityUnpublished', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'ActivityUnpublished',
                handler: h2,
            });

            let h3 = (e) => {
                axios.post(`student/tests/${that.test.uuid}/discussions/answers`, { activityQuestionUuid: e.activityQuestion.uuid }).then(function (response) {
                    for (var i = 0; i < that.test.questions.length; i++) {
                        for (var j = 0; j < that.test.questions[i].length; j++) {
                            if (that.test.questions[i][j].uuid == e.activityQuestion.uuid) {
                                that.test.questions[i][j].displayAnswer = e.activityQuestion.displayAnswer ? true : false;
                                that.test.questions[i][j].displayAnswerStatistics = e.activityQuestion.displayAnswerStatistics ? true : false;
                                that.test.questions[i][j].displayKeywords = e.activityQuestion.displayAnswer ? true : false;
                                that.test.questions[i][j].displayVotes = e.activityQuestion.displayVotes ? true : false;
                                that.test.questions[i][j].displayComments = e.activityQuestion.displayComments ? true : false;
                                if (that.test.questions[i][j].type == 'openended') {
                                    let oldStatus = that.test.questions[i][j].eGallery.status;
                                    that.test.questions[i][j].eGallery.rankingValue = e.activityQuestion.rankingValue;
                                    that.test.questions[i][j].eGallery.status = e.activityQuestion.eGalleryStatus;
                                    that.test.questions[i][j].eGallery.votingMethod = e.activityQuestion.votingMethod;
                                    that.test.questions[i][j].eGalleryWalkSettings = e.activityQuestion.eGalleryWalkSettings
                                        ? e.activityQuestion.eGalleryWalkSettings
                                        : {};
                                    /*Vue.set(
                                            that.test.questions[i][j].eGallery,
                                            'rankingValue',
                                            e.activityQuestion.rankingValue
                                        );
                                        Vue.set(
                                            that.test.questions[i][j].eGallery,
                                            'status',
                                            e.activityQuestion.eGalleryStatus
                                        );
                                        Vue.set(
                                            that.test.questions[i][j].eGallery,
                                            'votingMethod',
                                            e.activityQuestion.votingMethod
                                        );
                                        Vue.set(
                                            that.test.questions[i][j],
                                            'eGalleryWalkSettings',
                                            e.activityQuestion
                                                .eGalleryWalkSettings,
                                            {}
                                        );*/
                                    let question = _.cloneDeep(that.test.questions[i][j]);
                                    let o = [];
                                    for (var k = 0; k < question.answers.length; k++) {
                                        o.push({
                                            uuid: question.answers[k].uuid,
                                            myComment: question.answers[k].myComment,
                                            myVote: question.answers[k].myVote,
                                        });
                                    }
                                    that.test.questions[i][j].answers = that.mergeArrayOfObjectsWithKey(o, response.data.data.answers, 'uuid');
                                    /*Vue.set(
                                            that.test.questions[i][j],
                                            'answers',
                                            that.mergeArrayOfObjectsWithKey(
                                                o,
                                                response.data.data.answers,
                                                'uuid'
                                            )
                                        );*/
                                    if (oldStatus != that.test.questions[i][j].eGallery.status && that.test.questions[i][j].eGallery.status == 'ongoing') {
                                        that.test.questions[i][j].eGallery.hasSubmitted = false;
                                        /*Vue.set(
                                                that.test.questions[i][j]
                                                    .eGallery,
                                                'hasSubmitted',
                                                false
                                            );*/
                                    }
                                }
                                return;
                            }
                        }
                    }
                });
            };
            let c3 = window.Echo.private(`test.${that.test.uuid}.student`).listen('DiscussionQuestionStatusUpdated', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'DiscussionQuestionStatusUpdated',
                handler: h3,
            });

            let h4 = (e) => {
                this.test.presentationMode = e.presentationMode;
                if (this.test.presentationMode) {
                    this.tab = 'question';
                } else {
                    this.tab = 'leaderboard';
                }
            };
            let c4 = window.Echo.private(`test.${that.test.uuid}.student`).listen('PresentationModeStatusUpdated', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'PresentationModeStatusUpdated',
                handler: h4,
            });

            let h5 = (e) => {
                if (!this.isTeamLeader) {
                    this.debouncedFetchTest();
                }
            };
            if (_.get(this.test, 'others.applicationType') == 'team') {
                let c5 = window.Echo.join(`test.${that.test.uuid}.team.${that.test.myProgression.team.uuid}`).listen('TeamDiscussionUpdated', h5);
                this.echoChannels.push({
                    channel: c5,
                    event: 'TeamDiscussionUpdated',
                    handler: h5,
                });
            }

            let h6 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has deleted this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c6 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityDeleted', h6);
            this.echoChannels.push({
                channel: c6,
                event: 'ActivityDeleted',
                handler: h6,
            });
            let h7 = (e) => {
                if (e.test.uuid != that.test.testUuid) {
                    return false;
                }
                that.debouncedFetchTest();
                //that.test.enableAnonymityInEGalleryWalk = e.test.enableAnonymityInEGalleryWalk;
            };
            let c7 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestVisibilityUpdated', h7);
            this.echoChannels.push({
                channel: c7,
                event: 'TestVisibilityUpdated',
                handler: h7,
            });
        },
        fetch() {
            if (this.tab == 'question') {
                return this.fetchTest();
            } else if (this.tab == 'leaderboard') {
                return this.fetchScreen();
            }
        },
        fetchScreen() {
            return axios.get('student/tests/' + this.$route.params.id + '/discussions/screen');
        },
        fetchTest() {
            return axios.get('student/tests/' + this.$route.params.id + '/discussions');
        },
        getIsTeamLeader(data) {
            this.isTeamLeader = data;
        },
    },
    components: {
        'question-pagination': require(`./partials/question-pagination.vue`).default,
        'question-groups': require(`./partials/questions/question-groups.vue`).default,
        'test-topbar': require(`./../partials/topbar.vue`).default,
        'team-leadership': require(`./../partials/team-leadership.vue`).default,
        'test-timer-progress': require(`./partials/index-timer-progress.vue`).default,
    },
    watch: {
        tab: {
            handler: function () {
                var that = this;
                that.fetch().then(function (response) {
                    let a = {};
                    _.merge(a, that.test, response.data.data);
                    that.test = a;
                    that.$nextTick(function () {
                        that.$forceUpdate();
                    });
                });
            },
            deep: true,
        },
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
        //from mixin.
        Events.off('current-question-changed');
    },
};
</script>
<style scoped>
@media (min-width: 992px) {
    .discussion .timer_Div {
        font-size: 100px;
    }

    .discussion .time .number-div {
        padding: 10px 24px 5px;
    }

    .discussion .time .number-div:last-child {
        margin-left: 10px;
    }

    .discussion .timeText {
        /* font-size:14px; */
        font-size: 0.778rem;
        margin-bottom: 10px;
    }
}

.discussion .test-progression th:first-child,
.discussion .test-progression td:first-child {
    width: 10%;
}

.discussion .test-progression tbody {
    display: block;
    height: 480px;
    overflow: auto;
}
.discussion .test-progression thead,
.discussion .test-progression tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
</style>
