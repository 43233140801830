<template>
    <div class="eGalleryQuestionGroup">
        <template v-for="(question, idx) in testObj.questions[current_question_group_idx]">
            <question :test-obj="testObj" :question="question" :question_group_idx="current_question_group_idx" :question_idx="idx" />
        </template>
    </div>
</template>
<script>
export default {
    props: ['testObj'],
    components: {
        question: require(`./question/question.vue`).default,
    },
    data() {
        return {
            current_question_group_idx: 0,
            current_question_idx: 0,
        };
    },
    created() {
        this.init();
    },
    mounted() {
        var that = this;
        Events.listen('change-question', function (to) {
            that.changeQuestion(to);
        });
        this.processEchoListeners();
        Events.fire('current-question-changed', {
            question_group_idx: this.current_question_group_idx,
            question_idx: this.current_question_idx,
        });
    },
    beforeUnmount() {
        Events.off('change-question');
    },
    methods: {
        processEchoListeners() {
            var that = this;
            let h1 = (e) => {
                this.testObj.eGalleryProgression.currentQuestionUuid = e.eGalleryCurrentQuestionUuid;
                for (var i = 0; i < this.testObj.questions.length; i++) {
                    for (var j = 0; j < this.testObj.questions[i].length; j++) {
                        //get current saved question position
                        if (this.testObj.questions[i][j].uuid == this.testObj.eGalleryProgression.currentQuestionUuid) {
                            this.changeQuestion({
                                question_group_idx: i,
                                question_idx: j,
                            });
                            return;
                        }
                    }
                }
            };
            let c1 = window.Echo.private(`test.${that.testObj.testUuid}.student`).listen('DiscussionCurrentQuestionUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'DiscussionCurrentQuestionUpdated',
                handler: h1,
            });

            /*let h2 = (e) => {
                for(var i = 0; i <this.testObj.questions.length;i++){
                    for(var j = 0; j<this.testObj.questions[i].length;j++){
                        //get current saved question position
                        this.testObj.questions[i][j].displayAnswer = e.displayAnswer===0?false:true;
                    }
                }
            }
            let c2 = window.Echo.private(`test.${that.testObj.testUuid}.student`).listen('DiscussionQuestionsSyncToggled', h2 );
            this.echoChannels.push({channel:c2,event:'DiscussionQuestionsSyncToggled',handler:h2})*/
        },
        init() {
            let currentQuestionUuid = _.get(this.testObj, 'eGalleryProgression.currentQuestionUuid', false);
            if (currentQuestionUuid == null) {
                this.changeQuestion({ question_group_idx: 0, question_idx: 0 });
            }
            for (var i = 0; i < this.testObj.questions.length; i++) {
                for (var j = 0; j < this.testObj.questions[i].length; j++) {
                    //get current saved question position
                    if (currentQuestionUuid) {
                        if (this.testObj.questions[i][j].uuid == currentQuestionUuid) {
                            this.current_question_group_idx = i;
                            this.current_question_idx = j;
                        }
                    }
                }
            }
        },
        changeQuestion(to) {
            this.current_question_group_idx = to.question_group_idx;
            this.current_question_idx = to.question_idx;
            Events.fire('current-question-changed', {
                question_group_idx: to.question_group_idx,
                question_idx: to.question_idx,
            });

            /*axios.put('student/tests/'+this.testObj.uuid+'/update/current-question',{currentQuestionUuid:this.testObj.questions[this.current_question_group_idx][this.current_question_idx].uuid}).then(function(response){
                    Events.fire('changed-test',response.data.data);
            })*/
        },
    },
};
</script>

<style>
.eGalleryQuestionGroup .panel-footer {
    padding: 20px;
    border-top: 1px solid #d8d8d8;
    box-shadow: 0 -2px 4px 0 rgba(168, 168, 168, 0.5);
    background: #fff;
}
</style>
