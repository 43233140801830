<template>
    <div class="modal" v-bind="$attrs" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="calculator-title">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="close" data-dismiss="modal" aria-label="Close Modal" @click.prevent="closeAllModal()">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="calculator-title" class="modal-title"><i class="fa fa-arrows marginRight8" aria-hidden="true" />Calculator</h2>
                </div>
                <div class="modal-body">
                    <div class="calculator">
                        <div class="answer">
                            {{ answer }}
                        </div>
                        <div id="calculatorDisplay" class="display" aria-live="polite">
                            {{ logList + current }}
                        </div>

                        <button id="clear" class="btn operator" aria-label="Clear" @click="clear" @keydown="handleArrowKeys">C</button>

                        <button id="sign" class="btn operator" aria-label="Positive or Negative" @click="sign" @keydown="handleArrowKeys">+/-</button>

                        <button id="percentage" class="btn operator" @click="percent" @keydown="handleArrowKeys">%</button>

                        <button id="divide" class="btn operator" aria-label="divide" @click="divide" @keydown="handleArrowKeys">/</button>

                        <button id="n7" class="btn" @click="append('7')" @keydown="handleArrowKeys">7</button>

                        <button id="n8" class="btn" @click="append('8')" @keydown="handleArrowKeys">8</button>

                        <button id="n9" class="btn" @click="append('9')" @keydown="handleArrowKeys">9</button>

                        <button id="times" class="btn operator" aria-label="times" @click="times" @keydown="handleArrowKeys">*</button>

                        <button id="n4" class="btn" @click="append('4')" @keydown="handleArrowKeys">4</button>

                        <button id="n5" class="btn" @click="append('5')" @keydown="handleArrowKeys">5</button>

                        <button id="n6" class="btn" @click="append('6')" @keydown="handleArrowKeys">6</button>

                        <button id="minus" class="btn operator" aria-label="minus" @click="minus" @keydown="handleArrowKeys">-</button>

                        <button id="n1" class="btn" @click="append('1')" @keydown="handleArrowKeys">1</button>

                        <button id="n2" class="btn" @click="append('2')" @keydown="handleArrowKeys">2</button>

                        <button id="n3" class="btn" @click="append('3')" @keydown="handleArrowKeys">3</button>

                        <button id="plus" class="btn operator" aria-label="plus" @click="plus" @keydown="handleArrowKeys">+</button>

                        <button id="n0" class="zero" @click="append('0')" @keydown="handleArrowKeys">0</button>

                        <button id="decimal" class="btn" @click="dot" @keydown="handleArrowKeys">.</button>

                        <button id="equal" class="btn operator" aria-label="equal" @click="equal" @keydown="handleArrowKeys">=</button>

                        <div :id="'append-answer-alert'" class="visible-hidden" />

                        <button
                            v-if="canAnswer && answer != ''"
                            id="append"
                            class="btn"
                            style="width: 280px; height: 60px"
                            data-dismiss="modal"
                            @click="setAnswer"
                            @keydown="handleArrowKeys"
                        >
                            Append to answer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['canAnswer'],
    data() {
        return {
            logList: '',
            current: '',
            answer: '',
            operatorClicked: true,
        };
    },
    mounted() {
        var that = this;
        Vue.nextTick(function () {
            $(`#${that.$attrs.id} .modal-dialog`).draggable({
                handle: '.modal-header',
            });
        });
    },
    methods: {
        setAnswer() {
            this.$emit('answer', this.answer + '');
        },
        open() {
            this.clear();
            $(`#${this.$attrs.id}`).modal('show');
        },
        close() {
            $(`#${this.$attrs.id}`).modal('hide');
        },
        append(number) {
            if (this.operatorClicked) {
                this.current = '';
                this.operatorClicked = false;
            }
            this.current = `${this.current}${number}`;
        },
        addtoLog(operator) {
            if (this.operatorClicked == false) {
                this.logList += `${this.current} ${operator} `;
                this.current = '';
                this.operatorClicked = true;
            }
        },
        animateNumber(number) {
            let tl = anime.timeline({
                targets: `#${number}`,
                duration: 250,
                easing: 'easeInOutCubic',
            });
            tl.add({ backgroundColor: '#c1e3ff' });
            tl.add({ backgroundColor: '#f4faff' });
        },
        animateOperator(operator) {
            let tl = anime.timeline({
                targets: `#${operator}`,
                duration: 250,
                easing: 'easeInOutCubic',
            });
            tl.add({ backgroundColor: '#a6daff' });
            tl.add({ backgroundColor: '#d9efff' });
        },
        clear() {
            this.current = '';
            this.answer = '';
            this.logList = '';
            this.operatorClicked = false;
        },
        sign() {
            if (this.current != '') {
                this.current = this.current.charAt(0) === '-' ? this.current.slice(1) : `-${this.current}`;
            }
        },
        percent() {
            if (this.current != '') {
                this.current = `${parseFloat(this.current) / 100}`;
            }
        },
        dot() {
            if (this.current.indexOf('.') === -1) {
                this.append('.');
            }
        },
        divide() {
            this.addtoLog('/');
        },
        times() {
            this.addtoLog('*');
        },
        minus() {
            this.addtoLog('-');
        },
        plus() {
            this.addtoLog('+');
        },
        equal() {
            if (this.operatorClicked == false) {
                this.answer = eval(this.logList + this.current);
            } else {
                this.answer = 'WHAT?!!';
            }

            $('#append-answer-alert').attr('role', 'alert');
            $('#append-answer-alert').html('equal ' + this.answer);
        },
        handleArrowKeys(event) {
            const buttons = [
                'clear',
                'sign',
                'percentage',
                'divide',
                'n7',
                'n8',
                'n9',
                'times',
                'n4',
                'n5',
                'n6',
                'minus',
                'n1',
                'n2',
                'n3',
                'plus',
                'n0',
                'decimal',
                'equal',
                'append',
            ];

            const currentIndex = buttons.findIndex((id) => id === document.activeElement.id);

            if (event.key === 'ArrowRight' && currentIndex < buttons.length - 1) {
                document.getElementById(buttons[currentIndex + 1]).focus();
            } else if (event.key === 'ArrowLeft' && currentIndex > 0) {
                document.getElementById(buttons[currentIndex - 1]).focus();
            } else if (event.key === 'ArrowUp') {
                if (currentIndex == 19) {
                    document.getElementById(buttons[18]).focus();
                } else if (currentIndex == 17 || currentIndex == 18) {
                    document.getElementById(buttons[currentIndex - 3]).focus();
                } else if (currentIndex >= 4) {
                    document.getElementById(buttons[currentIndex - 4]).focus();
                }
            } else if (event.key === 'ArrowDown') {
                if (currentIndex == 16 || currentIndex == 17 || currentIndex == 18) {
                    if (document.getElementById(buttons[19])) {
                        document.getElementById(buttons[19]).focus();
                    }
                } else if (currentIndex == 13 || currentIndex == 14 || currentIndex == 15) {
                    document.getElementById(buttons[currentIndex + 3]).focus();
                } else if (currentIndex < buttons.length - 4) {
                    document.getElementById(buttons[currentIndex + 4]).focus();
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.modal-content {
    width: 400px !important;
}
.modal-body {
    width: 400px;
}

.calculator {
    display: grid;
    grid-template-rows: repeat(7, minmax(60px, auto));
    grid-template-columns: repeat(4, 60px);
    grid-gap: 12px;
    padding: 35px;
    font-weight: 300;
    font-size: 18px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 80px -30px rgba(13, 81, 134, 1);
}

.btn {
    margin-left: 0px;
}

.btn,
.zero {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    /* outline: none; */
    color: #ffffff;
    background-color: #135b79;
    border: 2px solid #135b79;
    border-radius: 5px;

    &:hover {
        color: #135b79;
        border-color: #135b79;
        background: #fff;
    }

    &:active {
        color: #fff;
        background: #083243;
        border-color: #083243;
    }
}

.display,
.answer {
    grid-column: 1 / 5;
    display: flex;
    align-items: center;
}

.display {
    color: #222222;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: clip;
}

.answer {
    font-weight: 500;
    color: #135b79;
    font-size: 55px;
    height: 65px;
    overflow-x: auto;
    overflow-y: hidden;
}

.zero {
    grid-column: 1 / 3;
}

.operator {
    background-color: #0e435a;
    border: 2px solid #0e435a;
    color: #ffffff;

    &:hover {
        color: #0e435a;
        border-color: #0e435a;
        background: #fff;
    }

    &:active {
        color: #fff;
        background: #082633;
        border-color: #082633;
    }
}
</style>
