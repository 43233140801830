<template>
    <div class="slider-new" :class="{ hasButtons: includeButtons }">
        <button
            v-if="includeButtons"
            class="btn btn-outline-dark icon-only me-2"
            :disabled="value == setMin || isDisabled"
            tabindex="-1"
            @click="changeValue(value--)"
        >
            <i class="material-icons" role="img" aria-label="Decrease Value"> remove </i>
        </button>

        <div ref="sliderContainer" class="slider-content" :class="{ rangeOnly: isRangeOnly }">
            <template v-if="isRangeOnly">
                <input
                    v-if="showNumberInput"
                    v-model="sliderMinValue"
                    class="form-control minField"
                    type="number"
                    :min="setMin"
                    :max="setMax"
                    :disabled="isDisabled"
                    @input="rangeChangeValue"
                />

                <input
                    v-if="showNumberInput"
                    v-model="sliderMaxValue"
                    class="form-control maxField"
                    type="number"
                    :min="setMin"
                    :max="setMax"
                    :disabled="isDisabled"
                    @input="rangeChangeValue"
                />

                <div ref="rangeProgressColor" class="range-color" aria-hidden="true" :class="{ disabled: isDisabled }"></div>

                <input v-model="sliderMinValue" type="range" :min="setMin" :max="setMax" :disabled="isDisabled" tabindex="-1" @input="rangeChangeValue" />

                <input v-model="sliderMaxValue" type="range" :min="setMin" :max="setMax" :disabled="isDisabled" tabindex="-1" @input="rangeChangeValue" />
            </template>
            <template v-else>
                <input
                    v-if="showNumberInput"
                    v-model.number="value"
                    class="form-control"
                    type="number"
                    :min="setMin"
                    :max="setMax"
                    :disabled="isDisabled"
                    @input="changeValue"
                />

                <div v-if="!isFirefox" ref="sliderProgressColor" class="range-color" aria-hidden="true" :class="{ disabled: isDisabled }"></div>

                <input
                    :id="sliderIdx + '-label'"
                    :aria-labelledby="sliderIdx"
                    v-model.number="value"
                    type="range"
                    :min="setMin"
                    :max="setMax"
                    :aria-valuenow="value"
                    :aria-valuemin="setMin"
                    :aria-valuemax="setMax"
                    :aria-valuetext="value + ' ' + valueText + ' for ' + sliderName"
                    :step="hasStep ? stepValue : ''"
                    :disabled="isDisabled"
                    @input="changeValue"
                />
            </template>
        </div>

        <button
            v-if="includeButtons"
            class="btn btn-outline-dark icon-only ms-2"
            :disabled="value == setMax || isDisabled || leftPoints == 0"
            tabindex="-1"
            @click="changeValue(value++)"
        >
            <i class="material-icons" role="img" aria-label="Increase Value"> add </i>
        </button>
    </div>
</template>
<script>
export default {
    compatConfig: {
        MODE: 3,
    },
};
</script>
<script setup>
import { ref, onMounted, watch, computed } from 'vue';
const props = defineProps({
    modelValue: {
        type: Number,
        default: 0,
    },
    isDisabled: Boolean,
    isValid: Boolean,
    isInvalid: Boolean,
    setMin: {
        type: Number,
        default: 0,
    },
    setMax: {
        type: Number,
        default: 100,
    },
    includeButtons: Boolean,
    hasStep: Boolean,
    stepValue: {
        type: Number,
        default: 5,
    },
    isRangeOnly: Boolean,
    setRangeMinValue: {
        type: Number,
        default: 0,
    },
    setRangeMaxValue: {
        type: Number,
        default: 2000,
    },
    showNumberInput: Boolean,
    leftPoints: {
        type: Number,
        default: 100000000,
    },
    sliderIdx: {
        type: String,
        default: '',
    },
    valueText: {
        type: String,
        default: '',
    },
    sliderName: {
        type: String,
        default: '',
    },
});
const emit = defineEmits(['update:modelValue']);
const value = computed({
    get() {
        if (props.modelValue) {
            return props.modelValue;
        } else {
            return 0;
        }
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
const sliderMinValue = ref(props.setRangeMinValue);
const sliderMaxValue = ref(props.setRangeMaxValue);

const setMinValue = ref(props.setMin);
const setMaxValue = ref(props.setMax);

const sliderContainer = ref(null);

const sliderProgressColor = ref(null);
const rangeProgressColor = ref(null);

const changeValue = () => {
    var val = value.value;
    var myInput = sliderContainer.value.querySelector("input[type='number']");
    var thumbWidth = 50;
    const min = props.setMin;
    const max = props.setMax;

    if (props.leftPoints <= 0) {
        val -= -props.leftPoints;
    }

    var off = (sliderContainer.value.clientWidth - thumbWidth) / (max - min);

    if (props.showNumberInput) {
        var px = (val - min) * off - myInput.clientWidth / 2 + thumbWidth / 2;
        myInput.style.left = px + 'px';
    }

    var newPercent = val / (max / 100);
    if (!props.isRangeOnly) {
        if (newPercent >= 50) {
            sliderProgressColor.value.style.width = 'calc(' + newPercent + '% - 20px )';
        } else {
            sliderProgressColor.value.style.width = 'calc(' + newPercent + '%)';
        }
    }
};

const rangeChangeValue = () => {
    const sliderMinVal = sliderMinValue.value;
    const sliderMaxVal = sliderMaxValue.value;
    var myInputMinField = sliderContainer.value.querySelector('input.minField');
    var myInputMaxField = sliderContainer.value.querySelector('input.maxField');
    var thumbWidth = 50;
    const min = props.setMin;
    const max = props.setMax;

    if (props.isRangeOnly) {
        var offMin = (sliderContainer.value.clientWidth - thumbWidth) / (max - min);
        var pxMin = (sliderMinVal - min) * offMin - myInputMinField.clientWidth / 2 + thumbWidth / 2;
        myInputMinField.style.left = pxMin + 'px';

        var offMax = (sliderContainer.value.clientWidth - thumbWidth) / (max - min);
        var pxMax = (sliderMaxVal - min) * offMax - myInputMaxField.clientWidth / 2 + thumbWidth / 2;
        myInputMaxField.style.left = pxMax + 'px';
    }

    var leftSliderY = sliderMinValue.value / (setMaxValue.value / 100);
    if (props.isRangeOnly) {
        rangeProgressColor.value.style.left = 'calc(' + leftSliderY + '% + 10px )';
    }

    var rightSliderY = sliderMaxValue.value / (setMaxValue.value / 100);
    if (props.isRangeOnly) {
        rangeProgressColor.value.style.width = 'calc(' + (rightSliderY - leftSliderY) + '% - 30px )';
    }
};

onMounted(() => {
    changeValue();
    rangeChangeValue();
});
watch(value, async (newValue, oldValue) => {
    changeValue();
    rangeChangeValue();
});
</script>
