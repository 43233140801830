<template>
    <div v-if="!isMobileView" ref="matchContainerDiscussion" class="flexSpaceBetween position-relative zIndex2 gap10rem">
        <div ref="leftCol" class="width40">
            <div :ref="promptsColumn" class="match-options" :class="{ 'match-correct': displayAnswer }">
                <label class="position-relative">
                    {{ promptVal }}

                    <span class="position-absolute circle">
                        <span class="visible-hidden capitalize">{{ promptTitle }}</span>
                        <b> {{ index }} </b>
                    </span>
                </label>
            </div>
        </div>
        <div ref="rightCol" class="width40">
            <div :ref="answersColumn" class="match-options right" :class="{ 'match-correct': displayAnswer }">
                <label class="position-relative">
                    {{ answerVal }}

                    <span class="position-absolute circle">
                        <i v-if="displayAnswer" class="fa fa-check" aria-hidden="true"></i>
                        <template v-else> 0 </template>
                    </span>
                </label>
            </div>
        </div>
        <canvas ref="matchCanvasDiscussion"></canvas>
    </div>

    <template v-else>
        <div class="sequencing-div-student">
            <div>
                <label :id="'matching-one-to-one-instructor-mobile-prompt-label-' + index" class="control-label capitalize">
                    {{ promptTitle }} {{ index }}
                </label>
                <p>
                    {{ promptVal }}
                </p>
            </div>

            <div v-if="displayAnswer" class="marginTop20">
                <dl class="margin0">
                    <dt class="control-label capitalize">{{ answerTitle }} for {{ promptTitle }} {{ index }}</dt>
                    <dd class="flexOnly categorize-option categorize-correct">
                        <i class="fa fa-check-circle marginRight8"></i>
                        <p>{{ answerVal }}</p>
                    </dd>
                </dl>
            </div>
        </div>
    </template>
</template>
<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';

const props = defineProps({
    promptVal: {
        type: String,
        default: '',
    },
    answerVal: {
        type: String,
        default: '',
    },
    displayAnswer: {
        type: Boolean,
        default: false,
    },
    index: {
        type: Number,
        default: 0,
    },
    promptTitle: {
        type: String,
        default: '',
    },
    answerTitle: {
        type: String,
        default: '',
    },
});

const promptsGroupRefs = ref([]);
const answersGroupRefs = ref([]);

const promptsColumn = (el) => promptsGroupRefs.value.push(el);
const answersColumn = (el) => answersGroupRefs.value.push(el);

const matchCanvasDiscussion = ref(null);
const matchContainerDiscussion = ref(null);

const drawLine = (ctx, startX, startY, endX, endY, style) => {
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);

    if (props.displayAnswer) {
        ctx.strokeStyle = '#2d8659';
    } else {
        ctx.strokeStyle = 'transparent';
    }

    ctx.lineWidth = 2;

    switch (style) {
        case 'solid':
            ctx.setLineDash([]);
            break;
        case 'dotted':
            ctx.setLineDash([2, 4]);
            break;
        case 'dashed':
            ctx.setLineDash([8, 4]);
            break;
    }

    ctx.stroke();
};

const drawLines = () => {
    if (window.innerWidth >= 992) {
        if (matchCanvasDiscussion.value && matchContainerDiscussion.value) {
            const ctx = matchCanvasDiscussion.value.getContext('2d');
            ctx.clearRect(0, 0, matchCanvasDiscussion.value.width, matchCanvasDiscussion.value.height);

            const matchContainerRect = matchContainerDiscussion.value.getBoundingClientRect();

            promptsGroupRefs.value.forEach((prompt, index) => {
                const answer = answersGroupRefs.value[index];

                if (prompt && answer) {
                    const rectA = prompt.getBoundingClientRect();
                    const rectB = answer.getBoundingClientRect();

                    const startX = rectA.right - matchContainerRect.left;
                    const startY = rectA.top + rectA.height / 2 - matchContainerRect.top;

                    const endX = rectB.left - matchContainerRect.left;
                    const endY = rectB.top + rectB.height / 2 - matchContainerRect.top;

                    const style = index % 3 === 0 ? 'solid' : index % 3 === 1 ? 'dotted' : 'dashed';

                    drawLine(ctx, startX, startY, endX, endY, style);
                }
            });
        }
    }
};

const canvasDimension = () => {
    if (window.innerWidth >= 992) {
        const matchContainerElement = matchContainerDiscussion.value;

        if (matchContainerElement) {
            matchCanvasDiscussion.value.width = matchContainerElement.offsetWidth;
            matchCanvasDiscussion.value.height = matchContainerElement.offsetHeight;
        }
    }
};

const handleResize = () => {
    if (window.innerWidth >= 992) {
        nextTick(() => {
            setTimeout(() => {
                canvasDimension();
                drawLines();
            }, 50);
        });
    }
};

watch(
    () => props.question,
    (question) => {
        handleResize();
    }
);

watch(
    () => props.displayAnswer,
    () => {
        handleResize();
    }
);

onMounted(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});
</script>
