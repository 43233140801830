<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container receipts">
        <div style="margin: 25px auto; max-width: 670px; box-shadow: 0 0 11px grey">
            <div style="background: #fff; background-color: #fff; margin: 0px auto; max-width: 670px">
                <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="background: #fff; background-color: #fff; width: 100%"
                >
                    <tbody>
                        <tr>
                            <td style="direction: ltr; font-size: 0px; padding: 20px 0; text-align: center; vertical-align: middle; background: #495060">
                                <div
                                    class="mj-column-per-100 outlook-group-fix"
                                    style="font-size: 13px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%"
                                >
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top" width="100%">
                                        <tr>
                                            <td align="center" style="font-size: 0px; padding: 0; word-break: break-word">
                                                <table
                                                    align="center"
                                                    border="0"
                                                    cellpadding="0"
                                                    cellspacing="0"
                                                    role="presentation"
                                                    style="border-collapse: collapse; border-spacing: 0px"
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td style="width: 270px">
                                                                <a href="https://www.intedashboard.com/" target="_blank">
                                                                    <img
                                                                        height="auto"
                                                                        src="https://cdn.intedashboard.com/emails/logo/white_intedashboard.png"
                                                                        style="border: 0; display: block; outline: none; text-decoration: none; width: 100%"
                                                                        width="270"
                                                                        alt="Intedashboard Logo"
                                                                    />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="background: #fff; background-color: #fff; margin: 0px auto; max-width: 670px">
                <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="background: #fff; background-color: #fff; width: 100%"
                >
                    <tbody>
                        <tr>
                            <td style="direction: ltr; font-size: 0px; padding: 30px 0 20px 0; text-align: center; vertical-align: top">
                                <div style="font-size: 24px; font-weight: bold; line-height: 1; text-align: center; color: #222">THANK YOU!</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="background: #fff; background-color: #fff; margin: 0px auto; max-width: 670px">
                <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="background: #fff; background-color: #fff; width: 100%"
                >
                    <tbody>
                        <tr>
                            <td style="direction: ltr; font-size: 0px; padding: 0 0 20px 0; text-align: center; vertical-align: top">
                                <div
                                    class="outlook-group-fix"
                                    style="font-size: 14px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%"
                                >
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top" width="100%">
                                        <tr>
                                            <td
                                                align="left"
                                                style="
                                                    font-size: 14px;
                                                    line-height: 1;
                                                    text-align: left;
                                                    color: #222;
                                                    padding: 10px 20px;
                                                    word-break: break-word;
                                                "
                                            >
                                                <div>Dear Ms Lydia Dean,</div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                align="left"
                                                style="
                                                    font-size: 14px;
                                                    line-height: 1.5;
                                                    text-align: left;
                                                    color: #222;
                                                    padding: 10px 20px;
                                                    word-break: break-word;
                                                "
                                            >
                                                <div>
                                                    Your order
                                                    <span style="color: #0078d7; font-weight: bold">#1324543</span>, quis nostrud exercitation ullamco laboris
                                                    nisi ut aliquip ex ea jakjga commodo consequat. Duis aute irure dolor in reprehenderit in de voluptate velit
                                                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                                                    qui officia deserunt mollit anim est laborum.
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td align="left" style="font-size: 0px; padding: 10px 20px; word-break: break-word">
                                                <div style="font-size: 14px; line-height: 21px; text-align: left; color: #222">
                                                    Any Questions? Suggestions? Insightful showe thoughts? Email us at
                                                    <a href="#" style="color: #0078d7; font-weight: bold; text-decoration: none; text-transform: none"
                                                        >help@intedashboard.com</a
                                                    >
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="background: #fff; background-color: #fff; margin: 0px auto; max-width: 670px; padding: 0 0 20px 0">
                <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="background: #fff; background-color: #fff; width: 100%"
                >
                    <tbody>
                        <tr>
                            <td style="direction: ltr; font-size: 0px; padding: 0 20px; text-align: center; vertical-align: top">
                                <div
                                    class="mj-column-per-50 outlook-group-fix"
                                    style="font-size: 14px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%"
                                >
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top; width: 100%">
                                        <tr>
                                            <td vertical-align="top" style="font-size: 0px; word-break: break-word">
                                                <div
                                                    class="mj-column-per-100 outlook-group-fix"
                                                    style="
                                                        font-size: 14px;
                                                        text-align: left;
                                                        direction: ltr;
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: 100%;
                                                    "
                                                >
                                                    <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="border-bottom: solid 2px #ebebeb; vertical-align: top"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                align="left"
                                                                style="
                                                                    font-size: 14px;
                                                                    font-weight: bold;
                                                                    line-height: 1.5;
                                                                    text-align: left;
                                                                    color: #222;
                                                                    padding: 10px 20px 10px 0;
                                                                    word-break: break-word;
                                                                "
                                                            >
                                                                <div>Order Number</div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td vertical-align="top">
                                                <div
                                                    class="mj-column-per-100 outlook-group-fix"
                                                    style="
                                                        font-size: 14px;
                                                        text-align: left;
                                                        direction: ltr;
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: 100%;
                                                    "
                                                >
                                                    <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="vertical-align: top"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                align="left"
                                                                style="
                                                                    font-size: 14px;
                                                                    line-height: 1.5;
                                                                    text-align: left;
                                                                    color: #222;
                                                                    padding: 10px 20px 10px 0;
                                                                    word-break: break-word;
                                                                "
                                                            >
                                                                <div>#1324543</div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div
                                    class="mj-column-per-50 outlook-group-fix"
                                    style="font-size: 14px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%"
                                >
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top; width: 100%">
                                        <tr>
                                            <td vertical-align="top" style="font-size: 0px; word-break: break-word">
                                                <div
                                                    class="mj-column-per-100 outlook-group-fix"
                                                    style="
                                                        font-size: 14px;
                                                        text-align: left;
                                                        direction: ltr;
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: 100%;
                                                    "
                                                >
                                                    <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="border-bottom: solid 2px #ebebeb; vertical-align: top"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                align="left"
                                                                style="
                                                                    font-size: 14px;
                                                                    font-weight: bold;
                                                                    line-height: 1.5;
                                                                    text-align: left;
                                                                    color: #222;
                                                                    padding: 10px 20px 10px 0;
                                                                    word-break: break-word;
                                                                "
                                                            >
                                                                <div>Order Date</div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td vertical-align="top">
                                                <div
                                                    class="mj-column-per-100 outlook-group-fix"
                                                    style="
                                                        font-size: 14px;
                                                        text-align: left;
                                                        direction: ltr;
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: 100%;
                                                    "
                                                >
                                                    <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="vertical-align: top"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                align="left"
                                                                style="
                                                                    font-size: 14px;
                                                                    line-height: 1.5;
                                                                    text-align: left;
                                                                    color: #222;
                                                                    padding: 10px 20px 10px 0;
                                                                    word-break: break-word;
                                                                "
                                                            >
                                                                <div>7 May 2018</div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="background: #fff; background-color: #fff; margin: 0px auto; max-width: 670px; padding: 0 0 20px 0">
                <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="background: #fff; background-color: #fff; width: 100%"
                >
                    <tbody>
                        <tr>
                            <td style="direction: ltr; font-size: 0px; padding: 0 20px; text-align: center; vertical-align: top">
                                <div
                                    class="mj-column-per-50 outlook-group-fix"
                                    style="font-size: 14px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%"
                                >
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top; width: 100%">
                                        <tr>
                                            <td vertical-align="top" style="font-size: 0px; word-break: break-word">
                                                <div
                                                    class="mj-column-per-100 outlook-group-fix"
                                                    style="
                                                        font-size: 14px;
                                                        text-align: left;
                                                        direction: ltr;
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: 100%;
                                                    "
                                                >
                                                    <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="border-bottom: solid 2px #ebebeb; vertical-align: top"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                align="left"
                                                                style="
                                                                    font-size: 14px;
                                                                    font-weight: bold;
                                                                    line-height: 1.5;
                                                                    text-align: left;
                                                                    color: #222;
                                                                    padding: 10px 20px 10px 0;
                                                                    word-break: break-word;
                                                                "
                                                            >
                                                                <div>Shipping Address</div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td vertical-align="top">
                                                <div
                                                    class="mj-column-per-100 outlook-group-fix"
                                                    style="
                                                        font-size: 14px;
                                                        text-align: left;
                                                        direction: ltr;
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: 100%;
                                                    "
                                                >
                                                    <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="vertical-align: top"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                align="left"
                                                                style="
                                                                    font-size: 14px;
                                                                    line-height: 1.5;
                                                                    text-align: left;
                                                                    color: #222;
                                                                    padding: 10px 20px 10px 0;
                                                                    word-break: break-word;
                                                                "
                                                            >
                                                                <div>CognaLearn Pte Ltd</div>
                                                                <div>75 Ayer Rajah Crescent, #01-01,</div>
                                                                <div>Singapore 139951</div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div
                                    class="mj-column-per-50 outlook-group-fix"
                                    style="font-size: 14px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%"
                                >
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top; width: 100%">
                                        <tr>
                                            <td vertical-align="top">
                                                <div
                                                    class="mj-column-per-100 outlook-group-fix"
                                                    style="
                                                        font-size: 14px;
                                                        text-align: left;
                                                        direction: ltr;
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: 100%;
                                                    "
                                                >
                                                    <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="border-bottom: solid 2px #ebebeb; vertical-align: top"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                align="left"
                                                                style="
                                                                    font-size: 14px;
                                                                    font-weight: bold;
                                                                    line-height: 1.5;
                                                                    text-align: left;
                                                                    color: #222;
                                                                    padding: 10px 20px 10px 0;
                                                                    word-break: break-word;
                                                                "
                                                            >
                                                                <div>Billing Address</div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td vertical-align="top">
                                                <div
                                                    class="mj-column-per-100 outlook-group-fix"
                                                    style="
                                                        font-size: 14px;
                                                        text-align: left;
                                                        direction: ltr;
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: 100%;
                                                    "
                                                >
                                                    <table
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        role="presentation"
                                                        style="vertical-align: top"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                align="left"
                                                                style="
                                                                    font-size: 14px;
                                                                    line-height: 1.5;
                                                                    text-align: left;
                                                                    color: #222;
                                                                    padding: 10px 20px 10px 0;
                                                                    word-break: break-word;
                                                                "
                                                            >
                                                                <div>CognaLearn Pte Ltd</div>
                                                                <div>75 Ayer Rajah Crescent, #01-01,</div>
                                                                <div>Singapore 139951</div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="background: #fff; background-color: #fff; margin: 0px auto; max-width: 670px; padding: 0 0 10px 0">
                <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    style="background: #fff; background-color: #fff; width: 100%"
                >
                    <tbody>
                        <tr>
                            <td style="direction: ltr; font-size: 0px; text-align: center; vertical-align: top">
                                <div
                                    class="mj-column-per-100 outlook-group-fix"
                                    style="font-size: 14px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%"
                                >
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top" width="100%">
                                        <tr>
                                            <td align="left" style="font-size: 0px; padding: 10px 20px; word-break: break-word">
                                                <div
                                                    style="
                                                        font-family: Calibri;
                                                        font-size: 14px;
                                                        font-weight: bold;
                                                        line-height: 1;
                                                        text-align: left;
                                                        color: #222;
                                                    "
                                                >
                                                    Here’s What You Ordered:
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style="direction: ltr; font-size: 0px; text-align: center; vertical-align: top">
                                <div
                                    class="mj-column-per-100 outlook-group-fix"
                                    style="font-size: 14px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%"
                                >
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top" width="100%">
                                        <tr>
                                            <td align="left" style="font-size: 0px; padding: 0 20px 10px 20px; word-break: break-word">
                                                <table
                                                    border="0"
                                                    style="cellspacing: 0; color: #000; font-size: 14px; line-height: 22px; table-layout: auto; width: 100%"
                                                >
                                                    <tr style="border-bottom: 2px solid #ebebeb; text-align: left">
                                                        <th class="priceTable1" style="padding: 10px 10px 10px 0">Item</th>
                                                        <th class="priceTableAlt" style="padding: 10px">Qty</th>
                                                        <th class="priceTableAlt" style="padding: 10px 0 10px 10px">Price</th>
                                                    </tr>
                                                    <tr style="border-bottom: 2px solid #ebebeb">
                                                        <td style="padding: 10px 10px 10px 0; font-weight: bold">Intedashboard Premium Account</td>
                                                        <td style="padding: 10px">1</td>
                                                        <td style="padding: 10px 0 10px 10px">SGD 10,000</td>
                                                    </tr>
                                                    <tr style="border-bottom: 2px solid #ebebeb">
                                                        <td style="padding: 10px 10px 10px 0; font-weight: bold">Intedashboard Premium Account</td>
                                                        <td style="padding: 10px">1</td>
                                                        <td style="padding: 10px 0 10px 10px">SGD 5,000</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td
                                align="left"
                                style="
                                    font-size: 14px;
                                    line-height: 1;
                                    text-align: left;
                                    color: #000000;
                                    padding: 0 20px;
                                    word-break: break-word;
                                    text-align: right;
                                "
                            >
                                <div>Subtotal: SGD 15,000</div>
                            </td>
                        </tr>

                        <tr>
                            <td
                                align="left"
                                style="
                                    font-size: 14px;
                                    line-height: 1;
                                    text-align: left;
                                    color: #000000;
                                    padding: 0 20px;
                                    word-break: break-word;
                                    text-align: right;
                                "
                            >
                                <div style="border-bottom: 2px solid #ebebeb">
                                    <div style="padding: 10px 0">Tax: SGD 100</div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td
                                align="left"
                                style="
                                    font-size: 14px;
                                    line-height: 1;
                                    text-align: left;
                                    color: #000000;
                                    padding: 10px 20px 0 20px;
                                    word-break: break-word;
                                    text-align: right;
                                    font-weight: bold;
                                "
                            >
                                <div>TOTAL: SGD 15,100</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="background: #0078d7; background-color: #0078d7; margin: 0px auto; max-width: 670px">
                <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background-color: #495060; width: 100%">
                    <tbody>
                        <tr>
                            <td style="direction: ltr; font-size: 0px; padding: 10px 0; text-align: center; vertical-align: top">
                                <div
                                    class="mj-column-per-100 outlook-group-fix"
                                    style="font-size: 14px; text-align: left; direction: ltr; display: inline-block; vertical-align: top; width: 100%"
                                >
                                    <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top" width="100%">
                                        <tr>
                                            <td align="left" style="font-size: 0px; word-break: break-word">
                                                <div
                                                    style="
                                                        font-family: 'Open Sans', sans-serif;
                                                        font-size: 12px;
                                                        font-weight: normal;
                                                        line-height: 1;
                                                        text-align: left;
                                                        color: #fff;
                                                    "
                                                >
                                                    <p style="text-align: center; margin: 0">Copyright © CognaLearn Pte Ltd 2019</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            component_done_loading: true,
        };
    },
};
</script>
<style type="text/css">
.receipts {
    background: #f6f8fa;
    font-family: Calibri;
}
.receipts #outlook a {
    padding: 0;
}
.receipts .ReadMsgBody {
    width: 100%;
}
.receipts .ExternalClass {
    width: 100%;
}
.receipts .ExternalClass * {
    line-height: 100%;
}

.receipts table th,
.receipts table td {
    border: 0;
    padding: 0;
}

.receipts table {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    margin: inherit;
    width: inherit;
}
.receipts table,
td {
    border-collapse: collapse;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
}
.receipts img {
    border: 0;
    height: auto;
    line-height: 100%;
    outline: none;
    text-decoration: none;
    -ms-interpolation-mode: bicubic;
}
.receipts p {
    display: block;
    margin: 13px 0;
}

@media only screen and (max-width: 480px) {
    @-ms-viewport {
        width: 320px;
    }
    @viewport {
        width: 320px;
    }
}

@media only screen and (min-width: 480px) {
    .mj-column-per-100 {
        width: 100% !important;
    }
    .mj-column-per-50 {
        width: 50% !important;
    }
    .mj-column-px-600 {
        width: 600px !important;
    }
    .mj-column-per-33 {
        width: 33.33333333333333% !important;
    }
    .mj-column-per-30 {
        width: 30% !important;
    }
    .mj-column-px-500 {
        width: 500px !important;
    }
    .mj-column-6 {
        width: 6.84px !important;
    }
    .mj-column-200 {
        width: 200px !important;
    }
    .mj-column-300 {
        width: 300px !important;
    }
    .mj-column-400 {
        width: 400px !important;
    }
    .priceTable1 {
        width: 70% !important;
    }
    .priceTableAlt {
        width: 15% !important;
    }
}

@media only screen and (max-width: 480px) {
    .receipts .priceTable1 {
        width: 50% !important;
    }
}
</style>
