const testObj = {
    data: {
        uuid: '8b7ee9b2-712c-4896-a4fa-f092410655f9',
        name: 'CIPC Review',
        others: [],
        attendanceStatus: null,
        testUuid: 'b2674cc9-ad71-40ef-909e-3045dcac967a',
        activityUuid: 'f04a5e8e-6adf-4c70-868b-d03d0b0209c6',
        type: 'irat',
        totalMarks: 10,
        status: 'ongoing',
        timeStarted: '2023-08-21T18:30:13Z',
        timeEnded: '2023-09-31T18:40:14Z',
        timePaused: null,
        isVisible: true,
        presentationMode: false,
        isPublished: true,
        allowStudentsToViewAnswer: true,
        allowStudentsToViewScore: false,
        allowStudentsToPreviewQuestions: false,
        allowStudentsToViewAttachments: true,
        allowTeamReporterToMarkAttendance: false,
        clarificationStatus: null,
        plannedDuration: '10 Min, 0 Sec',
        teamUuid: '5f1dd281-1634-452f-b9b8-4b532c2938b4',
        teamsNotAllowedToUpdateTeamLeader: null,
        settings: {
            type: 'synchronous',
            startDate: '2023-08-24T05:52:13Z',
            endDate: '2023-08-24T05:52:13Z',
            instructionIsHTML: true,
            durationDays: 0,
            durationHours: 0,
            durationMinutes: 10,
            hasTimeLimit: true,
            confidenceBasedTesting: false,
            immediateFeedback: false,
            allowStudentsToUploadFiles: true,
            limitTimeByPages: false,
            randomizeQuestionOrder: null,
            randomizeAnswerOptionOrder: false,
            hideQuestionOptionLabels: false,
            allowChangeAnswersBeforeFinalSubmission: true,
            allowViewPreviousQuestions: true,
            allowViewQuestionsAhead: false,
            allowTeamClarifications: null,
            instruction:
                '<p>This is a 10 question, closed-book, single best-answer quiz.&nbsp; Please do not access any resources between IRAT and TRAT.&nbsp; Close your device when done.</p>',
            attachments: [],
            password: false,
            enableMichaelsenPointDistribution: null,
            michaelsenPointDistribution: null,
            isSyncToTeamQuestion: true,
            enableHintAndExplanation: false,
            allowSafeExamBrowserOnly: false,
            allowPdfDownload: false,
            showExplanationsAfterTestHasCompleted: true,
            showHintsAndExplanationsDuringTest: false,
        },
        eGalleryProgression: {
            currentQuestionUuid: null,
        },
        course: {
            name: 'Integrated Pharmacotherapy 1a (AU23)',
        },
        module: {
            name: '8/21/23 Intro',
        },
        myProgression: {
            currentQuestionUuid: null,
            userTestStatus: 'ongoing',
            enteredPassword: null,
            team: [],
            startTime: '2023-08-21T18:30:13Z',
            endTime: '2023-09-31T18:40:13Z',
        },
        questions: [
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation:
                        '<p>Key concept: Understanding proper use of the Pooled Cohort Equation for cardiovascular risk</p>\n<p>Rationale: By definition, the pooled cohort equations can be used to assess cardiovascular risk in patients without pre-existing cardiovascular disease between the ages of 40 and 79 (rules out B and D). You would also not use it in the patient (C) whose LDL is &gt;190 mg/dL thus automatically qualifying him/her into a statin benefit group.&nbsp; That leaves the moderate, maybe high risk patient (A); it is reasonable to consider the equation for this population.</p>',
                    group: 1,
                    hint: null,
                    id: 369800,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content:
                                '<p><span data-contrast=“none“>Moderate-intensity statin</span><span data-ccp-props=“{&quot;134233279&quot;:true,&quot;201341983&quot;:0,&quot;335559739&quot;:160,&quot;335559740&quot;:240}“>&nbsp;</span></p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content: '<p>A history of myocardial infarction</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content: '<p>An LDL cholesterol of 200 mg/dL</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content: '<p>An elevated LD cholesterol diagnosed at 10 years of age</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question: '<p>Is it appropriate to use the Pooled Cohort Equations for ASCVD 10-year risk assessment in patients with:</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: '0723330c-5fe8-4549-915a-cf00007bbac5',
                    votingMethod: null,
                    vsaSettings: {
                        label: null,
                        placeholder: null,
                        wrongAnswers: [],
                        correctAnswers: [],
                    },
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: true,
                        isCompleted: true,
                        lastAnswerKey: 'A',
                        attempts: [
                            {
                                A: 4,
                                B: 0,
                                C: 0,
                                D: 0,
                                undefined: 0,
                            },
                            {
                                A: 4,
                                B: 0,
                                C: 0,
                                D: 0,
                                undefined: 0,
                            },
                            {
                                A: 4,
                                B: 0,
                                C: 0,
                                D: 0,
                                undefined: 0,
                            },
                            {
                                A: 4,
                                B: 0,
                                C: 0,
                                D: 0,
                                undefined: 0,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 4,
                                    B: 0,
                                    C: 0,
                                    D: 0,
                                    undefined: 0,
                                },
                                timestamp: '2023-08-21 18:30:39',
                            },
                            {
                                attempt: {
                                    A: 4,
                                    B: 0,
                                    C: 0,
                                    D: 0,
                                    undefined: 0,
                                },
                                timestamp: '2023-08-21 18:30:51',
                            },
                            {
                                attempt: {
                                    A: 4,
                                    B: 0,
                                    C: 0,
                                    D: 0,
                                    undefined: 0,
                                },
                                timestamp: '2023-08-21 18:31:02',
                            },
                            {
                                attempt: {
                                    A: 4,
                                    B: 0,
                                    C: 0,
                                    D: 0,
                                    undefined: 0,
                                },
                                timestamp: '2023-08-21 18:31:17',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation:
                        '<p>Key concept: Recognizing conditions categorized as clinical ASCVD.</p>\n<p>Rationale: Conditions making of clinical ASCVD include stable/unstable angina, coronary revascularization (stent), acute coronary syndromes, myocardial infarction, Stroke/transient ischemic attack (TIA), and peripheral artery disease (PAD).</p>',
                    group: 2,
                    hint: null,
                    id: 369801,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content: '<p>Congestive heart failure</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content: '<p>Diabetes mellitus</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content: '<p>Venous thromboembolism</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content: '<p>Ischemic Stroke</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question: '<p>A condition below which is included in the clinical definition of ASCVD is:</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: '435d6482-beb2-49a6-86b3-7e37ae952c0f',
                    votingMethod: null,
                    vsaSettings: {
                        label: null,
                        placeholder: null,
                        wrongAnswers: [],
                        correctAnswers: [],
                    },
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: false,
                        isCompleted: true,
                        lastAnswerKey: 'C',
                        attempts: [
                            {
                                A: 0,
                                B: 0,
                                C: 4,
                                D: 0,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 0,
                                    B: 0,
                                    C: 4,
                                    D: 0,
                                },
                                timestamp: '2023-08-21 18:32:47',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation: '<p>Key concept: Recall target goal for blood pressure.</p>\n<p>Rationale: See 2017 ACC/AHA HTN Guidelines</p>',
                    group: 3,
                    hint: null,
                    id: 369802,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content: '<p>&lt;130/80 mmHg</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content: '<p>&lt;120/80 mmHg</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content: '<p>&lt;150/90 mmHg</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content: '<p>&lt;140/90 mmHg</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question:
                        '<p>According to the 2017 ACC/AHA Hypertension Guidelines, the target blood pressure for a 69 year old patient diagnosed with hypertension is:</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: 'd9251f13-a80e-4c59-86c9-952b8cfc521e',
                    votingMethod: null,
                    vsaSettings: [],
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: true,
                        isCompleted: true,
                        lastAnswerKey: 'A',
                        attempts: [
                            {
                                A: 4,
                                B: 0,
                                C: 0,
                                D: 0,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 4,
                                    B: 0,
                                    C: 0,
                                    D: 0,
                                },
                                timestamp: '2023-08-21 18:32:56',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation:
                        '<p>Key concept: Applying staging criteria to categorize blood pressures.</p>\n<p>Rationale: Reference 2017 ACC/AHA Guidelines Made Simple for Table: Categoreis of BP in Adults</p>',
                    group: 4,
                    hint: null,
                    id: 369803,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content: '<p>Stage 2 hypertension</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content: '<p>Elevated blood pressure</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content: '<p>Stage 1 hypertension</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content: '<p>Normal blood pressure</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question: '<p>Consistent blood pressure readings of 132-137/80-85 mmHg are in the blood pressure category of:</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: '336b088d-ddb5-4efe-93a2-d71aedfc33fd',
                    votingMethod: null,
                    vsaSettings: {
                        label: null,
                        placeholder: null,
                        wrongAnswers: [],
                        correctAnswers: [],
                    },
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: true,
                        isCompleted: true,
                        lastAnswerKey: 'C',
                        attempts: [
                            {
                                A: 0,
                                B: 0,
                                C: 4,
                                D: 0,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 0,
                                    B: 0,
                                    C: 4,
                                    D: 0,
                                },
                                timestamp: '2023-08-21 18:33:18',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation:
                        '<p>Key Concept: Understanding guideline algorithm for initiation of anti-hypertensive therapy.</p>\n<p>Rationale: Patient with elevated blood pressure or Stage 1 hypertension with ASCVD risk less than 10% can be initially be managed on nonpharmacologic therapy (rules out B). Patient with Stage 1 hypertension with ASCVD risk greater than or equal to 10% should start nonpharmacologic therapy AND BP-lowering medication (correct answer A).&nbsp; Patients with Stage 2 hypertension start nonpharmacologic therapy and 1-2 BP-lowering medications (ruling out D). Answer C is simply not true, as discussed ASCVD risk comes into play in Stage 1 hypertension therapy decisions.</p>',
                    group: 5,
                    hint: null,
                    id: 369804,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content:
                                '<p>Adults with Stage 1 Hypertension with an estimated 10-year ASCVD risk \u2265 10% should be managed with a combination of nonpharmacological and antihypertensive drug therapy.</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content:
                                '<p>Adults with an elevated blood pressure or Stage 1 Hypertension with an estimated 10-year ASCVD risk &lt; 10% should be initiated on pharmacological therapy immediately.</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content:
                                '<p>A patient\u2019s 10- year ASCVD risk score should not inform a clinician\u2019s decision to initiate anti-hypertensive therapy.</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content:
                                '<p>Adults with Stage 2 Hypertension and an estimated 10-year ASCVD risk &lt; 10% should initially be managed with nonpharmacological therapy with close follow-up.</p>\n<p>&nbsp;</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question:
                        '<p>Which of the following statements is true regarding the use of a patient\u2019s 10-year ASCVD risk score to initiate antihypertensive therapy?</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: '4f88a9ab-80d3-4666-8aea-ad6e11c60c84',
                    votingMethod: null,
                    vsaSettings: [],
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: true,
                        isCompleted: true,
                        lastAnswerKey: 'A',
                        attempts: [
                            {
                                A: 4,
                                B: 0,
                                C: 0,
                                D: 0,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 4,
                                    B: 0,
                                    C: 0,
                                    D: 0,
                                },
                                timestamp: '2023-08-21 18:33:44',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation:
                        '<p>Key concept: Categorizing patients into statin benefit groups.</p>\n<p>Rationale: Statin benefit groups include thos who have clinical ASCVD, those whose LDL is greater than or equal to 190 mg/dL, those age 40 - 79 YO with diabetes and LDL 70 - 189 without clinical ASCVD, and patients without clinical ASCVD or diabetes, but 40 to 75 YO with LDL 70 to 189 mg/dL and a 10-year ASCVD risk of 7.5% or higher.</p>',
                    group: 6,
                    hint: null,
                    id: 369805,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content: '<p>35 year old female with risk factors for coronary artery disease</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content: '<p>45 year old male with type 2 diabetes</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content: '<p>24 year old female with LDL-C 180 mg/dL</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content: '<p>45 year old male with 10-y ASCVD risk &lt;5%</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question: '<p>The patient who qualifies for inclusion in one of the statin benefit groups is:</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: 'ab137cba-ae6b-46b4-af16-e53a360ad48e',
                    votingMethod: null,
                    vsaSettings: [],
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: true,
                        isCompleted: true,
                        lastAnswerKey: 'B',
                        attempts: [
                            {
                                A: 0,
                                B: 4,
                                C: 0,
                                D: 0,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 0,
                                    B: 4,
                                    C: 0,
                                    D: 0,
                                },
                                timestamp: '2023-08-21 18:33:58',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation:
                        '<p>Key concept: Recognizing intensities of common statins.</p>\n<p>Rationale: See statin comparisons table provided in lecture with statin intensities color-coded.</p>',
                    group: 7,
                    hint: null,
                    id: 369806,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content: '<p>Pravastatin 20 mg; moderate intensity</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content: '<p>Simvastatin 40 mg; high intensity</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content: '<p>Atorvastatin 10 mg; moderate intensity</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content: '<p>Rosuvastatin 10 mg;&nbsp; low intensity</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question: '<p>Which of the following pairings of statin dosage/expected LDL lowering (AKA "intensity") is correct?</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: 'd3a42d56-4e01-4e8f-b24a-6ed214a0b45c',
                    votingMethod: null,
                    vsaSettings: {
                        label: null,
                        placeholder: null,
                        wrongAnswers: [],
                        correctAnswers: [],
                    },
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: true,
                        isCompleted: true,
                        lastAnswerKey: 'C',
                        attempts: [
                            {
                                A: 0,
                                B: 0,
                                C: 4,
                                D: 0,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 0,
                                    B: 0,
                                    C: 4,
                                    D: 0,
                                },
                                timestamp: '2023-08-21 18:34:20',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation:
                        '<p>Key concept: Identifying proper statin therapy in secondary prevention patients.</p>\n<p>Rationale: Secondary prevention patients are at increased risk for recurrent events and so high intensity statins (Atorva 40mg or 80 mg and rosuva 20mg and 40mg) are most appropriate.</p>',
                    group: 8,
                    hint: null,
                    id: 369808,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content: '<p>No statin is indicated</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content: '<p>Low intensity statin</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content: '<p>Moderate intensity statin</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content: '<p>High intensity statin</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question:
                        '<p>What intensity statin is most appropriate for a secondary prevention patient, according to the 2018 Blood Cholesterol guidelines?</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: '91f0ca3e-a467-4434-9a3c-437ae4e09354',
                    votingMethod: null,
                    vsaSettings: [],
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: true,
                        isCompleted: true,
                        lastAnswerKey: 'D',
                        attempts: [
                            {
                                A: 0,
                                B: 0,
                                C: 0,
                                D: 4,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 0,
                                    B: 0,
                                    C: 0,
                                    D: 4,
                                },
                                timestamp: '2023-08-21 18:34:34',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation:
                        '<p>Key concept: Recognizing diagnostic criteria for diabetes.</p>\n<p>&nbsp;</p>\n<p>Rationale: An A1C indicative of Pre-Diabetes falls in the 5.7 \u2013 6.4% range.&nbsp; An A1C indicating diabetes would be greater than or equal to 6.5%.&nbsp; Normal A1C is around 5%.&nbsp;</p>',
                    group: 9,
                    hint: null,
                    id: 369809,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content: '<p>&lt;4.5%</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content: '<p>4.5% - 5.6%</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content: '<p>5.7% - 6.4%</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content: '<p>&gt; 6.5%</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question: '<p>Pre-diabetes would be diagnosed with an A1C in which of the following ranges?</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: '814915e5-39b4-406f-9ede-9a0a250835b7',
                    votingMethod: null,
                    vsaSettings: [],
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: true,
                        isCompleted: true,
                        lastAnswerKey: 'C',
                        attempts: [
                            {
                                A: 0,
                                B: 0,
                                C: 4,
                                D: 0,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 0,
                                    B: 0,
                                    C: 4,
                                    D: 0,
                                },
                                timestamp: '2023-08-21 18:34:46',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
            [
                {
                    assignedTo: null,
                    attachments: [],
                    canAllowStudentRichTextResponses: 0,
                    difficultyLevel: 1,
                    displayAnswer: true,
                    displayAnswerStatistics: true,
                    displayComments: false,
                    displayKeywords: false,
                    displayVotes: false,
                    eGalleryStatus: null,
                    eGalleryWalkSettings: null,
                    explanation:
                        '<p>Key concept: Characteristics of NRT therapy &nbsp;</p>\n<p>Rationale: A is incorrect because dosing for patches is determined by number of cigarettes smoker per day. B is incorrect because NRT is excluded from the Ohio law limiting sale of tobacco products to those over 21 years of age. C is correct, combination NRT therapy is more effective than single NRT therapy. D is incorrect because dosing for lozenges is determined by time to first cigarette. &nbsp;</p>',
                    group: 10,
                    hint: null,
                    id: 369810,
                    maximum: 100,
                    minimum: 1,
                    options: [
                        {
                            key: 'A',
                            tags: [],
                            content: '<p>Dosing for patches is determined by time to first cigarette</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'B',
                            tags: [],
                            content: '<p>In Ohio NRT can only be purchased if over the age of 21</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'C',
                            tags: [],
                            content: '<p>NRT is more effective when a long-acting patch is combined with a short-acting agent</p>',
                            remarks: null,
                            contentIsHTML: true,
                        },
                        {
                            key: 'D',
                            tags: [],
                            content: '<p>Dosing for lozenges/gum is determined by number of cigarettes smoked per day</p>',
                            remarks: null,
                            contentIsHTML: true,
                            retainLastOptionSequence: false,
                        },
                    ],
                    order: 1,
                    question: '<p>Which of the following statements about NRT is accurate?</p>',
                    rankingValue: null,
                    ratingSettings: [],
                    settings: {
                        irat: {
                            point: 1,
                        },
                        trat: {
                            points: [5, 3, 1, 0],
                        },
                    },
                    tags: [],
                    target: null,
                    topics: ['cipc review'],
                    type: 'mcqs',
                    uuid: 'd787b5e8-3b56-48bc-a24b-c9da7013d203',
                    votingMethod: null,
                    vsaSettings: {
                        label: null,
                        placeholder: null,
                        wrongAnswers: [],
                        correctAnswers: [],
                    },
                    isAssignedToMe: false,
                    studentAnswer: {
                        isCorrect: true,
                        isCompleted: true,
                        lastAnswerKey: 'C',
                        attempts: [
                            {
                                A: 0,
                                B: 0,
                                C: 4,
                                D: 0,
                            },
                        ],
                        attempts2: [
                            {
                                attempt: {
                                    A: 0,
                                    B: 0,
                                    C: 4,
                                    D: 0,
                                },
                                timestamp: '2023-08-21 18:35:13',
                            },
                        ],
                        comments: null,
                        clarifications: null,
                        attachments: null,
                        isBookmarked: false,
                    },
                    hintOrExplanation: null,
                },
            ],
        ],
    },
};
export { testObj };
