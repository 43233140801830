<template>
    <div v-if="component_done_loading">
        <!-- v-shortkey.propagte="{left:['arrowleft'],right:['arrowright']}" @shortkey="paginationAccessibility" -->
        <template v-for="(question, idx) in testObj.questions[current_question_group_idx]">
            <div class="visible-hidden" role="alert" aria-live="assertive">
                You are now at Question
                {{ getQuestionNo(current_question_group_idx, current_question_idx) }}
            </div>

            <question
                :test-obj="testObj"
                :question="question"
                :question_group_idx="current_question_group_idx"
                :question_idx="idx"
                :temp-answers="tempAnswers"
                :typing="typing"
                :shared-obj="sharedObj"
                @change-question="changeQuestion"
            />
        </template>
    </div>
</template>
<script>
import { testMixins } from './../../../../../../mixins/test';
export default {
    mixins: [testMixins],
    props: ['testObj', 'fetchTest'],
    components: {
        question: require(`./question/question.vue`).default,
    },
    data() {
        var that = this;
        return {
            component_done_loading: false,
            tempAnswers: [],
            typing: false,
            sharedObj: { isFormBusy: false },
            questionInView: [],
        };
    },
    mounted() {
        var that = this;
        this.init();
        Events.listen('test-restarted', function () {
            that.component_done_loading = false;
            Vue.nextTick(function () {
                that.init();
                that.component_done_loading = true;
            });
        });
        Events.listen('change-question', function (to) {
            let bool = true;
            if (that.$route.query.faker !== undefined) {
                bool = false;
            }
            if ((that.testObj.type == 'trat' || (that.testObj.type == 'application' && that.testObj.others.applicationType == 'team')) && !that.isTeamLeader) {
                bool = false;
            }
            if (
                (that.testObj.type == 'trat' || (that.testObj.type == 'application' && that.testObj.others.applicationType == 'team')) &&
                that.isTestCompleted
            ) {
                bool = false;
            }
            that.changeQuestion(to, bool);
        });
        this.processEchoListeners();
        this.component_done_loading = true;

        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        Events.off('test-restarted', this.init);
        Events.off('change-question');
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        processEchoListeners() {
            var that = this;
            if (this.isPreview) {
                return false;
            }
            if (this.testObj.type == 'trat' || (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team')) {
                let h1 = (e) => {
                    that.fetchTest().then(function (response) {
                        let test = response.data.data;
                        for (var i = 0; i < that.testObj.questions.length; i++) {
                            for (var j = 0; j < that.testObj.questions[i].length; j++) {
                                if (_.isEmpty(that.testObj.questions[i][j].studentAnswer)) {
                                    that.testObj.questions[i][j].studentAnswer = {
                                        attempts: [],
                                        isCorrect: null,
                                        attachments: [],
                                        clarifications: '',
                                        comments: '',
                                    };
                                }
                                if (_.isEmpty(test.questions[i][j].studentAnswer)) {
                                    test.questions[i][j].studentAnswer = {
                                        attempts: [],
                                        isCorrect: null,
                                        attachments: [],
                                        clarifications: '',
                                        comments: '',
                                    };
                                }
                                if (test.questions[i][j].studentAnswer || !that.isTeamLeader) {
                                    if (that.testObj.questions[i][j].type != 'mcqm') {
                                        let a = test.questions[i][j].studentAnswer.attempts.length - 1;
                                        if (a > -1) {
                                            that.tempAnswers[i][j] = test.questions[i][j].studentAnswer.attempts[a];
                                        }
                                    } else {
                                        //mcqm array of arrays
                                        let attempts = _.get(test.questions[i][j].studentAnswer, 'attempts', []);
                                        if (attempts.length > 0) {
                                            that.tempAnswers[i][j] = attempts[attempts.length - 1];
                                        }
                                    }
                                }
                                that.testObj.questions[i][j].studentAnswer.attempts = test.questions[i][j].studentAnswer.attempts;
                                /*Vue.set(
                                    that.testObj.questions[i][j].studentAnswer,
                                    'attempts',
                                    test.questions[i][j].studentAnswer.attempts
                                );*/
                                that.testObj.questions[i][j].studentAnswer.isCorrect = test.questions[i][j].studentAnswer.isCorrect;
                                that.testObj.questions[i][j].studentAnswer.attempts2 = test.questions[i][j].studentAnswer.attempts2;
                                that.testObj.questions[i][j].hintOrExplanation = test.questions[i][j].hintOrExplanation;
                            }
                        }
                    });
                };
                let c1 = window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.teamUuid}`).listen('TeamAnswerSaved', h1);
                this.echoChannels.push({
                    channel: c1,
                    event: 'TeamAnswerSaved',
                    handler: h1,
                });

                let h2 = (e) => {
                    if (!this.testObj.settings.isSyncToTeamQuestion || this.isTeamLeader) {
                        return false;
                    }
                    for (var i = 0; i < this.testObj.questions.length; i++) {
                        for (var j = 0; j < this.testObj.questions[i].length; j++) {
                            if (this.testObj.questions[i][j].uuid == e.currentQuestionUuid) {
                                this.changeQuestion({ question_group_idx: i, question_idx: j }, false);
                                return;
                            }
                        }
                    }
                };
                let c2 = window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.teamUuid}`).listen('TeamCurrentQuestionUpdated', h2);
                this.echoChannels.push({
                    channel: c2,
                    event: 'TeamCurrentQuestionUpdated',
                    handler: h2,
                });

                let h3 = (e) => {
                    that.testObj.myProgression.userTestStatus = e.userPlacementTests.status;
                    if (e.userPlacementTests.status == 'completed' && !that.testObj._isSubmitting) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Your team has successfully finished the test',
                        });
                        if (that.testObj.type == 'trat' && that.testObj.settings.allowTeamClarifications) {
                            that.$router.push({
                                name: 'tests.clarification',
                                params: { id: that.testObj.uuid },
                            });
                        } else {
                            that.$router.push({ name: 'tests.index' });
                        }
                    }
                };
                let c3 = window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.teamUuid}`).listen('StudentTestStatusUpdated', h3);
                this.echoChannels.push({
                    channel: c3,
                    event: 'StudentTestStatusUpdated',
                    handler: h3,
                });

                if (this.testObj.type == 'trat') {
                    let h4 = (e) => {
                        for (var i = 0; i < this.testObj.questions.length; i++) {
                            for (var j = 0; j < this.testObj.questions[i].length; j++) {
                                if (this.testObj.questions[i][j].uuid == e.activityQuestionUuid) {
                                    if (_.isEmpty(this.testObj.questions[i][j].studentAnswer)) {
                                        this.testObj.questions[i][j].studentAnswer = {
                                            attempts: [],
                                            isCorrect: null,
                                            attachments: [],
                                            clarifications: '',
                                            comments: '',
                                        };
                                    }
                                    this.testObj.questions[i][j].studentAnswer.isBookmarked = e.answer.isBookmarked;
                                    return;
                                }
                            }
                        }
                    };
                    let c4 = window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.teamUuid}`).listen('AnswerBookmarked', h4);
                    this.echoChannels.push({
                        channel: c4,
                        event: 'AnswerBookmarked',
                        handler: h4,
                    });

                    let h5 = (e) => {
                        for (var i = 0; i < this.testObj.questions.length; i++) {
                            for (var j = 0; j < this.testObj.questions[i].length; j++) {
                                if (this.testObj.questions[i][j].uuid == e.activityQuestionUuid) {
                                    if (_.isEmpty(this.testObj.questions[i][j].studentAnswer)) {
                                        this.testObj.questions[i][j].studentAnswer = {
                                            attempts: [],
                                            isCorrect: null,
                                            attachments: [],
                                            clarifications: '',
                                            comments: '',
                                        };
                                    }
                                    this.testObj.questions[i][j].studentAnswer.clarifications = e.answer.clarifications;
                                    this.testObj.questions[i][j].studentAnswer.attachments = e.answer.attachments;
                                    if (!this.isTeamLeader) {
                                        this.testObj.questions[i][j].studentAnswer._clarifications = e.answer.clarifications;
                                        this.testObj.questions[i][j].studentAnswer._attachments = e.answer.attachments;
                                    }
                                    return;
                                }
                            }
                        }
                    };
                    let c5 = window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.teamUuid}`).listen('ClarificationSaved', h5);
                    this.echoChannels.push({
                        channel: c5,
                        event: 'ClarificationSaved',
                        handler: h5,
                    });
                }
                if (this.testObj.type == 'application') {
                    let h6 = (e) => {
                        if (!that.isTeamLeader) {
                            this.typing = e;
                            if (this.typingTimer) clearTimeout(this.typingTimer);
                            this.typingTimer = setTimeout(() => {
                                this.typing = false;
                            }, 2000);
                            for (var i = 0; i < that.testObj.questions.length; i++) {
                                for (var j = 0; j < that.testObj.questions[i].length; j++) {
                                    if (that.testObj.questions[i][j].uuid == e.currentQuestionUuid) {
                                        that.tempAnswers[i][j] = e.answer;
                                        that.tempAnswers.splice();
                                        return;
                                    }
                                }
                            }
                        }
                    };
                    let c6 = window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.teamUuid}`).listenForWhisper('typing', h6);
                    this.echoChannels.push({
                        channel: c6,
                        event: 'typing',
                        handler: h6,
                    });

                    let h7 = (e) => {
                        for (var i = 0; i < this.testObj.questions.length; i++) {
                            for (var j = 0; j < this.testObj.questions[i].length; j++) {
                                if (this.testObj.questions[i][j].uuid == e.activityQuestionUuid) {
                                    if (_.isEmpty(this.testObj.questions[i][j].studentAnswer)) {
                                        this.testObj.questions[i][j].studentAnswer = {
                                            attempts: [],
                                            isCorrect: null,
                                            attachments: [],
                                            clarifications: '',
                                            comments: '',
                                        };
                                    }
                                    this.testObj.questions[i][j].studentAnswer.comments = e.answer.comments;
                                    this.testObj.questions[i][j].studentAnswer.attachments = e.answer.attachments;
                                    if (!this.isTeamLeader) {
                                        this.testObj.questions[i][j].studentAnswer._comments = e.answer.comments;
                                        this.testObj.questions[i][j].studentAnswer._attachments = e.answer.attachments;
                                    }
                                    return;
                                }
                            }
                        }
                    };
                    let c7 = window.Echo.join(`test.${this.testObj.testUuid}.team.${this.testObj.teamUuid}`).listen('ElaborationSaved', h7);
                    this.echoChannels.push({
                        channel: c7,
                        event: 'ElaborationSaved',
                        handler: h7,
                    });
                }
            } else {
                let h8 = (e) => {
                    that.testObj.myProgression.userTestStatus = e.userPlacementTests.status;
                    if (e.userPlacementTests.status == 'completed' && !that.testObj._isSubmitting) {
                        if (that.testObj.type == 'trat' || (that.testObj.type == 'application' && that.testObj.others.applicationType == 'team')) {
                            that.$notify({
                                group: 'form',
                                type: 'success',
                                title: 'Success',
                                text: 'Your team has successfully finished the test',
                            });
                        } else {
                            that.$notify({
                                group: 'form',
                                type: 'success',
                                title: 'Success',
                                text: 'You have finished the test on a different tab',
                            });
                        }
                        if (that.testObj.type == 'trat' && that.testObj.settings.allowTeamClarifications) {
                            that.$router.push({
                                name: 'tests.clarification',
                                params: { id: that.testObj.uuid },
                            });
                        } else {
                            that.$router.push({ name: 'tests.index' });
                        }
                    }
                };
                let c8 = window.Echo.private(`placementtest.${this.testObj.uuid}`).listen('StudentTestStatusUpdated', h8);
                this.echoChannels.push({
                    channel: c8,
                    event: 'StudentTestStatusUpdated',
                    handler: h8,
                });
            }
        },
        paginationAccessibility(event) {
            var that = this;
            if (($('#test_confirm_modal').data('bs.modal') || {}).isShown) {
                return;
            }
            switch (event.srcKey) {
                case 'left':
                    if (that.canChangeQuestion(that.previous_question_group_idx, that.previous_question_idx)) {
                        that.moveToDifferentQuestion(that.previous_question_group_idx, that.previous_question_idx);
                    }
                    break;
                case 'right':
                    if (that.canChangeQuestion(that.next_question_group_idx, that.next_question_group_idx)) {
                        that.moveToDifferentQuestion(that.next_question_group_idx, that.next_question_idx);
                    }
                    break;
            }
        },
        init() {
            var that = this;
            let currentQuestionUuid = _.get(this.testObj, 'myProgression.currentQuestionUuid', false);
            if (currentQuestionUuid == null) {
                this.changeQuestion({ question_group_idx: 0, question_idx: 0 }, false);
            }
            for (var i = 0; i < this.testObj.questions.length; i++) {
                this.tempAnswers.push([]);
                for (var j = 0; j < this.testObj.questions[i].length; j++) {
                    let question = this.testObj.questions[i][j];
                    //get current saved question position
                    if (currentQuestionUuid) {
                        if (question.uuid == currentQuestionUuid) {
                            var q_group_idx = i;
                            var q_idx = j;
                            Vue.nextTick(function () {
                                that.changeQuestion(
                                    {
                                        question_group_idx: q_group_idx,
                                        question_idx: q_idx,
                                    },
                                    false
                                );
                            });
                        }
                    }
                    if (_.isEmpty(question.studentAnswer)) {
                        question.studentAnswer = {
                            attempts: [],
                            clarifications: '',
                            comments: '',
                            attachments: [],
                        };
                        /*Vue.set(question, 'studentAnswer', {
                            attempts: [],
                            clarifications: '',
                            comments: '',
                            attachments: [],
                        });*/
                    }
                    let storedAnswer = _.get(question, 'studentAnswer.attempts', []);
                    if (storedAnswer.length == 0) {
                        if (this.testObj.settings.confidenceBasedTesting && ['mcqs'].includes(question.type)) {
                            //irat confidence based testing
                            this.tempAnswers[i][j] = {};
                            for (var k = 0; k < question.options.length; k++) {
                                this.tempAnswers[i][j][question.options[k].key] = 0;
                            }
                        } else if (['mcqs', 'openended'].includes(question.type)) {
                            this.tempAnswers[i][j] = '';
                        } else if (['sequence', 'mcqm', 'matching'].includes(this.testObj.questions[i][j].type)) {
                            this.tempAnswers[i][j] = [];

                            if (this.testObj.questions[i][j].type == 'sequence') {
                                for (var k = 0; k < this.testObj.questions[i][j].options.length; k++) {
                                    this.tempAnswers[i][j].push(null);
                                }
                            }

                            if (this.testObj.questions[i][j].type == 'matching') {
                                if (this.isTeacherPreview) {
                                    this.testObj.questions[i][j].options.matches.forEach((content, idx) => {
                                        this.tempAnswers[i][j].push({ prompt: content.prompt.key, answer: [] });
                                    });
                                } else {
                                    this.testObj.questions[i][j].options.matches.prompts.forEach((content, idx) => {
                                        this.tempAnswers[i][j].push({ prompt: content.key, answer: [] });
                                    });
                                }
                            }
                        }
                    } else {
                        this.tempAnswers[i][j] = _.cloneDeep(storedAnswer[storedAnswer.length - 1]);
                        if (['mcqs'].includes(question.type) && this.testObj.settings.confidenceBasedTesting) {
                            //remove errant saved keys that are not in options.key
                            const keys = Object.keys(this.tempAnswers[i][j]);
                            keys.forEach((key, index) => {
                                let idx = _.findIndex(question.options, function (o) {
                                    return o.key == key;
                                });
                                if (idx == -1) {
                                    delete this.tempAnswers[i][j][key];
                                }
                            });
                        }
                    }
                    if (!_.isArray(question.studentAnswer.attachments)) {
                        question.studentAnswer.attachments = [];
                    }
                    //initialize
                    question.studentAnswer._clarifications = _.get(question, 'studentAnswer.clarifications', '');
                    question.studentAnswer._comments = _.get(question, 'studentAnswer.comments', '');
                    question.studentAnswer._attachments = _.get(question, 'studentAnswer.attachments', []);
                    /*Vue.set(
                        question.studentAnswer,
                        '_clarifications',
                        _.get(question, 'studentAnswer.clarifications', '')
                    );
                    Vue.set(
                        question.studentAnswer,
                        '_comments',
                        _.get(question, 'studentAnswer.comments', '')
                    );
                    Vue.set(
                        question.studentAnswer,
                        '_attachments',
                        _.clone(
                            _.get(question, 'studentAnswer.attachments', [])
                        )
                    );*/
                    //randomize question order
                    if (this.testObj.type == 'irat' || (this.testObj.type == 'trat' && this.isPreview)) {
                        if (this.testObj.settings.randomizeAnswerOptionOrder) {
                            let len = question.options.length;
                            let retainLastOptionSequence = _.get(question.options, len - 1 + '.retainLastOptionSequence', false);
                            let lastOption = {};
                            if (retainLastOptionSequence) {
                                lastOption = question.options.pop();
                            }
                            question.options = _.cloneDeep(_.shuffle(question.options));
                            if (retainLastOptionSequence) {
                                question.options.push(lastOption);
                            }
                            for (var z = 0; z < question.options.length; z++) {
                                if (!question.options[z].originalKey) {
                                    question.options[z].originalKey = _.cloneDeep(question.options[z].key);
                                }
                                question.options[z].key = String.fromCharCode(65 + z);
                            }

                            let curr_ans = false;
                            if (!this.testObj.settings.confidenceBasedTesting) {
                                curr_ans = _.find(question.options, function (o) {
                                    return o.originalKey == that.tempAnswers[i][j];
                                });
                                if (curr_ans) {
                                    this.tempAnswers[i][j] = curr_ans.key;
                                }
                            } else {
                                let cloneTempAnswers = _.cloneDeep(this.tempAnswers[i][j]);
                                //console.log(cloneTempAnswers);

                                for (var z = 0; z < question.options.length; z++) {
                                    this.tempAnswers[i][j][question.options[z].key] = cloneTempAnswers[question.options[z].originalKey];
                                }
                                //console.log(this.tempAnswers[i][j]);
                            }
                        }
                    }
                }
            }
        },
        changeQuestion(to, sendToAPI = true) {
            this.current_question_group_idx = to.question_group_idx;
            this.current_question_idx = to.question_idx;
            Events.fire('current-question-changed', {
                question_group_idx: to.question_group_idx,
                question_idx: to.question_idx,
            });
            if (sendToAPI && !this.isPreview) {
                if (this.testObj.type == 'irat') {
                    this.testObj.myProgression.currentQuestionUuid = this.testObj.questions[this.current_question_group_idx][this.current_question_idx].uuid;
                }
                axios
                    .put('student/tests/' + this.testObj.uuid + '/update/current-question', {
                        currentQuestionUuid: this.testObj.questions[this.current_question_group_idx][this.current_question_idx].uuid,
                    })
                    .then(function (response) {});
            }
        },
        handleScroll() {
            this.testObj.questions[this.current_question_group_idx].forEach((question, idx) => {
                const sectionEl = document.getElementById(question.uuid + '-question');
                const rect = sectionEl.getBoundingClientRect();
                const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
                this.questionInView[idx] = isInView;
                //this.$set(this.questionInView, idx, isInView);
                if (isInView) {
                    Events.fire('current-question-changed', {
                        question_group_idx: this.current_question_group_idx,
                        question_idx: idx,
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss">
body {
    &.test {
        .panel {
            height: 500px;
            border-radius: 3px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            background-color: #ffffff;
            border: 1px solid #ededed;

            &:focus:focus-visible {
                outline: 4px solid #1941a6;
                outline-offset: 3px;
                box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
            }
        }
    }
}

body.test .panel-heading {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
}

body.test .panel-body {
    white-space: pre-line;
    padding: 15px 20px;
}

body.test .panel-footer {
    padding: 20px;
    border-top: 1px solid #d8d8d8;
    box-shadow: 0 -2px 4px 0 rgba(168, 168, 168, 0.5);
    background: #fff;
}
</style>
