<template>
    <div>
        <kr-search
            ref="templates_listing"
            name="templates_listing"
            :url="{
                url: '/templates',
            }"
            :columns="columns"
            :options="options"
            @retrieve-data="getSearchData"
        >
            <template #ndata="props">
                <tr>
                    <td :colspan="props.model.total_column_count" class="borderNone">No template match your search / filter</td>
                </tr>
            </template>
            <template #lower-left>
                <div class="viewFilter marginLeft20">
                    <div class="form-group displayContent">
                        <div class="form-dropdown viewFilter marginLeft0">
                            <select
                                v-model="query_fields.template_type"
                                class="form-control"
                                :aria-label="'Show ' + query_fields.template_type + ' templates'"
                                @change="$refs['templates_listing'].debounced_search(true)"
                            >
                                <option value="all">All</option>
                                <option value="intedashboard">InteDashboard</option>
                                <option value="account">Non-InteDashboard</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="viewFilter marginLeft20">
                    <div class="form-group displayContent">
                        <div class="form-dropdown viewFilter marginLeft0">
                            <select
                                v-model="query_fields.activity_type"
                                class="form-control"
                                :aria-label="'Show ' + query_fields.activity_type + ' activities'"
                                @change="$refs['templates_listing'].debounced_search(true)"
                            >
                                <option value="">All Activity Type</option>
                                <option value="irat">IRAT</option>
                                <option value="trat">TRAT</option>
                                <option value="iratntrat">IRAT & TRAT</option>
                                <option value="application">Application</option>
                                <option value="peerEvaluationV2">Peer Evaluation</option>
                            </select>
                        </div>
                    </div>
                </div>
            </template>
            <template #upper-right>
                <router-link v-slot="{ navigate }" :to="{ name: 'templates.create' }" custom>
                    <button class="btn btn-success" :disabled="auth.isImpersonation() ? true : undefined" @click="navigate">
                        <i class="fa fa-plus marginRight8" aria-label="Add" /> New Template
                    </button>
                </router-link>
            </template>
            <template #table-title="props">
                <th colspan="6">
                    <template v-if="props.model.meta.paginator.total > 0">
                        Showing {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </template>
                </th>
            </template>
            <template #bottom-display-from-to="props">
                <div v-if="props.model.meta.paginator.total > 0" class="displayNo fontBold">
                    Showing {{ props.model.from }} - {{ props.model.to }} of
                    {{ props.model.meta.paginator.total }}
                </div>
            </template>
            <template #cdata="props">
                <td>
                    {{ props.model.name }}
                </td>
                <td>
                    <template v-if="props.model.description">
                        <p class="whiteSpacePreLine limit4Lines">
                            {{ props.model.description }}
                        </p>
                    </template>
                    <template v-else> - </template>
                </td>
                <td>
                    <template v-if="props.model.activityType == 'irat'"> IRAT </template>
                    <template v-else-if="props.model.activityType == 'trat'">TRAT </template>
                    <template v-else-if="props.model.activityType == 'iratntrat'"> IRAT & TRAT </template>
                    <template v-else-if="props.model.activityType == 'application'"> Application </template>
                    <template v-else-if="props.model.activityType == 'peerEvaluationV2'"> Peer Evaluation </template>
                    <template v-else-if="props.model.activityType == 'instructorEvaluation'"> Instructor Evaluation </template>
                    <template v-else-if="props.model.activityType == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                    <template v-else-if="props.model.activityType == 'evaluation'"> 360 Degree Evaluation </template>
                </td>
                <td>
                    <p v-if="!props.model.defaultTemplate" v-html="highlight(props.model.owner)" />
                    <template v-else>
                        <div class="tableStatusTag tableStatusTag-primary displayInlineBlock">InteDashboard</div>
                    </template>
                </td>
                <td>{{ props.model.questionsCount }}</td>
                <td class="py-9px">
                    <div class="dropdown setting dropdown-new" @click.prevent="toggleDropdown">
                        <button
                            id="viewDropdownMenuButton"
                            class="btn drop-down dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                            <ul>
                                <li>
                                    <router-link
                                        class="buttonRole primary-state"
                                        :to="{
                                            name: 'templates.view',
                                            params: { id: props.model.uuid },
                                        }"
                                    >
                                        <i class="fas fa-eye marginRight8" aria-hidden="true"></i>View
                                    </router-link>
                                </li>

                                <li>
                                    <button
                                        v-tooltip="props.model.questionsCount == 0 ? 'No question in the Template' : ''"
                                        class="buttonRole primary-state"
                                        :class="{
                                            'disabled hasToolTip': props.model.questionsCount == 0,
                                        }"
                                        @click.prevent="props.model.questionsCount == 0 ? '' : $refs['createActivityFromTemplateComponent'].create(props.model)"
                                    >
                                        <i class="material-icons marginRight8" aria-hidden="true">quiz</i>
                                        Use
                                    </button>
                                </li>

                                <li>
                                    <button
                                        v-tooltip="props.model.questionsCount == 0 ? 'No question in the Template' : ''"
                                        class="buttonRole primary-state"
                                        :class="{
                                            'disabled hasToolTip': props.model.questionsCount == 0,
                                        }"
                                        @click.prevent="props.model.questionsCount == 0 ? '' : $refs['copyTemplateComponent'].copy(props.model)"
                                    >
                                        <i class="fa fa-files-o" aria-hidden="true" />
                                        Make a Copy
                                    </button>
                                </li>

                                <li>
                                    <button
                                        v-tooltip="props.model.questionsCount == 0 ? 'No question in the Template' : ''"
                                        class="buttonRole primary-state"
                                        :class="{
                                            'disabled hasToolTip': props.model.questionsCount == 0,
                                        }"
                                        @click.prevent="props.model.questionsCount == 0 ? '' : $refs['shareTemplateComponent'].share(props.model)"
                                    >
                                        <i class="material-symbols-outlined" aria-hidden="true"> link </i>
                                        Get Sharable Link
                                    </button>
                                </li>

                                <hr />

                                <li>
                                    <button
                                        v-tooltip="props.model.defaultTemplate ? 'Default templates can’t be delete ' : ''"
                                        class="buttonRole danger-state"
                                        :class="{
                                            'disabled hasToolTip': props.model.defaultTemplate,
                                        }"
                                        @click.prevent="$refs['deleteComponent'].deleteTemplate(props.model)"
                                    >
                                        <i class="fa fa-trash marginRight8" aria-hidden="true"></i> Delete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </td>
            </template>
        </kr-search>
    </div>
    <DeleteComponent ref="deleteComponent" @deleted="$refs['templates_listing'].getList()"></DeleteComponent>
    <CreateFromTemplateComponent ref="createActivityFromTemplateComponent"></CreateFromTemplateComponent>
    <CopyTemplateComponent ref="copyTemplateComponent" @copied="$refs['templates_listing'].getList()"></CopyTemplateComponent>
    <ShareTemplateComponent ref="shareTemplateComponent"></ShareTemplateComponent>
    <ImportTemplateComponent ref="importTemplateComponent" @imported="$refs['templates_listing'].getList()"></ImportTemplateComponent>
</template>

<script>
import { notify } from '@kyvg/vue3-notification';
import KrAuth from '../../../../components/auth/auth';
import DeleteComponent from './actions/delete.vue';
import CreateFromTemplateComponent from './actions/createActivityFromTemplate.vue';
import CopyTemplateComponent from './actions/copy.vue';
import ShareTemplateComponent from './actions/share.vue';
import ImportTemplateComponent from './actions/import.vue';
export default {
    components: {
        DeleteComponent,
        CreateFromTemplateComponent,
        CopyTemplateComponent,
        ShareTemplateComponent,
        ImportTemplateComponent,
    },
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            columns: {},
            options: {},
            default_query_fields: { sort: 'id', order: 'desc' },
            query_fields: { activity_type: '', template_type: 'all' },
            previewTemplate: {},
            searchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                q: '',
            },
        };
    },
    created() {
        var that = this;
        this.columns = {
            title: 'display:Title|src:name|sortable:true|width:50%|minWidth:230px|mobile_width:50%|mobile_minWidth:230px',
            description: 'display:Description|src:description|sortable:false|width:50%|minWidth:230px|mobile_width:50%|mobile_minWidth:230px',
            activityType:
                'display:Activity Type|src:activityType|sortable:false|width:170px|maxWidth:170px|minWidth:170px|mobile_width:170px|mobile_maxWidth:170px|mobile_minWidth:170px',
            owner: 'display:Owner|sortable:false|width:170px|maxWidth:170px|minWidth:170px|mobile_width:170px|mobile_maxWidth:170px|mobile_minWidth:170px',
            questionsCount:
                'display:Question|sortable:false|width:102px|maxWidth:102px|minWidth:102px|mobile_width:102px|mobile_maxWidth:102px|mobile_minWidth:102px',
            action: 'display:Actions|sortable:false|width:132px',
        };
        this.options = {
            query_fields: this.query_fields,
            action_column: false,
            newServerMode: {
                includes: ['sort', 'order', 'name', 'q', 'activity_type', 'template_type'],
            },
        };
    },
    mounted() {
        if (this.$route.name == 'templates.share') {
            if (this.$route.params.id && this.$route.query.cluster) {
                this.$refs['importTemplateComponent'].importTemplate(this.$route.params.id, this.$route.query.cluster);
            }
        }
        this.handleTableScroll();

        window.addEventListener('resize', this.resizeTable);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.resizeTable);
    },
    methods: {
        getSearchData(data) {
            this.searchData = data;
        },
        highlight(text) {
            if (this.searchData.query.q != '') {
                if (text != null) {
                    return text.replace(new RegExp(this.searchData.query.q, 'gi'), '<span class="table-keyword-highlight">$&</span>');
                }
            } else {
                return text;
            }
        },
        toggleDropdown(event) {
            const dropdown = event.currentTarget.closest('.dropdown');
            if (!dropdown.classList.contains('open')) {
                const btnDropDown = dropdown.querySelector('.dropdown-toggle');
                dropdown.style.position = 'static';
                const listHolder = dropdown.querySelector('.dropdown-menu');
                const buttonRect = btnDropDown.getBoundingClientRect();
                const tableResponsive = document.querySelector('.table-responsive').getBoundingClientRect();
                setTimeout(() => {
                    listHolder.style.top = buttonRect.top - tableResponsive.top - listHolder.clientHeight - 20 + 'px';
                    if (this.isMobileView) {
                        listHolder.style.left = buttonRect.left - 55 - 52 + 'px';
                    } else {
                        listHolder.style.left = buttonRect.left - 165 - 52 + 'px';
                    }
                    listHolder.dataset.open = true;
                }, 0);
            }
        },
        handleTableScroll() {
            $('.table-responsive').scroll(function () {
                const listHolder = document.querySelectorAll('.dropdown-menu');

                const $lastColumn = $(this).find('table tr:first-child td:last-child');
                const scrollAmount = $lastColumn.offset().left - $(this).width();

                listHolder.forEach((item) => {
                    if (item.dataset.open) {
                        const btnDropDown = item.parentElement.querySelector('.dropdown-toggle');
                        const buttonRect = btnDropDown.getBoundingClientRect();
                        const tableResponsive = document.querySelector('.table-responsive').getBoundingClientRect();

                        item.style.top = buttonRect.top - tableResponsive.top - listHolder.clientHeight - 20 + 'px';
                        item.style.left = buttonRect.left + 'px';
                        if (window.innerWidth <= 992) {
                            item.style.left = buttonRect.left - 20 - 52 + 'px';
                        } else {
                            item.style.left = buttonRect.left - 130 - 52 + 'px';
                        }

                        if (scrollAmount <= 0) {
                            item.parentElement.style.position = 'static';
                        } else {
                            item.parentElement.style.position = 'relative';
                        }
                    }
                });
            });
        },
        resizeTable() {
            const listHolder = document.querySelectorAll('.dropdown-menu');

            listHolder.forEach((item) => {
                if (item.dataset.open) {
                    const btnDropDown = item.parentElement.querySelector('.dropdown-toggle');
                    const buttonRect = btnDropDown.getBoundingClientRect();
                    const tableResponsive = document.querySelector('.table-responsive').getBoundingClientRect();

                    item.style.top = buttonRect.top - tableResponsive.top - listHolder.clientHeight - 20 + 'px';
                    item.style.left = buttonRect.left + 'px';
                    if (window.innerWidth <= 992) {
                        item.style.left = buttonRect.left - 20 - 52 + 'px';
                    } else {
                        item.style.left = buttonRect.left - 130 - 52 + 'px';
                    }
                }
            });
        },
    },
};
</script>
